import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './TemplateOptions.scss'
import { Data, Table, DataList, EquipmentDocument } from '../BodyComponents'
import {
  bodyTemplate,
  templateUpdateError
} from 'routes/Template/modules/template'
import {
  formatProComponentNames,
  formatBenchmarkFields,
  formatUtilityFields,
  formatLocationFields,
  formatOverviewFields,
  formatEndUseBreakDownFields,
  formatCamelCaseNotation,
  formatChartFields
} from 'utils/Utils'
import { find } from 'lodash'
import ChartContainer from '../BodyComponents/ChartContainer'
import UserFeature from 'utils/Feature/UserFeature'
import { CHART_TYPES } from 'static/charts'

export class Body extends Component {
  static propTypes = {
    closeAllOptions: PropTypes.bool.isRequired,
    bodyTemplate: PropTypes.func.isRequired,
    templateUpdateError: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    removeWidget: PropTypes.func.isRequired,
    setCloseAllOptions: PropTypes.func.isRequired,
    body: PropTypes.array,
    widget: PropTypes.object.isRequired,
    getChartReports: PropTypes.func,
    views: PropTypes.array,
    disableDraggable: PropTypes.bool
  }

  static defaultProps = {
    disableDraggable: false
  }

  state = {
    optionsVisible: false,
    deleteToggleOpen: false,
    preview: {},
    isFocus: false
  }

  componentDidUpdate = prevProps => {
    const { index, body } = this.props
    let fields
    let mField
    let temp
    if (prevProps !== this.props && body?.[index]) {
      if (body[index].type === 'equipment') return
      if (body[index].type === 'chart') {
        let updatedPreview = { ...this.state.preview }
        if (body[index].target) {
          updatedPreview.target = body[index].target
        }
        const options = body[index].options || []
        updatedPreview.mainField = ''
        updatedPreview.fields = options
        updatedPreview.customLabels = []

        this.setState({ preview: updatedPreview })
      } else {
        let updatedPreview = { ...this.state.preview }
        if (body[index].target) {
          updatedPreview.target = body[index].target
        }
        if (body[index].fields?.length > 0) {
          if (
            body[index].fields[0].substring(
              0,
              body[index].fields[0].indexOf('.')
            ) == 'chart' ||
            body[index].fields[0].substring(
              0,
              body[index].fields[0].indexOf('.')
            ) == 'year'
          ) {
            updatedPreview.mainField = ''
          } else {
            temp = body[index].fields[0].substring(
              0,
              body[index].fields[0].indexOf('.')
            )
            mField = formatCamelCaseNotation(temp)
            fields = body[index].fields
            fields.map((each, i) => {
              let tem = formatCamelCaseNotation(
                each.substring(0, each.indexOf('.'))
              )
              if (!mField.includes(tem)) {
                mField = mField + `,${tem}`
              }
            })
            updatedPreview.mainField = mField
          }
          updatedPreview.general = body[index].general
          updatedPreview.fields = body[index].fields
          updatedPreview.configs = body[index].configs
          updatedPreview.maintenances = body[index].maintenances
          updatedPreview.customLabels = body[index].customLabels || []
        } else {
          updatedPreview.mainField = ''
          updatedPreview.general = []
          updatedPreview.fields = []
          updatedPreview.configs = []
          updatedPreview.maintenances = []
          updatedPreview.customLabels = body[index].customLabels || []
        }
        if (body[index].metaData) updatedPreview.metaData = body[index].metaData
        this.setState({ preview: updatedPreview })
      }
    }
  }

  componentDidMount = () => {
    const preview = this.handleInitialPreview()
    this.setState({ preview })
  }

  componentWillUnmount = () => {
    this.props.setCloseAllOptions(true)
  }

  handleInitialPreview = () => {
    const { body, index } = this.props
    const card = body[index]
    if (card && ['equipment', 'equipmentBlock'].includes(card.target)) {
      return this.handleEquipmentPreview(card)
    } else if (card && ['charts'].includes(card.target)) {
      return this.handleChartPreview(card)
    } else {
      return {
        target: (card && card.target) || '',
        label: '',
        mainField: '',
        fields: (card && card.fields) || [],
        customLabels: (card && card.customLabels) || [],
        formatType: ''
      }
    }
  }

  handleEquipmentPreview = card => {
    const equipmentConfig = card.equipmentConfig || {}
    const preview = equipmentConfig && equipmentConfig.preview
    const label =
      preview &&
      `Equipment - ${[preview.category, preview.application, preview.technology]
        .filter(displayName => displayName && displayName.length)
        .join(' / ') ||
        preview.targetName ||
        ''}`
    const defaultLabel =
      (card.applicationType && `Equipment - ${card.applicationType}`) ||
      'Equipment'
    return {
      ...this.state.preview,
      label: label || defaultLabel,
      general: Array.from(equipmentConfig.general || []),
      fields: Array.from(equipmentConfig.fields || []),
      configs: Array.from(equipmentConfig.configs || []),
      maintenances: Array.from(equipmentConfig.maintenances || []),
      order: Array.from(equipmentConfig.order || []),
      formatType: equipmentConfig.format || '',
      target: card.target || ''
    }
  }

  handleChartPreview = card => {
    return {
      ...this.state.preview,
      label: `Chart ${card.layoutOption ? `| ${card.layoutOption}` : ''}`,
      mainField: '',
      fields: card.options || [],
      target: card.target || ''
    }
  }

  handleClick = () => {
    if (this.node !== null) {
      this.props.setCloseAllOptions(false)
      if (!this.state.optionsVisible) {
        document.addEventListener('click', this.handleOutsideClick, false)
      } else {
        document.removeEventListener('click', this.handleOutsideClick, false)
      }
      this.setState(prevState => ({
        optionsVisible: !prevState.optionsVisible
      }))
    }
  }

  handleOutsideClick = e => {
    const icon = e.target.closest('i')
    if (this.node !== null) {
      this.setState({ isFocus: this.node.contains(e.target) })
    }
    if (
      (this.node !== null && this.node.contains(e.target)) ||
      icon === e.target
    ) {
      return
    }
    this.handleClick()
  }

  handleRemoveToggle = toggle => {
    if (toggle) {
      this.setState({ deleteToggleOpen: !this.state.deleteToggleOpen })
    } else {
      this.setState({ deleteToggleOpen: false })
    }
  }

  handleClickRemoveWidget = () => {
    this.setState({ deleteToggleOpen: false })
    let body = JSON.parse(JSON.stringify(this.props.body))
    body.splice(this.props.index, 1)
    this.props.bodyTemplate(body)
    this.props.removeWidget(this.props.index)
    this.props.templateUpdateError()
  }

  handleClickSelectTarget = event => {
    let body = JSON.parse(JSON.stringify(this.props.body))
    body[this.props.index].target = event.target.value
    body[this.props.index].fields = []
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }

  handleEquipmentUpdated = body => {
    const { index } = this.props
    const card = body[index]
    this.setState({
      preview: this.handleEquipmentPreview(card)
    })
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }

  handleChartUpdated = body => {
    const { index } = this.props
    const card = body[index]
    this.setState({
      preview: this.handleChartPreview(card)
    })
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }

  renderDynamicIconClass = () => {
    return this.props.widget.icon
  }

  renderControlOptions = () => {
    return (
      <div
        className={classNames(
          styles['content-options'],
          (!this.props.closeAllOptions && this.state.optionsVisible) ||
            this.state.deleteToggleOpen
            ? styles['optionsOpen']
            : ''
        )}
      >
        <i className={classNames('material-icons', styles['move'])}>gamepad</i>
        <i
          onClick={() => {
            this.handleClick()
          }}
          data-test={`editor-body-edit-${this.props.widget.text.toLowerCase()}-icon`}
          className={classNames(
            'material-icons',
            !this.props.closeAllOptions && this.state.optionsVisible
              ? styles['editing']
              : ''
          )}
        >
          edit
        </i>
        <i
          onClick={() => {
            this.handleRemoveToggle(true)
          }}
          className='material-icons'
        >
          delete
        </i>
        {this.state.deleteToggleOpen && (
          <div className={styles['content-options__delete']}>
            <p>Delete this widget?</p>
            <div className={styles['content-options__delete-confirm']}>
              <div
                className={classNames(styles['content-options__btn-delete'])}
                onClick={() => this.handleClickRemoveWidget()}
              >
                Yes
              </div>
              <div
                className={classNames(styles['content-options__btn-cancel'])}
                onClick={() => this.handleRemoveToggle(false)}
              >
                No
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  renderTargetOptions = () => {
    const { widget, index, body } = this.props
    let fieldList = body[index].fields || []
    let configList = body[index].configs || []
    switch (widget.text) {
      case 'Data':
        return (
          <div className={styles['editor-body__select']}>
            <span>Choose your data source:</span>
            <div className={styles.selectContainer}>
              <select
                value={body[index].target ? body[index].target : 'select'}
                onChange={this.handleClickSelectTarget}
              >
                <option disabled value='select'>
                  Select Data
                </option>
                <option value='overview'>Overview</option>
                <option value='building'>Property</option>
                <option value='user'>User</option>
                <option value='utility'>Utilities</option>
                <option value='endusebreakdown'>End Use Breakdown</option>
              </select>
            </div>
          </div>
        )
      case 'Table':
        return (
          <div className={styles['editor-body__select']}>
            <span>Choose your data source:</span>
            <div className={styles.selectContainer}>
              <select
                value={body[index].target ? body[index].target : 'select'}
                onChange={this.handleClickSelectTarget}
              >
                <option disabled value='select'>
                  Select Data
                </option>
                <option value='overview'>Overview</option>
                <option value='endusebreakdown'>End Use Breakdown</option>
                <option value='utility'>Utilities</option>
                <option value='operation'>Operation</option>
                <UserFeature name='buildingConstruction'>
                  {({ enabled }) => {
                    if (!enabled) return null
                    return <option value='construction'>Construction</option>
                  }}
                </UserFeature>
                <option value='location'>Locations</option>
                <option value='contact'>Contact</option>
              </select>
            </div>
          </div>
        )
      case 'Equipment':
        return (
          ((body[index].target = 'equipmentBlock'),
          (body[index].fields = fieldList || []),
          (body[index].configs = configList || [])),
          null
        )
      case 'Chart':
        return (
          (body[index].target = 'charts'),
          (body[index].fields = fieldList || []),
          null
        )
      case 'Data List':
        return (
          <div className={styles['editor-body__select']}>
            <span>Please select where you'd like your data to come from:</span>
            <div className={styles.selectContainer}>
              <select
                value={body[index].target ? body[index].target : 'select'}
                onChange={this.handleClickSelectTarget}
              >
                <option disabled value='select'>
                  Select Data
                </option>
                <option value='overview'>Overview</option>
                <option value='utility'>Utilities</option>
                <option value='endusebreakdown'>End Use Breakdown</option>
              </select>
            </div>
          </div>
        )
    }
  }

  renderBodyComponents = () => {
    const { index } = this.props
    switch (this.props.widget.text) {
      case 'Data':
        return <Data index={index} target={this.state.preview.target} />
      case 'Table':
        return <Table index={index} target={this.state.preview.target} />
      case 'Equipment':
        return (
          <EquipmentDocument
            index={index}
            handleUpdateTemplateState={this.handleEquipmentUpdated}
          />
        )
      case 'Chart':
        return (
          <ChartContainer
            index={index}
            handleUpdateTemplateState={this.handleChartUpdated}
          />
        )
      case 'Data List':
        return <DataList index={index} target={this.state.preview.target} />
    }
  }

  handlePreviewName = previewName => {
    switch (previewName) {
      case 'audit':
        return 'buildee Pro'
      case 'building':
        return 'Property'
      case 'utility':
        return 'Utilities'
      case 'overview':
        return 'Overview'
      case 'endusebreakdown':
        return 'End Use Breakdown'
      case 'operation':
        return 'Operation'
      case 'construction':
        return 'Construction'
      case 'location':
        return 'Locations'
      case 'charts':
        return this.state.preview.label
      case 'equipmentBlock':
        return this.state.preview.label
      case 'contact':
        return 'Contact'
      default:
        return previewName
    }
  }

  renderTimePeriod = metaData => {
    const { preview } = this.state
    const left = preview && preview.fields && preview.fields.length ? ' |' : ''
    if (
      (preview.target != 'overview' &&
        preview.target != 'endusebreakdown' &&
        preview.target != 'utility' &&
        preview.target != 'charts') ||
      !metaData ||
      (metaData && metaData.yearOption === 'SetOnExport')
    ) {
      return <span />
    }

    if (
      metaData.yearRange === '12' ||
      metaData.yearRange === 'Last 12 months'
    ) {
      return <span>{left}Time Period - Last 12 months</span>
    }
    if (
      metaData.yearRange === '24' ||
      metaData.yearRange === 'Last 24 months'
    ) {
      return <span>{left}Time Period - Last 24 months</span>
    }
    if (
      metaData.yearRange === '36' ||
      metaData.yearRange === 'Last 36 months'
    ) {
      return <span>{left}Time Period - Last 36 months</span>
    }
    if (metaData.yearRange === 'Custom') {
      return (
        <span>
          {left}
          {`Time Period - From ${metaData.selectedStartMonth}/${metaData.selectedStartYear} to ${metaData.selectedEndMonth}/${metaData.selectedEndYear}`}
        </span>
      )
    }
    return (
      <span>
        {left}Time Period - {metaData.yearRange}
      </span>
    )
  }

  allChartsAreEmpty = () => {
    const { body = {}, index } = this.props
    const options = body[index]?.options || []
    return options.every(option => {
      return !(
        (option.chartSettings.type === CHART_TYPES.Breakdown &&
          option.chartSettings.wedges) ||
        (option.chartSettings.type === CHART_TYPES.Savings &&
          option.chartSettings.metric) ||
        (option.chartSettings.type === CHART_TYPES.Trend &&
          option.chartSettings.yAxisPrimary)
      )
    })
  }

  widgetOptionsAreEmpty = () => {
    const target = this.state.preview?.target
    if (target === 'charts') {
      const { body = {}, index } = this.props
      return !body[index]?.layoutOption
    } else return !target
  }

  render() {
    const { closeAllOptions, index, body, widget } = this.props
    const { isFocus } = this.state
    const previewFields = [
      ...(this.state.preview?.general || []),
      ...(this.state.preview?.fields || []),
      ...(this.state.preview?.configs || []),
      ...(this.state.preview?.maintenances || [])
    ]
    return (
      <div
        className={styles['editor-body']}
        data-test={`editor-body-${widget.text.toLowerCase()}-element`}
        style={{ zIndex: `${isFocus ? '20' : '10'}` }}
      >
        <div className={styles['editor-body__info']}>
          <i className='material-icons'>{this.renderDynamicIconClass()}</i>
          <span>{widget.text}</span>
          {this.state.preview.target === 'equipmentBlock' &&
            this.state.preview.formatType.includes('table') &&
            previewFields.length && (
              <div className={styles['editor-body__warning']}>
                <i className='material-icons warning'>warning</i>
                No fields selected
              </div>
            )}
          {this.state.preview.target !== 'equipmentBlock' &&
            this.state.preview.target !== 'charts' &&
            this.state.preview.fields &&
            this.state.preview.fields.length === 0 && (
              <div className={styles['editor-body__warning']}>
                <i className='material-icons warning'>warning</i>
                No fields selected
              </div>
            )}
          {this.state.preview.target === 'charts' && this.allChartsAreEmpty() && (
            <div className={styles['editor-body__warning']}>
              <i className='material-icons warning'>warning</i>
              No charts selected
            </div>
          )}
        </div>

        {this.renderControlOptions()}

        <div
          className={classNames(styles['editor-body__options'])}
          ref={node => {
            this.node = node
          }}
          onDragStart={event => {
            if (this.props.disableDraggable) {
              event.preventDefault()
              event.stopPropagation()
            }
          }}
          draggable
        >
          <div className={classNames(styles['editor-body__preview'])}>
            <p>
              {this.widgetOptionsAreEmpty() ? (
                <i>
                  Please click the pencil icon to select {widget.text} options
                </i>
              ) : (
                this.handlePreviewName(this.state.preview.target)
              )}
              {this.state.preview?.mainField && (
                <span>
                  {' '}
                  - {formatProComponentNames(this.state.preview.mainField)}
                </span>
              )}
            </p>
            <p>
              {previewFields.length > 0 && (
                <span>
                  {previewFields.map((field, i) => {
                    let display = ''
                    switch (this.state.preview.target) {
                      case 'charts':
                        display = formatChartFields(field)
                        break
                      case 'utility':
                        display = formatUtilityFields(field)
                        break
                      case 'overview':
                        display = formatOverviewFields(field)
                        break
                      case 'location':
                        display = formatLocationFields(field)
                        break
                      case 'endusebreakdown':
                        display = formatEndUseBreakDownFields(field)
                        break
                      default: {
                        let ret = []
                        let tmp
                        field.split('.').map(ele => {
                          tmp = ele.replace(/([A-Z])/g, ' $1').toLowerCase()
                          ret.push(tmp.charAt(0).toUpperCase() + tmp.slice(1))
                        })
                        display = ret[ret.length - 1]
                      }
                    }
                    if (this.state.preview.customLabels) {
                      const customLabel = find(
                        this.state.preview.customLabels,
                        { field }
                      )
                      const options = display.split(' – ')
                      if (customLabel) {
                        if (options.length >= 2) {
                          display = options[0] + ' – ' + customLabel.value
                        } else {
                          display = customLabel.value
                        }
                      }
                    }
                    if (previewFields.length === i + 1) {
                      return (
                        <span key={i}>
                          {display === 'Open247' ? 'Open 24/7' : display}
                        </span>
                      )
                    } else {
                      return (
                        <span key={i}>
                          {display === 'Open247' ? 'Open 24/7' : display} |{' '}
                        </span>
                      )
                    }
                  })}
                </span>
              )}
              {this.state.preview.fields?.length > 0 &&
                this.renderTimePeriod(this.state.preview.metaData)}
            </p>
          </div>
          {!closeAllOptions && this.state.optionsVisible && (
            <div className={styles['editor-body__inner']}>
              {this.renderTargetOptions()}
              {body[index].target && this.renderBodyComponents()}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  bodyTemplate,
  templateUpdateError
}

const mapStateToProps = state => ({
  body: state.template.templateViewBody || []
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default withConnect(Body)
