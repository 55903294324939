import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import {
  bodyTemplate,
  templateUpdateError
} from 'routes/Template/modules/template'
import { validateDescription, formatCamelCaseNotation } from 'utils/Utils'
import styles from './Projects.scss'
import { FILTERS, GROUPING } from 'static/dictionaries/project'

function ProjectOrdering({ index, filteringFields = {} }) {
  const templateViewBody =
    useSelector(({ template }) => template.templateViewBody) || []
  const dispatch = useDispatch()

  const changeFilterOptionOrder = (e, name, filter) => {
    const newBody = JSON.parse(JSON.stringify(templateViewBody))
    const projectConfig = newBody?.[index]?.projectConfig
    const option = projectConfig?.filter?.[filter]?.find(
      option => option?.name === name
    )
    const targetValue = parseInt(e.target.value)
    const secondOption = projectConfig.filter[filter].find(
      option => option?.order === targetValue
    )
    if (secondOption) {
      secondOption.order = option.order
    }
    if (option) {
      option.order = targetValue
    }
    dispatch(bodyTemplate(newBody))
    dispatch(templateUpdateError())
  }

  const changeFilterOptionDescription = (e, name, filter) => {
    if (validateDescription(e.target.value)) {
      const newBody = JSON.parse(JSON.stringify(templateViewBody))
      const projectConfig = newBody?.[index]?.projectConfig
      const option = projectConfig?.filter?.[filter]?.find(
        option => option?.name === name
      )
      if (option) {
        option.description = e.target.value
      }
      dispatch(bodyTemplate(newBody))
      dispatch(templateUpdateError())
    }
  }

  const compareOrder = (a, b) => {
    const orderA = parseInt(a.order)
    const orderB = parseInt(b.order)
    return orderA > orderB ? 1 : -1
  }

  const projectConfig = templateViewBody?.[index]?.projectConfig

  return (
    <div className={styles.order}>
      {projectConfig?.filter &&
        Object.keys(projectConfig.filter)?.map(filter => {
          if (
            filter === FILTERS.Category &&
            projectConfig.filter[filter]?.length > 0
          ) {
            return (
              <div key={filter}>
                <div
                  className={classNames(
                    styles.orderDetails,
                    styles.orderHeadings
                  )}
                >
                  <div
                    className={classNames(
                      styles.orderNumber,
                      styles.categoryHeaderOrder
                    )}
                  >
                    Order
                  </div>
                  <div
                    className={classNames(
                      styles.orderDescription,
                      styles.contentHeader
                    )}
                  >
                    {formatCamelCaseNotation(filter)}
                  </div>
                </div>
                {projectConfig.filter[filter].sort(compareOrder).map(option => {
                  let filterField = filteringFields[filter]?.data?.find(
                    item => item === option.name
                  )
                  if (filterField === undefined) {
                    filterField = option.name
                  }
                  return (
                    <div key={option.name} className={styles.orderDetails}>
                      <div
                        className={classNames(
                          styles.orderNumber,
                          styles.selectContainer,
                          styles.categoryOrder
                        )}
                      >
                        <select
                          value={option.order}
                          onChange={e =>
                            changeFilterOptionOrder(e, option.name, filter)
                          }
                        >
                          {projectConfig.filter[filter]?.map(option => {
                            return (
                              <option key={option.name} value={option.order}>
                                {option.order}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                      <div className={styles.orderlabelContent}>
                        <p>{filterField}</p>
                      </div>
                      {filter === FILTERS.Category && (
                        <div>
                          Description:
                          <br />
                          <textarea
                            value={option.description}
                            onChange={e =>
                              changeFilterOptionDescription(
                                e,
                                option.name,
                                filter
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            )
          }
        })}
    </div>
  )
}

ProjectOrdering.propTypes = {
  index: PropTypes.number.isRequired,
  filteringFields: PropTypes.object.isRequired
}

export default ProjectOrdering
