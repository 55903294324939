import React from 'react'
import PropTypes from 'prop-types'
import styles from './ChartError.scss'
import { CHART_ERROR, chartErrorMessages } from 'static/charts'

function ChartError({ error }) {
  if (!error) return
  const title = chartErrorMessages?.[error]?.title || 'An Error Occurred'
  const description =
    chartErrorMessages?.[error]?.description || 'Please reload the page'
  return (
    <div className={styles.chartError}>
      <div className={styles.chartErrorTitle}>{title}</div>
      <div className={styles.chartErrorDescription}>{description}</div>
    </div>
  )
}

ChartError.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(CHART_ERROR)),
    PropTypes.bool
  ])
}

export default ChartError
