// ------------------------------------
// Constants
// ------------------------------------

export const TEMPLATE_GET_SURVEY = 'SURVEY_TEMPLATE/TEMPLATE_GET_SURVEY'

export const TEMPLATE_GET_SURVEY_SUCCESS =
  'SURVEY_TEMPLATE/TEMPLATE_GET_SURVEY_SUCCESS'
export const TEMPLATE_GET_SURVEY_FAIL =
  'SURVEY_TEMPLATE/TEMPLATE_GET_SURVEY_FAIL'

export const TEMPLATE_GET_BUILDING_SURVEY_TEMPLATE =
  'SURVEY_TEMPLATE/TEMPLATE_GET_BUILDING_SURVEY_TEMPLATE'
export const TEMPLATE_GET_BUILDING_SURVEY_TEMPLATE_SUCCESS =
  'SURVEY_TEMPLATE/TEMPLATE_GET_BUILDING_SURVEY_TEMPLATE_SUCCESS'
export const TEMPLATE_GET_BUILDING_SURVEY_TEMPLATE_FAIL =
  'SURVEY_TEMPLATE/TEMPLATE_GET_BUILDING_SURVEY_TEMPLATE_FAIL'

import ApiClient from 'utils/ApiClient'

import { showFlash as flash } from 'utils/Flash/modules/flash'

// ------------------------------------
// Actions
// ------------------------------------

//Get selected template from survey list page
export const getSurveyOrgTemplate = orgId => {
  return (dispatch, getState) => {
    dispatch({ type: TEMPLATE_GET_SURVEY })
    return new Promise((resolve, reject) => {
      const client = new ApiClient(dispatch, getState())
      client
        .get('/getSurvey/' + orgId)
        .then(response => {
          dispatch({
            type: TEMPLATE_GET_SURVEY_SUCCESS,
            data: response
          })
          resolve(response)
        })
        .catch(err => {
          dispatch({ type: TEMPLATE_GET_SURVEY_FAIL })
          dispatch(flash(err || 'Issues retrieving template', 'error'))
          reject(err)
        })
    })
  }
}

//Get building survey sessions list
export const getSurveySessionsByBuilding = _id => {
  return (dispatch, getState) => {
    dispatch({ type: TEMPLATE_GET_BUILDING_SURVEY_TEMPLATE })
    return new Promise((resolve, reject) => {
      const request = {
        _id
      }
      const client = new ApiClient(dispatch, getState())
      client
        .post('/getSurveySessionByBuilding', { data: request })
        .then(response => {
          dispatch({
            type: TEMPLATE_GET_BUILDING_SURVEY_TEMPLATE_SUCCESS,
            data: response
          })
          let resData = response.data
          let storedata = resData.map((session, i) => {
            session.userName = 'Survey Submission ' + (i + 1)
            return session
          })
          response.data = storedata
          resolve(response)
        })
        .catch(err => {
          dispatch({ type: TEMPLATE_GET_BUILDING_SURVEY_TEMPLATE_FAIL })
          dispatch(flash(err || 'Issues retrieving template', 'error'))
          reject(err)
        })
    })
  }
}

const handleGetSurvey = (state, action) => {
  return Object.assign({}, state, {
    surveyList: action.data.data
  })
}

const handleGetSurveySessions = (state, action) => {
  return Object.assign({}, state, {
    surveySessionList: action.data.data
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TEMPLATE_GET_SURVEY]: (state, action) => {
    return { ...state }
  },

  [TEMPLATE_GET_SURVEY_FAIL]: (state, action) => {
    return { ...state }
  },
  [TEMPLATE_GET_SURVEY_SUCCESS]: handleGetSurvey,
  [TEMPLATE_GET_BUILDING_SURVEY_TEMPLATE]: (state, action) => {
    return { ...state }
  },

  [TEMPLATE_GET_BUILDING_SURVEY_TEMPLATE_FAIL]: (state, action) => {
    return { ...state }
  },
  [TEMPLATE_GET_BUILDING_SURVEY_TEMPLATE_SUCCESS]: handleGetSurveySessions
}

// ------------------------------------
// Initial State
// ------------------------------------
const initialState = { surveyList: [], surveySessionList: [] }

// ------------------------------------
// Reducer
// ------------------------------------
export default function templateReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
