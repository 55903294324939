import moment from 'moment'
import _ from 'lodash'
import { monthOrder } from 'utils/Utils'
import { sortFunction } from 'utils/Portfolio'
import {
  UTILITY_TYPES,
  UNIT_TYPES,
  UNIT_DETAILS,
  convertValueFromUnitAToUnitB,
  getDefaultCommoditySettings
} from 'static/utility-units'
import { DEFAULT_TREND_PERIOD } from 'utils/constants'
import { MONTH_LIST } from 'static/settings-constants'

const defaultUtilityTypes = Object.keys(UNIT_DETAILS)

const getBestRangeForAllFuels = (
  array,
  initialIndex,
  maxValue = 0,
  savedIndexes = [],
  prevIndex = 11,
  nextIndex = 0
) => {
  const sumValue = array
    .slice(initialIndex - prevIndex, initialIndex + nextIndex)
    .reduce((a, b) => a + b, 0)
  if (sumValue > maxValue) {
    maxValue = sumValue
    savedIndexes = [initialIndex - prevIndex, initialIndex + nextIndex]
  }
  if (prevIndex > 0) {
    return getBestRangeForAllFuels(
      array,
      initialIndex,
      maxValue,
      savedIndexes,
      prevIndex - 1,
      nextIndex + 1
    )
  }
  return savedIndexes
}

export const calculateLastTwelveUtilitySummaries = (
  incomingMonthlyUtilities,
  building
) => {
  let monthlyUtilities = incomingMonthlyUtilities
  let finalObject = {}
  let lastTwelveMonthsUtility = []
  const trendPeriod =
    building?.baselineSettings?.trendPeriod || DEFAULT_TREND_PERIOD
  monthlyUtilities = sortFunction(monthlyUtilities, 'year').reverse()
  monthlyUtilities = monthlyUtilities.sort((a, b) =>
    a.year === b.year
      ? monthOrder[a.month] < monthOrder[b.month]
      : a.year < b.yeay
  )
  const endPos = moment()
    .subtract(1, 'months')
    .endOf('month')
  const startPos = moment()
    .subtract(12 * (trendPeriod?.span ?? 1), 'months')
    .startOf('month')

  if (trendPeriod.type === 'per-fuel') {
    const initialMonthUtilitiesPerFuelType = Object.values(
      UTILITY_TYPES
    ).reduce((acc, curr) => {
      acc[curr] = []
      return acc
    }, {})
    let monthUtilitiesPerFuelType = monthlyUtilities.reduce((accum, curr) => {
      Object.values(UTILITY_TYPES).forEach(ft => {
        if (curr[ft] && (curr[ft].totalCost > 0 || curr[ft].totalUsage > 0))
          accum[ft].push({
            building: curr.building,
            createdAt: curr.createdAt,
            date: curr.date,
            daysInPeriod: curr.daysInPeriod,
            updatedAt: curr.updatedAt,
            _id: curr._id,
            month: curr.month,
            year: curr.year,
            ...curr[ft]
          })
      })
      return accum
    }, initialMonthUtilitiesPerFuelType)

    Object.entries(monthUtilitiesPerFuelType).forEach(([key, value]) => {
      // This array is used to save 12 months of non consecutive data starting on the newest value
      const extraValueObject = []
      let firstValueDate = undefined
      const finalArray = value.reduce((accum, current, index) => {
        const monthDate = moment.utc(current.date)
        if (accum.length < 12 && monthDate.isBetween(startPos, endPos)) {
          if (accum.length === 0) {
            accum.push(current)
            extraValueObject.push(current)
            firstValueDate = moment.utc(current.date)
            return accum
          } else if (index + 1 === monthUtilitiesPerFuelType[key].length) {
            accum.push(current)
          } else {
            const prevMonthIsJan = accum[accum.length - 1].month === 'Jan'
            const nextMonthOrderIndex = prevMonthIsJan
              ? 11
              : monthOrder.indexOf(accum[accum.length - 1].month) - 1
            if (
              (prevMonthIsJan &&
                +current.year + 1 === +accum[accum.length - 1].year &&
                monthOrder.indexOf(current.month) === nextMonthOrderIndex) ||
              monthOrder.indexOf(current.month) === nextMonthOrderIndex
            ) {
              accum.push(current)
            } else {
              accum = [current]
            }
          }

          if (extraValueObject.length) {
            const monthDate = moment.utc(current.date)
            const difference = Math.abs(
              firstValueDate.diff(monthDate, 'months')
            )
            if (difference <= 12 && extraValueObject.length < 12) {
              extraValueObject.push(current)
            }
          }
        } else {
          if (extraValueObject.length) {
            const monthDate = moment.utc(current.date)
            const difference = Math.abs(
              firstValueDate.diff(monthDate, 'months')
            )
            if (difference <= 12 && extraValueObject.length < 12) {
              extraValueObject.push(current)
            }
          } else {
            extraValueObject.push(current)
            firstValueDate = moment.utc(current.date)
          }
        }
        return accum
      }, [])
      if (finalArray.length === 12) {
        finalObject[key] = finalArray
      } else if (extraValueObject.length) {
        finalObject[key] = extraValueObject
      }
    })

    lastTwelveMonthsUtility = Object.entries(finalObject).reduce(
      (acc, [key, values]) => {
        values.map(
          ({
            month,
            year,
            building,
            createdAt,
            date,
            daysInPeriod,
            updatedAt,
            _id,
            ...rest
          }) => {
            const totalCost =
              (key === 'electric' && rest.totalCost + rest.demandCost) ||
              rest.totalCost
            acc.push({
              month,
              year,
              building,
              createdAt,
              date,
              daysInPeriod,
              totalCost,
              totalEnergy: {
                value: rest.kbtu || 0,
                units: UNIT_TYPES.kBTU
              },
              totalEnergyCost: totalCost,
              updatedAt,
              _id,
              [key]: rest
            })
          }
        )
        return acc
      },
      []
    )
  } else {
    const monthlyUtilitiesPerMonth = monthlyUtilities.reduce((accum, curr) => {
      const monthDate = moment.utc(curr.date)
      if (monthDate.isBetween(startPos, endPos)) {
        Object.values(UTILITY_TYPES).forEach(ft => {
          if (curr[ft] && (curr[ft].totalCost > 0 || curr[ft].totalUsage > 0)) {
            const monthIndex = MONTH_LIST.findIndex(
              month => month === curr.month
            )
            if (!accum[`${curr.year}-${monthIndex + 1}`]) {
              accum[`${curr.year}-${monthIndex + 1}`] = {
                [ft]: {
                  building: curr.building,
                  createdAt: curr.createdAt,
                  date: curr.date,
                  daysInPeriod: curr.daysInPeriod,
                  updatedAt: curr.updatedAt,
                  _id: curr._id,
                  month: curr.month,
                  year: curr.year,
                  ...curr[ft]
                }
              }
            } else {
              accum[`${curr.year}-${monthIndex + 1}`][ft] = {
                building: curr.building,
                createdAt: curr.createdAt,
                date: curr.date,
                daysInPeriod: curr.daysInPeriod,
                updatedAt: curr.updatedAt,
                _id: curr._id,
                month: curr.month,
                year: curr.year,
                ...curr[ft]
              }
            }
          }
        })
      }
      return accum
    }, {})
    const monthlyUtilitiesPerMonthKeys = Object.keys(
      monthlyUtilitiesPerMonth
    ).sort(
      (a, b) =>
        a.split('-')[0] - b.split('-')[0] || a.split('-')[1] - b.split('-')[1]
    )

    const utilitiesCountPerMonth = monthlyUtilitiesPerMonthKeys.map(
      e => Object.keys(monthlyUtilitiesPerMonth[e]).length
    )
    if (utilitiesCountPerMonth.length) {
      const max = _.max(utilitiesCountPerMonth)
      const initialIndex = utilitiesCountPerMonth.indexOf(max)
      const [startIndex, endIndex] = getBestRangeForAllFuels(
        utilitiesCountPerMonth,
        initialIndex
      )
      lastTwelveMonthsUtility = Object.values(monthlyUtilitiesPerMonth).reduce(
        (acc, curr, index) => {
          if (startIndex <= index && index <= endIndex) {
            Object.values(Object.entries(curr)).forEach(
              ([
                key,
                {
                  month,
                  year,
                  building,
                  createdAt,
                  date,
                  daysInPeriod,
                  updatedAt,
                  _id,
                  ...rest
                }
              ]) => {
                const totalCost =
                  (key === 'electric' && rest.totalCost + rest.demandCost) ||
                  rest.totalCost
                acc.push({
                  month,
                  year,
                  building,
                  createdAt,
                  date,
                  daysInPeriod,
                  totalCost,
                  totalEnergy: {
                    value: rest.kbtu || 0,
                    units: UNIT_TYPES.kBTU
                  },
                  totalEnergyCost: totalCost,
                  updatedAt,
                  _id,
                  [key]: rest
                })
              }
            )
          }
          return acc
        },
        []
      )
    }
  }
  return lastTwelveMonthsUtility
}

const handleUpdateEmissions = (totalUtilGhg, utilityTypes) => {
  let totalEmissions = 0

  utilityTypes.forEach(utilityName => {
    totalEmissions += totalUtilGhg[utilityName] || 0
  })

  return totalEmissions || 0
}

export const calculateSummaryData = (
  monthlyUtilities,
  yearLength,
  utilityTypes,
  building
) => {
  const calculateIntensity = (value, squareFeet) =>
    squareFeet !== 0 ? value / squareFeet / (yearLength || 1) : 0
  // Postgres data come with squarefeet instead of squareFeet
  const squareFeet = building.squareFeet || building.squarefeet || 0

  if (yearLength > 0) {
    let totalUtilityCosts = 0
    let totalUtilityWaterCosts = 0
    let totalUtilUsages = {}
    let totalUtilCosts = {}
    let totalUtilGhg = {}
    let totalUtilIntensities = {}
    let totalEnergyUsage = 0
    let maxElectricityDemand = {
      usage: 0,
      cost: 0
    }

    monthlyUtilities.forEach(monthlyUtility => {
      totalEnergyUsage += monthlyUtility.totalEnergy?.value || 0
      totalUtilityCosts += monthlyUtility.totalCost || 0
      totalUtilityWaterCosts += monthlyUtility.water?.totalCost || 0
      utilityTypes.forEach(utilityName => {
        let data = monthlyUtility[utilityName] || {}
        if (totalUtilUsages[utilityName] === undefined) {
          totalUtilUsages[utilityName] = 0
        }
        if (totalUtilCosts[utilityName] === undefined) {
          totalUtilCosts[utilityName] = 0
        }
        if (totalUtilGhg[utilityName] === undefined) {
          totalUtilGhg[utilityName] = 0
        }
        const totalUsageUnit =
          utilityName === UTILITY_TYPES.WATER ? UNIT_TYPES.CCF : UNIT_TYPES.kBTU
        let totalUsage =
          data.kbtu ??
          (data.totalUsage
            ? convertValueFromUnitAToUnitB(
                data.totalUsage,
                utilityName,
                data.units,
                totalUsageUnit
              )
            : 0)
        let totalCost = data.totalCost || 0
        if (utilityName === UTILITY_TYPES.ELECTRICITY && data.demandCost) {
          totalCost += data.demandCost
        }
        let totalGhg = data.ghg || 0
        totalUtilUsages[utilityName] += totalUsage / yearLength
        totalUtilCosts[utilityName] += totalCost / yearLength
        totalUtilGhg[utilityName] += totalGhg / yearLength
      })
      if (monthlyUtility.electric?.demand > maxElectricityDemand.usage) {
        maxElectricityDemand.usage = monthlyUtility.electric?.demand
      }
      if (monthlyUtility.electric?.demandCost > maxElectricityDemand.cost) {
        maxElectricityDemand.cost = monthlyUtility.electric?.demandCost
      }
    })

    Object.keys(totalUtilUsages).forEach(utilKey => {
      totalUtilIntensities[utilKey] = totalUtilUsages[utilKey] / squareFeet || 0
    })

    const ghg = handleUpdateEmissions(totalUtilGhg, utilityTypes)
    const totalEnergyCost = totalUtilityCosts - totalUtilityWaterCosts

    const utilityMetrics = {
      totalCost: Math.round(totalUtilityCosts / yearLength),
      totalWaterCost: Math.round(totalUtilityWaterCosts / yearLength),
      totalEnergyCost: Math.round(totalEnergyCost / yearLength),
      totalEnergyCostIntensity: calculateIntensity(totalEnergyCost, squareFeet),
      costPerSqFoot:
        squareFeet !== 0
          ? totalUtilityCosts / squareFeet / (yearLength || 1)
          : 0,
      totalUtilUsages,
      totalUtilCosts,
      totalUtilGhg,
      totalEnergyUsage: totalEnergyUsage / yearLength,
      eui: calculateIntensity(totalEnergyUsage, squareFeet),
      ghgIntensity: calculateIntensity(ghg, squareFeet),
      ghg: ghg,
      maxElectricityDemand,
      totalUtilIntensities
    }

    return utilityMetrics
  }
  return {}
}

export const calculateSummaryDataWithLastTwelveUtilitySummaries = (
  monthlyUtilities,
  building,
  utilityTypes
) => {
  const selectedUtilityTypes = utilityTypes || defaultUtilityTypes
  return calculateSummaryData(
    monthlyUtilities,
    1,
    selectedUtilityTypes,
    building
  )
}

export const getCommoditySettings = (building, organization) => ({
  ...getDefaultCommoditySettings(),
  ...(organization?.settings?.commodity || {}),
  ...(building?.commoditySettings || {})
})
