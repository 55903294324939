import React, { useState } from 'react'
import styles from './ProjectContentItem.scss'
import { noop } from 'lodash'
import classNames from 'classnames'

const ProjectContentItem = ({
  isCollapsible,
  isRemovable,
  handleContentRemoved,
  renderHeaderSection = noop,
  renderCollapsibleSection = noop
}) => {
  const [isCollapsed, toggleCollapsible] = useState(true)
  return (
    <div className={styles.projectContent}>
      <div className={styles.projectContentHeaderSection}>
        <div
          className={styles.projectContentHeader}
          onClick={() => isCollapsible && toggleCollapsible(!isCollapsed)}
        >
          {renderHeaderSection()}
          {isCollapsible && (
            <div className={styles.projectContentCollapsible}>
              <i className='material-icons'>
                {isCollapsed ? 'arrow_drop_down' : 'arrow_drop_up'}
              </i>
            </div>
          )}
          {isRemovable && (
            <div
              className={styles.projectContentClose}
              onClick={handleContentRemoved}
            >
              <i className='material-icons'>close</i>
            </div>
          )}
        </div>
      </div>
      {isCollapsible && (
        <div
          className={classNames(
            styles.projectContentCollapsibleSection,
            isCollapsed && styles.hide
          )}
        >
          {renderCollapsibleSection()}
        </div>
      )}
    </div>
  )
}

export default ProjectContentItem
