import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { find, findIndex } from 'lodash'
import CollapseSection from 'components/UI/CollapseSection'
import { ProjectFiltering } from './'
import { formatProjectFields } from 'utils/Utils'
import {
  bodyTemplate,
  templateUpdateError
} from 'routes/Template/modules/template'
import styles from './Projects.scss'
import targetStyles from '../../TemplateComponent/BodyComponents/TemplateTarget.scss'
import {
  PROJECT_DATA_FIELDS,
  PROJECT_LAYOUT_FIELDS
} from 'static/measure-sections'

export class ProjectSummaryTable extends Component {
  static propTypes = {
    bodyTemplate: PropTypes.func.isRequired,
    templateUpdateError: PropTypes.func.isRequired,
    body: PropTypes.array.isRequired
  }

  saveRadioFields = (e, type) => {
    let body = JSON.parse(JSON.stringify(this.props.body))
    let card = body[this.props.index]
    card.projectConfig[type] = e.target.value
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }
  componentDidMount() {
    let body = JSON.parse(JSON.stringify(this.props.body))
    let widget = body[this.props.index]
    if (!widget.tableLayout) {
      widget.tableLayout = 'horizontal'
    }
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }

  saveCheckboxFields = e => {
    let body = JSON.parse(JSON.stringify(this.props.body))
    let card = body[this.props.index]
    // if it doesn't already exist
    if (!card.projectConfig.data) {
      card.projectConfig.data = {}
    }
    if (!card.projectConfig.data.fields) {
      card.projectConfig.data.fields = []
    }
    if (e.target.checked) {
      card.projectConfig.data.fields.push(e.target.value)
    } else {
      card.projectConfig.data.fields = card.projectConfig.data.fields.filter(
        item => item !== e.target.value
      )
      // if the unchecked box was the field they were ordering by
      if (e.target.value === card.projectConfig.data.orderBy) {
        card.projectConfig.data.orderBy = ''
      }
    }
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }

  saveSelectFields = (e, type) => {
    let body = JSON.parse(JSON.stringify(this.props.body))
    let card = body[this.props.index]
    // if it doesn't already exist
    if (!card.projectConfig.data[type]) {
      card.projectConfig.data[type] = ''
    }
    card.projectConfig.data[type] = e.target.value
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }

  saveHeading = (selectedField, value = '') => {
    let body = JSON.parse(JSON.stringify(this.props.body))
    let card = body[this.props.index]
    let customLabels =
      card &&
      card.projectConfig &&
      card.projectConfig.data &&
      card.projectConfig.data.customLabels
    if (!customLabels) customLabels = []
    const index = findIndex(customLabels, { field: selectedField.value })
    if (index > -1) customLabels.splice(index, 1)
    if (value) {
      customLabels.push({
        field: selectedField.value,
        value
      })
    }
    card.projectConfig.data.customLabels = customLabels
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }

  clearDataFields = () => {
    let body = JSON.parse(JSON.stringify(this.props.body))
    let card = body[this.props.index]
    card.projectConfig.data.fields = []
    card.projectConfig.data.customLabels = []
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }

  radioFieldsFormat = (array, type) => {
    const { index, body } = this.props
    const card = body[index]

    return array.map((item, i) => {
      let checkboxChecked = false
      if (
        type === 'projectGrouping' &&
        card &&
        card.projectConfig &&
        card.projectConfig.projectGrouping === item.value
      ) {
        checkboxChecked = true
      }
      if (
        type === 'style' &&
        card &&
        card.projectConfig &&
        card.projectConfig.style === item.value
      ) {
        checkboxChecked = true
      }
      return (
        <label key={i}>
          <input
            defaultChecked={checkboxChecked}
            value={item.value}
            name={'Projects ' + type}
            onChange={e => this.saveRadioFields(e, type)}
            className={classNames(checkboxChecked ? styles.checked : '')}
            type='radio'
          />
          <span>{item.name}</span>
        </label>
      )
    })
  }

  saveLayout(e) {
    let body = JSON.parse(JSON.stringify(this.props.body))
    let widget = body[this.props.index]

    if (!widget.tableLayout) {
      widget.tableLayout = e.target.value
    } else {
      widget.tableLayout = e.target.value
    }
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }
  tableLayout() {
    const layoutList = [
      { label: 'Horizontal', value: 'horizontal' },
      { label: 'Vertical', value: 'vertical' }
    ]
    let widget = this.props.body[this.props.index]
    let templateLayout = widget.tableLayout || 'horizontal'

    return (
      <label
        className={classNames(
          targetStyles['target__select'],
          targetStyles['target__table-type']
        )}
      >
        <span>Select table layout:</span>
        <div className={targetStyles.radioContainer}>
          {layoutList.map((item, i) => {
            return (
              <label key={i}>
                <input
                  type='radio'
                  name='layout'
                  value={item.value}
                  defaultChecked={templateLayout === item.value}
                  onChange={e => this.saveLayout(e)}
                />
                <span>{item.label}</span>
              </label>
            )
          })}
        </div>
      </label>
    )
  }

  selectFieldsFormat = (array, type) => {
    const { index, body } = this.props
    const card = body[index]
    let selectValue = 'select'
    if (
      card &&
      card.projectConfig &&
      card.projectConfig.data &&
      card.projectConfig.data[type]
    ) {
      selectValue = card.projectConfig.data[type]
    }
    return (
      <select
        value={selectValue}
        onChange={e => this.saveSelectFields(e, type)}
      >
        <option disabled value='select'>
          Select Order
        </option>
        {array.map((item, i) => {
          return (
            <option key={i} value={item.value}>
              {item.name}{' '}
            </option>
          )
        })}
      </select>
    )
  }

  selectTotalRowFormat = (array, type) => {
    const { index, body } = this.props
    const card = body[index]
    let selectValue = 'select'
    if (
      card &&
      card.projectConfig &&
      card.projectConfig.data &&
      card.projectConfig.data[type]
    ) {
      selectValue = card.projectConfig.data[type]
    }
    return (
      <select
        value={selectValue}
        onChange={e => this.saveSelectFields(e, type)}
      >
        <option disabled value='select'>
          Select Total Row Include Option
        </option>
        {array.map((item, i) => {
          return (
            <option key={i} value={item.value}>
              {item.name}{' '}
            </option>
          )
        })}
      </select>
    )
  }

  renderText = (projectConfig, customLabels = []) => {
    let fields =
      (projectConfig && projectConfig.data && projectConfig.data.fields) || []
    let textList = fields.map(item => {
      const customLabel =
        customLabels.length > 0 ? find(customLabels, { field: item }) : null
      if (customLabel) return customLabel.value
      let label = item.includes('.') ? item.split('.')[1] : item
      return formatProjectFields(label)
    })
    return textList.join(', ')
  }

  render() {
    const { index, body } = this.props

    const projectConfig = body[index].projectConfig

    const orderFields = [
      { name: 'A-Z (Low to high)', value: 'ascending' },
      { name: 'Z-A (High to low)', value: 'descending' }
    ]

    const totalRowFields = [
      { name: 'Include', value: 'include' },
      { name: 'Do not include', value: 'notInclude' }
    ]

    // get orderBy array from only selected data fields
    const orderByFields = PROJECT_DATA_FIELDS.filter(object => {
      if (projectConfig && projectConfig.data && projectConfig.data.fields) {
        return projectConfig.data.fields.some(item => {
          return object.value === item
        })
      }
    })

    const card = body[index]
    const customLabels =
      (card &&
        card.projectConfig &&
        card.projectConfig.data &&
        card.projectConfig.data.customLabels) ||
      []
    const selectedFields =
      (card &&
        card.projectConfig &&
        card.projectConfig.data &&
        card.projectConfig.data.fields) ||
      []

    return (
      <div>
        <h3>Measure Grouping</h3>
        <div className={styles.selectContainer}>
          <select
            value={(projectConfig && projectConfig.projectGrouping) || ''}
            onChange={e => this.saveRadioFields(e, 'projectGrouping')}
          >
            <option value='' defaultValue disabled>
              Select Type
            </option>
            {PROJECT_LAYOUT_FIELDS.map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </select>
        </div>

        <ProjectFiltering index={this.props.index} />
        <div>{this.tableLayout()}</div>

        <div className={styles.fieldSelector}>
          <div className={styles.fieldSelectorTitle}>Fields</div>
          <div className={styles.fieldSelectorDescription}>
            Select the fields for your table in the order they should appear.
          </div>
        </div>

        <div className={styles.fieldContainer}>
          <div className={styles.fieldContainerItem}>
            <div className={styles.fieldContainerTitle}>
              Order: {this.renderText(projectConfig, customLabels)}
            </div>
          </div>
          <div
            className={styles.fieldContainerClear}
            onClick={() => this.clearDataFields()}
          >
            Clear Fields
          </div>
        </div>

        <CollapseSection
          customLabels={customLabels}
          addField={this.saveCheckboxFields}
          saveHeading={this.saveHeading}
          fields={selectedFields}
        />

        <h3>Order By</h3>
        <div className={styles.selectContainer}>
          {this.selectFieldsFormat(orderByFields, 'orderBy')}
        </div>

        <h3>Order</h3>
        <div className={styles.selectContainer}>
          {this.selectFieldsFormat(orderFields, 'order')}
        </div>

        <h3>Total Row</h3>
        <div className={styles.selectContainer}>
          {this.selectTotalRowFormat(totalRowFields, 'totalRow')}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  bodyTemplate,
  templateUpdateError
}

const mapStateToProps = state => ({
  body: state.template.templateViewBody || []
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default withConnect(ProjectSummaryTable)
