import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './MultiLevelSelect.scss'
import Icon from '../Icon'

function MultiLevelSelect({
  id,
  value,
  valueToShow,
  options,
  onChange,
  label,
  placeholder = '',
  isButtonStyle,
  icon
}) {
  const [localValue, setLocalValue] = useState(value)
  const [firstLevelOpen, setFirstLevelOpen] = useState(false)
  const [secondLevelOpen, setSecondLevelOpen] = useState(false)
  const [thirdLevelOpen, setThirdLevelOpen] = useState(false)

  const closeMultiLevelSelect = () => {
    setFirstLevelOpen(false)
    setSecondLevelOpen(false)
    setThirdLevelOpen(false)
    setLocalValue(value)
  }

  const handleOpenFirstLevel = () => {
    if (firstLevelOpen) {
      setFirstLevelOpen(false)
      setSecondLevelOpen(false)
      setThirdLevelOpen(false)
    } else {
      if (value?.value !== localValue?.value) {
        setLocalValue(value)
      }
      setFirstLevelOpen(true)
    }
  }

  const handleOpenSecondLevel = (value, hasSubLevel) => {
    if (value?.value !== localValue?.value) {
      setLocalValue(value)
    }
    if (hasSubLevel) setSecondLevelOpen(true)
    else {
      onChange(value)
      setFirstLevelOpen(false)
    }
  }

  const handleOpenThirdLevel = (value, hasSubLevel) => {
    const newLocalValue = {
      ...localValue,
      options: value
    }
    if (value?.value !== localValue?.options?.value) {
      setLocalValue(newLocalValue)
    }
    if (hasSubLevel) setThirdLevelOpen(true)
    else {
      onChange(newLocalValue)
      setFirstLevelOpen(false)
      setSecondLevelOpen(false)
    }
  }

  const handleCloseThirdLevel = value => {
    const newLocalValue = {
      ...localValue,
      options: { ...localValue?.options, options: value }
    }
    if (value?.value !== localValue?.options?.options?.value) {
      setLocalValue(newLocalValue)
    }
    onChange(newLocalValue)
    setThirdLevelOpen(false)
    setSecondLevelOpen(false)
    setFirstLevelOpen(false)
  }

  return options ? (
    <div className={classNames(styles.multiLevelSelectContainer)}>
      {!isButtonStyle && <label htmlFor={id}>{label}</label>}
      <div
        id={id}
        className={classNames(
          isButtonStyle ? styles.buttonStyle : styles.multiLevelSelect
        )}
        onClick={handleOpenFirstLevel}
      >
        {isButtonStyle ? (
          <>
            {icon && <Icon materialIconCode={icon} />}
            {valueToShow || label}
          </>
        ) : (
          <>
            {icon && <Icon materialIconCode={icon} />}
            <span
              className={classNames({
                [styles['placeholder']]: !valueToShow
              })}
            >
              {valueToShow || placeholder}
            </span>
            <div
              className={classNames(styles.selectIcons, {
                [styles['selectIcons-opened']]: firstLevelOpen
              })}
            >
              <i className={classNames('material-icons', styles.selectArrow)}>
                arrow_drop_down
              </i>
            </div>
          </>
        )}

        {firstLevelOpen && (
          <>
            <ul className={styles.firstLevel}>
              {options.map((option, index) => {
                return (
                  <li
                    key={index}
                    onClick={e => {
                      e.stopPropagation()
                      handleOpenSecondLevel(
                        { label: option.label, value: option.value },
                        !!option?.options?.length
                      )
                    }}
                  >
                    <div
                      className={classNames(styles.option, {
                        [styles.active]: localValue?.value === option.value
                      })}
                    >
                      <div>{option.label}</div>
                      {option?.options ? (
                        <div className={classNames(styles.rightArrowContainer)}>
                          <i
                            className={classNames(
                              'material-icons',
                              styles.rightArrow
                            )}
                          >
                            arrow_right
                          </i>
                        </div>
                      ) : null}
                    </div>
                    {secondLevelOpen &&
                      localValue?.value === option.value &&
                      !!option?.options?.length && (
                        <ul className={styles.secondLevel}>
                          {option.options.map((option, index) => (
                            <li
                              key={index}
                              onClick={e => {
                                e.stopPropagation()
                                handleOpenThirdLevel(
                                  { label: option.label, value: option.value },
                                  !!option?.options?.length
                                )
                              }}
                            >
                              <div
                                className={classNames(styles.option, {
                                  [styles.active]:
                                    localValue?.options?.value === option.value
                                })}
                              >
                                <div>{option.label}</div>
                                {option?.options ? (
                                  <div
                                    className={classNames(
                                      styles.rightArrowContainer
                                    )}
                                  >
                                    <i
                                      className={classNames(
                                        'material-icons',
                                        styles.rightArrow
                                      )}
                                    >
                                      arrow_right
                                    </i>
                                  </div>
                                ) : null}
                              </div>
                              {thirdLevelOpen &&
                                localValue?.options?.value === option.value &&
                                !!option?.options?.length && (
                                  <ul className={styles.thirdLevel}>
                                    {option.options.map((option, index) => (
                                      <li
                                        key={index}
                                        onClick={e => {
                                          e.stopPropagation()
                                          handleCloseThirdLevel({
                                            label: option.label,
                                            value: option.value
                                          })
                                        }}
                                      >
                                        <div
                                          className={classNames(styles.option, {
                                            [styles.active]:
                                              localValue?.options?.options
                                                ?.value === option.value
                                          })}
                                        >
                                          <div>{option.label}</div>
                                          {option?.options ? (
                                            <div
                                              className={classNames(
                                                styles.rightArrowContainer
                                              )}
                                            >
                                              <i
                                                className={classNames(
                                                  'material-icons',
                                                  styles.rightArrow
                                                )}
                                              >
                                                arrow_right
                                              </i>
                                            </div>
                                          ) : null}
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                            </li>
                          ))}
                        </ul>
                      )}
                  </li>
                )
              })}
            </ul>
            <div
              className={classNames(styles.multiLevelSelectOverlay)}
              onClick={e => {
                closeMultiLevelSelect()
                e.stopPropagation()
              }}
            />
          </>
        )}
      </div>
    </div>
  ) : null
}

MultiLevelSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.object,
  valueToShow: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isButtonStyle: PropTypes.bool,
  icon: PropTypes.string
}

export default MultiLevelSelect
