import ApiClient from 'utils/ApiClient'
import { showFlash as flash } from 'utils/Flash/modules/flash'

// ------------------------------------
// Constants
// ------------------------------------
export const GET_SUPPLY_LIST = 'GET_SUPPLY_LIST'
export const GET_SUPPLY_LIST_SUCCESS = 'GET_SUPPLY_LIST_SUCCESS'
export const GET_SUPPLY_LIST_FAIL = 'GET_SUPPLY_LIST_FAIL'
export const SUPPLY_LIST_RESET = 'SUPPLY_LIST_RESET'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL'
export const PRODUCTS_RESET = 'PRODUCTS_RESET'
export const GET_SUPPLY_LIST_ENTRY = 'GET_SUPPLY_LIST_ENTRY'

// ------------------------------------
// Actions
// ------------------------------------
export const getSupplyListEntry = (supplyListEntryId, organizationId) => {
  return (dispatch, getState) => {
    dispatch({ type: GET_SUPPLY_LIST_ENTRY })
    return new Promise((resolve, reject) => {
      const client = new ApiClient(dispatch, getState())
      client
        .get(
          `/organization/${organizationId}/supply-list-entry/${supplyListEntryId}`
        )
        .then(response => {
          resolve(response.supplyListEntry)
        })
        .catch(err => {
          dispatch(flash(err || 'Issues retrieving product', 'error'))
          reject(err)
        })
    })
  }
}
export const getSupplyList = (supplyListId, organizationId) => {
  return (dispatch, getState) => {
    dispatch({ type: GET_SUPPLY_LIST })
    return new Promise((resolve, reject) => {
      const client = new ApiClient(dispatch, getState())
      client
        .get(`/organization/${organizationId}/supplyList/${supplyListId || ''}`)
        .then(response => {
          const supplyList = { [response.supplyList._id]: response.supplyList }
          dispatch({
            type: GET_SUPPLY_LIST_SUCCESS,
            supplyListId,
            supplyList
          })
          resolve(supplyList)
        })
        .catch(err => {
          dispatch({ type: GET_SUPPLY_LIST_FAIL })
          dispatch(flash(err || 'Issues retrieving supply list', 'error'))
          reject(err)
        })
    })
  }
}
export const getProducts = (
  supplyListId,
  organizationId,
  search,
  queries,
  page,
  take,
  sortBy,
  sortDirection,
  saveOnStore = true
) => {
  return (dispatch, getState) => {
    saveOnStore && dispatch({ type: GET_PRODUCTS })
    return new Promise((resolve, reject) => {
      const client = new ApiClient(dispatch, getState())
      client
        .put(
          `/organization/${organizationId}/supply-list/${supplyListId}/supply-list-entry/filter`,
          {
            data: { search, queries, page, take, sortBy, sortDirection }
          }
        )
        .then(
          ({ entries, count, currentPage, lastPage, nextPage, prevPage }) => {
            saveOnStore &&
              dispatch({
                type: GET_PRODUCTS_SUCCESS,
                supplyListId,
                entries,
                count,
                currentPage,
                lastPage,
                nextPage,
                prevPage
              })
            resolve(entries)
          }
        )
        .catch(err => {
          saveOnStore && dispatch({ type: GET_PRODUCTS_FAIL })
          dispatch(flash(err || 'Issues retrieving products', 'error'))
          reject(err)
        })
    })
  }
}

// ------------------------------------
// Action Helpers
// ------------------------------------
const handleGetSupplyList = state => {
  return Object.assign({}, state, {
    supplyLists: { ...state.supplyLists, isLoading: true }
  })
}
const handleGetSupplyListSuccess = (state, action) => {
  return Object.assign({}, state, {
    supplyLists: {
      data: {
        ...state.supplyLists.data,
        ...action.supplyList
      },
      isLoading: false,
      idle: false,
      error: false
    }
  })
}
const handleGetSupplyListFail = state => {
  return Object.assign({}, state, {
    supplyLists: { ...state.supplyLists, isLoading: false, error: true }
  })
}
const handleSupplyListReset = state => {
  return Object.assign({}, state, {
    supplyLists: initialState.supplyLists
  })
}

const handleGetProducts = state => {
  return Object.assign({}, state, {
    products: {
      ...state.products,
      isLoading: true
    }
  })
}
const handleGetProductsSuccess = (
  state,
  { supplyListId, entries, count, currentPage, lastPage, nextPage, prevPage }
) => {
  return Object.assign({}, state, {
    products: {
      data: {
        supplyListId,
        entries,
        count,
        currentPage,
        lastPage,
        nextPage,
        prevPage
      },
      isLoading: false,
      idle: false,
      error: false
    }
  })
}
const handleGetProductsFail = state => {
  return Object.assign({}, state, {
    products: {
      ...state.products,
      isLoading: false,
      error: true
    }
  })
}
const handleProductsReset = state => {
  return Object.assign({}, state, {
    supplyLists: initialState.products
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [GET_SUPPLY_LIST]: handleGetSupplyList,
  [GET_SUPPLY_LIST_SUCCESS]: handleGetSupplyListSuccess,
  [GET_SUPPLY_LIST_FAIL]: handleGetSupplyListFail,
  [SUPPLY_LIST_RESET]: handleSupplyListReset,
  [GET_PRODUCTS]: handleGetProducts,
  [GET_PRODUCTS_SUCCESS]: handleGetProductsSuccess,
  [GET_PRODUCTS_FAIL]: handleGetProductsFail,
  [PRODUCTS_RESET]: handleProductsReset
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  supplyLists: { data: {}, isLoading: false, idle: true, error: false },
  products: {
    data: {
      currentPage: 1
    },
    isLoading: false,
    idle: true,
    error: false
  }
}
export default function supplyListReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
