import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getChartPreview } from 'routes/Template/modules/template'
import { Loader } from 'utils/Loader'
import Chart, { FUSION_CHARTS_TYPE } from 'components/Chart'
import { CHART_SETTINGS, LEGEND_POSITION } from 'static/charts'
import styles from './ChartPreview.scss'
import CustomLegend from '../CustomLegend'
import classNames from 'classnames'

function ChartPreview({ index, chartSettings }) {
  const dispatch = useDispatch()
  const chartPreview = useSelector(state => state.template.chartPreview[index])

  useEffect(() => {
    if (
      chartSettings?.type &&
      (chartSettings?.[CHART_SETTINGS.Metric] ||
        chartSettings?.[CHART_SETTINGS.Wedges] ||
        chartSettings?.[CHART_SETTINGS.YAxisPrimary])
    ) {
      dispatch(getChartPreview(chartSettings, index))
    } else dispatch(getChartPreview(null, index))
  }, [chartSettings])

  const legendPosition =
    chartPreview?.fusionChart?.dataSource?.chart?.legendPosition

  const isDonutOrPieChart =
    chartPreview?.fusionChart?.type === FUSION_CHARTS_TYPE.Doughnut2D ||
    chartPreview?.fusionChart?.type === FUSION_CHARTS_TYPE.Pie2D

  const donutOrPieChartClasses = {
    [LEGEND_POSITION.Top]: styles.chartPreviewDonutOrPieTop,
    [LEGEND_POSITION.Bottom]: styles.chartPreviewDonutOrPieBottom,
    [LEGEND_POSITION.Left]: styles.chartPreviewDonutOrPieLeft,
    [LEGEND_POSITION.Right]: styles.chartPreviewDonutOrPieRight
  }

  const chartPreviewClass = classNames(
    styles.chartPreview,
    isDonutOrPieChart && styles.chartPreviewDonutOrPie,
    isDonutOrPieChart && donutOrPieChartClasses[legendPosition]
  )

  const chartWidth =
    isDonutOrPieChart &&
    (legendPosition === LEGEND_POSITION.Right ||
      legendPosition === LEGEND_POSITION.Left)
      ? '50%'
      : '100%'

  return chartPreview?.isLoading ? (
    <div className={styles.loaderSpinnerCentered}>
      <Loader />
    </div>
  ) : chartPreview?.fusionChart ? (
    <div className={chartPreviewClass}>
      <Chart
        type={chartPreview.fusionChart.type}
        height='400'
        width={chartWidth}
        dataSource={chartPreview.fusionChart.dataSource}
      />
      {isDonutOrPieChart && (
        <CustomLegend dataSource={chartPreview.fusionChart.dataSource} />
      )}
    </div>
  ) : null
}

ChartPreview.propTypes = {
  index: PropTypes.number.isRequired,
  chartSettings: PropTypes.object.isRequired
}

export default ChartPreview
