import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { SketchPicker } from 'react-color'
import { PortalWithState } from 'react-portal'

function ColorPickerWrapper({ color, onColorChange, children }) {
  const [{ top, left }, setPosition] = useState({ top: 0, left: 0 })
  const node = useRef(null)

  const handleChange = color => {
    onColorChange(color.hex)
  }

  const handleClick = (args, e) => {
    args.openPortal(e)
    if (node.current) {
      const element = node.current.getClientRects()[0]
      const top = element.top + window.scrollY + element.height
      const left = element.left + window.scrollX
      setPosition({ top, left })
    }
  }

  let colorValue = color ? color.substring(1) : ''
  return (
    <PortalWithState closeOnOutsideClick closeOnEsc>
      {args => {
        return (
          <>
            {React.cloneElement(children, {
              ref: node,
              value: colorValue,
              onClick: e => handleClick(args, e)
            })}
            {args.portal(
              <div
                id='portal'
                style={{ zIndex: '20', position: 'absolute', top, left }}
                onClick={e => e.stopPropagation()}
              >
                <SketchPicker
                  disableAlpha
                  color={colorValue}
                  onChangeComplete={handleChange}
                />
              </div>
            )}
          </>
        )
      }}
    </PortalWithState>
  )
}

ColorPickerWrapper.propTypes = {
  color: PropTypes.string.isRequired,
  onColorChange: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
}

export default ColorPickerWrapper
