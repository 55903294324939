import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Icon.scss'

function Icon({ icon = '', materialIconCode, className, ...props }) {
  return (
    <i
      className={classNames(
        materialIconCode && 'material-icons',
        styles[className]
      )}
      {...props}
    >
      {materialIconCode ?? icon}
    </i>
  )
}

Icon.propTypes = {
  icon: PropTypes.string,
  materialIconCode: PropTypes.string,
  className: PropTypes.string
}

export default Icon
