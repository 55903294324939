import React from 'react'
import PropTypes from 'prop-types'
import styles from './CompactColorInput.scss'
import classNames from 'classnames'
import ColorPickerWrapper from '../ColorPickerWrapper'

function CompactColorInput({ label, color, onColorChange }) {
  return (
    <div className={classNames(styles.compactColorInput)}>
      <ColorPickerWrapper color={color} onColorChange={onColorChange}>
        <div className={styles.color} style={{ background: color }} />
      </ColorPickerWrapper>
      <label className={styles.label}>{label}</label>
    </div>
  )
}

CompactColorInput.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  onColorChange: PropTypes.func.isRequired
}

export default CompactColorInput
