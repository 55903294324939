import ApiClient from '../../../utils/ApiClient'
import { showFlash as flash } from 'utils/Flash/modules/flash'
import { push } from 'react-router-redux'
import { updatePortfolioReload } from 'routes/Portfolio/modules/portfolio'

// ------------------------------------
// Constants
// ------------------------------------

export const GET_TASK_LISTS_REQUEST = 'TASK_LIST/GET_TASK_LISTS_REQUEST'
export const GET_TASK_LISTS_SUCCESS = 'TASK_LIST/GET_TASK_LISTS_SUCCESS'
export const GET_TASK_LISTS_FAIL = 'TASK_LIST/GET_TASK_LISTS_FAIL'

export const GET_TASK_LIST_BY_ID_REQUEST =
  'TASK_LIST/GET_TASK_LIST_BY_ID_REQUEST'
export const GET_TASK_LIST_BY_ID_SUCCESS =
  'TASK_LIST/GET_TASK_LIST_BY_ID_SUCCESS'
export const GET_TASK_LIST_BY_ID_FAIL = 'TASK_LIST/GET_TASK_LIST_BY_ID_FAIL'

export const CREATE_TASK_LIST_REQUEST = 'TASK_LIST/CREATE_TASK_LIST_REQUEST'
export const CREATE_TASK_LIST_SUCCESS = 'TASK_LIST/CREATE_TASK_LIST_SUCCESS'
export const CREATE_TASK_LIST_FAIL = 'TASK_LIST/CREATE_TASK_LIST_FAIL'

export const DELETE_TASK_LIST_REQUEST = 'TASK_LIST/DELETE_TASK_LIST_REQUEST'
export const DELETE_TASK_LIST_SUCCESS = 'TASK_LIST/DELETE_TASK_LIST_SUCCESS'
export const DELETE_TASK_LIST_FAIL = 'TASK_LIST/DELETE_TASK_LIST_FAIL'

export const UPDATE_TASK_LIST_REQUEST = 'TASK_LIST/UPDATE_TASK_LIST_REQUEST'
export const UPDATE_TASK_LIST_SUCCESS = 'TASK_LIST/UPDATE_TASK_LIST_SUCCESS'
export const UPDATE_TASK_LIST_FAIL = 'TASK_LIST/UPDATE_TASK_LIST_FAIL'
// ------------------------------------
// Actions
// ------------------------------------

export const getTaskLists = ({
  organizationID,
  page,
  pageSize,
  search,
  sortBy = 'updatedAt',
  sortOrder = 'DESC'
}) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_TASK_LISTS_REQUEST })
    const api = new ApiClient(dispatch, getState())
    try {
      const data = await api.get(`/organization/${organizationID}/task-list`, {
        params: {
          page,
          pageSize,
          search,
          sortBy,
          sortOrder
        }
      })
      dispatch({ type: GET_TASK_LISTS_SUCCESS, payload: data })
    } catch (error) {
      dispatch({ type: GET_TASK_LISTS_FAIL, payload: error })
    }
  }
}

export const getTaskListById = ({ organizationID, taskListId }) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_TASK_LIST_BY_ID_REQUEST })
    const api = new ApiClient(dispatch, getState())
    try {
      const data = await api.get(
        `/organization/${organizationID}/task-list/${taskListId}`
      )
      dispatch({ type: GET_TASK_LIST_BY_ID_SUCCESS, payload: data })
    } catch (error) {
      dispatch({ type: GET_TASK_LIST_BY_ID_FAIL, payload: error })
    }
  }
}

export const createNewTaskList = ({ organizationID, payload }) => {
  return async (dispatch, getState) => {
    dispatch({ type: CREATE_TASK_LIST_REQUEST })
    const api = new ApiClient(dispatch, getState())
    try {
      const data = await api.post(
        `/organization/${organizationID}/task-list/`,
        {
          data: payload
        }
      )
      dispatch({ type: CREATE_TASK_LIST_SUCCESS, payload: data })
      dispatch(updatePortfolioReload(true))
      dispatch(flash(data.message, 'success', 2))
      dispatch(push(`/organization/${organizationID}/tasklist`))
    } catch ({ error }) {
      dispatch({ type: CREATE_TASK_LIST_FAIL, payload: error })
      dispatch(flash(error[0].message, 'error', 5))
    }
  }
}

export const updateTaskList = ({
  organizationID,
  taskListId,
  payload,
  pushRoute
}) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_TASK_LIST_REQUEST })
    const api = new ApiClient(dispatch, getState())
    try {
      const data = await api.put(
        `/organization/${organizationID}/task-list/${taskListId}`,
        {
          data: payload
        }
      )
      dispatch({ type: UPDATE_TASK_LIST_SUCCESS, payload: data })
      dispatch(updatePortfolioReload(true))
      dispatch(flash(data.message, 'success', 2))
      pushRoute && dispatch(push(`/organization/${organizationID}/tasklist`))
    } catch ({ error }) {
      dispatch({ type: UPDATE_TASK_LIST_FAIL, payload: error })
      dispatch(flash(error[0].message, 'error', 5))
    }
  }
}

export const deleteTaskList = ({ organizationID, taskListId }) => {
  return async (dispatch, getState) => {
    dispatch({ type: DELETE_TASK_LIST_REQUEST })
    const api = new ApiClient(dispatch, getState())
    try {
      const data = await api.del(
        `/organization/${organizationID}/task-list/${taskListId}`
      )
      dispatch({ type: DELETE_TASK_LIST_SUCCESS, payload: { taskListId } })
      dispatch(flash(data.message, 'success', 2))
      dispatch(push(`/organization/${organizationID}/tasklist`))
    } catch ({ error }) {
      dispatch({ type: DELETE_TASK_LIST_FAIL, payload: error })
      dispatch(flash(error[0].message, 'error', 5))
    }
  }
}

// ------------------------------------
// Action Helpers
// ------------------------------------

const handleGetTasksListsRequest = (state, action) => {
  return {
    ...state,
    taskLists: {
      ...state.taskLists,
      loading: true,
      error: undefined
    }
  }
}

const handleGetTasksListsFail = (state, action) => {
  return {
    ...state,
    taskLists: {
      ...state.taskLists,
      loading: false,
      error: action.payload
    }
  }
}

const handleGetTasksListsSuccess = (state, action) => {
  return {
    ...state,
    taskLists: {
      ...state.taskLists,
      loading: false,
      page: action.payload.pagination.page,
      pageSize: action.payload.pagination.pageSize,
      totalPages: action.payload.pagination.totalPages,
      data: action.payload.taskLists
    }
  }
}

const handleGetTasksListByIdRequest = (state, action) => {
  return {
    ...state,
    activeTaskList: {
      ...state.activeTaskList,
      loading: true,
      error: undefined
    }
  }
}

const handleGetTasksListByIdFail = (state, action) => {
  return {
    ...state,
    activeTaskList: {
      ...state.activeTaskList,
      loading: false,
      error: action.payload
    }
  }
}

const handleGetTasksListByIdSuccess = (state, action) => {
  return {
    ...state,
    activeTaskList: {
      ...state.activeTaskList,
      loading: false,
      data: action.payload.taskList
    }
  }
}

const handleUpdateTaskListRequest = (state, action) => {
  return {
    ...state,
    activeTaskList: {
      ...state.activeTaskList,
      loading: true,
      error: undefined
    }
  }
}

const handleUpdateTaskListFail = (state, action) => {
  return {
    ...state,
    activeTaskList: {
      ...state.activeTaskList,
      loading: false,
      error: action.payload
    }
  }
}

const handleUpdateTaskListSuccess = (state, action) => {
  const data = state.taskLists.data.map(taskList => {
    if (taskList._id === action.payload.taskList._id) {
      return action.payload.taskList
    }
    return taskList
  })

  return {
    taskLists: {
      ...state.taskLists,
      data: data,
      loading: false,
      error: undefined
    },
    activeTaskList: {
      ...state.activeTaskList,
      loading: false,
      data: action.payload.taskList
    }
  }
}
const handleCreateTaskListRequest = (state, action) => {
  return {
    ...state,
    activeTaskList: {
      ...state.activeTaskList,
      loading: true,
      error: undefined
    }
  }
}

const handleCreateTaskListFail = (state, action) => {
  return {
    ...state,
    activeTaskList: {
      ...state.activeTaskList,
      loading: false,
      error: action.payload
    }
  }
}

const handleCreateTaskListSuccess = (state, action) => {
  const totalPages =
    state.taskLists.data.length === state.taskLists.pageSize
      ? state.taskLists.totalPages + 1
      : state.taskLists.totalPages
  const pageSize = state.taskLists.pageSize
  const data = [action.payload.taskList, ...state.taskLists.data]

  return {
    taskLists: {
      page: 1,
      pageSize: pageSize,
      totalPages: totalPages,
      data: data.splice(-1, 1),
      loading: false,
      error: undefined
    },
    activeTaskList: {
      ...state.activeTaskList,
      loading: false,
      data: action.payload.taskList
    }
  }
}

const handleDeleteTaskListRequest = (state, action) => {
  return {
    ...state,
    activeTaskList: {
      ...state.activeTaskList,
      loading: true,
      error: undefined
    }
  }
}

const handleDeleteTaskListFail = (state, action) => {
  return {
    ...state,
    activeTaskList: {
      ...state.activeTaskList,
      loading: false,
      error: action.payload
    }
  }
}

const handleDeleteTaskListSuccess = (state, action) => {
  return {
    ...state,
    activeTaskList: {
      ...state.activeTaskList,
      loading: false
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [GET_TASK_LISTS_REQUEST]: handleGetTasksListsRequest,
  [GET_TASK_LISTS_SUCCESS]: handleGetTasksListsSuccess,
  [GET_TASK_LISTS_FAIL]: handleGetTasksListsFail,
  [GET_TASK_LIST_BY_ID_REQUEST]: handleGetTasksListByIdRequest,
  [GET_TASK_LIST_BY_ID_SUCCESS]: handleGetTasksListByIdSuccess,
  [GET_TASK_LIST_BY_ID_FAIL]: handleGetTasksListByIdFail,
  [CREATE_TASK_LIST_REQUEST]: handleCreateTaskListRequest,
  [CREATE_TASK_LIST_SUCCESS]: handleCreateTaskListSuccess,
  [CREATE_TASK_LIST_FAIL]: handleCreateTaskListFail,
  [DELETE_TASK_LIST_REQUEST]: handleDeleteTaskListRequest,
  [DELETE_TASK_LIST_SUCCESS]: handleDeleteTaskListSuccess,
  [DELETE_TASK_LIST_FAIL]: handleDeleteTaskListFail,
  [UPDATE_TASK_LIST_REQUEST]: handleUpdateTaskListRequest,
  [UPDATE_TASK_LIST_SUCCESS]: handleUpdateTaskListSuccess,
  [UPDATE_TASK_LIST_FAIL]: handleUpdateTaskListFail
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  taskLists: {
    page: 1,
    pageSize: 10,
    totalPages: 1,
    data: [],
    loading: false,
    error: undefined
  },
  activeTaskList: {
    loading: false,
    error: undefined,
    data: {}
  }
}

export default function taskListReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
