import { useEffect } from 'react'

const useOutsideClick = (ref, callback, excludedIds = []) => {
  useEffect(() => {
    document.addEventListener(
      'mousedown',
      e => handleClickOutside(e, ref, callback, excludedIds),
      false
    )
    return () => {
      document.removeEventListener(
        'mousedown',
        e => handleClickOutside(e, ref, callback, excludedIds),
        false
      )
    }
  }, [ref, callback])
}

function handleClickOutside(e, ref, callback, excludedIds) {
  const preventOutsideClick = e.target.closest('#portal')
  const clickedOnExcludedEl = excludedIds.some(id => e.target.closest(`#${id}`))
  if (preventOutsideClick || clickedOnExcludedEl) return

  const outsideClick = ref.current && !ref.current.contains(e.target)
  if (outsideClick && callback) callback()
}

export default useOutsideClick
