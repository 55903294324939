import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import styles from './SubHeader.scss'
import UserFeature from '../../utils/Feature/UserFeature'
import { Query } from 'react-apollo'
import { detectMobileTouch, parentNodeHasClass } from 'utils/Utils'
import { ENABLED_FEATURES } from '../../utils/graphql/queries/user'
import SubHeaderTab from './SubHeaderTab'
import { FF_TASK_LIST } from 'static/feature-flag-constants'

const Tabs = {
  TaskList: 'TaskList'
}

const TabsDictionary = {
  [Tabs.TaskList]: {
    value: Tabs.TaskList,
    label: 'Task Lists',
    redirectTo: organizationID =>
      `/organization/${organizationID || 'all'}/tasklist`
  }
}

class SubHeader extends React.Component {
  state = {
    activeTab: 'manage',
    tabs: [
      { name: 'Documents', active: true, featureFlag: 'reportDocuments' },
      { name: 'Spreadsheets', active: true, featureFlag: 'reportSpreadsheets' }
    ],
    showOrgDropdown: false,
    showLibraryDropDown: false,
    showAllOrganizationDropdown: false,
    windowWidth: '',
    currentUserRole: 'user'
  }

  setCurrentUserRole = () => {
    const { user, organizationView } = this.props

    if (
      organizationView &&
      organizationView.users &&
      organizationView.users.length > 0
    ) {
      const currentUserObject = organizationView.users.filter(userFromOrg => {
        return userFromOrg.userId === user._id
      })
      if (currentUserObject && currentUserObject.length > 0) {
        this.setState({ currentUserRole: currentUserObject[0].userRole })
      }
    }
  }

  UNSAFE_componentWillMount = () => {
    this.handleUpdateDimensions()
    document.addEventListener('mousedown', this.handleReportsClick, false)
    window.addEventListener('resize', this.handleUpdateDimensions)
  }

  handleUpdateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidMount = () => {
    this.handleActiveTab()
    this.setCurrentUserRole()
  }

  componentDidUpdate = prevProps => {
    if (prevProps !== this.props) {
      this.handleActiveTab()
      this.setCurrentUserRole()
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleReportsClick, false)
    window.removeEventListener('resize', this.handleUpdateDimensions)
  }

  handleActiveTab = () => {
    if (window.location.href.indexOf('manage') > -1) {
      if (window.location.href.includes('users')) {
        this.setState({ activeTab: 'users' })
      } else if (window.location.href.includes('features')) {
        this.setState({ activeTab: 'features' })
      } else this.setState({ activeTab: 'manage' })
    }
    if (window.location.href.indexOf('library/my') > -1) {
      this.setState({ activeTab: 'myLibrary' })
      return
    }
    if (window.location.href.indexOf('library/public') > -1) {
      this.setState({ activeTab: 'publicLibrary' })
      return
    }
    if (window.location.href.indexOf('library') > -1) {
      this.setState({ activeTab: 'myLibrary' })
      return
    }
    if (window.location.href.indexOf('spreadsheet') > -1) {
      this.setState({ activeTab: 'spreadsheets' })
      return
    }
    if (window.location.href.indexOf('survey-template') > -1) {
      this.setState({ activeTab: 'survey' })
      return
    }
    if (window.location.href.indexOf('template') > -1) {
      this.setState({ activeTab: 'templates' })
      return
    }
    if (window.location.href.indexOf('baseline_settings') > -1) {
      this.setState({ activeTab: 'baseline_settings' })
      return
    }
    if (window.location.href.indexOf('target_settings') > -1) {
      this.setState({ activeTab: 'target_settings' })
      return
    }
    if (window.location.href.indexOf('tasklist') > -1) {
      this.setState({ activeTab: Tabs.TaskList })
    }
  }

  handleReportsClick = e => {
    // the click is inside, continue to menu links
    if (parentNodeHasClass(e.target, 'libraryClick')) {
      this.setState({
        showOrgDropdown: false,
        showAllOrganizationDropdown: false,
        showSettingsDropdown: false
      })
      return
    }
    if (parentNodeHasClass(e.target, 'reportsClick')) {
      this.setState({
        showLibraryDropDown: false,
        showAllOrganizationDropdown: false,
        showSettingsDropdown: false
      })
      return
    }
    if (parentNodeHasClass(e.target, 'allOrganizationClick')) {
      this.setState({
        showOrgDropdown: false,
        showLibraryDropDown: false,
        showSettingsDropdown: false
      })
      return
    }
    if (parentNodeHasClass(e.target, 'settingsClick')) {
      this.setState({
        showOrgDropdown: false,
        showAllOrganizationDropdown: false,
        showLibraryDropDown: false
      })
      return
    }
    // otherwise, toggle (close) the dropdowns
    this.setState({
      showOrgDropdown: false,
      showLibraryDropDown: false,
      showAllOrganizationDropdown: false,
      showSettingsDropdown: false
    })
  }

  checkRoute = () => {
    if (window.location.href.indexOf('manage') > -1) {
      return true
    }
    if (window.location.href.indexOf('library') > -1) {
      return true
    }
    if (window.location.href.indexOf('spreadsheet') > -1) {
      return true
    }
    if (window.location.href.indexOf('survey-template') > -1) {
      return true
    }
    if (window.location.href.indexOf('template') > -1) {
      return true
    }
    if (window.location.href.indexOf('_settings') > -1) {
      return true
    }
    if (window.location.href.indexOf('tasklist') > -1) {
      return true
    }
    return false
  }

  toggleOrganizationDropdown = () => {
    this.setState({ showOrgDropdown: !this.state.showOrgDropdown })
  }

  toggleLibraryDropdown = () => {
    this.setState({ showLibraryDropDown: !this.state.showLibraryDropDown })
  }

  toggleAllOrganizationDropdown = () => {
    this.setState({
      showAllOrganizationDropdown: !this.state.showAllOrganizationDropdown
    })
  }

  toggleSettingsDropdown = () => {
    this.setState({ showSettingsDropdown: !this.state.showSettingsDropdown })
  }

  closeOrganizationDropdown = () => {
    this.setState({ showOrgDropdown: false })
  }

  closeLibraryDropdown = () => {
    this.setState({ showLibraryDropDown: false })
  }

  closeAllOrganizationDropdown = () => {
    this.setState({ showAllOrganizationDropdown: false })
  }

  closeSettingsDropdown = () => {
    this.setState({ showSettingsDropdown: false })
  }

  handleClickManage = event => {
    event.preventDefault()
    if (this.props.manageAllOrgSelected) {
      this.props.push('/organization/manage/all')
      this.setState({ activeTab: 'manage' })
    } else {
      this.props.push(
        '/organization/' + this.props.organizationView._id + '/manage'
      )
      this.setState({ activeTab: 'manage' })
    }
  }

  handleClickSettings = (event, setting) => {
    event.preventDefault()
    const url = `/organization/${this.props.organizationView._id}/${setting}`
    this.props.push(url)
    this.setState({ activeTab: setting }, () => this.closeSettingsDropdown())
  }

  handleClickTemplates = value => {
    if (value == 'Documents') {
      if (this.props.manageAllOrgSelected) {
        this.props.push('/organization/template/all')
      } else {
        this.props.push(
          '/organization/' + this.props.organizationView._id + '/template'
        )
      }

      this.setState({ activeTab: 'templates' })
    } else if (value == 'Spreadsheets') {
      this.props.push('/spreadsheet/templatelist')
      this.setState({ activeTab: 'spreadsheets' })
    }
    this.closeOrganizationDropdown()
  }

  handleClickSurvey = () => {
    this.props.push(
      '/organization/' + this.props.organizationView._id + '/survey-template'
    )
    this.setState({ activeTab: 'survey' })
  }

  handleClickLibraries = value => {
    if (value == 'myLibrary') {
      if (this.props.manageAllOrgSelected) {
        this.props.push('/organization/library/my')
      } else {
        this.props.push(
          '/organization/' + this.props.organizationView._id + '/library/my'
        )
      }

      this.setState({ activeTab: 'myLibrary' })
    } else if (value == 'publicLibrary') {
      if (this.props.manageAllOrgSelected) {
        this.props.push('/organization/library/public')
      } else {
        this.props.push(
          '/organization/' + this.props.organizationView._id + '/library/public'
        )
      }
      this.setState({ activeTab: 'publicLibrary' })
    }
    this.closeLibraryDropdown()
  }

  handleClickAllOrganization = value => {
    let tab = ''
    switch (value) {
      case 'users':
        tab = '/users'
        break
      case 'features':
        tab = '/features'
        break
      default:
        break
    }
    let url = `/organization/manage/all`
    if (!this.props.manageAllOrgSelected) {
      url = '/organization/' + this.props.organizationView._id + '/manage'
    }
    this.props.push(tab ? `${url}${tab}` : url)
    this.closeAllOrganizationDropdown()
  }

  isOrganizationOwner = () => {
    const { user, organizationView: organization } = this.props
    if (organization && organization.users) {
      const currentUser = organization.users.find(
        data => data.userId === user._id
      )
      if (currentUser) {
        return (
          currentUser.userRole === 'owner' || currentUser.userRole === 'admin'
        )
      }
    }
    return false
  }

  renderAllOrganizationDropdownText = () => {
    const { activeTab } = this.state
    switch (activeTab) {
      case 'users':
        return 'Users'
      case 'features':
        return 'Feature Flags'
      default:
        return 'Organization'
    }
  }

  handleClickTab = tab => {
    if (TabsDictionary[tab]?.redirectTo) {
      const url = TabsDictionary[tab].redirectTo(
        this.props.manageAllOrgSelected
          ? undefined
          : this.props.organizationView._id
      )
      this.props.push(url)
    }
    this.setState({
      activeTab: tab
    })
  }

  render() {
    const {
      windowWidth,
      showAllOrganizationDropdown,
      activeTab,
      showSettingsDropdown
    } = this.state
    const { manageAllOrgSelected } = this.props
    let check = this.checkRoute()
    if (!check || windowWidth < 799) return null
    const isOrgOwner = this.isOrganizationOwner()
    return (
      <div className={styles.SubHeaderNav}>
        <div className={styles.container}>
          <div
            className={classNames(
              styles.SubHeaderNavDesktop,
              styles.SubHeaderNavDesktopShow
            )}
          >
            <div
              className={classNames(
                styles.link,
                'allOrganizationClick',
                activeTab === 'manage' ||
                  activeTab === 'users' ||
                  activeTab === 'features'
                  ? styles.active
                  : ''
              )}
            >
              <a
                className={classNames('allOrganization')}
                onClick={() => this.toggleAllOrganizationDropdown()}
                data-test='reports-header-link'
              >
                <span>{this.renderAllOrganizationDropdownText()}</span>
                <i className='material-icons'>expand_more</i>
              </a>
              {showAllOrganizationDropdown && (
                <ul
                  className={classNames(
                    styles.organziationDropdown,
                    'allOrganization'
                  )}
                >
                  <li
                    className={
                      activeTab == 'manage' ? styles['link-active'] : ''
                    }
                    onClick={() => this.handleClickAllOrganization('manage')}
                    value='OrganizationManage'
                    data-test='AllOrganization-header-link'
                  >
                    Organizations
                  </li>
                  <li
                    className={
                      activeTab == 'users' ? styles['link-active'] : ''
                    }
                    onClick={() => this.handleClickAllOrganization('users')}
                    value='UserManage'
                    data-test='AllOrganizationUsers-header-link'
                  >
                    Users
                  </li>
                </ul>
              )}
            </div>
            {/* <div
                className={classNames(
                  styles.link,
                  styles.manage,
                  this.state.activeTab === 'manage' ? styles.active : ''
                )}
                onClick={this.handleClickManage}
              >
                Users
              </div> */}
            <Query query={ENABLED_FEATURES}>
              {({ data }) => {
                if (!data) return null
                const enabledFeatures = data.enabledFeatures || []
                const { showLibraryDropDown, activeTab } = this.state
                const isLibaryEnabled = enabledFeatures.some(
                  feature => feature.name === 'library'
                )
                const isMyLibraryEnabled = enabledFeatures.some(
                  feature => feature.name === 'myLibrary'
                )
                const isPublicLibraryEnabled = enabledFeatures.some(
                  feature => feature.name === 'publicLibrary'
                )
                const isTaskListEnabled = enabledFeatures.some(
                  feature => feature.name === FF_TASK_LIST
                )
                const libraryFlag =
                  isLibaryEnabled &&
                  (isMyLibraryEnabled || isPublicLibraryEnabled)

                return (
                  <>
                    {libraryFlag && (
                      <div
                        className={classNames(
                          styles.link,
                          'libraryClick',
                          activeTab === 'myLibrary' ||
                            activeTab === 'publicLibrary'
                            ? styles.active
                            : ''
                        )}
                      >
                        <a
                          className={classNames('libraryClick')}
                          onClick={() => this.toggleLibraryDropdown()}
                          data-test='reports-header-link'
                        >
                          <span>Measure Library</span>
                          <i className='material-icons'>expand_more</i>
                        </a>
                        {showLibraryDropDown && (
                          <ul
                            className={classNames(
                              windowWidth > 799
                                ? styles.organziationDropdown
                                : '',
                              windowWidth > 799 ? styles.wide : '',
                              windowWidth <= 799
                                ? styles.mobileOrganziationDropdown
                                : '',
                              'libraryClick'
                            )}
                          >
                            {isMyLibraryEnabled && (
                              <li
                                className={
                                  activeTab == 'myLibrary'
                                    ? styles['link-active']
                                    : ''
                                }
                                onClick={() =>
                                  this.handleClickLibraries('myLibrary')
                                }
                                value='Documents'
                                data-test='documents-header-link'
                              >
                                My Library
                              </li>
                            )}
                            {isPublicLibraryEnabled && (
                              <li
                                className={
                                  activeTab == 'publicLibrary'
                                    ? styles['link-active']
                                    : ''
                                }
                                onClick={() =>
                                  this.handleClickLibraries('publicLibrary')
                                }
                                value='Spreadsheets'
                                data-test='spreadsheets-header-link'
                              >
                                Public Library
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    )}
                    {isTaskListEnabled && (
                      <SubHeaderTab
                        label={TabsDictionary[Tabs.TaskList].label}
                        tab={Tabs.TaskList}
                        handleClick={this.handleClickTab}
                        activeTab={activeTab}
                        active={
                          TabsDictionary[Tabs.TaskList].value === activeTab
                        }
                        subSectionOption={[]}
                      />
                    )}
                  </>
                )
              }}
            </Query>
            {detectMobileTouch() === 'desktop' && (
              <div>
                <Query query={ENABLED_FEATURES}>
                  {({ data }) => {
                    if (!data) return null
                    const enabledFeatures = data.enabledFeatures || []
                    const { showOrgDropdown, activeTab } = this.state
                    const tabs = this.state.tabs.filter(
                      ({ name, featureFlag }) =>
                        enabledFeatures.some(
                          feature => feature.name === featureFlag
                        )
                    )
                    if (tabs.length === 0) return null
                    if (tabs.length > 1) {
                      return (
                        <div
                          className={classNames(
                            styles.link,
                            'reportsClick',
                            activeTab === 'templates' ||
                              activeTab === 'spreadsheets'
                              ? styles.active
                              : ''
                          )}
                        >
                          <a
                            className={classNames('reportsClick')}
                            onClick={() => this.toggleOrganizationDropdown()}
                            data-test='reports-header-link'
                          >
                            <span>Report Templates</span>
                            <i className='material-icons'>expand_more</i>
                          </a>
                          {showOrgDropdown && (
                            <ul
                              className={classNames(
                                windowWidth > 799
                                  ? styles.organziationDropdown
                                  : '',
                                windowWidth > 799 ? styles.wide : '',
                                windowWidth <= 799
                                  ? styles.mobileOrganziationDropdown
                                  : '',
                                'reportsClick'
                              )}
                            >
                              <li
                                className={
                                  activeTab == 'templates'
                                    ? styles['link-active']
                                    : ''
                                }
                                onClick={() =>
                                  this.handleClickTemplates('Documents')
                                }
                                value='Documents'
                                data-test='documents-header-link'
                              >
                                Documents Templates
                              </li>
                              <li
                                className={
                                  activeTab == 'spreadsheets'
                                    ? styles['link-active']
                                    : ''
                                }
                                onClick={() =>
                                  this.handleClickTemplates('Spreadsheets')
                                }
                                value='Spreadsheets'
                                data-test='spreadsheets-header-link'
                              >
                                Spreadsheets Templates
                              </li>
                            </ul>
                          )}
                        </div>
                      )
                    }
                    let tab = tabs[0]
                    return (
                      <div
                        className={classNames(
                          styles.link,
                          this.state.activeTab === 'templates' ||
                            this.state.activeTab === 'spreadsheets'
                            ? styles.active
                            : ''
                        )}
                        name={tab.name}
                        id={`${tab.name}`}
                        value={tab.name}
                        onClick={() => this.handleClickTemplates(tab.name)}
                        data-test='reports-header-link'
                      >
                        Reports
                      </div>
                    )
                  }}
                </Query>
              </div>
            )}
            {isOrgOwner && (
              <Query query={ENABLED_FEATURES}>
                {({ data }) => {
                  const enabledFeatures = data?.enabledFeatures || []
                  const hasTargetsPermissions = enabledFeatures.some(
                    feature => feature.name === 'targets'
                  )
                  return (
                    <div
                      className={classNames(
                        styles.link,
                        'settingsClick',
                        activeTab === 'baseline_settings' ||
                          activeTab === 'target_settings'
                          ? styles.active
                          : ''
                      )}
                    >
                      <a
                        className={classNames('settingsClick')}
                        onClick={this.toggleSettingsDropdown}
                        data-test='reports-header-link'
                      >
                        <span>
                          {activeTab === 'target_settings'
                            ? 'Target Settings'
                            : 'Baseline Settings'}
                        </span>
                        <i className='material-icons'>expand_more</i>
                      </a>
                      {showSettingsDropdown && (
                        <ul
                          className={classNames(
                            windowWidth > 799
                              ? styles.organziationDropdown
                              : '',
                            windowWidth > 799 ? styles.wide : '',
                            windowWidth <= 799
                              ? styles.mobileOrganziationDropdown
                              : '',
                            'settingsClick'
                          )}
                        >
                          <li
                            className={
                              activeTab === 'baseline_settings'
                                ? styles['link-active']
                                : ''
                            }
                            onClick={ev =>
                              this.handleClickSettings(ev, 'baseline_settings')
                            }
                            value='Baseline Settings'
                            data-test='documents-header-link'
                          >
                            Baseline Settings
                          </li>
                          {hasTargetsPermissions &&
                            !this.props.manageAllOrgSelected && (
                              <li
                                className={
                                  activeTab === 'target_settings'
                                    ? styles['link-active']
                                    : ''
                                }
                                onClick={ev =>
                                  this.handleClickSettings(
                                    ev,
                                    'target_settings'
                                  )
                                }
                                value='Target Settings'
                                data-test='spreadsheets-header-link'
                              >
                                Target Settings
                              </li>
                            )}
                        </ul>
                      )}
                    </div>
                  )
                }}
              </Query>
            )}
            {(this.state.currentUserRole === 'owner' ||
              this.state.currentUserRole === 'admin') && (
              <UserFeature name='survey'>
                {({ enabled }) => {
                  if (!enabled) {
                    return true
                  }
                  return (
                    <div
                      className={classNames(
                        styles.link,
                        activeTab == 'survey' ? styles.active : ''
                      )}
                    >
                      <a
                        onClick={() => this.handleClickSurvey()}
                        data-test='reports-header-link'
                      >
                        <span>Survey Templates</span>
                      </a>
                    </div>
                  )
                }}
              </UserFeature>
            )}{' '}
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  push
}

const mapStateToProps = state => ({
  user: state.login.user || {},
  organizationView: state.organization.organizationView || {},
  organizationList: state.organization.organizationList || [],
  manageAllOrgSelected: state.organization.manageAllOrgSelected || false
})

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(SubHeader)
)
