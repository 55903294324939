export const DEFAULT_TREND_PERIOD = {
  type: 'per-fuel',
  span: 3
}
const VEHICLES_DRIVEN_FACTOR = 4.67
const OIL_BARRELS_CONSUMED_FACTOR = 0.43
const COAL_RAILCARS_BURNED_FACTOR = 183.22
const ACRES_OF_FOREST_FACTOR = 0.84
const HOME_ENERGY_USE_FACTOR = 7.94

const VEHICLES_DRIVEN = 'vehiclesDriven'
const OIL_BARRELS_CONSUMED = 'oilBarrelsConsumed'
const COAL_RAILCARS_BURNED = 'coalRailcarsBurned'
const ACRES_OF_FOREST = 'acresOfForest'
const HOME_ENERGY_USE = 'homeEnergyUse'

export const CONVERSION_ITEMS = {
  [VEHICLES_DRIVEN]: {
    factor: VEHICLES_DRIVEN_FACTOR,
    label: 'Vehicles Driven in a Year',
    equivalency_label: 'Vehicles Driven Equivalency'
  },
  [OIL_BARRELS_CONSUMED]: {
    factor: OIL_BARRELS_CONSUMED_FACTOR,
    label: 'Barrels of Oil Consumed',
    equivalency_label: 'Oil Barrels Equivalency'
  },
  [COAL_RAILCARS_BURNED]: {
    factor: COAL_RAILCARS_BURNED_FACTOR,
    label: 'Railcars of Coal Burned',
    equivalency_label: 'Rail Cars of Coal Equivalency'
  },
  [ACRES_OF_FOREST]: {
    factor: ACRES_OF_FOREST_FACTOR,
    label: 'Acres of Forest to Remove CO2',
    equivalency_label: 'Acres of Forest to Remove CO2 Equivalency'
  },
  [HOME_ENERGY_USE]: {
    factor: HOME_ENERGY_USE_FACTOR,
    label: 'Home energy use',
    equivalency_label: 'Home energy use Equivalency'
  }
}
