/* eslint-disable max-len */
import { targetDictionary } from 'static/targets'
import { UNIT_TYPES, UTILITY_TYPES } from 'static/utility-units'

// Buildings
export const buildingEnergySummaryKeys = {
  avgAnnualEnergyUse: 'lastTwelveUtilityMetrics.totalEnergyUsage',
  avgEnergyUseIntensity: 'lastTwelveUtilityMetrics.eui',
  avgAnnualEnergyCost: 'lastTwelveUtilityMetrics.totalEnergyCost',
  avgEnergyCostIntensity: 'lastTwelveUtilityMetrics.totalEnergyCostIntensity',
  avgGHGIntensity: 'lastTwelveUtilityMetrics.ghgIntensity',
  avgAnnualGHGEmissions: 'lastTwelveUtilityMetrics.ghg'
}

export const utilitiesKeysValues = {
  electricUsage: 'lastTwelveUtilityMetrics.totalUtilUsages.electric',
  electricCost: 'lastTwelveUtilityMetrics.totalUtilCosts.electric',
  electricIntensity: 'lastTwelveUtilityMetrics.totalUtilIntensities.electric',
  electricityDemandUsage:
    'lastTwelveUtilityMetrics.totalUtilUsages.electricityDemand',
  electricityDemandCost:
    'lastTwelveUtilityMetrics.totalUtilCosts.electricityDemand',
  electricityDemandIntensity:
    'lastTwelveUtilityMetrics.totalUtilIntensities.electricityDemand',
  solarUsage: 'lastTwelveUtilityMetrics.totalUtilUsages.solar',
  solarCost: 'lastTwelveUtilityMetrics.totalUtilCosts.solar',
  solarIntensity: 'lastTwelveUtilityMetrics.totalUtilIntensities.solar',
  naturalGasUsage: 'lastTwelveUtilityMetrics.totalUtilUsages.naturalgas',
  naturalGasCost: 'lastTwelveUtilityMetrics.totalUtilCosts.naturalgas',
  naturalGasIntensity:
    'lastTwelveUtilityMetrics.totalUtilIntensities.naturalgas',
  steamUsage: 'lastTwelveUtilityMetrics.totalUtilUsages.steam',
  steamCost: 'lastTwelveUtilityMetrics.totalUtilCosts.steam',
  steamIntensity: 'lastTwelveUtilityMetrics.totalUtilIntensities.steam',
  fuelOil1Usage: 'lastTwelveUtilityMetrics.totalUtilUsages.fueloil1',
  fuelOil1Cost: 'lastTwelveUtilityMetrics.totalUtilCosts.fueloil1',
  fuelOil1Intensity: 'lastTwelveUtilityMetrics.totalUtilIntensities.fueloil1',
  fuelOil2Usage: 'lastTwelveUtilityMetrics.totalUtilUsages.fueloil2',
  fuelOil2Cost: 'lastTwelveUtilityMetrics.totalUtilCosts.fueloil2',
  fuelOil2Intensity: 'lastTwelveUtilityMetrics.totalUtilIntensities.fueloil2',
  fuelOil4Usage: 'lastTwelveUtilityMetrics.totalUtilUsages.fueloil4',
  fuelOil4Cost: 'lastTwelveUtilityMetrics.totalUtilCosts.fueloil4',
  fuelOil4Intensity: 'lastTwelveUtilityMetrics.totalUtilIntensities.fueloil4',
  fuelOil56Usage: 'lastTwelveUtilityMetrics.totalUtilUsages.fueloil56',
  fuelOil56Cost: 'lastTwelveUtilityMetrics.totalUtilCosts.fueloil56',
  fuelOil56Intensity: 'lastTwelveUtilityMetrics.totalUtilIntensities.fueloil56',
  dieselUsage: 'lastTwelveUtilityMetrics.totalUtilUsages.diesel',
  dieselCost: 'lastTwelveUtilityMetrics.totalUtilCosts.diesel',
  dieselIntensity: 'lastTwelveUtilityMetrics.totalUtilIntensities.diesel',
  waterUsage: 'lastTwelveUtilityMetrics.totalUtilUsages.water',
  waterCost: 'lastTwelveUtilityMetrics.totalUtilCosts.water',
  waterIntensity: 'lastTwelveUtilityMetrics.totalUtilIntensities.water',
  propaneUsage: 'lastTwelveUtilityMetrics.totalUtilUsages.propane',
  propaneCost: 'lastTwelveUtilityMetrics.totalUtilCosts.propane',
  propaneIntensity: 'lastTwelveUtilityMetrics.totalUtilIntensities.propane',
  keroseneUsage: 'lastTwelveUtilityMetrics.totalUtilUsages.kerosene',
  keroseneCost: 'lastTwelveUtilityMetrics.totalUtilCosts.kerosene',
  keroseneIntensity: 'lastTwelveUtilityMetrics.totalUtilIntensities.kerosene',
  districtChilledWaterElectricMeterUsage:
    'lastTwelveUtilityMetrics.totalUtilUsages.districtChilledWaterElectricMeter',
  districtChilledWaterElectricMeterCost:
    'lastTwelveUtilityMetrics.totalUtilCosts.districtChilledWaterElectricMeter',
  districtChilledWaterElectricMeterIntensity:
    'lastTwelveUtilityMetrics.totalUtilIntensities.districtChilledWaterElectricMeter',
  districtChilledWaterEngineMeterUsage:
    'lastTwelveUtilityMetrics.totalUtilUsages.districtChilledWaterEngineMeter',
  districtChilledWaterEngineMeterCost:
    'lastTwelveUtilityMetrics.totalUtilCosts.districtChilledWaterEngineMeter',
  districtChilledWaterEngineMeterIntensity:
    'lastTwelveUtilityMetrics.totalUtilIntensities.districtChilledWaterEngineMeter',
  districtChilledWaterOtherMeterUsage:
    'lastTwelveUtilityMetrics.totalUtilUsages.districtChilledWaterOtherMeter',
  districtChilledWaterOtherMeterCost:
    'lastTwelveUtilityMetrics.totalUtilCosts.districtChilledWaterOtherMeter',
  districtChilledWaterOtherMeterIntensity:
    'lastTwelveUtilityMetrics.totalUtilIntensities.districtChilledWaterOtherMeter',
  windUsage: 'lastTwelveUtilityMetrics.totalUtilUsages.wind',
  windCost: 'lastTwelveUtilityMetrics.totalUtilCosts.wind',
  windIntensity: 'lastTwelveUtilityMetrics.totalUtilIntensities.wind',
  woodUsage: 'lastTwelveUtilityMetrics.totalUtilUsages.wood',
  woodCost: 'lastTwelveUtilityMetrics.totalUtilCosts.wood',
  woodIntensity: 'lastTwelveUtilityMetrics.totalUtilIntensities.wood',
  districtHotWaterUsage:
    'lastTwelveUtilityMetrics.totalUtilUsages.districtHotWater',
  districtHotWaterCost:
    'lastTwelveUtilityMetrics.totalUtilCosts.districtHotWater',
  districtHotWaterIntensity:
    'lastTwelveUtilityMetrics.totalUtilIntensities.districtHotWater',
  districtChilledWaterAbsorptionMeterUsage:
    'lastTwelveUtilityMetrics.totalUtilUsages.districtChilledWaterAbsorptionMeter',
  districtChilledWaterAbsorptionMeterCost:
    'lastTwelveUtilityMetrics.totalUtilCosts.districtChilledWaterAbsorptionMeter',
  districtChilledWaterAbsorptionMeterIntensity:
    'lastTwelveUtilityMetrics.totalUtilIntensities.districtChilledWaterAbsorptionMeter'
}

export const BuildingFilterOptions = [
  {
    name: 'Organization',
    value: 'Organization',
    subFields: [
      {
        name: 'Organization Name',
        label: 'Organization Name',
        value: 'organization.name',
        mongoValue: 'organization.name',
        select: 'multiSelect',
        tab: 'building'
      }
    ]
  },
  {
    name: 'Property',
    value: 'info',
    subFields: [
      {
        name: 'Primary Use',
        label: 'Primary Use',
        value: 'buildinguse',
        mongoValue: 'buildingUse',
        select: 'multiSelect',
        paramName: 'building_usetype',
        tab: 'building'
      },
      {
        name: 'Gross Floor Area',
        label: 'Gross Floor Area',
        value: 'squarefeet',
        mongoValue: 'squareFeet',
        select: 'range',
        tab: 'building',
        unit: UNIT_TYPES.FT2,
        paramName: 'gross_floor_area'
      },
      {
        name: 'Above Grade Floors',
        label: 'Above Grade Floors',
        value: 'floorcount',
        mongoValue: 'floorCount',
        select: 'range',
        tab: 'building'
      },
      {
        name: 'Below Grade Floors',
        label: 'Below Grade Floors',
        value: 'belowgradefloorcount',
        mongoValue: 'belowGradeFloorCount',
        select: 'range',
        tab: 'building'
      },
      {
        name: 'Year Built',
        label: 'Year Built',
        value: 'buildyear',
        mongoValue: 'buildYear',
        select: 'yearRange',
        tab: 'building',
        paramName: 'year_built'
      },
      {
        name: 'City',
        label: 'City',
        value: 'location_city',
        mongoValue: 'location.city',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'city'
      },
      {
        name: 'State/Province',
        label: 'State/Province',
        value: 'location_state',
        mongoValue: 'location.state',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'state'
      },
      {
        name: 'Zip Code',
        label: 'Zip Code',
        value: 'location_zipcode',
        mongoValue: 'location.zipCode',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'zip_code'
      },
      {
        name: 'Borough',
        label: 'Borough',
        value: 'nycfields_borough',
        mongoValue: 'nycFields.borough',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'borough'
      },
      {
        name: 'Created By',
        label: 'Created By',
        value: 'building.createdBy.id',
        mongoValue: 'createdByUserId',
        select: 'multiSelect',
        tab: 'building'
      },
      {
        name: 'Created',
        label: 'Created',
        value: 'building.created',
        mongoValue: 'created',
        select: 'dateRange',
        tab: 'building'
      },
      {
        name: 'Updated',
        label: 'Updated',
        value: 'building.updated',
        mongoValue: 'updated',
        select: 'dateRange',
        tab: 'building'
      }
    ]
  },
  {
    name: 'Task Lists',
    value: 'Task Lists',
    subFields: [
      {
        name: 'Task Lists Completion',
        label: 'Task Lists Completion',
        value: 'taskCompletionPercent',
        select: 'range',
        tab: 'building',
        unit: '%'
      }
    ]
  },
  {
    name: 'Baseline',
    value: 'Baseline',
    subFields: [
      {
        name: 'Energy Cost Intensity',
        label: 'Energy Cost Intensity',
        value: 'baseline.totalEnergyCostIntensity',
        select: 'range',
        tab: 'building',
        unit: `$/${UNIT_TYPES.FT2}`
      },
      {
        name: 'Energy Use Intensity',
        label: 'Energy Use Intensity',
        value: 'baseline.eui',
        select: 'range',
        tab: 'building',
        unit: 'kBtu/ft\u00B2'
      },
      {
        name: 'Electricity Cost',
        label: 'Electricity Cost',
        value: 'baseline.totalUtilCosts.electric',
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'range',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Electricity Use',
        label: 'Electricity Use',
        value: 'baseline.totalUtilUsages.electric',
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'range',
        tab: 'building',
        unit: UNIT_TYPES.kBTU
      },
      {
        name: 'Electricity Use Intensity',
        label: 'Electricity Use Intensity',
        value: 'baseline.electricityUseIntensity',
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'range',
        tab: 'building',
        unit: 'kBtu/ft\u00B2'
      },
      {
        name: 'Electricity Blended Rate',
        label: 'Electricity Blended Rate',
        value: 'projectRates.electric',
        select: 'range',
        tab: 'building'
      },
      {
        name: 'Natural Gas Cost',
        label: 'Natural Gas Cost',
        value: 'baseline.totalUtilCosts.naturalgas',
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        select: 'range',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Natural Gas Use',
        label: 'Natural Gas Use',
        value: 'baseline.totalUtilUsages.naturalgas',
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        select: 'range',
        tab: 'building',
        unit: UNIT_TYPES.kBTU
      },
      {
        name: 'Natural Gas Blended Rate',
        label: 'Natural Gas Blended Rate',
        value: 'projectRates.gas',
        select: 'range',
        tab: 'building'
      },
      {
        name: 'GHG Emissions Use',
        label: 'GHG Emissions Use',
        value: 'baseline.ghg',
        select: 'range',
        tab: 'building',
        unit: UNIT_TYPES.MTCO2E
      }
    ]
  },
  {
    name: 'Energy Use',
    value: 'Energy Use',
    subFields: [
      {
        name: 'Annual Energy Use',
        label: 'Annual Energy Use',
        value: buildingEnergySummaryKeys.avgAnnualEnergyUse,
        mongoValue: buildingEnergySummaryKeys.avgAnnualEnergyUse,
        select: 'range',
        tab: 'building',
        unit: UNIT_TYPES.MMBTU,
        paramName: 'energy_use'
      },
      {
        name: 'Energy Use Intensity',
        label: 'Energy Use Intensity',
        value: buildingEnergySummaryKeys.avgEnergyUseIntensity,
        mongoValue: buildingEnergySummaryKeys.avgEnergyUseIntensity,
        select: 'range',
        timeDepends: true,
        tab: 'building',
        unit: 'kBtu/ft\u00B2',
        paramName: 'energyuse_intensity'
      },
      {
        name: 'Electricity Use Intensity',
        label: 'Electricity Use Intensity',
        value: utilitiesKeysValues.electricIntensity,
        mongoValue: utilitiesKeysValues.electricIntensity,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'range',
        tab: 'building',
        unit: 'kBtu/ft\u00B2'
      },
      {
        name: 'Natural Gas Use Intensity',
        label: 'Natural Gas Use Intensity',
        value: utilitiesKeysValues.naturalGasIntensity,
        mongoValue: utilitiesKeysValues.naturalGasIntensity,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        select: 'range',
        tab: 'building',
        unit: 'kBtu/ft\u00B2'
      },
      {
        name: 'Energy Star Score',
        label: 'Energy Star Score',
        value: 'buildingPmScores.score',
        mongoValue: 'benchmark.pmScores',
        select: 'range',
        tab: 'building',
        paramName: 'energy_star_score'
      },
      {
        name: 'Annual Electricity Use',
        label: 'Annual Electricity Use',
        value: utilitiesKeysValues.electricUsage,
        mongoValue: utilitiesKeysValues.electricUsage,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'range',
        tab: 'building',
        unit: 'kWh',
        paramName: 'electric_use'
      },
      {
        name: 'Annual Electricity Demand Use',
        label: 'Annual Electricity Demand Use',
        value: utilitiesKeysValues.electricityDemandUsage,
        mongoValue: utilitiesKeysValues.electricityDemandUsage,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'range',
        tab: 'building',
        unit: UNIT_TYPES.kW,
        paramName: 'electric_demand_use'
      },
      {
        name: 'Annual Solar Generation Use',
        label: 'Annual Solar Generation Use',
        value: utilitiesKeysValues.solarUsage,
        mongoValue: utilitiesKeysValues.solarUsage,
        fuelType: UTILITY_TYPES.SOLAR,
        select: 'range',
        tab: 'building',
        unit: 'kWH',
        paramName: 'solar_use'
      },
      {
        name: 'Annual Natural Gas Use',
        label: 'Annual Natural Gas Use',
        value: utilitiesKeysValues.naturalGasUsage,
        mongoValue: utilitiesKeysValues.naturalGasUsage,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        select: 'range',
        tab: 'building',
        unit: 'therms',
        paramName: 'natural_gas_use'
      },
      {
        name: 'Annual Steam Use',
        label: 'Annual Steam Use',
        value: utilitiesKeysValues.steamUsage,
        mongoValue: utilitiesKeysValues.steamUsage,
        fuelType: UTILITY_TYPES.STEAM,
        select: 'range',
        tab: 'building',
        unit: 'Mlb',
        paramName: 'steam_use'
      },
      {
        name: 'Annual Fuel Oil 1 Use',
        label: 'Annual Fuel Oil 1 Use',
        value: utilitiesKeysValues.fuelOil1Usage,
        mongoValue: utilitiesKeysValues.fuelOil1Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_1,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'fuel_oil1_use'
      },
      {
        name: 'Annual Fuel Oil 2 Use',
        label: 'Annual Fuel Oil 2 Use',
        value: utilitiesKeysValues.fuelOil2Usage,
        mongoValue: utilitiesKeysValues.fuelOil2Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_2,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'fuel_oil2_use'
      },
      {
        name: 'Annual Fuel Oil 4 Use',
        label: 'Annual Fuel Oil 4 Use',
        value: utilitiesKeysValues.fuelOil4Usage,
        mongoValue: utilitiesKeysValues.fuelOil4Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_4,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'fuel_oil4_use'
      },
      {
        name: 'Annual Fuel Oil 5 & 6 Use',
        label: 'Annual Fuel Oil 5 & 6 Use',
        value: utilitiesKeysValues.fuelOil56Usage,
        mongoValue: utilitiesKeysValues.fuelOil56Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_56,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'fuel_oil56_use'
      },
      {
        name: 'Annual Diesel Use',
        label: 'Annual Diesel Use',
        value: utilitiesKeysValues.dieselUsage,
        mongoValue: utilitiesKeysValues.dieselUsage,
        fuelType: UTILITY_TYPES.DIESEL,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'diesel_use'
      },
      {
        name: 'Annual Propane Use',
        label: 'Annual Propane Use',
        value: utilitiesKeysValues.propaneUsage,
        mongoValue: utilitiesKeysValues.propaneUsage,
        fuelType: UTILITY_TYPES.PROPANE,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'propane_use'
      },
      {
        name: 'Annual Kerosene Use',
        label: 'Annual Kerosene Use',
        value: utilitiesKeysValues.keroseneUsage,
        mongoValue: utilitiesKeysValues.keroseneUsage,
        fuelType: UTILITY_TYPES.KEROSENE,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'kerosene_use'
      },
      {
        name: 'Annual District Hot Water Use',
        label: 'Annual District Hot Water Use',
        value: 'annual_districtHotWater_use',
        mongoValue: utilitiesKeysValues.districtHotWaterUsage,
        select: 'range',
        tab: 'building',
        unit: UNIT_TYPES.kBTU,
        paramName: 'districtHotWater_use'
      },
      {
        name: 'Annual District Chilled Water Electric Meter Use',
        label: 'Annual District Chilled Water Electric Meter Use',
        value: utilitiesKeysValues.districtChilledWaterElectricMeterUsage,
        mongoValue: utilitiesKeysValues.districtChilledWaterElectricMeterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER,
        select: 'range',
        tab: 'building',
        unit: 'ton hours',
        paramName: 'districtChilledWaterElectricMeter_use'
      },
      {
        name: 'Annual District Chilled Water Absorption Meter Use',
        label: 'Annual District Chilled Water Absorption Meter Use',
        value: utilitiesKeysValues.districtChilledWaterAbsorptionMeterUsage,
        mongoValue:
          utilitiesKeysValues.districtChilledWaterAbsorptionMeterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER,
        select: 'range',
        tab: 'building',
        unit: 'ton hours',
        paramName: 'districtChilledWaterAbsorptionMeter_use'
      },
      {
        name: 'Annual District Chilled Water Engine Meter Use',
        label: 'Annual District Chilled Water Engine Meter Use',
        value: utilitiesKeysValues.districtChilledWaterEngineMeterUsage,
        mongoValue: utilitiesKeysValues.districtChilledWaterEngineMeterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER,
        select: 'range',
        tab: 'building',
        unit: 'ton hours',
        paramName: 'districtChilledWaterEngineMeter_use'
      },
      {
        name: 'Annual District Chilled Water Other Meter Use',
        label: 'Annual District Chilled Water Other Meter Use',
        value: utilitiesKeysValues.districtChilledWaterOtherMeterUsage,
        mongoValue: utilitiesKeysValues.districtChilledWaterOtherMeterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER,
        select: 'range',
        tab: 'building',
        unit: 'ton hours',
        paramName: 'districtChilledWaterOtherMeter_use'
      },
      {
        name: 'Annual On-Site Wind Generation Use',
        label: 'Annual On-Site Wind Generation Use',
        value: utilitiesKeysValues.windUsage,
        mongoValue: utilitiesKeysValues.windUsage,
        fuelType: UTILITY_TYPES.WIND,
        select: 'range',
        tab: 'building',
        unit: 'kWH',
        paramName: 'wind_use'
      },
      {
        name: 'Annual Wood Use',
        label: 'Annual Wood Use',
        value: utilitiesKeysValues.woodUsage,
        mongoValue: utilitiesKeysValues.woodUsage,
        fuelType: UTILITY_TYPES.WOOD,
        select: 'range',
        tab: 'building',
        unit: 'tons',
        paramName: 'wood_use'
      }
    ]
  },
  {
    name: 'Energy Cost',
    value: 'Energy Cost',
    subFields: [
      {
        name: 'Annual Energy Cost',
        label: 'Annual Energy Cost',
        value: buildingEnergySummaryKeys.avgAnnualEnergyCost,
        mongoValue: buildingEnergySummaryKeys.avgAnnualEnergyCost,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'annual_cost'
      },
      {
        name: 'Energy Cost Intensity',
        label: 'Energy Cost Intensity',
        value: buildingEnergySummaryKeys.avgEnergyCostIntensity,
        mongoValue: buildingEnergySummaryKeys.avgEnergyCostIntensity,
        select: 'costRange',
        tab: 'building',
        unit: `$/${UNIT_TYPES.FT2}`,
        paramName: 'energycost_intensity'
      },
      {
        name: 'Annual Electricity Cost',
        label: 'Annual Electricity Cost',
        value: utilitiesKeysValues.electricCost,
        mongoValue: utilitiesKeysValues.electricCost,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'electric_cost'
      },
      {
        name: 'Annual Electricity Demand Cost',
        label: 'Annual Electricity Demand Cost',
        value: utilitiesKeysValues.electricityDemandCost,
        mongoValue: utilitiesKeysValues.electricityDemandCost,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'electric_demand_cost'
      },
      {
        name: 'Annual Solar Generation Cost',
        label: 'Annual Solar Generation Cost',
        value: utilitiesKeysValues.solarCost,
        mongoValue: utilitiesKeysValues.solarCost,
        fuelType: UTILITY_TYPES.SOLAR,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'solar_cost'
      },
      {
        name: 'Annual Natural Gas Cost',
        label: 'Annual Natural Gas Cost',
        value: utilitiesKeysValues.naturalGasCost,
        mongoValue: utilitiesKeysValues.naturalGasCost,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'natural_gas_cost'
      },
      {
        name: 'Annual Steam Cost',
        label: 'Annual Steam Cost',
        value: utilitiesKeysValues.steamCost,
        mongoValue: utilitiesKeysValues.steamCost,
        fuelType: UTILITY_TYPES.STEAM,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'steam_cost'
      },
      {
        name: 'Annual Fuel Oil 1 Cost',
        label: 'Annual Fuel Oil 1 Cost',
        value: utilitiesKeysValues.fuelOil1Cost,
        mongoValue: utilitiesKeysValues.fuelOil1Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_1,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'fuel_oil1_cost'
      },
      {
        name: 'Annual Fuel Oil 2 Cost',
        label: 'Annual Fuel Oil 2 Cost',
        value: utilitiesKeysValues.fuelOil2Cost,
        mongoValue: utilitiesKeysValues.fuelOil2Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_2,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'fuel_oil2_cost'
      },
      {
        name: 'Annual Fuel Oil 4 Cost',
        label: 'Annual Fuel Oil 4 Cost',
        value: utilitiesKeysValues.fuelOil4Cost,
        mongoValue: utilitiesKeysValues.fuelOil4Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_4,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'fuel_oil4_cost'
      },
      {
        name: 'Annual Fuel Oil 5 & 6 Cost',
        label: 'Annual Fuel Oil 5 & 6 Cost',
        value: utilitiesKeysValues.fuelOil56Cost,
        mongoValue: utilitiesKeysValues.fuelOil56Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_56,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'fuel_oil56_cost'
      },
      {
        name: 'Annual Diesel Cost',
        label: 'Annual Diesel Cost',
        value: utilitiesKeysValues.dieselCost,
        mongoValue: utilitiesKeysValues.dieselCost,
        fuelType: UTILITY_TYPES.DIESEL,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'diesel_cost'
      },
      {
        name: 'Annual Propane Cost',
        label: 'Annual Propane Cost',
        value: utilitiesKeysValues.propaneCost,
        mongoValue: utilitiesKeysValues.propaneCost,
        fuelType: UTILITY_TYPES.PROPANE,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'propane_cost'
      },
      {
        name: 'Annual Kerosene Cost',
        label: 'Annual Kerosene Cost',
        value: utilitiesKeysValues.keroseneCost,
        mongoValue: utilitiesKeysValues.keroseneCost,
        fuelType: UTILITY_TYPES.KEROSENE,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'kerosene_cost'
      },
      {
        name: 'Annual District Hot Water Cost',
        label: 'Annual District Hot Water Cost',
        value: utilitiesKeysValues.districtHotWaterCost,
        mongoValue: utilitiesKeysValues.districtHotWaterCost,
        fuelType: UTILITY_TYPES.DISTRICT_HOT_WATER,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'districtHotWater_cost'
      },
      {
        name: 'Annual District Chilled Water Electric Meter Cost',
        label: 'Annual District Chilled Water Electric Meter Cost',
        value: utilitiesKeysValues.districtChilledWaterElectricMeterCost,
        mongoValue: utilitiesKeysValues.districtChilledWaterElectricMeterCost,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'districtChilledWaterElectricMeter_cost'
      },
      {
        name: 'Annual District Chilled Water Absorption Meter Cost',
        label: 'Annual District Chilled Water Absorption Meter Cost',
        value: utilitiesKeysValues.districtChilledWaterAbsorptionMeterCost,
        mongoValue: utilitiesKeysValues.districtChilledWaterAbsorptionMeterCost,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'districtChilledWaterAbsorptionMeter_cost'
      },
      {
        name: 'Annual District Chilled Water Engine Meter Cost',
        label: 'Annual District Chilled Water Engine Meter Cost',
        value: utilitiesKeysValues.districtChilledWaterEngineMeterCost,
        mongoValue: utilitiesKeysValues.districtChilledWaterEngineMeterCost,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'districtChilledWaterEngineMeter_cost'
      },
      {
        name: 'Annual District Chilled Water Other Meter Use',
        label: 'Annual District Chilled Water Other Meter Use',
        value: utilitiesKeysValues.districtChilledWaterOtherMeterUsage,
        mongoValue: utilitiesKeysValues.districtChilledWaterOtherMeterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'districtChilledWaterOtherMeter_cost'
      },
      {
        name: 'Annual On-Site Wind Generation Cost',
        label: 'Annual On-Site Wind Generation Cost',
        value: utilitiesKeysValues.windCost,
        mongoValue: utilitiesKeysValues.windCost,
        fuelType: UTILITY_TYPES.WIND,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'wind_cost'
      },
      {
        name: 'Annual Wood Cost',
        label: 'Annual Wood Cost',
        value: utilitiesKeysValues.woodCost,
        mongoValue: utilitiesKeysValues.woodCost,
        fuelType: UTILITY_TYPES.WOOD,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'wood_cost'
      },
      {
        name: 'Electricity Rate',
        label: 'Electricity Rate',
        value: 'rates_electric',
        mongoValue: 'rates.electric',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Natural Gas Rate',
        label: 'Natural Gas Rate',
        value: 'rates_gas',
        mongoValue: 'rates.gas',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Water Rate',
        label: 'Water Rate',
        value: 'rates_water',
        mongoValue: 'rates.water',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Fuel Oil 2 Rate',
        label: 'Fuel Oil 2 Rate',
        value: 'rates_fueloil2',
        mongoValue: 'rates.fueloil2',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Fuel Oil 4 Rate',
        label: 'Fuel Oil 4 Rate',
        value: 'rates_fueloil4',
        mongoValue: 'rates.fueloil4',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Fuel Oil 5 & 6 Rate',
        label: 'Fuel Oil 5 & 6 Rate',
        value: 'rates_fueloil56',
        mongoValue: 'rates.fueloil56',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Steam Rate',
        label: 'Steam Rate',
        value: 'rates_steam',
        mongoValue: 'rates.steam',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Diesel Rate',
        label: 'Diesel Rate',
        value: 'rates_diesel',
        mongoValue: 'rates.diesel',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      }
    ]
  },
  {
    name: 'GHG Emissions',
    value: 'GHG Emissions',
    subFields: [
      {
        name: 'Annual GHG Emissions',
        label: 'Annual GHG Emissions',
        value: buildingEnergySummaryKeys.avgAnnualGHGEmissions,
        mongoValue: buildingEnergySummaryKeys.avgAnnualGHGEmissions,
        select: 'range',
        paramName: 'ghg_emission',
        tab: 'building',
        unit: UNIT_TYPES.MTCO2E
      },
      {
        name: 'GHG Intensity',
        label: 'GHG Intensity',
        value: buildingEnergySummaryKeys.avgGHGIntensity,
        mongoValue: buildingEnergySummaryKeys.avgGHGIntensity,
        select: 'range',
        tab: 'building',
        unit: 'mtCO2e/ft\u00B2',
        paramName: 'ghg_intensity'
      }
    ]
  },
  {
    name: 'Water Cost & Use',
    value: 'Water Cost & Use',
    subFields: [
      {
        name: 'Annual Water Use',
        label: 'Annual Water Use',
        value: utilitiesKeysValues.waterUsage,
        mongoValue: utilitiesKeysValues.waterUsage,
        fuelType: UTILITY_TYPES.WATER,
        select: 'range',
        tab: 'building',
        unit: 'ccf'
      },
      {
        name: 'Water Use Intensity',
        label: 'Water Use Intensity',
        value: utilitiesKeysValues.waterIntensity,
        mongoValue: utilitiesKeysValues.waterIntensity,
        fuelType: UTILITY_TYPES.WATER,
        select: 'range',
        tab: 'building',
        unit: 'ccf/ft\u00B2'
      },
      {
        name: 'Annual Water Cost',
        label: 'Annual Water Cost',
        value: utilitiesKeysValues.waterCost,
        mongoValue: utilitiesKeysValues.waterCost,
        fuelType: UTILITY_TYPES.WATER,
        select: 'costRange',
        tab: 'building',
        unit: '$'
      }
    ]
  },
  {
    name: 'Measure',
    value: 'Measure',
    subFields: [
      {
        name: 'Measure Name',
        label: 'Measure Name',
        value: 'displayname',
        mongoValue: 'displayName',
        select: 'multiSelect',
        paramName: 'measure_name',
        tab: 'project'
      },
      {
        name: 'Measure Cost',
        label: 'Measure Cost',
        value: 'metric_projectcost',
        mongoValue: 'metric.projectCost',
        select: 'range',
        paramName: 'measure_cost',
        tab: 'project',
        unit: '$'
      },
      {
        name: 'Measure Application',
        label: 'Measure Application',
        value: 'project_application',
        mongoValue: 'project_application',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Measure Category',
        label: 'Measure Category',
        value: 'project_category',
        mongoValue: 'project_category',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Measure Technology',
        label: 'Measure Technology',
        value: 'project_technology',
        mongoValue: 'project_technology',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Measures Identified',
        label: 'open',
        value: 'measureData.identified',
        select: 'range',
        tab: 'building'
      },
      {
        name: 'Measure In Progress',
        label: 'inProgress',
        value: 'measureData.inProgress',
        select: 'range',
        tab: 'building'
      },
      {
        name: 'Energy Cost Savings',
        label: 'Energy Cost Savings',
        value: 'metric_annualsavings',
        mongoValue: 'metric.annualSavings',
        select: 'range',
        paramName: 'cost_savings',
        tab: 'project',
        unit: '$'
      },
      {
        name: 'Electricity Savings - % of baseline',
        label: 'Electricity Savings - % of baseline',
        value: 'electricSavingsBaselinePercent',
        mongoValue: 'electricSavingsBaselinePercent',
        select: 'range',
        tab: 'project',
        unit: '%'
      },
      {
        name: 'Electricity Costs Savings',
        label: 'Electricity Costs Savings',
        value: 'metric_annualelectricsavings',
        mongoValue: 'metric.annualElectricSavings',
        select: 'range',
        tab: 'project',
        unit: '$'
      },
      {
        name: 'Natural Gas Reduction',
        label: 'Natural Gas Reduction',
        value: 'metric_gassavings',
        mongoValue: 'metric.gasSavings',
        select: 'range',
        paramName: 'natural_gas_reduction',
        tab: 'project',
        unit: 'therms',
        fuelType: UTILITY_TYPES.NATURAL_GAS
      },
      {
        name: 'Natural Gas Savings - % of baseline',
        label: 'Natural Gas Savings - % of baseline',
        value: 'gasSavingsBaselinePercent',
        mongoValue: 'gasSavingsBaselinePercent',
        select: 'range',
        tab: 'project',
        unit: '%'
      },
      {
        name: 'Natural Gas Costs Savings',
        label: 'Natural Gas Costs Savings',
        value: 'metric_annualgassavings',
        mongoValue: 'metric.annualGasSavings',
        select: 'range',
        tab: 'project',
        unit: '$'
      },
      {
        name: '$/GHG Reduction',
        label: '$/GHG Reduction',
        value: 'runresults_ghg_cost',
        mongoValue: 'metric.ghgSavingsCost',
        select: 'range',
        tab: 'project',
        paramName: 'ghg_emissions',
        unit: '$/mtCO2e'
      },
      {
        name: 'GHG Savings - % of baseline',
        label: 'GHG Savings - % of baseline',
        value: 'ghgSavingsBaselinePercent',
        mongoValue: 'ghgSavingsBaselinePercent',
        select: 'range',
        tab: 'project',
        unit: '%'
      },
      {
        name: 'Electricity Demand Savings',
        label: 'Electricity Demand Savings',
        value: 'runresults_energy_savings_demand',
        mongoValue: 'metric.demandSavings',
        select: 'range',
        tab: 'project',
        unit: 'kWh'
      },
      {
        name: 'Electricity Savings',
        label: 'Electricity Savings',
        value: 'metric_electricsavings',
        mongoValue: 'metric.electricSavings',
        select: 'range',
        unit: 'kWh',
        tab: 'project',
        paramName: 'electric_reduction',
        fuelType: UTILITY_TYPES.ELECTRICITY
      },
      {
        name: 'Status',
        label: 'Status',
        value: 'status',
        mongoValue: 'status',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Type',
        label: 'Type',
        value: 'type',
        mongoValue: 'type',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Total Budget',
        label: 'totalBudget',
        value: 'measureData.totalBudget',
        select: 'range',
        tab: 'building'
      },
      {
        name: 'Created By',
        label: 'Created By',
        value: 'project.createdBy.id',
        mongoValue: 'createdByUserId',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Created',
        label: 'Created',
        value: 'project.created',
        mongoValue: 'created',
        select: 'dateRange',
        tab: 'project'
      },
      {
        name: 'Updated',
        label: 'Updated',
        value: 'project.updated',
        mongoValue: 'updated',
        select: 'dateRange',
        tab: 'project'
      }
    ]
  },
  {
    name: 'Project',
    value: 'ProjectPackage',
    subFields: [
      {
        name: 'Project Name',
        label: 'Project Name',
        value: 'name',
        mongoValue: 'name',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Status',
        label: 'Status',
        value: 'status',
        mongoValue: 'status',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Construction Status',
        label: 'Construction Status',
        value: 'constructionstatus',
        mongoValue: 'constructionStatus',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Energy Cost Savings',
        label: 'Energy Cost Savings',
        value: 'total_annualsavings',
        mongoValue: 'total.annualSavings',
        select: 'range',
        unit: '$',
        tab: 'projectPackage'
      },
      {
        name: 'Energy Savings',
        label: 'Energy Savings',
        value: 'total_energysavings',
        mongoValue: 'total.energySavings',
        select: 'range',
        unit: UNIT_TYPES.kBTU,
        tab: 'projectPackage'
      },
      {
        name: 'Electricity Savings',
        label: 'Electricity Savings',
        value: 'total_electric',
        mongoValue: 'total.electric',
        select: 'range',
        unit: 'kWh',
        tab: 'projectPackage',
        fuelType: UTILITY_TYPES.ELECTRICITY
      },
      {
        name: 'Electricity Demand Savings',
        label: 'Electricity Demand Savings',
        value: 'total_demandsavings',
        mongoValue: 'total.demandSavings',
        unit: 'kWh',
        select: 'range',
        tab: 'projectPackage'
      },
      {
        name: 'Natural Gas Reduction',
        label: 'Natural Gas Reduction',
        value: 'total_gassavings',
        mongoValue: 'total.gasSavings',
        select: 'range',
        unit: 'therms',
        tab: 'projectPackage',
        fuelType: UTILITY_TYPES.NATURAL_GAS
      },
      {
        name: '$/GHG Reduction',
        label: '$/GHG Reduction',
        value: 'total_ghgsavingscost',
        mongoValue: 'total.ghgSavingsCost',
        select: 'range',
        unit: '$ /mtCO2e',
        tab: 'projectPackage'
      },
      {
        name: 'Project Cost',
        label: 'Project Cost',
        value: 'total_projectcost',
        mongoValue: 'total.projectCost',
        select: 'costRange',
        unit: '$',
        tab: 'projectPackage'
      },
      {
        name: 'Effective Useful Life',
        label: 'Effective Useful Life',
        value: 'total_eul',
        mongoValue: 'total.eul',
        select: 'range',
        unit: 'yrs',
        tab: 'projectPackage'
      },
      {
        name: 'Created By',
        label: 'Created By',
        value: 'projectPackage.createdBy.id',
        mongoValue: 'createdByUserId',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Created',
        label: 'Created',
        value: 'projectPackage.created',
        mongoValue: 'created',
        select: 'dateRange',
        tab: 'projectPackage'
      },
      {
        name: 'Updated',
        label: 'Updated',
        value: 'projectPackage.updated',
        mongoValue: 'updated',
        select: 'dateRange',
        tab: 'projectPackage'
      }
    ]
  }
]

// TODO: Submenu by energy type in Baseline and utilities
export const buildingColumnOptions = [
  {
    name: 'Property',
    value: 'info',
    subFields: [
      {
        value: 'buildingname',
        label: 'Name',
        option: 'left',
        order: 1
      },
      {
        label: 'Organization Name',
        value: 'organization.name',
        option: 'left',
        order: 2
      },
      {
        value: 'location_address',
        label: 'Address',
        option: 'left',
        order: 3
      },
      {
        value: 'location_city',
        label: 'City',
        option: 'left',
        order: 4
      },
      {
        value: 'location_state',
        label: 'State/ Province',
        option: 'left',
        order: 5
      },
      {
        value: 'location_zipcode',
        label: 'Zip Code',
        option: 'left',
        order: 6
      },
      {
        value: 'nycfields_borough',
        label: 'Borough',
        option: 'left',
        order: 7
      },
      {
        label: 'Primary Use',
        value: 'buildinguse',
        option: 'left',
        order: 8
      },
      {
        name: 'Gross Floor Area',
        label: 'Gross Floor Area',
        value: 'squarefeet',
        unit: UNIT_TYPES.FT2,
        order: 9,
        total: true
      },
      {
        label: 'Above Grade Floors',
        value: 'floorcount',
        option: 'right',
        order: 10
      },
      {
        label: 'Below Grade Floors',
        value: 'belowgradefloorcount',
        option: 'right',
        order: 11
      },
      {
        label: 'Year Built',
        value: 'buildyear',
        option: 'left',
        order: 12
      },
      {
        label: 'Secondary Use Types',
        value: 'buildingUseTypes.use',
        option: 'left',
        order: 19
      },
      {
        label: 'Secondary Use Type Area',
        value: 'buildingUseTypes.squarefeet',
        option: 'left',
        unit: UNIT_TYPES.FT2,
        order: 20
      },
      {
        value: 'nycfields_historicbuilding',
        label: 'Historic Building',
        option: 'left',
        order: 49
      },
      {
        value: 'nycfields_multitenant',
        label: 'Multi-Tenant',
        option: 'left',
        order: 50
      },
      {
        value: 'nycfields_percentleased',
        label: 'Percent Leased',
        option: 'left',
        order: 51
      },
      {
        value: 'nycfields_percentowned',
        label: 'Percent Owned',
        option: 'left',
        order: 52
      },
      {
        value: 'nycfields_energy_sys_multiple_lots',
        label: 'Shared Energy on Multiple Lots',
        option: 'left',
        order: 53
      },
      {
        value: 'nycfields_energy_sys_single_lotst',
        label: 'Shared Energy on Single Lot',
        option: 'left',
        order: 54
      },
      {
        value: 'open247',
        label: 'Open 24/7',
        option: 'left',
        order: 55
      },
      {
        value: 'sitename',
        label: 'Site Name',
        option: 'left',
        order: 57
      },
      {
        value: 'clientname',
        label: 'Client Name',
        option: 'left',
        order: 58
      },
      {
        label: 'Total Cost Saving',
        value: 'totalCostSaving',
        option: 'right',
        decimals: 0,
        unit: '$'
      },
      {
        value: 'nycfields_bin',
        label: 'Bin',
        option: 'left'
      },
      {
        value: 'nycfields_taxlot',
        label: 'Tax Lot',
        option: 'left'
      },
      {
        value: 'nycfields_block',
        label: 'Block',
        option: 'left'
      }
    ]
  },
  {
    name: 'Utilities',
    value: 'utilities',
    subFields: [
      {
        label: 'Avg. Annual Electricity Use',
        value: utilitiesKeysValues.electricUsage,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit: 'kWh',
        order: 15,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        targetKey: targetDictionary.ENERGY,
        baselineKey: 'totalEnergyUsage',
        colorComparison: true,
        decimals: 0
      },
      {
        label: 'Avg. Annual Electricity Cost',
        value: utilitiesKeysValues.electricCost,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit: '$',
        order: 16,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        targetKey: targetDictionary.COST,
        baselineKey: 'totalEnergyCost',
        colorComparison: true,
        decimals: 0
      },
      {
        label: 'Electricity Use Intensity',
        value: utilitiesKeysValues.electricIntensity,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit: 'kBtu/ft\u00B2',
        order: 17,
        timeDepends: true,
        total: true,
        baselineKey: 'eui',
        colorComparison: true,
        decimals: 0
      },
      {
        label: 'Avg. Annual Electricity Demand Use',
        value: utilitiesKeysValues.electricityDemandUsage,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'left',
        unit: UNIT_TYPES.kW,
        order: 18,
        timeDepends: true,
        total: true,
        decimals: 0
      },
      {
        label: 'Avg. Annual Electricity Demand Cost',
        value: utilitiesKeysValues.electricityDemandCost,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'left',
        unit: '$',
        order: 19,
        timeDepends: true,
        total: true,
        decimals: 0
      },
      {
        label: 'Avg. Annual Solar Generation Use',
        value: utilitiesKeysValues.solarUsage,
        fuelType: UTILITY_TYPES.SOLAR,
        option: 'left',
        unit: 'kWH',
        order: 20,
        timeDepends: true,
        total: true,
        decimals: 0
      },
      {
        label: 'Avg. Annual Solar Generation Cost',
        value: utilitiesKeysValues.solarCost,
        fuelType: UTILITY_TYPES.SOLAR,
        option: 'left',
        unit: '$',
        order: 21,
        timeDepends: true,
        total: true,
        decimals: 0
      },
      {
        label: 'Avg. Annual Natural Gas Use',
        value: utilitiesKeysValues.naturalGasUsage,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'left',
        unit: UNIT_TYPES.kBTU,
        order: 22,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        targetKey: targetDictionary.ENERGY,
        baselineKey: 'totalEnergyUsage',
        colorComparison: true,
        decimals: 0
      },
      {
        label: 'Avg. Annual Natural Gas Cost',
        value: utilitiesKeysValues.naturalGasCost,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'left',
        unit: '$',
        order: 23,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        targetKey: targetDictionary.COST,
        baselineKey: 'totalEnergyCost',
        colorComparison: true,
        decimals: 0
      },
      {
        label: 'Natural Gas Use Intensity',
        value: utilitiesKeysValues.naturalGasIntensity,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'right',
        unit: 'kBtu/ft\u00B2',
        order: 24,
        timeDepends: true,
        total: true,
        baselineKey: 'eui',
        colorComparison: true,
        decimals: 2
      },
      {
        label: 'Avg. Annual Steam Use',
        value: utilitiesKeysValues.steamUsage,
        fuelType: UTILITY_TYPES.STEAM,
        option: 'left',
        unit: 'Mlb',
        timeDepends: true,
        total: true,
        order: 25,
        decimals: 0
      },
      {
        label: 'Avg. Annual Steam Cost',
        value: utilitiesKeysValues.steamCost,
        fuelType: UTILITY_TYPES.STEAM,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 26,
        decimals: 0
      },
      {
        label: 'Avg. Annual Fuel Oil 1 Use',
        value: utilitiesKeysValues.fuelOil1Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_1,
        option: 'left',
        unit: 'gals',
        timeDepends: true,
        total: true,
        order: 27,
        decimals: 0
      },
      {
        label: 'Avg. Annual Fuel Oil 1 Cost',
        value: utilitiesKeysValues.fuelOil1Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_1,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 28,
        decimals: 0
      },
      {
        label: 'Avg. Annual Fuel Oil 2 Use',
        value: utilitiesKeysValues.fuelOil2Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_2,
        option: 'left',
        unit: 'gals',
        timeDepends: true,
        total: true,
        order: 29,
        decimals: 0
      },
      {
        label: 'Avg. Annual Fuel Oil 2 Cost',
        value: utilitiesKeysValues.fuelOil2Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_2,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 30,
        decimals: 0
      },
      {
        label: 'Avg. Annual Fuel Oil 4 Use',
        value: utilitiesKeysValues.fuelOil4Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_4,
        option: 'left',
        unit: 'gals',
        timeDepends: true,
        total: true,
        order: 31,
        decimals: 0
      },
      {
        label: 'Avg. Annual Fuel Oil 4 Cost',
        value: utilitiesKeysValues.fuelOil4Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_4,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 32,
        decimals: 0
      },
      {
        label: 'Avg. Annual Fuel Oil 5 & 6 Use',
        value: utilitiesKeysValues.fuelOil56Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_56,
        option: 'left',
        unit: 'gals',
        timeDepends: true,
        total: true,
        order: 33,
        decimals: 0
      },
      {
        label: 'Avg. Annual Fuel Oil 5 & 6 Cost',
        value: utilitiesKeysValues.fuelOil56Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_56,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 34,
        decimals: 0
      },
      {
        label: 'Avg. Annual Diesel Use',
        value: utilitiesKeysValues.dieselUsage,
        fuelType: UTILITY_TYPES.DIESEL,
        option: 'left',
        unit: 'gals',
        timeDepends: true,
        total: true,
        order: 35,
        decimals: 0
      },
      {
        label: 'Avg. Annual Diesel Cost',
        value: utilitiesKeysValues.dieselCost,
        fuelType: UTILITY_TYPES.DIESEL,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 36,
        decimals: 0
      },
      {
        label: 'Avg. Annual Propane Use',
        value: utilitiesKeysValues.propaneUsage,
        fuelType: UTILITY_TYPES.PROPANE,
        option: 'left',
        unit: 'gals',
        timeDepends: true,
        total: true,
        order: 37,
        decimals: 0
      },
      {
        label: 'Avg. Annual Propane Cost',
        value: utilitiesKeysValues.propaneCost,
        fuelType: UTILITY_TYPES.PROPANE,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 38,
        decimals: 0
      },
      {
        label: 'Avg. Annual Kerosene Use',
        value: utilitiesKeysValues.keroseneUsage,
        fuelType: UTILITY_TYPES.KEROSENE,
        option: 'left',
        unit: 'gals',
        timeDepends: true,
        total: true,
        order: 39,
        decimals: 0
      },
      {
        label: 'Avg. Annual Kerosene Cost',
        value: utilitiesKeysValues.keroseneCost,
        fuelType: UTILITY_TYPES.KEROSENE,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 40,
        decimals: 0
      },
      {
        label: 'Avg. Annual District Hot Water Use',
        value: utilitiesKeysValues.districtHotWaterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_HOT_WATER,
        option: 'left',
        unit: UNIT_TYPES.kBTU,
        timeDepends: true,
        total: true,
        order: 41,
        decimals: 0
      },
      {
        label: 'Avg. Annual District Hot Water Cost',
        value: utilitiesKeysValues.districtHotWaterCost,
        fuelType: UTILITY_TYPES.DISTRICT_HOT_WATER,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 42,
        decimals: 0
      },
      {
        label: 'Avg. Annual District Chilled Water Electric Meter Use',
        value: utilitiesKeysValues.districtChilledWaterElectricMeterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER,
        option: 'left',
        unit: 'ton hours',
        timeDepends: true,
        total: true,
        order: 43,
        decimals: 0
      },
      {
        label: 'Avg. Annual District Chilled Water Electric Meter Cost',
        value: utilitiesKeysValues.districtChilledWaterElectricMeterCost,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 44,
        decimals: 0
      },
      {
        label: 'Avg. Annual District Chilled Water Absorption Meter Use',
        value: utilitiesKeysValues.districtChilledWaterAbsorptionMeterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER,
        option: 'left',
        unit: 'ton hours',
        timeDepends: true,
        total: true,
        order: 45,
        decimals: 0
      },
      {
        label: 'Avg. Annual District Chilled Water Absorption Meter Cost',
        value: utilitiesKeysValues.districtChilledWaterAbsorptionMeterCost,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 46,
        decimals: 0
      },
      {
        label: 'Avg. Annual District Chilled Water Engine Meter Use',
        value: utilitiesKeysValues.districtChilledWaterEngineMeterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER,
        option: 'left',
        unit: 'ton hours',
        timeDepends: true,
        total: true,
        order: 47,
        decimals: 0
      },
      {
        label: 'Avg. Annual District Chilled Water Engine Meter Cost',
        value: utilitiesKeysValues.districtChilledWaterEngineMeterCost,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 48,
        decimals: 0
      },
      {
        label: 'Avg. Annual District Chilled Water Other Meter Use',
        value: utilitiesKeysValues.districtChilledWaterOtherMeterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER,
        option: 'left',
        unit: 'ton hours',
        timeDepends: true,
        total: true,
        order: 49,
        decimals: 0
      },
      {
        label: 'Avg. Annual District Chilled Water Other Meter Cost',
        value: utilitiesKeysValues.districtChilledWaterOtherMeterCost,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 50,
        decimals: 0
      },
      {
        label: 'Avg. Annual On-Site Wind Generation Use',
        value: utilitiesKeysValues.windUsage,
        fuelType: UTILITY_TYPES.WIND,
        option: 'left',
        unit: 'kWH',
        timeDepends: true,
        total: true,
        order: 51,
        decimals: 0
      },
      {
        label: 'Avg. Annual On-Site Wind Generation Cost',
        value: utilitiesKeysValues.windCost,
        fuelType: UTILITY_TYPES.WIND,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 52,
        decimals: 0
      },
      {
        label: 'Avg. Annual Wood Use',
        value: utilitiesKeysValues.woodUsage,
        fuelType: UTILITY_TYPES.WOOD,
        option: 'left',
        unit: 'tons',
        timeDepends: true,
        total: true,
        order: 53,
        decimals: 0
      },
      {
        label: 'Avg. Annual Wood Cost',

        value: utilitiesKeysValues.woodCost,
        fuelType: UTILITY_TYPES.WOOD,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 54,
        decimals: 0
      },
      {
        label: 'Avg. Annual Water Use',
        value: utilitiesKeysValues.waterUsage,
        fuelType: UTILITY_TYPES.WATER,
        option: 'left',
        unit: 'ccf',
        order: 55,
        timeDepends: true,
        decimals: 0
      },
      {
        label: 'Water Use Intensity',
        value: utilitiesKeysValues.waterIntensity,
        fuelType: UTILITY_TYPES.WATER,
        option: 'left',
        unit: 'ccf/ft\u00B2',
        order: 56,
        timeDepends: true,
        decimals: 2
      },
      {
        label: 'Avg. Annual Water Cost',
        value: utilitiesKeysValues.waterCost,
        fuelType: UTILITY_TYPES.WATER,
        option: 'left',
        unit: '$',
        order: 57,
        timeDepends: true,
        total: true,
        decimals: 0
      }
    ]
  },
  {
    name: 'Overview',
    value: 'overview',
    subFields: [
      {
        label: 'Avg. Annual Energy Use',
        value: buildingEnergySummaryKeys.avgAnnualEnergyUse,
        option: 'right',
        unit: UNIT_TYPES.MMBTU,
        order: 14,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        targetKey: targetDictionary.ENERGY,
        baselineKey: 'totalEnergyUsage',
        colorComparison: true,
        decimals: 0
      },
      {
        label: 'Avg. Energy Use Intensity',
        value: buildingEnergySummaryKeys.avgEnergyUseIntensity,
        option: 'right',
        unit: 'kBtu/ft\u00B2',
        order: 13,
        timeDepends: true,
        baselineKey: 'eui',
        colorComparison: true,
        decimals: 2
      },
      {
        label: 'Avg. Annual Energy Cost',
        value: buildingEnergySummaryKeys.avgAnnualEnergyCost,
        option: 'right',
        unit: '$',
        order: 15,
        timeDepends: true,
        total: true,
        width: 150,
        appendTargetColumns: true,
        targetKey: targetDictionary.COST,
        baselineKey: 'totalEnergyCost',
        colorComparison: true,
        decimals: 0
      },
      {
        label: 'Avg. Energy Cost Intensity',
        value: buildingEnergySummaryKeys.avgEnergyCostIntensity,
        option: 'right',
        unit: `$/${UNIT_TYPES.FT2}`,
        order: 13,
        timeDepends: true,
        decimals: 2
      },
      {
        label: 'Energy Star Score',
        value: 'buildingPmScores.score',
        option: 'right',
        order: 12,
        decimals: 0
      },
      {
        label: 'Avg. Annual GHG Emissions',
        value: buildingEnergySummaryKeys.avgAnnualGHGEmissions,
        option: 'right',
        unit: UNIT_TYPES.MTCO2E,
        order: 16,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        targetKey: targetDictionary.GHG_EMISSIONS,
        baselineKey: 'ghg',
        width: 150,
        colorComparison: false,
        decimals: 2
      },
      {
        label: 'Avg. GHG Intensity',
        value: buildingEnergySummaryKeys.avgGHGIntensity,
        option: 'right',
        unit: 'mtCO2e/ft\u00B2',
        order: 17,
        timeDepends: true,
        baselineCalculated: building =>
          building.baseline.ghg / building.squarefeet,
        colorComparison: false,
        decimals: 3
      }
    ]
  },
  {
    name: 'Measures',
    value: 'Projects',
    subFields: [
      {
        label: 'Measures',
        value: 'projects',
        option: 'right',
        order: 1,
        total: true
      },
      {
        label: 'Measures In Progress',
        value: 'measureData.inProgress',
        option: 'right',
        order: 2,
        total: true
      },
      {
        label: 'Measures Identified',
        value: 'measureData.identified',
        option: 'right',
        order: 3,
        total: true
      },
      {
        label: 'Total Budget',
        value: 'measureData.totalBudget',
        option: 'right',
        unit: '$',
        order: 5,
        total: true
      },
      {
        label: 'Budget Plan Year',
        value: 'measureData.budgetPeriod',
        option: 'right',
        order: 6
      }
    ]
  },
  {
    name: 'Task Lists',
    value: 'Task Lists',
    subFields: [
      {
        label: 'Task Completion',
        value: 'taskCompletionPercent',
        option: 'right',
        unit: '%',
        order: 1
      }
    ]
  },
  {
    name: 'Baseline',
    value: 'Baseline',
    subFields: [
      {
        label: 'Energy Cost Intensity',
        value: 'baseline.totalEnergyCostIntensity',
        option: 'right',
        unit: `$/${UNIT_TYPES.FT2}`,
        order: 1,
        total: true,
        decimals: 2
      },
      {
        label: 'Energy Use Intensity',
        value: 'baseline.eui',
        option: 'right',
        unit: 'kBtu/ft\u00B2',
        order: 2,
        total: true,
        decimals: 2
      },
      {
        label: 'Electricity Cost',
        value: 'baseline.totalUtilCosts.electric',
        option: 'right',
        unit: '$',
        order: 3,
        total: true,
        decimals: 0
      },
      {
        label: 'Electricity Use',
        value: 'baseline.totalUtilUsages.electric',
        option: 'right',
        unit: UNIT_TYPES.kBTU,
        order: 4,
        total: true,
        decimals: 0
      },
      {
        label: 'Electricity Use Intensity',
        value: 'baseline.electricityUseIntensity',
        option: 'right',
        unit: 'kBtu/ft\u00B2',
        order: 5,
        total: true,
        decimals: 2
      },
      {
        label: 'Electricity Blended Rate',
        value: 'projectRates.electric',
        option: 'right',
        order: 6,
        total: true,
        decimals: 2
      },
      {
        label: 'Natural Gas Cost',
        value: 'baseline.totalUtilCosts.naturalgas',
        option: 'right',
        unit: '$',
        order: 7,
        total: true,
        decimals: 0
      },
      {
        label: 'Natural Gas Use',
        value: 'baseline.totalUtilUsages.naturalgas',
        option: 'right',
        unit: 'therms',
        order: 8,
        total: true,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        decimals: 0
      },
      {
        label: 'Natural Gas Blended Rate',
        value: 'projectRates.gas',
        option: 'right',
        order: 9,
        total: true,
        decimals: 2
      },
      {
        label: 'GHG Emissions Use',
        value: 'baseline.ghg',
        option: 'right',
        unit: UNIT_TYPES.MTCO2E,
        order: 10,
        total: true,
        decimals: 2
      }
    ]
  },
  {
    name: 'Author',
    value: 'Author',
    subFields: [
      {
        label: 'Created',
        value: 'created'
      },
      {
        label: 'Updated',
        value: 'updated'
      },
      {
        value: 'createdBy.name',
        label: 'Author',
        option: 'left'
      }
    ]
  }
]

export const defaultBuildingColumn = [
  {
    value: 'buildingname',
    label: 'Name',
    option: 'left',
    order: 1
  },
  {
    value: 'organization.name',
    label: 'Organization Name',
    option: 'left',
    order: 2
  },
  {
    value: 'location_address',
    label: 'Address',
    option: 'left',
    order: 3
  },
  {
    value: 'location_city',
    label: 'City',
    option: 'left',
    order: 4
  },
  {
    value: 'location_state',
    label: 'State/ Province',
    option: 'left',
    order: 5
  },
  {
    value: 'location_zipcode',
    label: 'Zip Code',
    option: 'left',
    order: 6
  },
  {
    value: 'nycfields_borough',
    label: 'Borough',
    option: 'left',
    order: 7
  },
  {
    label: 'Primary Use',
    value: 'buildinguse',
    option: 'left',
    order: 8
  },
  {
    name: 'Gross Floor Area',
    label: 'Gross Floor Area',
    value: 'squarefeet',
    unit: UNIT_TYPES.FT2,
    order: 9,
    total: true
  },
  {
    label: 'Above Grade Floors',
    value: 'floorcount',
    option: 'right',
    order: 10
  },
  {
    label: 'Below Grade Floors',
    value: 'belowgradefloorcount',
    option: 'right',
    order: 11
  },
  {
    label: 'Year Built',
    value: 'buildyear',
    option: 'left',
    order: 12
  },
  {
    label: 'Energy Star Score',
    value: 'buildingPmScores.score',
    option: 'right',
    order: 13
  },
  {
    label: 'Avg. Energy Use Intensity',
    value: buildingEnergySummaryKeys.avgEnergyUseIntensity,
    option: 'right',
    unit: 'kBtu/ft\u00B2',
    order: 14,
    baselineKey: 'eui',
    timeDepends: true,
    colorComparison: true,
    decimals: 2
  },
  {
    label: 'Avg. Annual Energy Use',
    value: buildingEnergySummaryKeys.avgAnnualEnergyUse,
    option: 'right',
    unit: UNIT_TYPES.MMBTU,
    order: 15,
    timeDepends: true,
    total: true,
    appendTargetColumns: true,
    targetKey: targetDictionary.ENERGY,
    baselineKey: 'totalEnergyUsage',
    colorComparison: true,
    decimals: 0
  },
  {
    label: 'Avg. Annual Energy Cost',
    value: buildingEnergySummaryKeys.avgAnnualEnergyCost,
    option: 'right',
    unit: '$',
    order: 18,
    timeDepends: true,
    total: true,
    appendTargetColumns: true,
    targetKey: targetDictionary.COST,
    baselineKey: 'totalEnergyCost',
    width: 150,
    colorComparison: true,
    decimals: 0
  },
  {
    label: 'Avg. Annual GHG Emissions',
    value: buildingEnergySummaryKeys.avgAnnualGHGEmissions,
    option: 'right',
    unit: UNIT_TYPES.MTCO2E,
    order: 21,
    timeDepends: true,
    total: true,
    appendTargetColumns: true,
    targetKey: targetDictionary.GHG_EMISSIONS,
    baselineKey: 'ghg',
    width: 150,
    colorComparison: false,
    decimals: 2
  },
  {
    label: 'Avg. GHG Intensity',
    value: buildingEnergySummaryKeys.avgGHGIntensity,
    option: 'right',
    unit: 'mtCO2e/ft\u00B2',
    order: 23,
    timeDepends: true,
    baselineCalculated: building => building.baseline.ghg / building.squarefeet,
    colorComparison: false,
    decimals: 3
  },
  {
    label: 'Created',
    value: 'created',
    order: 25
  },
  {
    label: 'Updated',
    value: 'updated',
    order: 26
  },
  {
    value: 'createdBy.name',
    label: 'Author',
    option: 'left',
    order: 27
  }
]

// Projects -> Measures

export const defaultProjectColumn = [
  {
    value: 'displayname',
    label: 'Name',
    order: 1
  },
  {
    value: 'building.buildingname',
    label: 'Building Name',
    order: 2
  },
  {
    value: 'organization.name',
    label: 'Organization Name',
    order: 3
  },
  {
    name: 'Status',
    value: 'status',
    label: 'Status',
    order: 4
  },
  {
    name: 'Type',
    value: 'type',
    label: 'Type',
    order: 5
  },
  {
    name: 'Project Name',
    value: 'project_name',
    label: 'Project Name',
    order: 6
  },
  {
    name: 'Budget Type',
    value: 'budgetType',
    label: 'Budget Type',
    order: 7
  },
  {
    name: 'Total Budget',
    value: 'metric.totalBudget',
    label: 'Total Budget',
    order: 8,
    decimals: 0
  },
  {
    name: 'Annual Cost Savings',
    label: 'Annual Cost Savings',
    value: 'metric.annualSavings',
    unit: '$',
    total: true,
    order: 9,
    decimals: 0
  },
  {
    name: 'Energy Savings',
    label: 'Energy Savings',
    value: 'metric_energysavings',
    unit: UNIT_TYPES.kBTU,
    total: true,
    order: 11,
    decimals: 0
  },
  {
    name: 'Electricity Savings',
    label: 'Electricity Savings',
    value: 'metric_electricsavings',
    unit: 'kWh',
    fuelType: UTILITY_TYPES.ELECTRICITY,
    total: true,
    order: 12,
    decimals: 0
  },
  {
    name: 'Demand Savings',
    label: 'Demand Savings',
    value: 'metric_demandsavings',
    unit: UNIT_TYPES.kW,
    total: true,
    order: 13,
    decimals: 0
  },
  {
    name: 'Natural Gas Savings',
    label: 'Natural Gas Savings',
    value: 'metric_gassavings',
    unit: 'therms',
    fuelType: UTILITY_TYPES.NATURAL_GAS,
    total: true,
    order: 14,
    decimals: 0
  },
  {
    name: 'GHG Saving',
    label: 'GHG Saving',
    value: 'metric_ghgsavings',
    unit: UNIT_TYPES.MTCO2E,
    total: true,
    order: 15,
    decimals: 2
  },
  {
    name: 'GHG Savings Cost',
    label: 'GHG Savings Cost',
    value: 'metric_ghgsavingscost',
    unit: '$/mtCO2e',
    total: true,
    order: 16,
    decimals: 0
  },
  {
    label: 'Measure Cost',
    value: 'metric_projectcost',
    unit: '$',
    total: true,
    order: 17,
    decimals: 0
  },
  {
    label: 'Incentive',
    value: 'metric_incentive',
    unit: '$',
    total: true,
    order: 18,
    decimals: 0
  },
  {
    name: 'Maintenance Savings',
    label: 'Maintenance Savings',
    value: 'initialvalues_maintenance_savings',
    unit: '$',
    total: true,
    order: 19,
    decimals: 0
  },
  {
    name: 'Simple Payback',
    label: 'Simple Payback',
    value: 'metric_simple_payback',
    order: 20,
    decimals: 1
  },
  {
    name: 'Rebate Code',
    label: 'Rebate Code',
    value: 'incentive_rebate_code',
    order: 25
  },
  {
    name: 'Measure Category',
    label: 'Measure Category',
    value: 'project_category'
  },
  {
    name: 'Measure Application',
    label: 'Measure Application',
    value: 'project_application'
  },
  {
    name: 'Measure Technology',
    label: 'Measure Technology',
    value: 'project_technology'
  },
  {
    name: 'Effective Useful Life',
    label: 'Effective Useful Life',
    value: 'metric.eul',
    unit: 'yrs',
    total: true,
    decimals: 0
  },
  {
    name: 'updated',
    label: 'Updated',
    value: 'updated'
  },
  {
    name: 'created',
    label: 'Created',
    value: 'created'
  },
  {
    value: 'createdBy.name',
    label: 'Author',
    option: 'left'
  }
]

export const projectFilterOptions = [
  {
    name: 'Organization',
    value: 'Organization',
    subFields: [
      {
        name: 'Organization Name',
        label: 'Organization Name',
        value: 'organization.name',
        mongoValue: 'organization.name',
        select: 'multiSelect',
        tab: 'building'
      }
    ]
  },
  {
    name: 'Property',
    value: 'info',
    subFields: [
      {
        name: 'Primary Use',
        label: 'Primary Use',
        value: 'buildinguse',
        mongoValue: 'buildingUse',
        select: 'multiSelect',
        paramName: 'building_usetype',
        tab: 'building'
      },
      {
        name: 'Gross Floor Area',
        label: 'Gross Floor Area',
        value: 'squarefeet',
        mongoValue: 'squareFeet',
        select: 'range',
        tab: 'building',
        unit: UNIT_TYPES.FT2,
        paramName: 'gross_floor_area'
      },
      {
        name: 'Year Built',
        label: 'Year Built',
        value: 'buildyear',
        mongoValue: 'buildYear',
        select: 'yearRange',
        tab: 'building',
        paramName: 'year_built'
      },
      {
        name: 'City',
        label: 'City',
        value: 'location_city',
        mongoValue: 'location.city',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'city'
      },
      {
        name: 'State/Province',
        label: 'State/Province',
        value: 'location_state',
        mongoValue: 'location.state',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'state'
      },
      {
        name: 'Zip Code',
        label: 'Zip Code',
        value: 'location_zipcode',
        mongoValue: 'location.zipCode',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'zip_code'
      },
      {
        name: 'Borough',
        label: 'Borough',
        value: 'nycfields_borough',
        mongoValue: 'nycFields.borough',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'borough'
      },
      {
        name: 'Created By',
        label: 'Created By',
        value: 'building.createdBy.id',
        mongoValue: 'createdByUserId',
        select: 'multiSelect',
        tab: 'building'
      },
      {
        name: 'Created',
        label: 'Created',
        value: 'building.created',
        mongoValue: 'created',
        select: 'dateRange',
        tab: 'building'
      },
      {
        name: 'Updated',
        label: 'Updated',
        value: 'building.updated',
        mongoValue: 'updated',
        select: 'dateRange',
        tab: 'building'
      }
    ]
  },
  {
    name: 'Energy Use',
    value: 'Energy Use',
    subFields: [
      {
        name: 'Energy Use Intensity',
        label: 'Energy Use Intensity',
        value: buildingEnergySummaryKeys.avgEnergyUseIntensity,
        mongoValue: buildingEnergySummaryKeys.avgEnergyUseIntensity,
        select: 'range',
        timeDepends: true,
        tab: 'building',
        unit: 'kBtu/ft\u00B2',
        paramName: 'energyuse_intensity'
      },
      {
        name: 'Energy Star Score',
        label: 'Energy Star Score',
        value: 'buildingPmScores.score',
        mongoValue: 'benchmark.pmScores',
        select: 'range',
        tab: 'building',
        paramName: 'energy_star_score'
      },
      {
        name: 'Annual Electricity Use',
        label: 'Annual Electricity Use',
        value: utilitiesKeysValues.electricUsage,
        mongoValue: utilitiesKeysValues.electricUsage,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'range',
        tab: 'building',
        unit: 'kWh',
        paramName: 'electric_use'
      },
      {
        name: 'Annual Electricity Demand Use',
        label: 'Annual Electricity Demand Use',
        value: utilitiesKeysValues.electricityDemandUsage,
        mongoValue: utilitiesKeysValues.electricityDemandUsage,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'range',
        tab: 'building',
        unit: UNIT_TYPES.kW,
        paramName: 'electric_demand_use'
      },
      {
        name: 'Annual Natural Gas Use',
        label: 'Annual Natural Gas Use',
        value: utilitiesKeysValues.naturalGasUsage,
        mongoValue: utilitiesKeysValues.naturalGasUsage,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        select: 'range',
        tab: 'building',
        unit: 'therms',
        paramName: 'natural_gas_use'
      },
      {
        name: 'Annual Fuel Oil 2 Use',
        label: 'Annual Fuel Oil 2 Use',
        value: utilitiesKeysValues.fuelOil2Usage,
        mongoValue: utilitiesKeysValues.fuelOil2Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_2,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'fuel_oil2_use'
      },
      {
        name: 'Annual Fuel Oil 4 Use',
        label: 'Annual Fuel Oil 4 Use',
        value: utilitiesKeysValues.fuelOil4Usage,
        mongoValue: utilitiesKeysValues.fuelOil4Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_4,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'fuel_oil4_use'
      },
      {
        name: 'Annual Fuel Oil 5 & 6 Use',
        label: 'Annual Fuel Oil 5 & 6 Use',
        value: utilitiesKeysValues.fuelOil56Usage,
        mongoValue: utilitiesKeysValues.fuelOil56Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_56,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'fuel_oil56_use'
      },
      {
        name: 'Annual Steam Use',
        label: 'Annual Steam Use',
        value: utilitiesKeysValues.steamUsage,
        mongoValue: utilitiesKeysValues.steamUsage,
        fuelType: UTILITY_TYPES.STEAM,
        select: 'range',
        tab: 'building',
        unit: 'Mlb',
        paramName: 'steam_use'
      },
      {
        name: 'Annual Diesel Use',
        label: 'Annual Diesel Use',
        value: utilitiesKeysValues.dieselUsage,
        mongoValue: utilitiesKeysValues.dieselUsage,
        fuelType: UTILITY_TYPES.DIESEL,
        select: 'range',
        tab: 'building',
        unit: 'gals'
      }
    ]
  },
  {
    name: 'Energy Cost',
    value: 'Energy Cost',
    subFields: [
      {
        name: 'Annual Energy Cost',
        label: 'Annual Energy Cost',
        value: buildingEnergySummaryKeys.avgAnnualEnergyCost,
        mongoValue: buildingEnergySummaryKeys.avgAnnualEnergyCost,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'annual_cost'
      },
      {
        name: 'Energy Cost Intensity',
        label: 'Energy Cost Intensity',
        value: buildingEnergySummaryKeys.avgEnergyCostIntensity,
        mongoValue: buildingEnergySummaryKeys.avgEnergyCostIntensity,
        select: 'costRange',
        tab: 'building',
        unit: `$/${UNIT_TYPES.FT2}`,
        paramName: 'energycost_intensity'
      },
      {
        name: 'Annual Electricity Cost',
        label: 'Annual Electricity Cost',
        value: utilitiesKeysValues.electricCost,
        mongoValue: utilitiesKeysValues.electricCost,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'electric_cost'
      },
      {
        name: 'Annual Electricity Demand Cost',
        label: 'Annual Electricity Demand Cost',
        value: utilitiesKeysValues.electricityDemandCost,
        mongoValue: utilitiesKeysValues.electricityDemandCost,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'electric_demand_cost'
      },
      {
        name: 'Annual Natural Gas Cost',
        label: 'Annual Natural Gas Cost',
        value: utilitiesKeysValues.naturalGasCost,
        mongoValue: utilitiesKeysValues.naturalGasCost,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'natural_gas_cost'
      },
      {
        name: 'Annual Fuel Oil 2 Cost',
        label: 'Annual Fuel Oil 2 Cost',
        value: utilitiesKeysValues.fuelOil2Cost,
        mongoValue: utilitiesKeysValues.fuelOil2Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_2,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'fuel_oil2_cost'
      },
      {
        name: 'Annual Fuel Oil 4 Cost',
        label: 'Annual Fuel Oil 4 Cost',
        value: utilitiesKeysValues.fuelOil4Cost,
        mongoValue: utilitiesKeysValues.fuelOil4Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_4,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'fuel_oil4_cost'
      },
      {
        name: 'Annual Fuel Oil 5 & 6 Cost',
        label: 'Annual Fuel Oil 5 & 6 Cost',
        value: utilitiesKeysValues.fuelOil56Cost,
        mongoValue: utilitiesKeysValues.fuelOil56Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_56,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'fuel_oil56_cost'
      },
      {
        name: 'Annual Steam Cost',
        label: 'Annual Steam Cost',
        value: utilitiesKeysValues.steamCost,
        mongoValue: utilitiesKeysValues.steamCost,
        fuelType: UTILITY_TYPES.STEAM,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'steam_cost'
      },
      {
        name: 'Annual Diesel Cost',
        label: 'Annual Diesel Cost',
        value: utilitiesKeysValues.dieselCost,
        mongoValue: utilitiesKeysValues.dieselCost,
        fuelType: UTILITY_TYPES.DIESEL,
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Electricity Rate',
        label: 'Electricity Rate',
        value: 'rates_electric',
        mongoValue: 'rates.electric',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Natural Gas Rate',
        label: 'Natural Gas Rate',
        value: 'rates_gas',
        mongoValue: 'rates.gas',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Water Rate',
        label: 'Water Rate',
        value: 'rates_water',
        mongoValue: 'rates.water',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Fuel Oil 2 Rate',
        label: 'Fuel Oil 2 Rate',
        value: 'rates_fueloil2',
        mongoValue: 'rates.fueloil2',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Fuel Oil 4 Rate',
        label: 'Fuel Oil 4 Rate',
        value: 'rates_fueloil4',
        mongoValue: 'rates.fueloil4',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Fuel Oil 5 & 6 Rate',
        label: 'Fuel Oil 5 & 6 Rate',
        value: 'rates_fueloil56',
        mongoValue: 'rates.fueloil56',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Steam Rate',
        label: 'Steam Rate',
        value: 'rates_steam',
        mongoValue: 'rates.steam',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Diesel Rate',
        label: 'Diesel Rate',
        value: 'rates_diesel',
        mongoValue: 'rates.diesel',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      }
    ]
  },
  {
    name: 'GHG Emissions',
    value: 'GHG Emissions',
    subFields: [
      {
        name: 'Annual GHG Emissions',
        label: 'Annual GHG Emissions',
        value: buildingEnergySummaryKeys.avgAnnualGHGEmissions,
        mongoValue: buildingEnergySummaryKeys.avgAnnualGHGEmissions,
        select: 'range',
        paramName: 'ghg_emission',
        tab: 'building',
        unit: UNIT_TYPES.MTCO2E
      },
      {
        name: 'GHG Intensity',
        label: 'GHG Intensity',
        value: buildingEnergySummaryKeys.avgGHGIntensity,
        mongoValue: buildingEnergySummaryKeys.avgGHGIntensity,
        select: 'range',
        tab: 'building',
        unit: 'mtCO2e/ft\u00B2',
        paramName: 'ghg_intensity'
      }
    ]
  },
  {
    name: 'Water Cost & Use',
    value: 'Water Cost & Use',
    subFields: [
      {
        name: 'Annual Water Use',
        label: 'Annual Water Use',
        value: utilitiesKeysValues.waterUsage,
        mongoValue: utilitiesKeysValues.waterUsage,
        fuelType: UTILITY_TYPES.WATER,
        select: 'range',
        tab: 'building',
        unit: 'ccf'
      },
      {
        name: 'Water Use Intensity',
        label: 'Water Use Intensity',
        value: utilitiesKeysValues.waterIntensity,
        mongoValue: utilitiesKeysValues.waterIntensity,
        fuelType: UTILITY_TYPES.WATER,
        select: 'range',
        tab: 'building',
        unit: 'ccf/ft\u00B2'
      },
      {
        name: 'Annual Water Cost',
        label: 'Annual Water Cost',
        value: utilitiesKeysValues.waterCost,
        mongoValue: utilitiesKeysValues.waterCost,
        fuelType: UTILITY_TYPES.WATER,
        select: 'costRange',
        tab: 'building',
        unit: '$'
      }
    ]
  },
  {
    name: 'Measure',
    value: 'Measure',
    subFields: [
      {
        name: 'Measure Name',
        label: 'Measure Name',
        value: 'displayname',
        mongoValue: 'displayName',
        select: 'multiSelect',
        paramName: 'measure_name',
        tab: 'project'
      },
      {
        name: 'Measure Cost',
        label: 'Measure Cost',
        value: 'metric_projectcost',
        mongoValue: 'metric.projectCost',
        select: 'range',
        paramName: 'measure_cost',
        tab: 'project',
        unit: '$'
      },
      {
        name: 'Measure Application',
        label: 'Measure Application',
        value: 'project_application',
        mongoValue: 'project_application',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Measure Category',
        label: 'Measure Category',
        value: 'project_category',
        mongoValue: 'project_category',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Measure Technology',
        label: 'Measure Technology',
        value: 'project_technology',
        mongoValue: 'project_technology',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Energy Cost Savings',
        label: 'Energy Cost Savings',
        value: 'metric_annualsavings',
        mongoValue: 'metric.annualSavings',
        select: 'range',
        paramName: 'cost_savings',
        tab: 'project',
        unit: '$'
      },
      {
        name: 'Electricity Savings - % of baseline',
        label: 'Electricity Savings - % of baseline',
        value: 'electricSavingsBaselinePercent',
        mongoValue: 'metric.electricitySavingsBaselinePercent',
        select: 'range',
        tab: 'project',
        unit: '%'
      },
      {
        name: 'Electricity Costs Savings',
        label: 'Electricity Costs Savings',
        value: 'metric_annualelectricsavings',
        mongoValue: 'metric.annualElectricSavings',
        select: 'range',
        tab: 'project',
        unit: '$'
      },
      {
        name: 'Natural Gas Reduction',
        label: 'Natural Gas Reduction',
        value: 'metric_gassavings',
        mongoValue: 'metric.gasSavings',
        select: 'range',
        paramName: 'natural_gas_reduction',
        tab: 'project',
        unit: 'therms',
        fuelType: UTILITY_TYPES.NATURAL_GAS
      },
      {
        name: 'Natural Gas Savings - % of baseline',
        label: 'Natural Gas Savings - % of baseline',
        value: 'gasSavingsBaselinePercent',
        mongoValue: 'metric.gasSavingsBaselinePercent',
        select: 'range',
        tab: 'project',
        unit: '%'
      },
      {
        name: 'Natural Gas Costs Savings',
        label: 'Natural Gas Costs Savings',
        value: 'metric_annualgassavings',
        mongoValue: 'metric.annualGasSavings',
        select: 'range',
        tab: 'project',
        unit: '$'
      },
      {
        name: '$/GHG Reduction',
        label: '$/GHG Reduction',
        value: 'runresults_ghg_cost',
        mongoValue: 'metric.ghgSavingsCost',
        select: 'range',
        tab: 'project',
        paramName: 'ghg_emissions',
        unit: '$/mtCO2e'
      },
      {
        name: 'GHG Savings - % of baseline',
        label: 'GHG Savings - % of baseline',
        value: 'ghgSavingsBaselinePercent',
        mongoValue: 'metric.ghgSavingsBaselinePercent',
        select: 'range',
        tab: 'project',
        unit: '%'
      },
      {
        name: 'Electricity Demand Savings',
        label: 'Electricity Demand Savings',
        value: 'runresults_energy_savings_demand',
        mongoValue: 'metric.demandSavings',
        select: 'range',
        tab: 'project',
        unit: 'kWh'
      },
      {
        name: 'Electricity Savings',
        label: 'Electricity Savings',
        value: 'metric_electricsavings',
        mongoValue: 'metric.electricSavings',
        select: 'range',
        unit: 'kWh',
        tab: 'project',
        paramName: 'electric_reduction',
        fuelType: UTILITY_TYPES.ELECTRICITY
      },
      {
        name: 'Status',
        label: 'Status',
        value: 'status',
        mongoValue: 'status',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Type',
        label: 'Type',
        value: 'type',
        mongoValue: 'type',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Total Budget',
        label: 'Total Budget',
        value: 'totalBudget',
        select: 'range',
        tab: 'project'
      },
      {
        name: 'Created By',
        label: 'Created By',
        value: 'project.createdBy.id',
        mongoValue: 'createdByUserId',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Created',
        label: 'Created',
        mongoValue: 'created',
        value: 'project.created',
        select: 'dateRange',
        tab: 'project'
      },
      {
        name: 'Updated',
        label: 'Updated',
        mongoValue: 'updated',
        value: 'project.updated',
        select: 'dateRange',
        tab: 'project'
      }
    ]
  },
  {
    name: 'Project',
    value: 'ProjectPackage',
    subFields: [
      {
        name: 'Project Name',
        label: 'Project Name',
        value: 'name',
        mongoValue: 'name',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Status',
        label: 'Status',
        value: 'status',
        mongoValue: 'status',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Construction Status',
        label: 'Construction Status',
        value: 'constructionstatus',
        mongoValue: 'constructionStatus',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Energy Cost Savings',
        label: 'Energy Cost Savings',
        value: 'total_annualsavings',
        mongoValue: 'metric.annualSavings',
        select: 'range',
        unit: '$',
        tab: 'projectPackage'
      },
      {
        name: 'Energy Savings',
        label: 'Energy Savings',
        value: 'total_energysavings',
        mongoValue: 'metric.energySavings',
        select: 'range',
        unit: UNIT_TYPES.kBTU,
        tab: 'projectPackage'
      },
      {
        name: 'Electricity Savings',
        label: 'Electricity Savings',
        value: 'total_electric',
        mongoValue: 'metric.electric',
        select: 'range',
        unit: 'kWh',
        tab: 'projectPackage',
        fuelType: UTILITY_TYPES.ELECTRICITY
      },
      {
        name: 'Electricity Demand Savings',
        label: 'Electricity Demand Savings',
        value: 'total_demandsavings',
        mongoValue: 'metric.demandSavings',
        unit: 'kWh',
        select: 'range',
        tab: 'projectPackage'
      },
      {
        name: 'Natural Gas Reduction',
        label: 'Natural Gas Reduction',
        value: 'total_gassavings',
        mongoValue: 'metric.gasSavings',
        select: 'range',
        unit: 'therms',
        tab: 'projectPackage',
        fuelType: UTILITY_TYPES.NATURAL_GAS
      },
      {
        name: '$/GHG Reduction',
        label: '$/GHG Reduction',
        value: 'total_ghgsavingscost',
        mongoValue: 'metric.ghgSavingsCost',
        select: 'range',
        unit: '$ /mtCO2e',
        tab: 'projectPackage'
      },
      {
        name: 'Project Cost',
        label: 'Project Cost',
        value: 'total_projectcost',
        mongoValue: 'metric.projectCost',
        select: 'costRange',
        unit: '$',
        tab: 'projectPackage'
      },
      {
        name: 'Effective Useful Life',
        label: 'Effective Useful Life',
        value: 'total_eul',
        mongoValue: 'metric.eul',
        select: 'range',
        unit: 'yrs',
        tab: 'projectPackage'
      },
      {
        name: 'Created By',
        label: 'Created By',
        value: 'projectPackage.createdBy.id',
        mongoValue: 'createdByUserId',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Created',
        label: 'Created',
        value: 'projectPackage.created',
        mongoValue: 'created',
        select: 'dateRange',
        tab: 'projectPackage'
      },
      {
        name: 'Updated',
        label: 'Updated',
        value: 'projectPackage.updated',
        mongoValue: 'updated',
        select: 'dateRange',
        tab: 'projectPackage'
      }
    ]
  }
]

// ProjectPackages -> Projects

export const defaultProjectPackageColumn = [
  {
    value: 'name',
    label: 'Name',
    order: 1
  },
  {
    value: 'building.buildingname',
    label: 'Building Name',
    order: 2
  },
  {
    value: 'organization.name',
    label: 'Organization Name',
    order: 3
  },
  {
    value: 'status',
    label: 'Status',
    order: 4
  },
  {
    value: 'constructionstatus',
    label: 'Construction Status',
    order: 5
  },
  {
    name: 'Annual Cost Savings',
    label: 'Annual Cost Savings',
    value: 'total_annualsavings',
    unit: '$',
    total: true,
    order: 9,
    decimals: 0
  },
  {
    name: 'Energy Savings',
    label: 'Energy Savings',
    value: 'total_energysavings',
    unit: UNIT_TYPES.kBTU,
    total: true,
    order: 11,
    decimals: 0
  },
  {
    name: 'Electric Savings',
    label: 'Electricity Savings',
    value: 'total_electric',
    unit: 'kWh',
    total: true,
    order: 12,
    fuelType: UTILITY_TYPES.ELECTRICITY,
    decimals: 0
  },
  {
    name: 'Demand Savings',
    label: 'Demand Savings',
    value: 'metric.demandSavings',
    unit: 'kWh',
    total: true,
    order: 13,
    decimals: 0
  },
  {
    name: 'Natural Gas Savings',
    label: 'Natural Gas Savings',
    value: 'metric.gasSavings',
    unit: 'therms',
    total: true,
    order: 14,
    fuelType: UTILITY_TYPES.NATURAL_GAS,
    decimals: 0
  },
  {
    name: 'GHG Savings',
    label: 'GHG Savings',
    value: 'metric.ghgSavings',
    unit: UNIT_TYPES.MTCO2E,
    total: true,
    order: 15,
    decimals: 2
  },
  {
    name: 'GHG Savings Cost',
    label: 'GHG Savings Cost',
    value: 'total_ghgsavingscost',
    unit: '$/mtCO2e',
    total: true,
    order: 16,
    decimals: 0
  },
  {
    name: 'Water Savings',
    label: 'Water Savings',
    value: 'total_watersavings',
    unit: 'ccf',
    total: true,
    order: 17,
    fuelType: UTILITY_TYPES.WATER,
    decimals: 0
  },
  {
    name: 'Project Cost',
    label: 'Project Cost',
    value: 'total_projectcost',
    unit: '$',
    total: true,
    order: 18,
    decimals: 0
  },
  {
    name: 'Incentive',
    label: 'Incentive',
    value: 'metric.incentive',
    unit: '$',
    total: true,
    order: 19,
    decimals: 0
  },
  {
    name: 'Maintenance Savings',
    label: 'Maintenance Savings',
    value: 'total_maintenancesavings',
    unit: '$',
    total: true,
    order: 20,
    decimals: 0
  },
  {
    name: 'ROI',
    label: 'ROI',
    value: 'total_roi',
    order: 21,
    decimals: 1
  },
  {
    name: 'Simple Payback',
    label: 'Simple Payback',
    value: 'metric.simplepayback',
    order: 22,
    decimals: 1
  },
  {
    name: 'NPV',
    label: 'NPV',
    value: 'total_npv',
    order: 23,
    decimals: 0
  },
  {
    name: 'SIR',
    label: 'SIR',
    value: 'total_sir',
    order: 24,
    decimals: 1
  },
  {
    name: 'Effective Useful Life',
    label: 'Effective Useful Life',
    value: 'total_eul',
    order: 25,
    unit: 'yrs',
    decimals: 0
  },
  {
    name: 'updated',
    label: 'Updated',
    value: 'updated',
    order: 26
  },
  {
    name: 'created',
    label: 'Created',
    value: 'created',
    order: 27
  },
  {
    name: 'Author',
    value: 'createdBy.name',
    label: 'Author',
    option: 'left',
    order: 28
  }
]

export const projectPackageFilterOptions = [
  {
    name: 'Organization',
    value: 'Organization',
    subFields: [
      {
        name: 'Organization Name',
        label: 'Organization Name',
        value: 'organization.name',
        mongoValue: 'organization.name',
        select: 'multiSelect',
        tab: 'building'
      }
    ]
  },
  {
    name: 'Property',
    value: 'info',
    subFields: [
      {
        name: 'Primary Use',
        label: 'Primary Use',
        value: 'buildinguse',
        mongoValue: 'buildingUse',
        select: 'multiSelect',
        paramName: 'building_usetype',
        tab: 'building'
      },
      {
        name: 'Gross Floor Area',
        label: 'Gross Floor Area',
        value: 'squarefeet',
        mongoValue: 'squareFeet',
        select: 'range',
        tab: 'building',
        unit: UNIT_TYPES.FT2,
        paramName: 'gross_floor_area'
      },
      {
        name: 'Year Built',
        label: 'Year Built',
        value: 'buildyear',
        mongoValue: 'buildYear',
        select: 'yearRange',
        tab: 'building',
        paramName: 'year_built'
      },
      {
        name: 'City',
        label: 'City',
        value: 'location_city',
        mongoValue: 'location.city',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'city'
      },
      {
        name: 'State/Province',
        label: 'State/Province',
        value: 'location_state',
        mongoValue: 'location.state',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'state'
      },
      {
        name: 'Zip Code',
        label: 'Zip Code',
        value: 'location_zipcode',
        mongoValue: 'location.zipCode',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'zip_code'
      },
      {
        name: 'Borough',
        label: 'Borough',
        value: 'nycfields_borough',
        mongoValue: 'nycFields.borough',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'borough'
      },
      {
        name: 'Created By',
        label: 'Created By',
        value: 'building.createdBy.id',
        mongoValue: 'createdByUserId',
        select: 'multiSelect',
        tab: 'building'
      },
      {
        name: 'Created',
        label: 'Created',
        value: 'building.created',
        mongoValue: 'created',
        select: 'dateRange',
        tab: 'building'
      },
      {
        name: 'Updated',
        label: 'Updated',
        value: 'building.updated',
        mongoValue: 'updated',
        select: 'dateRange',
        tab: 'building'
      }
    ]
  },
  {
    name: 'Energy Use',
    value: 'Energy Use',
    subFields: [
      {
        name: 'Energy Use Intensity',
        label: 'Energy Use Intensity',
        value: buildingEnergySummaryKeys.avgEnergyUseIntensity,
        mongoValue: buildingEnergySummaryKeys.avgEnergyUseIntensity,
        select: 'range',
        timeDepends: true,
        tab: 'building',
        unit: 'kBtu/ft\u00B2',
        paramName: 'energyuse_intensity'
      },
      {
        name: 'Energy Star Score',
        label: 'Energy Star Score',
        value: 'buildingPmScores.score',
        mongoValue: 'benchmark.pmScores',
        select: 'range',
        tab: 'building',
        paramName: 'energy_star_score'
      },
      {
        name: 'Annual Electricity Use',
        label: 'Annual Electricity Use',
        value: utilitiesKeysValues.electricUsage,
        mongoValue: utilitiesKeysValues.electricUsage,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'range',
        tab: 'building',
        unit: 'kWh',
        paramName: 'electric_use'
      },
      {
        name: 'Annual Electricity Demand Use',
        label: 'Annual Electricity Demand Use',
        value: utilitiesKeysValues.electricityDemandUsage,
        mongoValue: utilitiesKeysValues.electricityDemandUsage,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'range',
        tab: 'building',
        unit: UNIT_TYPES.kW,
        paramName: 'electric_demand_use'
      },
      {
        name: 'Annual Natural Gas Use',
        label: 'Annual Natural Gas Use',
        value: utilitiesKeysValues.naturalGasUsage,
        mongoValue: utilitiesKeysValues.naturalGasUsage,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        select: 'range',
        tab: 'building',
        unit: 'therms',
        paramName: 'natural_gas_use'
      },
      {
        name: 'Annual Fuel Oil 2 Use',
        label: 'Annual Fuel Oil 2 Use',
        value: utilitiesKeysValues.fuelOil2Usage,
        mongoValue: utilitiesKeysValues.fuelOil2Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_2,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'fuel_oil2_use'
      },
      {
        name: 'Annual Fuel Oil 4 Use',
        label: 'Annual Fuel Oil 4 Use',
        value: utilitiesKeysValues.fuelOil4Usage,
        mongoValue: utilitiesKeysValues.fuelOil4Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_4,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'fuel_oil4_use'
      },
      {
        name: 'Annual Fuel Oil 5 & 6 Use',
        label: 'Annual Fuel Oil 5 & 6 Use',
        value: utilitiesKeysValues.fuelOil56Usage,
        mongoValue: utilitiesKeysValues.fuelOil56Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_56,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'fuel_oil56_use'
      },
      {
        name: 'Annual Steam Use',
        label: 'Annual Steam Use',
        value: utilitiesKeysValues.steamUsage,
        mongoValue: utilitiesKeysValues.steamUsage,
        fuelType: UTILITY_TYPES.STEAM,
        select: 'range',
        tab: 'building',
        unit: 'Mlb',
        paramName: 'steam_use'
      },
      {
        name: 'Annual Diesel Use',
        label: 'Annual Diesel Use',
        value: utilitiesKeysValues.dieselUsage,
        mongoValue: utilitiesKeysValues.dieselUsage,
        fuelType: UTILITY_TYPES.DIESEL,
        select: 'range',
        tab: 'building',
        unit: 'gals'
      }
    ]
  },
  {
    name: 'Energy Cost',
    value: 'Energy Cost',
    subFields: [
      {
        name: 'Annual Energy Cost',
        label: 'Annual Energy Cost',
        value: buildingEnergySummaryKeys.avgAnnualEnergyCost,
        mongoValue: buildingEnergySummaryKeys.avgAnnualEnergyCost,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'annual_cost'
      },
      {
        name: 'Energy Cost Intensity',
        label: 'Energy Cost Intensity',
        value: buildingEnergySummaryKeys.avgEnergyCostIntensity,
        mongoValue: buildingEnergySummaryKeys.avgEnergyCostIntensity,
        select: 'costRange',
        tab: 'building',
        unit: `$/${UNIT_TYPES.FT2}`,
        paramName: 'energycost_intensity'
      },
      {
        name: 'Annual Electricity Cost',
        label: 'Annual Electricity Cost',
        value: utilitiesKeysValues.electricCost,
        mongoValue: utilitiesKeysValues.electricCost,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'electric_cost'
      },
      {
        name: 'Annual Electricity Demand Cost',
        label: 'Annual Electricity Demand Cost',
        value: utilitiesKeysValues.electricityDemandCost,
        mongoValue: utilitiesKeysValues.electricityDemandCost,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'electric_demand_cost'
      },
      {
        name: 'Annual Natural Gas Cost',
        label: 'Annual Natural Gas Cost',
        value: utilitiesKeysValues.naturalGasCost,
        mongoValue: utilitiesKeysValues.naturalGasCost,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'natural_gas_cost'
      },
      {
        name: 'Annual Fuel Oil 2 Cost',
        label: 'Annual Fuel Oil 2 Cost',
        value: utilitiesKeysValues.fuelOil2Cost,
        mongoValue: utilitiesKeysValues.fuelOil2Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_2,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'fuel_oil2_cost'
      },
      {
        name: 'Annual Fuel Oil 4 Cost',
        label: 'Annual Fuel Oil 4 Cost',
        value: utilitiesKeysValues.fuelOil4Cost,
        mongoValue: utilitiesKeysValues.fuelOil4Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_4,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'fuel_oil4_cost'
      },
      {
        name: 'Annual Fuel Oil 5 & 6 Cost',
        label: 'Annual Fuel Oil 5 & 6 Cost',
        value: utilitiesKeysValues.fuelOil56Cost,
        mongoValue: utilitiesKeysValues.fuelOil56Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_56,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'fuel_oil56_cost'
      },
      {
        name: 'Annual Steam Cost',
        label: 'Annual Steam Cost',
        value: utilitiesKeysValues.steamCost,
        mongoValue: utilitiesKeysValues.steamCost,
        fuelType: UTILITY_TYPES.STEAM,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'steam_cost'
      },
      {
        name: 'Annual Diesel Cost',
        label: 'Annual Diesel Cost',
        value: utilitiesKeysValues.dieselCost,
        mongoValue: utilitiesKeysValues.dieselCost,
        fuelType: UTILITY_TYPES.DIESEL,
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Electricity Rate',
        label: 'Electricity Rate',
        value: 'rates_electric',
        mongoValue: 'rates.electric',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Natural Gas Rate',
        label: 'Natural Gas Rate',
        value: 'rates_gas',
        mongoValue: 'rates.gas',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Water Rate',
        label: 'Water Rate',
        value: 'rates_water',
        mongoValue: 'rates.water',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Fuel Oil 2 Rate',
        label: 'Fuel Oil 2 Rate',
        value: 'rates_fueloil2',
        mongoValue: 'rates.fueloil2',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Fuel Oil 4 Rate',
        label: 'Fuel Oil 4 Rate',
        value: 'rates_fueloil4',
        mongoValue: 'rates.fueloil4',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Fuel Oil 5 & 6 Rate',
        label: 'Fuel Oil 5 & 6 Rate',
        value: 'rates_fueloil56',
        mongoValue: 'rates.fueloil56',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Steam Rate',
        label: 'Steam Rate',
        value: 'rates_steam',
        mongoValue: 'rates.steam',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Diesel Rate',
        label: 'Diesel Rate',
        value: 'rates_diesel',
        mongoValue: 'rates.diesel',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      }
    ]
  },
  {
    name: 'GHG Emissions',
    value: 'GHG Emissions',
    subFields: [
      {
        name: 'Annual GHG Emissions',
        label: 'Annual GHG Emissions',
        value: buildingEnergySummaryKeys.avgAnnualGHGEmissions,
        mongoValue: buildingEnergySummaryKeys.avgAnnualGHGEmissions,
        select: 'range',
        paramName: 'ghg_emission',
        tab: 'building',
        unit: UNIT_TYPES.MTCO2E
      },
      {
        name: 'GHG Intensity',
        label: 'GHG Intensity',
        value: buildingEnergySummaryKeys.avgGHGIntensity,
        mongoValue: buildingEnergySummaryKeys.avgGHGIntensity,
        select: 'range',
        tab: 'building',
        unit: 'mtCO2e/ft\u00B2',
        paramName: 'ghg_intensity'
      }
    ]
  },
  {
    name: 'Water Cost & Use',
    value: 'Water Cost & Use',
    subFields: [
      {
        name: 'Annual Water Use',
        label: 'Annual Water Use',
        value: utilitiesKeysValues.waterUsage,
        mongoValue: utilitiesKeysValues.waterUsage,
        fuelType: UTILITY_TYPES.WATER,
        select: 'range',
        tab: 'building',
        unit: 'ccf'
      },
      {
        name: 'Water Use Intensity',
        label: 'Water Use Intensity',
        value: utilitiesKeysValues.waterIntensity,
        mongoValue: utilitiesKeysValues.waterIntensity,
        fuelType: UTILITY_TYPES.WATER,
        select: 'range',
        tab: 'building',
        unit: 'ccf/ft\u00B2'
      },
      {
        name: 'Annual Water Cost',
        label: 'Annual Water Cost',
        value: utilitiesKeysValues.waterCost,
        mongoValue: utilitiesKeysValues.waterCost,
        fuelType: UTILITY_TYPES.WATER,
        select: 'costRange',
        tab: 'building',
        unit: '$'
      }
    ]
  },
  {
    name: 'Measure',
    value: 'Measure',
    subFields: [
      {
        name: 'Measure Name',
        label: 'Measure Name',
        value: 'displayname',
        mongoValue: 'displayName',
        select: 'multiSelect',
        paramName: 'measure_name',
        tab: 'project'
      },
      {
        name: 'Measure Cost',
        label: 'Measure Cost',
        value: 'metric_projectcost',
        mongoValue: 'total.projectCost',
        select: 'range',
        paramName: 'measure_cost',
        tab: 'project',
        unit: '$'
      },
      {
        name: 'Measure Application',
        label: 'Measure Application',
        value: 'project_application',
        mongoValue: 'project_application',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Measure Category',
        label: 'Measure Category',
        value: 'project_category',
        mongoValue: 'project_category',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Measure Technology',
        label: 'Measure Technology',
        value: 'project_technology',
        mongoValue: 'project_technology',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Energy Cost Savings',
        label: 'Energy Cost Savings',
        value: 'metric_annualsavings',
        mongoValue: 'total.annualSavings',
        select: 'range',
        paramName: 'cost_savings',
        tab: 'project',
        unit: '$'
      },
      {
        name: 'Natural Gas Reduction',
        label: 'Natural Gas Reduction',
        value: 'metric_gassavings',
        mongoValue: 'total.gasSavings',
        select: 'range',
        paramName: 'natural_gas_reduction',
        tab: 'project',
        unit: 'therms',
        fuelType: UTILITY_TYPES.NATURAL_GAS
      },
      {
        name: '$/GHG Reduction',
        label: '$/GHG Reduction',
        value: 'runresults_ghg_cost',
        mongoValue: 'total.ghgSavingsCost',
        select: 'range',
        tab: 'project',
        paramName: 'ghg_emissions',
        unit: '$/mtCO2e'
      },
      {
        name: 'Electricity Demand Savings',
        label: 'Electricity Demand Savings',
        value: 'runresults_energy_savings_demand',
        mongoValue: 'total.demandSavings',
        select: 'range',
        tab: 'project',
        unit: 'kWh'
      },
      {
        name: 'Electricity Savings',
        label: 'Electricity Savings',
        value: 'metric_electricsavings',
        mongoValue: 'total.electricSavings',
        select: 'range',
        unit: 'kWh',
        tab: 'project',
        paramName: 'electric_reduction',
        fuelType: UTILITY_TYPES.ELECTRICITY
      },
      {
        name: 'Status',
        label: 'Status',
        value: 'status',
        mongoValue: 'status',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Type',
        label: 'Type',
        value: 'type',
        mongoValue: 'type',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Created By',
        label: 'Created By',
        value: 'project.createdBy.id',
        mongoValue: 'createdByUserId',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Created',
        label: 'Created',
        mongoValue: 'created',
        value: 'project.created',
        select: 'dateRange',
        tab: 'project'
      },
      {
        name: 'Updated',
        label: 'Updated',
        mongoValue: 'updated',
        value: 'project.updated',
        select: 'dateRange',
        tab: 'project'
      }
    ]
  },
  {
    name: 'Project',
    value: 'ProjectPackage',
    subFields: [
      {
        name: 'Project Name',
        label: 'Project Name',
        value: 'name',
        mongoValue: 'name',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Status',
        label: 'Status',
        value: 'status',
        mongoValue: 'status',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Construction Status',
        label: 'Construction Status',
        value: 'constructionstatus',
        mongoValue: 'constructionStatus',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Energy Cost Savings',
        label: 'Energy Cost Savings',
        value: 'total_annualsavings',
        mongoValue: 'total.annualSavings',
        select: 'range',
        unit: '$',
        tab: 'projectPackage'
      },
      {
        name: 'Energy Savings',
        label: 'Energy Savings',
        value: 'total_energysavings',
        mongoValue: 'total.energySavings',
        select: 'range',
        unit: UNIT_TYPES.kBTU,
        tab: 'projectPackage'
      },
      {
        name: 'Electricity Savings',
        label: 'Electricity Savings',
        value: 'total_electric',
        mongoValue: 'total.electric',
        select: 'range',
        unit: 'kWh',
        tab: 'projectPackage',
        fuelType: UTILITY_TYPES.ELECTRICITY
      },
      {
        name: 'Electricity Demand Savings',
        label: 'Electricity Demand Savings',
        value: 'total_demandsavings',
        mongoValue: 'total.demandSavings',
        unit: 'kWh',
        select: 'range',
        tab: 'projectPackage'
      },
      {
        name: 'Natural Gas Reduction',
        label: 'Natural Gas Reduction',
        value: 'total_gassavings',
        mongoValue: 'total.gasSavings',
        select: 'range',
        unit: 'therms',
        tab: 'projectPackage',
        fuelType: UTILITY_TYPES.NATURAL_GAS
      },
      {
        name: '$/GHG Reduction',
        label: '$/GHG Reduction',
        value: 'total_ghgsavingscost',
        mongoValue: 'total.ghgSavingsCost',
        select: 'range',
        unit: '$ /mtCO2e',
        tab: 'projectPackage'
      },
      {
        name: 'Project Cost',
        label: 'Project Cost',
        value: 'total_projectcost',
        mongoValue: 'total.projectCost',
        select: 'costRange',
        unit: '$',
        tab: 'projectPackage'
      },
      {
        name: 'Effective Useful Life',
        label: 'Effective Useful Life',
        value: 'total_eul',
        mongoValue: 'total.eul',
        select: 'range',
        unit: 'yrs',
        tab: 'projectPackage'
      },
      {
        name: 'Created By',
        label: 'Created By',
        value: 'projectPackage.createdBy.id',
        mongoValue: 'createdByUserId',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Created',
        label: 'Created',
        value: 'projectPackage.created',
        mongoValue: 'created',
        select: 'dateRange',
        tab: 'projectPackage'
      },
      {
        name: 'Updated',
        label: 'Updated',
        value: 'projectPackage.updated',
        mongoValue: 'updated',
        select: 'dateRange',
        tab: 'projectPackage'
      }
    ]
  }
]

// Dashboard

export const dashboardFilterOptions = [
  {
    name: 'Organization',
    value: 'Organization',
    subFields: [
      {
        name: 'Organization Name',
        label: 'Organization Name',
        value: 'organization.name',
        mongoValue: 'organization.name',
        select: 'multiSelect',
        tab: 'building'
      }
    ]
  },
  {
    name: 'Property',
    value: 'info',
    subFields: [
      {
        name: 'Primary Use',
        label: 'Primary Use',
        value: 'buildinguse',
        mongoValue: 'buildingUse',
        select: 'multiSelect',
        paramName: 'building_usetype',
        tab: 'building'
      },
      {
        name: 'Gross Floor Area',
        label: 'Gross Floor Area',
        value: 'squarefeet',
        mongoValue: 'squareFeet',
        select: 'range',
        tab: 'building',
        unit: UNIT_TYPES.FT2,
        paramName: 'gross_floor_area'
      },
      {
        name: 'Year Built',
        label: 'Year Built',
        value: 'buildyear',
        mongoValue: 'buildYear',
        select: 'yearRange',
        tab: 'building',
        paramName: 'year_built'
      },
      {
        name: 'City',
        label: 'City',
        value: 'location_city',
        mongoValue: 'location.city',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'city'
      },
      {
        name: 'State/Province',
        label: 'State/Province',
        value: 'location_state',
        mongoValue: 'location.state',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'state'
      },
      {
        name: 'Zip Code',
        label: 'Zip Code',
        value: 'location_zipcode',
        mongoValue: 'location.zipCode',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'zip_code'
      },
      {
        name: 'Borough',
        label: 'Borough',
        value: 'nycfields_borough',
        mongoValue: 'nycFields.borough',
        select: 'multiSelect',
        tab: 'building',
        paramName: 'borough'
      },
      {
        name: 'Created By',
        label: 'Created By',
        value: 'building.createdBy.id',
        mongoValue: 'createdByUserId',
        select: 'multiSelect',
        tab: 'building'
      },
      {
        name: 'Created',
        label: 'Created',
        value: 'building.created',
        mongoValue: 'created',
        select: 'dateRange',
        tab: 'building'
      },
      {
        name: 'Updated',
        label: 'Updated',
        value: 'building.updated',
        mongoValue: 'updated',
        select: 'dateRange',
        tab: 'building'
      }
    ]
  },
  {
    name: 'Energy Use',
    value: 'Energy Use',
    subFields: [
      {
        name: 'Energy Use Intensity',
        label: 'Energy Use Intensity',
        value: buildingEnergySummaryKeys.avgEnergyUseIntensity,
        mongoValue: buildingEnergySummaryKeys.avgEnergyUseIntensity,
        select: 'range',
        timeDepends: true,
        tab: 'building',
        unit: 'kBtu/ft\u00B2',
        paramName: 'energyuse_intensity'
      },
      {
        name: 'Energy Star Score',
        label: 'Energy Star Score',
        value: 'buildingPmScores.score',
        mongoValue: 'benchmark.pmScores',
        select: 'range',
        tab: 'building',
        paramName: 'energy_star_score'
      },
      {
        name: 'Annual Electricity Use',
        label: 'Annual Electricity Use',
        value: utilitiesKeysValues.electricUsage,
        mongoValue: utilitiesKeysValues.electricUsage,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'range',
        tab: 'building',
        unit: 'kWh',
        paramName: 'electric_use'
      },
      {
        name: 'Annual Electricity Demand Use',
        label: 'Annual Electricity Demand Use',
        value: utilitiesKeysValues.electricityDemandUsage,
        mongoValue: utilitiesKeysValues.electricityDemandUsage,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'range',
        tab: 'building',
        unit: UNIT_TYPES.kW,
        paramName: 'electric_demand_use'
      },
      {
        name: 'Annual Natural Gas Use',
        label: 'Annual Natural Gas Use',
        value: utilitiesKeysValues.naturalGasUsage,
        mongoValue: utilitiesKeysValues.naturalGasUsage,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        select: 'range',
        tab: 'building',
        unit: 'therms',
        paramName: 'natural_gas_use'
      },
      {
        name: 'Annual Fuel Oil 2 Use',
        label: 'Annual Fuel Oil 2 Use',
        value: utilitiesKeysValues.fuelOil2Usage,
        mongoValue: utilitiesKeysValues.fuelOil2Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_2,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'fuel_oil2_use'
      },
      {
        name: 'Annual Fuel Oil 4 Use',
        label: 'Annual Fuel Oil 4 Use',
        value: utilitiesKeysValues.fuelOil4Usage,
        mongoValue: utilitiesKeysValues.fuelOil4Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_4,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'fuel_oil4_use'
      },
      {
        name: 'Annual Fuel Oil 5 & 6 Use',
        label: 'Annual Fuel Oil 5 & 6 Use',
        value: utilitiesKeysValues.fuelOil56Usage,
        mongoValue: utilitiesKeysValues.fuelOil56Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_56,
        select: 'range',
        tab: 'building',
        unit: 'gals',
        paramName: 'fuel_oil56_use'
      },
      {
        name: 'Annual Steam Use',
        label: 'Annual Steam Use',
        value: utilitiesKeysValues.steamUsage,
        mongoValue: utilitiesKeysValues.steamUsage,
        fuelType: UTILITY_TYPES.STEAM,
        select: 'range',
        tab: 'building',
        unit: 'Mlb',
        paramName: 'steam_use'
      },
      {
        name: 'Annual Diesel Use',
        label: 'Annual Diesel Use',
        value: utilitiesKeysValues.dieselUsage,
        mongoValue: utilitiesKeysValues.dieselUsage,
        fuelType: UTILITY_TYPES.DIESEL,
        select: 'range',
        tab: 'building',
        unit: 'gals'
      }
    ]
  },
  {
    name: 'Energy Cost',
    value: 'Energy Cost',
    subFields: [
      {
        name: 'Annual Energy Cost',
        label: 'Annual Energy Cost',
        value: buildingEnergySummaryKeys.avgAnnualEnergyCost,
        mongoValue: buildingEnergySummaryKeys.avgAnnualEnergyCost,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'annual_cost'
      },
      {
        name: 'Energy Cost Intensity',
        label: 'Energy Cost Intensity',
        value: buildingEnergySummaryKeys.avgEnergyCostIntensity,
        mongoValue: buildingEnergySummaryKeys.avgEnergyCostIntensity,
        select: 'costRange',
        tab: 'building',
        unit: `$/${UNIT_TYPES.FT2}`,
        paramName: 'energycost_intensity'
      },
      {
        name: 'Annual Electricity Cost',
        label: 'Annual Electricity Cost',
        value: utilitiesKeysValues.electricCost,
        mongoValue: utilitiesKeysValues.electricCost,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'electric_cost'
      },
      {
        name: 'Annual Electricity Demand Cost',
        label: 'Annual Electricity Demand Cost',
        value: utilitiesKeysValues.electricityDemandCost,
        mongoValue: utilitiesKeysValues.electricityDemandCost,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'electric_demand_cost'
      },
      {
        name: 'Annual Natural Gas Cost',
        label: 'Annual Natural Gas Cost',
        value: utilitiesKeysValues.naturalGasCost,
        mongoValue: utilitiesKeysValues.naturalGasCost,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'natural_gas_cost'
      },
      {
        name: 'Annual Fuel Oil 2 Cost',
        label: 'Annual Fuel Oil 2 Cost',
        value: utilitiesKeysValues.fuelOil2Cost,
        mongoValue: utilitiesKeysValues.fuelOil2Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_2,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'fuel_oil2_cost'
      },
      {
        name: 'Annual Fuel Oil 4 Cost',
        label: 'Annual Fuel Oil 4 Cost',
        value: utilitiesKeysValues.fuelOil4Cost,
        mongoValue: utilitiesKeysValues.fuelOil4Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_4,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'fuel_oil4_cost'
      },
      {
        name: 'Annual Fuel Oil 5 & 6 Cost',
        label: 'Annual Fuel Oil 5 & 6 Cost',
        value: utilitiesKeysValues.fuelOil56Cost,
        mongoValue: utilitiesKeysValues.fuelOil56Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_56,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'fuel_oil56_cost'
      },
      {
        name: 'Annual Steam Cost',
        label: 'Annual Steam Cost',
        value: utilitiesKeysValues.steamCost,
        mongoValue: utilitiesKeysValues.steamCost,
        fuelType: UTILITY_TYPES.STEAM,
        select: 'costRange',
        tab: 'building',
        unit: '$',
        paramName: 'steam_cost'
      },
      {
        name: 'Annual Diesel Cost',
        label: 'Annual Diesel Cost',
        value: utilitiesKeysValues.dieselCost,
        mongoValue: utilitiesKeysValues.dieselCost,
        fuelType: UTILITY_TYPES.DIESEL,
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Electricity Rate',
        label: 'Electricity Rate',
        value: 'rates_electric',
        mongoValue: 'rates.electric',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Natural Gas Rate',
        label: 'Natural Gas Rate',
        value: 'rates_gas',
        mongoValue: 'rates.gas',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Water Rate',
        label: 'Water Rate',
        value: 'rates_water',
        mongoValue: 'rates.water',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Fuel Oil 2 Rate',
        label: 'Fuel Oil 2 Rate',
        value: 'rates_fueloil2',
        mongoValue: 'rates.fueloil2',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Fuel Oil 4 Rate',
        label: 'Fuel Oil 4 Rate',
        value: 'rates_fueloil4',
        mongoValue: 'rates.fueloil4',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Fuel Oil 5 & 6 Rate',
        label: 'Fuel Oil 5 & 6 Rate',
        value: 'rates_fueloil56',
        mongoValue: 'rates.fueloil56',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Steam Rate',
        label: 'Steam Rate',
        value: 'rates_steam',
        mongoValue: 'rates.steam',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      },
      {
        name: 'Diesel Rate',
        label: 'Diesel Rate',
        value: 'rates_diesel',
        mongoValue: 'rates.diesel',
        select: 'costRange',
        tab: 'building',
        unit: '$'
      }
    ]
  },
  {
    name: 'GHG Emissions',
    value: 'GHG Emissions',
    subFields: [
      {
        name: 'Annual GHG Emissions',
        label: 'Annual GHG Emissions',
        value: buildingEnergySummaryKeys.avgAnnualGHGEmissions,
        mongoValue: buildingEnergySummaryKeys.avgAnnualGHGEmissions,
        select: 'range',
        paramName: 'ghg_emission',
        tab: 'building',
        unit: UNIT_TYPES.MTCO2E
      },
      {
        name: 'GHG Intensity',
        label: 'GHG Intensity',
        value: buildingEnergySummaryKeys.avgGHGIntensity,
        mongoValue: buildingEnergySummaryKeys.avgGHGIntensity,
        select: 'range',
        tab: 'building',
        unit: 'mtCO2e/ft\u00B2',
        paramName: 'ghg_intensity'
      }
    ]
  },
  {
    name: 'Water Cost & Use',
    value: 'Water Cost & Use',
    subFields: [
      {
        name: 'Annual Water Use',
        label: 'Annual Water Use',
        value: utilitiesKeysValues.waterUsage,
        mongoValue: utilitiesKeysValues.waterUsage,
        fuelType: UTILITY_TYPES.WATER,
        select: 'range',
        tab: 'building',
        unit: 'ccf'
      },
      {
        name: 'Water Use Intensity',
        label: 'Water Use Intensity',
        value: utilitiesKeysValues.waterIntensity,
        mongoValue: utilitiesKeysValues.waterIntensity,
        fuelType: UTILITY_TYPES.WATER,
        select: 'range',
        tab: 'building',
        unit: 'ccf/ft\u00B2'
      },
      {
        name: 'Annual Water Cost',
        label: 'Annual Water Cost',
        value: utilitiesKeysValues.waterCost,
        mongoValue: utilitiesKeysValues.waterCost,
        fuelType: UTILITY_TYPES.WATER,
        select: 'costRange',
        tab: 'building',
        unit: '$'
      }
    ]
  },
  {
    name: 'Measure',
    value: 'Measure',
    subFields: [
      {
        name: 'Measure Name',
        label: 'Measure Name',
        value: 'displayname',
        mongoValue: 'displayName',
        select: 'multiSelect',
        paramName: 'measure_name',
        tab: 'project'
      },
      {
        name: 'Measure Cost',
        label: 'Measure Cost',
        value: 'metric_projectcost',
        mongoValue: 'metric.projectCost',
        select: 'range',
        paramName: 'measure_cost',
        tab: 'project',
        unit: '$'
      },
      {
        name: 'Measure Application',
        label: 'Measure Application',
        value: 'project_application',
        mongoValue: 'project_application',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Measure Category',
        label: 'Measure Category',
        value: 'project_category',
        mongoValue: 'project_category',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Measure Technology',
        label: 'Measure Technology',
        value: 'project_technology',
        mongoValue: 'project_technology',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Energy Cost Savings',
        label: 'Energy Cost Savings',
        value: 'metric_annualsavings',
        mongoValue: 'metric.annualSavings',
        select: 'range',
        paramName: 'cost_savings',
        tab: 'project',
        unit: '$'
      },
      {
        name: 'Natural Gas Reduction',
        label: 'Natural Gas Reduction',
        value: 'metric_gassavings',
        mongoValue: 'metric.gasSavings',
        select: 'range',
        paramName: 'natural_gas_reduction',
        tab: 'project',
        unit: 'therms',
        fuelType: UTILITY_TYPES.NATURAL_GAS
      },
      {
        name: '$/GHG Reduction',
        label: '$/GHG Reduction',
        value: 'runresults_ghg_cost',
        mongoValue: 'metric.ghgSavingsCost',
        select: 'range',
        tab: 'project',
        paramName: 'ghg_emissions',
        unit: '$/mtCO2e'
      },
      {
        name: 'Electricity Demand Savings',
        label: 'Electricity Demand Savings',
        value: 'runresults_energy_savings_demand',
        mongoValue: 'metric.demandSavings',
        select: 'range',
        tab: 'project',
        unit: 'kWh'
      },
      {
        name: 'Electricity Savings',
        label: 'Electricity Savings',
        value: 'metric_electricsavings',
        mongoValue: 'metric.electricSavings',
        select: 'range',
        unit: 'kWh',
        tab: 'project',
        paramName: 'electric_reduction',
        fuelType: UTILITY_TYPES.ELECTRICITY
      },
      {
        name: 'Status',
        label: 'Status',
        value: 'status',
        mongoValue: 'status',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Type',
        label: 'Type',
        value: 'type',
        mongoValue: 'type',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Created By',
        label: 'Created By',
        value: 'project.createdBy.id',
        mongoValue: 'createdByUserId',
        select: 'multiSelect',
        tab: 'project'
      },
      {
        name: 'Created',
        label: 'Created',
        mongoValue: 'created',
        value: 'project.created',
        select: 'dateRange',
        tab: 'project'
      },
      {
        name: 'Updated',
        label: 'Updated',
        mongoValue: 'updated',
        value: 'project.updated',
        select: 'dateRange',
        tab: 'project'
      }
    ]
  },
  {
    name: 'Project',
    value: 'ProjectPackage',
    subFields: [
      {
        name: 'Project Name',
        label: 'Project Name',
        value: 'name',
        mongoValue: 'name',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Status',
        label: 'Status',
        value: 'status',
        mongoValue: 'status',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Construction Status',
        label: 'Construction Status',
        value: 'constructionstatus',
        mongoValue: 'constructionStatus',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Energy Cost Savings',
        label: 'Energy Cost Savings',
        value: 'total_annualsavings',
        mongoValue: 'total.annualSavings',
        select: 'range',
        unit: '$',
        tab: 'projectPackage'
      },
      {
        name: 'Energy Savings',
        label: 'Energy Savings',
        value: 'total_energysavings',
        mongoValue: 'total.energySavings',
        select: 'range',
        unit: UNIT_TYPES.kBTU,
        tab: 'projectPackage'
      },
      {
        name: 'Electricity Savings',
        label: 'Electricity Savings',
        value: 'total_electric',
        mongoValue: 'total.electric',
        select: 'range',
        unit: 'kWh',
        tab: 'projectPackage',
        fuelType: UTILITY_TYPES.ELECTRICITY
      },
      {
        name: 'Electricity Demand Savings',
        label: 'Electricity Demand Savings',
        value: 'total_demandsavings',
        mongoValue: 'total.demandSavings',
        unit: 'kWh',
        select: 'range',
        tab: 'projectPackage'
      },
      {
        name: 'Natural Gas Reduction',
        label: 'Natural Gas Reduction',
        value: 'total_gassavings',
        mongoValue: 'total.gasSavings',
        select: 'range',
        unit: 'therms',
        tab: 'projectPackage',
        fuelType: UTILITY_TYPES.NATURAL_GAS
      },
      {
        name: '$/GHG Reduction',
        label: '$/GHG Reduction',
        value: 'total_ghgsavingscost',
        mongoValue: 'total.ghgSavingsCost',
        select: 'range',
        unit: '$ /mtCO2e',
        tab: 'projectPackage'
      },
      {
        name: 'Project Cost',
        label: 'Project Cost',
        value: 'total_projectcost',
        mongoValue: 'total.projectCost',
        select: 'costRange',
        unit: '$',
        tab: 'projectPackage'
      },
      {
        name: 'Effective Useful Life',
        label: 'Effective Useful Life',
        value: 'total_eul',
        mongoValue: 'total.eul',
        select: 'range',
        unit: 'yrs',
        tab: 'projectPackage'
      },
      {
        name: 'Created By',
        label: 'Created By',
        value: 'createdBy.id',
        mongoValue: 'createdByUserId',
        select: 'multiSelect',
        tab: 'projectPackage'
      },
      {
        name: 'Created',
        label: 'Created',
        value: 'created',
        mongoValue: 'created',
        select: 'dateRange',
        tab: 'projectPackage'
      },
      {
        name: 'Updated',
        label: 'Updated',
        value: 'updated',
        mongoValue: 'updated',
        select: 'dateRange',
        tab: 'projectPackage'
      }
    ]
  }
]

// Scenario
export const defaultScenarioColumn = [
  {
    value: 'name',
    label: 'Name',
    order: 1
  },
  {
    name: 'updated',
    label: 'Updated',
    value: 'updated'
  },
  {
    name: 'created',
    label: 'Created',
    value: 'created'
  }
]

export const scenarioFilterOptions = [
  {
    name: 'Organization',
    value: 'Organization',
    subFields: [
      {
        name: 'Organization Name',
        label: 'Organization Name',
        value: 'organizations.organization.name',
        select: 'multiSelect',
        tab: 'scenario'
      }
    ]
  }
]

//Proposals
export const ProposalFilterOptions = [
  {
    name: 'Organization',
    value: 'Organization',
    subFields: [
      {
        name: 'Organization Name',
        label: 'Organization Name',
        value: 'organization.name',
        mongoValue: 'organization.name',
        select: 'multiSelect',
        tab: 'building'
      }
    ]
  },
  {
    name: 'Author',
    value: 'author',
    subFields: [
      {
        name: 'Created By',
        label: 'Created By',
        value: 'proposal.createdBy.id',
        mongoValue: 'createdByUserId',
        select: 'multiSelect',
        tab: 'proposal'
      },
      {
        name: 'Created',
        label: 'Created',
        value: 'proposal.created',
        mongoValue: 'created',
        select: 'dateRange',
        tab: 'proposal'
      },
      {
        name: 'Updated',
        label: 'Updated',
        value: 'proposal.updated',
        mongoValue: 'updated',
        select: 'dateRange',
        tab: 'proposal'
      }
    ]
  }
]

// Portfolio tabs
export const PORTFOLIO_TABS = [
  { name: 'Dashboard', route: 'dashboard', active: true },
  {
    name: 'Equipments',
    route: 'equipment',
    active: true,
    requiredFeatures: ['portfolio', 'portfolioBuilding']
  },
  {
    name: 'Buildings',
    route: 'building',
    active: true,
    requiredFeatures: ['portfolio', 'portfolioBuilding']
  },
  {
    name: 'Measures',
    route: 'measure',
    active: true,
    requiredFeatures: ['portfolio', 'buildingProjects']
  },
  {
    name: 'Proposals',
    route: 'proposal',
    active: true,
    requiredFeatures: ['portfolio', 'projectProposal']
  },
  {
    name: 'Projects',
    route: 'project',
    active: true,
    requiredFeatures: ['portfolio', 'projectProject']
  },
  {
    name: 'Teams',
    route: 'team',
    active: true,
    requiredFeatures: ['portfolio', 'team']
  },
  {
    name: 'Scenarios',
    route: 'scenario',
    active: true,
    featureFlag: true,
    requiredFeatures: ['scenario']
  },
  {
    name: 'Tasks',
    route: 'tasks',
    active: true,
    requiredFeatures: ['portfolio', 'taskLists']
  }
]

//Scenario Tabs
export const scenarioTabs = [
  {
    name: 'Comparison',
    subTabs: [
      {
        name: 'Energy Savings',
        value: 'Energy Savings',
        route:
          'https://tableau.buildee.com/#/site/buildeebeta/views/ScenarioComparisionviewEnergySavings/ScenarioComparisionviewEnergySavings',
        components: [
          {
            name: 'toggle',
            paramName: 'is_stacked_bar',
            options: [
              {
                value: 'Total',
                icon: 'bar_chart'
              },
              {
                value: 'By Commodity',
                icon: 'stacked_bar_chart'
              }
            ]
          }
        ]
      },
      {
        name: 'GHG Reduction',
        value: 'GHG Reduction',
        route:
          'https://tableau.buildee.com/#/site/buildeebeta/views/ScenarioComparisionviewGHGReduction/ScenarioComparisionviewGHGReduction',
        exportBeta:
          'https://tableau.buildee.com/t/buildee/views/AnnualEnergyCostbyFuel/AnnualEnergyCostbyFuel.pdf',
        exportQA:
          'https://tableau.buildee.com/t/buildeebeta/views/AnnualEnergyCostbyFuel/AnnualEnergyCostbyFuel.pdf',
        components: [
          {
            name: 'toggle',
            paramName: 'is_stacked_bar',
            options: [
              {
                value: 'Total',
                icon: 'bar_chart'
              },
              {
                value: 'By Commodity',
                icon: 'stacked_bar_chart'
              }
            ]
          }
        ]
      },
      {
        name: 'Cost Savings',
        value: 'Cost Savings',
        route:
          'https://tableau.buildee.com/#/site/buildeebeta/views/ScenarioComparisionviewCostSavings/ScenarioComparisionviewCostSavings',
        components: [
          {
            name: 'toggle',
            paramName: 'is_stacked_bar',
            options: [
              {
                value: 'Total',
                icon: 'bar_chart'
              },
              {
                value: 'By Commodity',
                icon: 'stacked_bar_chart'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'Timeline',
    subTabs: [
      {
        name: 'Energy Savings',
        value: 'Energy Savings',
        route:
          'https://tableau.buildee.com/#/site/buildeebeta/views/ScenarioTimelineviewEnergySavings/ScenarioTimelineviewEnergySavings'
      },
      {
        name: 'GHG Reduction',
        value: 'GHG Reduction',
        route:
          'https://tableau.buildee.com/#/site/buildeebeta/views/ScenarioTimelineviewGHGReduction/ScenarioTimelineviewGHGReduction'
      },
      {
        name: 'Cost Savings',
        value: 'Cost Savings',
        route:
          'https://tableau.buildee.com/#/site/buildeebeta/views/ScenarioTimelineviewCostSavings/ScenarioTimelineviewCostSavings'
      }
    ]
  }
]

export const defaultPortfolioColumns = [
  {
    columnOptionId: 'name',
    label: 'Name',
    option: 'left',
    order: 1,
    portfolios: ['building', 'measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'buildingName',
    label: 'Building Name',
    option: 'left',
    order: 2,
    portfolios: ['measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'organization_name',
    label: 'Organization Name',
    option: 'left',
    order: 3,
    portfolios: ['building', 'measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'location_address',
    label: 'Address',
    option: 'left',
    order: 4,
    portfolios: ['building']
  },
  {
    columnOptionId: 'location_city',
    label: 'City',
    option: 'left',
    order: 5,
    portfolios: ['building']
  },
  {
    columnOptionId: 'location_state',
    label: 'State/ Province',
    option: 'left',
    order: 5,
    portfolios: ['building']
  },
  {
    columnOptionId: 'location_zipcode',
    label: 'Zip Code',
    option: 'left',
    order: 6,
    portfolios: ['building']
  },
  {
    columnOptionId: 'nycfields_borough',
    label: 'Borough',
    option: 'left',
    order: 7,
    portfolios: ['building']
  },
  {
    columnOptionId: 'primaryUse',
    label: 'Primary Use',
    option: 'left',
    order: 8,
    portfolios: ['building']
  },
  {
    columnOptionId: 'glassFloorArea',
    label: 'Gross Floor Area',
    order: 9,
    portfolios: ['building']
  },
  {
    columnOptionId: 'aboveGradeFloors',
    label: 'Above Grade Floors',
    option: 'right',
    order: 10,
    portfolios: ['building']
  },
  {
    columnOptionId: 'belowGradeFloors',
    label: 'Below Grade Floors',
    option: 'right',
    order: 11,
    portfolios: ['building']
  },
  {
    columnOptionId: 'yearBuilt',
    label: 'Year Built',
    option: 'left',
    order: 12,
    portfolios: ['building']
  },
  {
    columnOptionId: 'energyStartScore',
    label: 'Energy Star Score',
    option: 'right',
    order: 13,
    portfolios: ['building']
  },
  {
    columnOptionId: 'avgEnergyUseIntensity',
    label: 'Avg. Energy Use Intensity',
    option: 'right',
    order: 14,
    portfolios: ['building']
  },
  {
    columnOptionId: 'avgAnnualEnergyUse',
    label: 'Avg. Annual Energy Use',
    option: 'right',
    order: 15,
    portfolios: ['building']
  },
  {
    columnOptionId: 'avgAnnualEnergyCost',
    label: 'Avg. Annual Energy Cost',
    option: 'right',
    order: 18,
    portfolios: ['building']
  },
  {
    columnOptionId: 'avgAnnualGhgEmissions',
    label: 'Avg. Annual GHG Emissions',
    option: 'right',
    order: 21,
    portfolios: ['building']
  },
  {
    columnOptionId: 'ghgIntensity',
    label: 'Avg. GHG Intensity',
    option: 'right',
    order: 23,
    portfolios: ['building']
  },
  {
    columnOptionId: 'type',
    label: 'Type',
    order: 5,
    portfolios: ['measures']
  },
  {
    columnOptionId: 'projectName',
    label: 'Project Name',
    order: 6,
    portfolios: ['measures']
  },
  {
    columnOptionId: 'budgetType',
    label: 'Budget Type',
    portfolios: ['measures']
  },
  {
    columnOptionId: 'totalBudget',
    label: 'Total Budget',
    order: 8,
    portfolios: ['measures']
  },
  {
    columnOptionId: 'electricitySavings',
    label: 'Electricity Savings',
    portfolios: ['measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'measureCategory',
    label: 'Measure Category',
    portfolios: ['measures']
  },
  {
    columnOptionId: 'measureApplication',
    label: 'Measure Application',
    portfolios: ['measures']
  },
  {
    columnOptionId: 'measureTechnology',
    label: 'Measure Technology',
    portfolios: ['measures']
  },
  {
    columnOptionId: 'rebateCode',
    label: 'Rebate Code',
    portfolios: ['measures']
  },
  {
    columnOptionId: 'status',
    label: 'Status',
    portfolios: ['measures', 'projects']
  },
  {
    columnOptionId: 'annualSavings',
    label: 'Annual Cost Savings',
    order: 9,
    portfolios: ['measures', 'proposals', 'projects'],
    total: true
  },
  {
    columnOptionId: 'demandSavings',
    label: 'Demand Savings',
    order: 13,
    portfolios: ['measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'eul',
    label: 'Effective Useful Life',
    portfolios: ['measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'energySavings',
    label: 'Energy Savings',
    portfolios: ['measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'ghgSavings',
    label: 'GHG Savings',
    portfolios: ['measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'ghgSavingsCost',
    label: 'GHG Savings Cost',
    portfolios: ['measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'naturalGasSavings',
    label: 'Natural Gas Savings',
    portfolios: ['measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'incentive',
    label: 'Incentive',
    portfolios: ['measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'maintenanceSavings',
    label: 'Maintenance Savings',
    portfolios: ['measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'npv',
    label: 'NPV',
    portfolios: ['proposals', 'projects']
  },
  {
    columnOptionId: 'roi',
    label: 'ROI',
    portfolios: ['proposals', 'projects']
  },
  {
    columnOptionId: 'sir',
    label: 'SIR',
    portfolios: ['proposals', 'projects']
  },
  {
    columnOptionId: 'simplePayback',
    label: 'Simple Payback',
    portfolios: ['measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'waterSavings',
    label: 'Water Savings',
    portfolios: ['proposals', 'projects']
  },
  {
    columnOptionId: 'proposalCost',
    label: 'Proposal Cost',
    portfolios: ['proposals']
  },
  {
    columnOptionId: 'projectCost',
    label: 'Project Cost',
    portfolios: ['projects']
  },
  {
    columnOptionId: 'measureCost',
    label: 'Measure Cost',
    portfolios: ['measures']
  },
  {
    columnOptionId: 'constructionStatus',
    label: 'Construction Status',
    portfolios: ['projects']
  },
  {
    columnOptionId: 'updated',
    label: 'Updated',
    option: 'left',
    order: 25,
    portfolios: ['building', 'measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'created',
    label: 'Created',
    option: 'left',
    order: 26,
    portfolios: ['building', 'measures', 'proposals', 'projects']
  },
  {
    columnOptionId: 'author',
    label: 'Author',
    option: 'left',
    order: 27,
    portfolios: ['building', 'measures', 'proposals', 'projects']
  }
]

export const portfolioColumnOptions = portfolioUnits => [
  {
    id: 'name',
    label: 'Name',
    option: 'left',
    order: 1,
    blocked: true,
    portfolios: [
      {
        tab: 'building',
        value: 'buildingname',
        order: 1
      },
      {
        tab: 'measures',
        value: 'displayname',
        order: 1
      },
      {
        tab: 'proposals',
        value: 'name',
        order: 1
      },
      {
        tab: 'projects',
        value: 'name',
        order: 1
      }
    ]
  },
  {
    id: 'buildingName',
    label: 'Building Name',
    order: 2,
    portfolios: [
      {
        tab: 'measures',
        value: 'building.buildingname',
        optionGroup: 'Building',
        order: 2
      },
      {
        tab: 'proposals',
        value: 'building.buildingName',
        optionGroup: 'Building',
        order: 2
      },
      {
        tab: 'projects',
        value: 'building.buildingname',
        optionGroup: 'Building',
        order: 2
      }
    ]
  },
  {
    id: 'organization_name',
    label: 'Organization Name',
    option: 'left',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'organization.name',
        order: 2
      },
      {
        tab: 'measures',
        optionGroup: 'Organization',
        value: 'organization.name',
        order: 3
      },
      {
        tab: 'proposals',
        optionGroup: 'Organization',
        value: 'organization.name',
        order: 3
      },
      {
        tab: 'projects',
        optionGroup: 'Organization',
        value: 'organization.name',
        order: 3
      }
    ]
  },
  {
    id: 'location_address',
    label: 'Address',
    option: 'left',
    order: 3,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'location_address',
        order: 3
      }
    ]
  },
  {
    id: 'location_city',
    label: 'City',
    option: 'left',
    order: 4,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'location_city',
        order: 4
      }
    ]
  },
  {
    id: 'location_state',
    label: 'State/ Province',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'location_state',
        option: 'left',
        order: 5
      }
    ]
  },
  {
    id: 'location_zipcode',
    label: 'Zip Code',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'location_zipcode',
        option: 'left',
        order: 6
      }
    ]
  },
  {
    id: 'nycfields_borough',
    label: 'Borough',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'nycfields_borough',
        option: 'left',
        order: 7
      }
    ]
  },
  {
    id: 'primaryUse',
    label: 'Primary Use',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'buildinguse',
        option: 'left',
        order: 8
      }
    ]
  },
  {
    id: 'glassFloorArea',
    label: 'Gross Floor Area',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'squarefeet',
        unit: UNIT_TYPES.FT2,
        order: 9,
        total: true
      }
    ]
  },
  {
    id: 'aboveGradeFloors',
    label: 'Above Grade Floors',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'floorcount',
        option: 'right',
        order: 10
      }
    ]
  },
  {
    id: 'belowGradeFloors',
    label: 'Below Grade Floors',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'belowgradefloorcount',
        option: 'right',
        order: 11
      }
    ]
  },
  {
    id: 'yearBuilt',
    label: 'Year Built',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'buildyear',
        option: 'left',
        order: 12
      }
    ]
  },
  {
    id: 'secondaryUseTypes',
    label: 'Secondary Use Types',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'buildingUseTypes.use',
        option: 'left',
        order: 19
      }
    ]
  },
  {
    id: 'secondaryUseTypeArea',
    label: 'Secondary Use Type Area',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'buildingUseTypes.squarefeet',
        option: 'left',
        unit: UNIT_TYPES.FT2,
        order: 20
      }
    ]
  },
  {
    id: 'nycfieldsHistoricBuilding',
    label: 'Historic Building',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'nycfields_historicbuilding',
        option: 'left',
        order: 49
      }
    ]
  },
  {
    id: 'nycfieldsMultiTenant',
    label: 'Multi-Tenant',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'nycfields_multitenant',
        option: 'left',
        order: 50
      }
    ]
  },
  {
    id: 'nycfieldsPercentLeased',
    label: 'Percent Leased',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'nycfields_percentleased',
        option: 'left',
        order: 51
      }
    ]
  },
  {
    id: 'nycfields_percentowned',
    label: 'Percent Owned',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'nycfields_percentowned',
        option: 'left',
        order: 52
      }
    ]
  },
  {
    id: 'nycfields_energy_sys_multiple_lots',
    label: 'Shared Energy on Multiple Lots',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'nycfields_energy_sys_multiple_lots',
        option: 'left',
        order: 53
      }
    ]
  },
  {
    id: 'nycfields_energy_sys_single_lotst',
    label: 'Shared Energy on Single Lot',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'nycfields_energy_sys_single_lotst',
        option: 'left',
        order: 54
      }
    ]
  },
  {
    id: 'open247',
    label: 'Open 24/7',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'open247',
        option: 'left',
        order: 55
      }
    ]
  },
  {
    id: 'sitename',
    label: 'Site Name',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'sitename',
        label: 'Site Name',
        option: 'left',
        order: 57
      }
    ]
  },
  {
    id: 'clientname',
    label: 'Client Name',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'clientname',
        option: 'left',
        order: 58
      }
    ]
  },
  {
    id: 'totalCostSaving',
    label: 'Total Cost Saving',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'totalCostSaving',
        option: 'right',
        unit: '$'
      }
    ]
  },
  {
    id: 'nycfields_bin',
    label: 'Bin',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'nycfields_bin',
        option: 'left'
      }
    ]
  },
  {
    id: 'nycfields_taxlot',
    label: 'Tax Lot',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'nycfields_taxlot',
        option: 'left'
      }
    ]
  },
  {
    id: 'nycfields_block',
    label: 'Block',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Property',
        value: 'nycfields_block',
        option: 'left'
      }
    ]
  },
  {
    id: 'avgAnnualEnergyUse',
    label: 'Avg. Annual Energy Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Overview',
        value: buildingEnergySummaryKeys.avgAnnualEnergyUse,
        option: 'right',
        unit: UNIT_TYPES.kBTU,
        order: 14,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        baselineKey: 'totalEnergyUsage',
        colorComparison: true
      },
      {
        tab: 'measures',
        optionGroup: 'Utilities',
        value: buildingEnergySummaryKeys.avgAnnualEnergyUse,
        option: 'right',
        unit: UNIT_TYPES.kBTU,
        order: 14,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        baselineKey: 'totalEnergyUsage',
        colorComparison: true
      }
    ]
  },
  {
    id: 'avgEnergyUseIntensity',
    label: 'Avg. Energy Use Intensity',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Overview',
        value: buildingEnergySummaryKeys.avgEnergyUseIntensity,
        option: 'right',
        unit: `${UNIT_TYPES.kBTU}/${UNIT_TYPES.FT2}`,
        order: 13,
        timeDepends: true,
        baselineKey: 'eui',
        colorComparison: true,
        isIntensity: true
      }
    ]
  },
  {
    id: 'avgAnnualEnergyCost',
    label: 'Avg. Annual Energy Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Overview',
        value: buildingEnergySummaryKeys.avgAnnualEnergyCost,
        option: 'right',
        unit: '$',
        order: 15,
        timeDepends: true,
        total: true,
        width: 150,
        appendTargetColumns: true,
        baselineKey: 'totalEnergyCost',
        colorComparison: true
      },
      {
        tab: 'measures',
        optionGroup: 'Utilities',
        value: buildingEnergySummaryKeys.avgAnnualEnergyCost,
        option: 'right',
        unit: '$',
        order: 15,
        timeDepends: true,
        total: true,
        width: 150,
        appendTargetColumns: true,
        baselineKey: 'totalEnergyCost',
        colorComparison: true
      }
    ]
  },
  {
    id: 'avg_annual_energy_cost_intensity',
    label: 'Avg. Energy Cost Intensity',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Overview',
        value: buildingEnergySummaryKeys.avgEnergyCostIntensity,
        option: 'right',
        unit: `$/${UNIT_TYPES.FT2}`,
        order: 13,
        timeDepends: true,
        isIntensity: true
      }
    ]
  },
  {
    id: 'energyStartScore',
    label: 'Energy Star Score',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Overview',
        value: 'buildingPmScores.score',
        option: 'right',
        order: 12
      }
    ]
  },
  {
    id: 'avgAnnualGhgEmissions',
    label: 'Avg. Annual GHG Emissions',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Overview',
        value: buildingEnergySummaryKeys.avgAnnualGHGEmissions,
        option: 'right',
        unit: UNIT_TYPES.MTCO2E,
        order: 16,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        baselineKey: 'ghg',
        width: 150,
        colorComparison: false
      },
      {
        tab: 'measures',
        optionGroup: 'Utilities',
        value: buildingEnergySummaryKeys.avgAnnualGHGEmissions,
        option: 'right',
        unit: UNIT_TYPES.MTCO2E,
        order: 16,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        baselineKey: 'ghg',
        width: 150,
        colorComparison: false
      }
    ]
  },
  {
    id: 'ghgIntensity',
    label: '12 Month Trend GHG Emissions Intensity',
    decimals: 3,
    portfolios: [
      {
        tab: 'building',
        optionGroup: '12 Month Trend',
        value: buildingEnergySummaryKeys.avgGHGIntensity,
        option: 'right',
        unit: `${UNIT_TYPES.MTCO2E}/${UNIT_TYPES.FT2}`,
        order: 17,
        timeDepends: true,
        baselineCalculated: building =>
          building.baseline.ghg / building.squarefeet,
        colorComparison: true,
        isIntensity: true
      }
    ]
  },
  {
    id: 'measuresCount',
    label: 'Measures',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'projects',
        option: 'right',
        order: 1,
        total: true
      }
    ]
  },
  {
    id: 'measuresInProgress',
    label: 'Measures In Progress',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'measureData.inProgress',
        option: 'right',
        order: 2,
        total: true
      }
    ]
  },
  {
    id: 'measuresOpen',
    label: 'Measures Identified',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'measureData.identified',
        option: 'right',
        order: 3,
        total: true
      }
    ]
  },
  {
    id: 'budgetPlanYear',
    label: 'Budget Plan Year',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'measureData.budgetPeriod',
        option: 'right',
        order: 6
      },
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'budgetPeriod',
        option: 'right',
        order: 6
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.budgetPeriod'
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.budgetPeriod'
      }
    ]
  },
  {
    id: 'taskCompletion',
    label: 'Task Completion',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Task Lists',
        value: 'taskCompletionPercent',
        option: 'right',
        unit: '%',
        order: 1
      }
    ]
  },
  {
    id: 'baselineElectricityUseIntesity',
    label: 'Electricity Use Intensity',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Baseline',
        value: 'baseline.electricityUseIntensity',
        option: 'right',
        unit: `${UNIT_TYPES.kBTU}/${UNIT_TYPES.FT2}`,
        order: 5,
        total: true,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        hasDynamicUnit: true,
        isIntensity: true
      }
    ]
  },
  {
    id: 'baselineElectricityBlendRate',
    label: 'Electricity Blended Rate',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Baseline',
        value: 'projectRates.electric',
        option: 'right',
        unit: '',
        order: 6,
        total: true
      }
    ]
  },
  {
    id: 'naturalGasCost',
    label: 'Natural Gas Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Baseline',
        value: 'baseline.totalUtilCosts.naturalgas',
        option: 'right',
        unit: '$',
        order: 7,
        total: true
      }
    ]
  },
  {
    id: 'naturalGasUse',
    label: 'Natural Gas Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Baseline',
        value: 'baseline.totalUtilUsages.naturalgas',
        option: 'right',
        unit:
          portfolioUnits?.[UTILITY_TYPES.NATURAL_GAS]?.unit ||
          UNIT_TYPES.THERMS,
        order: 8,
        total: true,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: 'naturalGasBlendedRate',
    label: 'Natural Gas Blended Rate',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Baseline',
        value: 'projectRates.gas',
        option: 'right',
        unit: '',
        order: 9,
        total: true
      }
    ]
  },
  {
    id: 'ghgEmissionsUse',
    label: 'GHG Emissions Use',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Baseline',
        value: 'baseline.ghg',
        option: 'right',
        unit: UNIT_TYPES.MTCO2E,
        order: 10,
        total: true
      }
    ]
  },
  {
    id: 'avgAnnualElectricityUse',
    label: 'Avg. Annual Electricity Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.electricUsage,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit:
          portfolioUnits?.[UTILITY_TYPES.ELECTRICITY]?.unit || UNIT_TYPES.kWH,
        order: 15,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        baselineKey: 'totalEnergyUsage',
        colorComparison: true,
        hasDynamicUnit: true
      },
      {
        tab: 'measures',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.electricUsage,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit:
          portfolioUnits?.[UTILITY_TYPES.ELECTRICITY]?.unit || UNIT_TYPES.kWH,
        order: 15,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        baselineKey: 'totalEnergyUsage',
        colorComparison: true,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.electricCost,
    label: 'Avg. Annual Electricity Cost',
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.electricCost,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit: '$',
        order: 16,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        baselineKey: 'totalEnergyCost',
        colorComparison: true
      },
      {
        tab: 'measures',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.electricCost,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit: '$',
        order: 16,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        baselineKey: 'totalEnergyCost',
        colorComparison: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.electricIntensity,
    label: '12 Month Trend Electricity Use Intensity',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: '12 Month Trend',
        value: utilitiesKeysValues.electricIntensity,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit: `${UNIT_TYPES.kBTU}/${UNIT_TYPES.FT2}`,
        order: 17,
        timeDepends: true,
        total: true,
        baselineKey: 'eui',
        colorComparison: true,
        isIntensity: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.electricityDemandUsage,
    label: 'Avg. Annual Electricity Demand Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.electricityDemandUsage,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'left',
        unit: UNIT_TYPES.kW,
        order: 18,
        timeDepends: true,
        total: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.electricityDemandCost,
    label: 'Avg. Annual Electricity Demand Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.electricityDemandCost,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'left',
        unit: '$',
        order: 19,
        timeDepends: true,
        total: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.solarUsage,
    label: 'Avg. Annual Solar Generation Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.solarUsage,
        fuelType: UTILITY_TYPES.SOLAR,
        option: 'left',
        unit: portfolioUnits?.[UTILITY_TYPES.SOLAR]?.unit || UNIT_TYPES.kWH,
        order: 20,
        timeDepends: true,
        total: true,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.solarCost,
    label: 'Avg. Annual Solar Generation Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.solarCost,
        fuelType: UTILITY_TYPES.SOLAR,
        option: 'left',
        unit: '$',
        order: 21,
        timeDepends: true,
        total: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.naturalGasUsage,
    label: 'Avg. Annual Natural Gas Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.naturalGasUsage,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'left',
        unit:
          portfolioUnits?.[UTILITY_TYPES.NATURAL_GAS]?.unit || UNIT_TYPES.kBTU,
        order: 22,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        baselineKey: 'totalEnergyUsage',
        colorComparison: true,
        hasDynamicUnit: true
      },
      {
        tab: 'measures',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.naturalGasUsage,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'left',
        unit:
          portfolioUnits?.[UTILITY_TYPES.NATURAL_GAS]?.unit || UNIT_TYPES.kBTU,
        order: 22,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        baselineKey: 'totalEnergyUsage',
        colorComparison: true,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.naturalGasCost,
    label: 'Avg. Annual Natural Gas Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.naturalGasCost,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'left',
        unit: '$',
        order: 23,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        baselineKey: 'totalEnergyCost',
        colorComparison: true
      },
      {
        tab: 'measures',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.naturalGasCost,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'left',
        unit: '$',
        order: 23,
        timeDepends: true,
        total: true,
        appendTargetColumns: true,
        baselineKey: 'totalEnergyCost',
        colorComparison: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.naturalGasIntensity,
    label: '12 Month Trend Natural Gas Use Intensity',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: '12 Month Trend',
        value: utilitiesKeysValues.naturalGasIntensity,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'right',
        unit: `${UNIT_TYPES.kBTU}/${UNIT_TYPES.FT2}`,
        order: 24,
        timeDepends: true,
        total: true,
        baselineKey: 'eui',
        colorComparison: true,
        isIntensity: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.steamUsage,
    label: 'Avg. Annual Steam Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.steamUsage,
        fuelType: UTILITY_TYPES.STEAM,
        option: 'left',
        unit: portfolioUnits?.[UTILITY_TYPES.STEAM]?.unit || UNIT_TYPES.MLB,
        timeDepends: true,
        total: true,
        order: 25,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.steamCost,
    label: 'Avg. Annual Steam Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.steamCost,
        fuelType: UTILITY_TYPES.STEAM,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 26
      }
    ]
  },
  {
    id: utilitiesKeysValues.fuelOil1Usage,
    label: 'Avg. Annual Fuel Oil 1 Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.fuelOil1Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_1,
        option: 'left',
        unit:
          portfolioUnits?.[UTILITY_TYPES.FUEL_OIL_1]?.unit || UNIT_TYPES.GAL,
        timeDepends: true,
        total: true,
        order: 27,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.fuelOil1Cost,
    label: 'Avg. Annual Fuel Oil 1 Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.fuelOil1Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_1,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 28
      }
    ]
  },
  {
    id: utilitiesKeysValues.fuelOil2Usage,
    label: 'Avg. Annual Fuel Oil 2 Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.fuelOil2Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_2,
        option: 'left',
        unit:
          portfolioUnits?.[UTILITY_TYPES.FUEL_OIL_2]?.unit || UNIT_TYPES.GAL,
        timeDepends: true,
        total: true,
        order: 29,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.fuelOil2Cost,
    label: 'Avg. Annual Fuel Oil 2 Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.fuelOil2Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_2,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 30
      }
    ]
  },
  {
    id: utilitiesKeysValues.fuelOil4Usage,
    label: 'Avg. Annual Fuel Oil 4 Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.fuelOil4Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_4,
        option: 'left',
        unit:
          portfolioUnits?.[UTILITY_TYPES.FUEL_OIL_4]?.unit || UNIT_TYPES.GAL,
        timeDepends: true,
        total: true,
        order: 31,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.fuelOil4Cost,
    label: 'Avg. Annual Fuel Oil 4 Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.fuelOil4Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_4,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 32
      }
    ]
  },
  {
    id: utilitiesKeysValues.fuelOil56Usage,
    label: 'Avg. Annual Fuel Oil 5 & 6 Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.fuelOil56Usage,
        fuelType: UTILITY_TYPES.FUEL_OIL_56,
        option: 'left',
        unit:
          portfolioUnits?.[UTILITY_TYPES.FUEL_OIL_56]?.unit || UNIT_TYPES.GAL,
        timeDepends: true,
        total: true,
        order: 33,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.fuelOil56Cost,
    label: 'Avg. Annual Fuel Oil 5 & 6 Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.fuelOil56Cost,
        fuelType: UTILITY_TYPES.FUEL_OIL_56,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 34
      }
    ]
  },
  {
    id: utilitiesKeysValues.dieselUsage,
    label: 'Avg. Annual Diesel Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.dieselUsage,
        fuelType: UTILITY_TYPES.DIESEL,
        option: 'left',
        unit: portfolioUnits?.[UTILITY_TYPES.DIESEL]?.unit || UNIT_TYPES.GAL,
        timeDepends: true,
        total: true,
        order: 35,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.dieselCost,
    label: 'Avg. Annual Diesel Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.dieselCost,
        fuelType: UTILITY_TYPES.DIESEL,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 36
      }
    ]
  },
  {
    id: utilitiesKeysValues.propaneUsage,
    label: 'Avg. Annual Propane Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.propaneUsage,
        fuelType: UTILITY_TYPES.PROPANE,
        option: 'left',
        unit: portfolioUnits?.[UTILITY_TYPES.PROPANE]?.unit || UNIT_TYPES.GAL,
        timeDepends: true,
        total: true,
        order: 37,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.propaneCost,
    label: 'Avg. Annual Propane Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.propaneCost,
        fuelType: UTILITY_TYPES.PROPANE,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 38
      }
    ]
  },
  {
    id: utilitiesKeysValues.keroseneUsage,
    label: 'Avg. Annual Kerosene Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.keroseneUsage,
        fuelType: UTILITY_TYPES.KEROSENE,
        option: 'left',
        unit: portfolioUnits?.[UTILITY_TYPES.KEROSENE]?.unit || UNIT_TYPES.GAL,
        timeDepends: true,
        total: true,
        order: 39,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.keroseneCost,
    label: 'Avg. Annual Kerosene Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.keroseneCost,
        fuelType: UTILITY_TYPES.KEROSENE,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 40
      }
    ]
  },
  {
    id: utilitiesKeysValues.districtHotWaterUsage,
    label: 'Avg. Annual District Hot Water Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.districtHotWaterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_HOT_WATER,
        option: 'left',
        unit:
          portfolioUnits?.[UTILITY_TYPES.DISTRICT_HOT_WATER]?.unit ||
          UNIT_TYPES.kBTU,
        timeDepends: true,
        total: true,
        order: 41,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.districtHotWaterCost,
    label: 'Avg. Annual District Hot Water Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.districtHotWaterCost,
        fuelType: UTILITY_TYPES.DISTRICT_HOT_WATER,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 42
      }
    ]
  },
  {
    id: utilitiesKeysValues.districtChilledWaterElectricMeterUsage,
    label: 'Avg. Annual District Chilled Water Electric Meter Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.districtChilledWaterElectricMeterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER,
        option: 'left',
        unit:
          portfolioUnits?.[UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER]
            ?.unit || UNIT_TYPES.TON_HOURS,
        timeDepends: true,
        total: true,
        order: 43,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.districtChilledWaterElectricMeterCost,
    label: 'Avg. Annual District Chilled Water Electric Meter Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.districtChilledWaterElectricMeterCost,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 44
      }
    ]
  },
  {
    id: utilitiesKeysValues.districtChilledWaterAbsorptionMeterUsage,
    label: 'Avg. Annual District Chilled Water Absorption Meter Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.districtChilledWaterAbsorptionMeterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER,
        option: 'left',
        unit:
          portfolioUnits?.[
            UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER
          ]?.unit || UNIT_TYPES.TON_HOURS,
        timeDepends: true,
        total: true,
        order: 45,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.districtChilledWaterAbsorptionMeterCost,
    label: 'Avg. Annual District Chilled Water Absorption Meter Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.districtChilledWaterAbsorptionMeterCost,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 46
      }
    ]
  },
  {
    id: utilitiesKeysValues.districtChilledWaterEngineMeterUsage,
    label: 'Avg. Annual District Chilled Water Engine Meter Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.districtChilledWaterEngineMeterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER,
        option: 'left',
        unit:
          portfolioUnits?.[UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER]
            ?.unit || UNIT_TYPES.TON_HOURS,
        timeDepends: true,
        total: true,
        order: 47,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.districtChilledWaterEngineMeterCost,
    label: 'Avg. Annual District Chilled Water Engine Meter Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.districtChilledWaterEngineMeterCost,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 48
      }
    ]
  },
  {
    id: utilitiesKeysValues.districtChilledWaterOtherMeterUsage,
    label: 'Avg. Annual District Chilled Water Other Meter Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.districtChilledWaterOtherMeterUsage,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER,
        option: 'left',
        unit:
          portfolioUnits?.[UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER]
            ?.unit || UNIT_TYPES.TON_HOURS,
        timeDepends: true,
        total: true,
        order: 49,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.districtChilledWaterOtherMeterCost,
    label: 'Avg. Annual District Chilled Water Other Meter Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.districtChilledWaterOtherMeterCost,
        fuelType: UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 50
      }
    ]
  },
  {
    id: utilitiesKeysValues.windUsage,
    label: 'Avg. Annual On-Site Wind Generation Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.windUsage,
        fuelType: UTILITY_TYPES.WIND,
        option: 'left',
        unit: portfolioUnits?.[UTILITY_TYPES.WIND]?.unit || UNIT_TYPES.kWH,
        timeDepends: true,
        total: true,
        order: 51,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.windCost,
    label: 'Avg. Annual On-Site Wind Generation Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.windCost,
        fuelType: UTILITY_TYPES.WIND,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 52
      }
    ]
  },
  {
    id: utilitiesKeysValues.woodUsage,
    label: 'Avg. Annual Wood Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.woodUsage,
        fuelType: UTILITY_TYPES.WOOD,
        option: 'left',
        unit: portfolioUnits?.[UTILITY_TYPES.WOOD]?.unit || UNIT_TYPES.TONS,
        timeDepends: true,
        total: true,
        order: 53,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.woodCost,
    label: 'Avg. Annual Wood Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.woodCost,
        fuelType: UTILITY_TYPES.WOOD,
        option: 'left',
        unit: '$',
        timeDepends: true,
        total: true,
        order: 54
      }
    ]
  },
  {
    id: utilitiesKeysValues.waterUsage,
    label: 'Avg. Annual Water Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.waterUsage,
        fuelType: UTILITY_TYPES.WATER,
        option: 'left',
        unit: portfolioUnits?.[UTILITY_TYPES.WATER]?.unit || UNIT_TYPES.CCF,
        order: 55,
        timeDepends: true,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.waterIntensity,
    label: 'Water Use Intensity',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.waterIntensity,
        fuelType: UTILITY_TYPES.WATER,
        option: 'left',
        unit: `${portfolioUnits?.[UTILITY_TYPES.WATER]?.unit ||
          UNIT_TYPES.CCF}/${UNIT_TYPES.FT2}`,
        order: 56,
        timeDepends: true,
        hasDynamicUnit: true,
        isIntensity: true
      }
    ]
  },
  {
    id: utilitiesKeysValues.waterCost,
    label: 'Avg. Annual Water Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Utilities',
        value: utilitiesKeysValues.waterCost,
        fuelType: UTILITY_TYPES.WATER,
        option: 'left',
        unit: '$',
        order: 57,
        timeDepends: true,
        total: true
      }
    ]
  },
  {
    id: 'type',
    label: 'Type',
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'type',
        order: 5
      }
    ]
  },
  {
    id: 'projectName',
    label: 'Project Name',
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'project_name',
        order: 6
      }
    ]
  },
  {
    id: 'budgetType',
    label: 'Budget type',
    order: 7,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'budgetType',
        order: 7
      }
    ]
  },
  {
    id: 'totalBudget',
    label: 'Total Budget',
    order: 8,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'totalBudget',
        option: 'right',
        unit: '$',
        total: true,
        order: 8
      },
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'measureData.totalBudget',
        option: 'right',
        unit: '$',
        total: true,
        order: 5
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.totalBudget',
        unit: '$',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.totalBudget',
        unit: '$',
        total: true
      }
    ]
  },
  {
    id: 'annualSavings',
    label: 'Annual Cost Savings',
    decimals: 0,
    order: 9,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.annualSavings',
        unit: '$',
        total: true
      },
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.annualSavings',
        unit: '$',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.annualSavings',
        unit: '$',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.annualSavings',
        unit: '$',
        total: true
      }
    ]
  },
  {
    id: 'electricitySavings',
    label: 'Electricity Savings',
    decimals: 0,
    order: 11,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.electricSavings',
        unit:
          portfolioUnits?.[UTILITY_TYPES.ELECTRICITY]?.unit || UNIT_TYPES.kWH,
        total: true,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        hasDynamicUnit: true
      },
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.electricSavings',
        unit:
          portfolioUnits?.[UTILITY_TYPES.ELECTRICITY]?.unit || UNIT_TYPES.kWH,
        total: true,
        order: 11,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        hasDynamicUnit: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.electricSavings',
        unit:
          portfolioUnits?.[UTILITY_TYPES.ELECTRICITY]?.unit || UNIT_TYPES.kWH,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        total: true,
        order: 12,
        hasDynamicUnit: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.electricSavings',
        unit:
          portfolioUnits?.[UTILITY_TYPES.ELECTRICITY]?.unit || UNIT_TYPES.kWH,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        total: true,
        order: 12,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: `${UTILITY_TYPES.ELECTRICITY}SavingsPercentOfTarget`,
    label: 'Electricity Savings - % of Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: `metric.${UTILITY_TYPES.ELECTRICITY}SavingsPercentOfTarget`,
        unit: '%',
        total: false,
        fuelType: UTILITY_TYPES.ELECTRICITY
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: `total.${UTILITY_TYPES.ELECTRICITY}SavingsPercentOfTarget`,
        unit: '%',
        fuelType: UTILITY_TYPES.ELECTRICITY,
        total: false
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: `total.${UTILITY_TYPES.ELECTRICITY}SavingsPercentOfTarget`,
        unit: '%',
        fuelType: UTILITY_TYPES.ELECTRICITY,
        total: false
      }
    ]
  },
  {
    id: 'demandSavings',
    label: 'Demand Savings',
    decimals: 0,
    order: 13,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.demandSavings',
        unit: UNIT_TYPES.kW,
        total: true,
        order: 13
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.demandSavings',
        unit: UNIT_TYPES.kW,
        total: true,
        order: 13
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.demandSavings',
        unit: UNIT_TYPES.kW,
        total: true,
        order: 13
      }
    ]
  },
  {
    id: 'eul',
    label: 'Effective Useful Life',
    decimals: 0,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.eul',
        unit: 'yrs',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.eul',
        unit: 'yrs'
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.eul',
        unit: 'yrs',
        total: true
      }
    ]
  },
  {
    id: 'energySavings',
    label: 'Energy Savings',
    decimals: 0,
    order: 11,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.energySavings',
        unit: UNIT_TYPES.kBTU,
        total: true
      },
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.energySavings',
        unit: UNIT_TYPES.kBTU,
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.energySavings',
        unit: UNIT_TYPES.kBTU,
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.energySavings',
        unit: UNIT_TYPES.kBTU,
        total: true
      }
    ]
  },
  {
    id: 'energySavingsBaselinePercent',
    label: 'Energy Savings - % of Baseline',
    decimals: 1,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.energySavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.energySavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.energySavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.energySavingsBaselinePercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'energySavingsTrendPercent',
    label: 'Energy Savings - % of 12 Month Trend',
    decimals: 1,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.energySavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.energySavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.energySavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.energySavingsTrendPercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'ghgSavings',
    label: 'GHG Savings',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.ghgSavings',
        unit: UNIT_TYPES.MTCO2E,
        total: true,
        order: 15
      },
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.ghgSavings',
        unit: UNIT_TYPES.MTCO2E,
        total: true,
        order: 15
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.ghgSavings',
        unit: UNIT_TYPES.MTCO2E,
        total: true,
        order: 15
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.ghgSavings',
        unit: UNIT_TYPES.MTCO2E,
        total: true
      }
    ]
  },
  {
    id: 'ghgSavingsPercentOfTarget',
    label: 'GHG Savings - % of Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.ghgSavingsPercentOfTarget',
        unit: '%',
        total: false
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.ghgSavingsPercentOfTarget',
        unit: '%',
        total: false
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.ghgSavingsPercentOfTarget',
        unit: '%',
        total: false
      }
    ]
  },
  {
    id: 'ghgSavingsCost',
    label: 'GHG Cost Savings',
    decimals: 0,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.ghgSavingsCost',
        unit: '$',
        total: true,
        order: 20
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.ghgSavingsCost',
        unit: '$',
        total: true,
        order: 16
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.ghgSavingsCost',
        unit: '$',
        total: true
      }
    ]
  },
  {
    id: 'incentive',
    label: 'Incentive',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.incentive',
        unit: '$',
        total: true
      },
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.incentive',
        unit: '$',
        total: true,
        order: 17
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.incentive',
        unit: '$',
        total: true,
        order: 19
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.incentive',
        unit: '$',
        total: true
      }
    ]
  },
  {
    id: 'maintenanceSavings',
    label: 'Maintenance Savings',
    decimals: 0,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'initialvalues_maintenance_savings',
        unit: '$',
        total: true,
        order: 21
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.maintenanceSavings',
        unit: '$',
        total: true,
        order: 20
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.maintenanceSavings',
        unit: '$',
        total: true
      }
    ]
  },
  {
    id: 'npv',
    label: 'NPV',
    decimals: 0,
    portfolios: [
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.npv',
        order: 23
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.npv'
      }
    ]
  },
  {
    id: 'naturalGasSavings',
    label: 'Natural Gas Savings',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.gasSavings',
        unit:
          portfolioUnits?.[UTILITY_TYPES.NATURAL_GAS]?.unit ||
          UNIT_TYPES.THERMS,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        total: true,
        hasDynamicUnit: true
      },
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.gasSavings',
        unit:
          portfolioUnits?.[UTILITY_TYPES.NATURAL_GAS]?.unit ||
          UNIT_TYPES.THERMS,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        total: true,
        order: 15,
        hasDynamicUnit: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.gasSavings',
        unit:
          portfolioUnits?.[UTILITY_TYPES.NATURAL_GAS]?.unit ||
          UNIT_TYPES.THERMS,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        total: true,
        order: 14,
        hasDynamicUnit: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.gasSavings',
        unit:
          portfolioUnits?.[UTILITY_TYPES.NATURAL_GAS]?.unit ||
          UNIT_TYPES.THERMS,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        total: true,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: `${UTILITY_TYPES.NATURAL_GAS}SavingsPercentOfTarget`,
    label: 'Natural Gas Savings - % of Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: `metric.${UTILITY_TYPES.NATURAL_GAS}SavingsPercentOfTarget`,
        unit: '%',
        total: false,
        fuelType: UTILITY_TYPES.ELECTRICITY
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: `total.${UTILITY_TYPES.NATURAL_GAS}SavingsPercentOfTarget`,
        unit: '%',
        fuelType: UTILITY_TYPES.ELECTRICITY,
        total: false
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: `total.${UTILITY_TYPES.NATURAL_GAS}SavingsPercentOfTarget`,
        unit: '%',
        fuelType: UTILITY_TYPES.ELECTRICITY,
        total: false
      }
    ]
  },
  {
    id: 'gasSavingsBaselinePercent',
    label: 'Natural Gas Savings - % of Baseline',
    decimals: 1,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.naturalGasSavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: `metric.naturalGasSavingsBaselinePercent`,
        option: 'right',
        order: 16,
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.naturalGasSavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.naturalGasSavingsBaselinePercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'gasSavingsTrendPercent',
    label: 'Natural Gas Savings - % of 12 Month Trend',
    decimals: 1,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.naturalGasSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.naturalGasSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.naturalGasSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.naturalGasSavingsTrendPercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'gasSavingsCost',
    label: 'Natural Gas Cost Savings',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.gasSavingsCost',
        unit: '$',
        total: true
      },
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.gasSavingsCost',
        option: 'right',
        order: 17,
        unit: '$',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.gasSavingsCost',
        unit: '$',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.gasSavingsCost',
        unit: '$',
        total: true
      }
    ]
  },
  {
    id: 'gasSavingsCostBaselinePercent',
    label: 'Natural Gas Cost Savings - % of Baseline',
    decimals: 1,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.naturalGasCostSavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.naturalGasCostSavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.naturalGasCostSavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.naturalGasCostSavingsBaselinePercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'gasSavingsCostTrendPercent',
    label: 'Natural Gas Cost Savings - % of 12 Month Trend',
    decimals: 1,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.naturalGasCostSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.naturalGasCostSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.naturalGasCostSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.naturalGasCostSavingsTrendPercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'ghgSavingsBaselinePercent',
    label: 'GHG Savings - % of Baseline',
    decimals: 1,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.ghgSavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.ghgSavingsBaselinePercent',
        option: 'right',
        order: 19,
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.ghgSavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.ghgSavingsBaselinePercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'ghgSavingsTrendPercent',
    label: 'GHG Savings - % of 12 Month Trend',
    decimals: 1,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.ghgSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.ghgSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.ghgSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.ghgSavingsTrendPercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'simplePayback',
    label: 'Simple Payback',
    decimals: 1,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric_simple_payback',
        order: 22,
        unit: 'yrs'
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total_simplepayback',
        order: 22,
        unit: 'yrs'
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.simplePayBack',
        unit: 'yrs'
      }
    ]
  },
  {
    id: 'roi',
    label: 'ROI',
    decimals: 1,
    portfolios: [
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.roi',
        order: 21
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.roi'
      }
    ]
  },
  {
    id: 'sir',
    label: 'SIR',
    decimals: 1,
    portfolios: [
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.sir',
        order: 24
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.sir'
      }
    ]
  },
  {
    id: 'waterSavings',
    label: 'Water Savings',
    decimals: 0,
    portfolios: [
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total_watersavings',
        unit: portfolioUnits?.[UTILITY_TYPES.WATER]?.unit || UNIT_TYPES.CCF,
        total: true,
        order: 17,
        fuelType: UTILITY_TYPES.WATER,
        hasDynamicUnit: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.waterSavings',
        unit: portfolioUnits?.[UTILITY_TYPES.WATER]?.unit || UNIT_TYPES.CCF,
        total: true,
        fuelType: UTILITY_TYPES.WATER,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: 'proposalCost',
    label: 'Proposal Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.projectCost',
        unit: '$',
        total: true
      }
    ]
  },
  {
    id: 'projectCost',
    label: 'Project Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.projectCost',
        unit: '$',
        total: true,
        order: 18
      }
    ]
  },
  {
    id: 'measureCost',
    label: 'Measure Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.projectCost',
        unit: '$',
        total: true,
        order: 17
      }
    ]
  },
  {
    id: 'measureCategory',
    label: 'Measure Category',
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'project_category'
      }
    ]
  },
  {
    id: 'measureApplication',
    label: 'Measure Application',
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'project_application'
      }
    ]
  },
  {
    id: 'measureTechnology',
    label: 'Measure Technology',
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'project_technology'
      }
    ]
  },
  {
    id: 'rebateCode',
    label: 'Rebate Code',
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'incentive_rebate_code',
        order: 26
      }
    ]
  },
  {
    id: 'electricitySavingsBaselinePercent',
    label: 'Electricity Savings - % of Baseline',
    decimals: 1,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.electricitySavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.electricitySavingsBaselinePercent',
        option: 'right',
        order: 12,
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.electricitySavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.electricitySavingsBaselinePercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'electricSavingsTrendPercent',
    label: 'Electricity Savings - % of 12 Month Trend',
    decimals: 1,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.electricitySavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.electricitySavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.electricitySavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.electricitySavingsTrendPercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'electricSavingsCost',
    label: 'Electricity Cost Savings',
    decimals: 0,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.electricSavingsCost',
        option: 'right',
        order: 13,
        unit: '$',
        total: true
      },
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.electricSavingsCost',
        unit: '$',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.electricSavingsCost',
        unit: '$',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.electricSavingsCost',
        unit: '$',
        total: true
      }
    ]
  },
  {
    id: 'electricSavingsCostBaselinePercent',
    label: 'Electricity Cost Savings - % of Baseline',
    decimals: 1,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.electricityCostSavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.electricityCostSavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.electricityCostSavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.electricityCostSavingsBaselinePercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'electricSavingsCostTrendPercent',
    label: 'Electricity Cost Savings - % of 12 Month Trend',
    decimals: 1,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.electricityCostSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.electricityCostSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.electricityCostSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.electricityCostSavingsTrendPercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'constructionStatus',
    label: 'Construction Status',
    portfolios: [
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'constructionstatus',
        order: 5
      }
    ]
  },
  {
    id: 'constructionEstimatedStartDate',
    label: 'Construction Estimated Start Date',
    portfolios: [
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'estimatedstartdate',
        order: 6
      }
    ]
  },
  {
    id: 'constructionEstimatedCompletionDate',
    label: 'Construction Estimated End Date',
    portfolios: [
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'estimatedcompletiondate',
        order: 7
      }
    ]
  },
  {
    id: 'constructionActualStartDate',
    label: 'Construction Actual Start Date',
    portfolios: [
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'actualstartdate',
        order: 8
      }
    ]
  },
  {
    id: 'constructionActualCompletionDate',
    label: 'Construction Actual End Date',
    portfolios: [
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'actualcompletiondate',
        order: 9
      }
    ]
  },
  {
    id: 'status',
    label: 'Status',
    portfolios: [
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'status',
        order: 4
      },
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'status',
        order: 4
      }
    ]
  },
  {
    id: 'updated',
    label: 'Updated',
    option: 'left',
    order: 25,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Author',
        value: 'updated'
      },
      {
        tab: 'measures',
        optionGroup: 'Author',
        value: 'updated'
      },
      {
        tab: 'proposals',
        optionGroup: 'Author',
        value: 'updated'
      },
      {
        tab: 'projects',
        optionGroup: 'Author',
        value: 'updated'
      }
    ]
  },
  {
    id: 'created',
    label: 'Created',
    option: 'left',
    order: 26,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Author',
        value: 'created'
      },
      {
        tab: 'measures',
        optionGroup: 'Author',
        value: 'created'
      },
      {
        tab: 'proposals',
        optionGroup: 'Author',
        value: 'created'
      },
      {
        tab: 'projects',
        optionGroup: 'Author',
        value: 'created'
      }
    ]
  },
  {
    id: 'author',
    label: 'Author',
    option: 'left',
    order: 27,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Author',
        value: 'createdBy.name'
      },
      {
        tab: 'measures',
        optionGroup: 'Author',
        value: 'createdBy.name'
      },
      {
        tab: 'proposals',
        optionGroup: 'Author',
        value: 'createdBy.name'
      },
      {
        tab: 'projects',
        optionGroup: 'Author',
        value: 'createdBy.name'
      }
    ]
  },
  {
    id: 'avgAnnualEnergyUse_target_energy_use_reductionPercentage',
    label: 'Energy Use - Current Reduction Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.ENERGY,
        baselineKey: 'totalEnergyUsage',
        option: 'right',
        unit: UNIT_TYPES.kBTU,
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.ENERGY,
        baselineKey: 'totalEnergyUsage',
        option: 'right',
        unit: UNIT_TYPES.kBTU,
        width: 150
      }
    ]
  },
  {
    id: 'avgAnnualEnergyUse_target_energy_use_progressToTarget',
    label: 'Energy Use - Progress to Current Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.ENERGY,
        option: 'right',
        unit: '%',
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.ENERGY,
        option: 'right',
        unit: '%',
        width: 150
      }
    ]
  },
  {
    id: 'avgAnnualEnergyCost_target_energy_cost_reductionPercentage',
    label: 'Energy Cost - Current Reduction Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.ENERGY_COST_REDUCTION,
        baselineKey: 'totalEnergyCost',
        option: 'right',
        unit: '$',
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.ENERGY_COST_REDUCTION,
        baselineKey: 'totalEnergyCost',
        option: 'right',
        unit: '$',
        width: 150
      }
    ]
  },
  {
    id: 'avgAnnualEnergyCost_target_energy_cost_progressToTarget',
    label: 'Energy Cost - Progress to Current Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.COST,
        option: 'right',
        unit: '%',
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.COST,
        option: 'right',
        unit: '%',
        width: 150
      }
    ]
  },
  {
    id: 'avgAnnualGhgEmissions_target_ghg_reductionPercentage',
    label: 'GHG Emissions - Current Reduction Target',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.GHG_EMISSIONS,
        baselineKey: 'ghg',
        option: 'right',
        unit: UNIT_TYPES.MTCO2E,
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.GHG_EMISSIONS,
        baselineKey: 'ghg',
        option: 'right',
        unit: UNIT_TYPES.MTCO2E,
        width: 150
      }
    ]
  },
  {
    id: 'avgAnnualGhgEmissions_target_ghg_progressToTarget',
    label: 'GHG Emissions - Progress to Current Target',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.GHG_EMISSIONS,
        option: 'right',
        unit: '%',
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.GHG_EMISSIONS,
        option: 'right',
        unit: '%',
        width: 150
      }
    ]
  },
  {
    id: 'avgAnnualElectricityUse_target_electricity_use_reductionPercentage',
    label: 'Electricity Use - Current Reduction Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.ENERGY,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit:
          portfolioUnits?.[UTILITY_TYPES.ELECTRICITY]?.unit || UNIT_TYPES.kBTU,
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.ENERGY,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit:
          portfolioUnits?.[UTILITY_TYPES.ELECTRICITY]?.unit || UNIT_TYPES.kBTU,
        width: 150
      }
    ]
  },
  {
    id: 'avgAnnualElectricityUse_target_electricity_use_progressToTarget',
    label: 'Electricity Use - Progress to Current Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.ENERGY,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit: '%',
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.ENERGY,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit: '%',
        width: 150
      }
    ]
  },
  {
    id: `${utilitiesKeysValues.electricCost}_target_electricity_cost_reductionPercentage`,
    label: 'Electricity Cost - Current Reduction Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.COST,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit: '$',
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.COST,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit: '$',
        width: 150
      }
    ]
  },
  {
    id: `${utilitiesKeysValues.electricCost}_target_electricity_cost_progressToTarget`,
    label: 'Electricity Cost - Progress to Current Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.COST,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit: '%',
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.COST,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        option: 'right',
        unit: '%',
        width: 150
      }
    ]
  },
  {
    id: `${utilitiesKeysValues.naturalGasUsage}_target_natural_gas_reductionPercentage`,
    label: 'Natural Gas Use - Current Reduction Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.ENERGY,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'right',
        unit:
          portfolioUnits?.[UTILITY_TYPES.NATURAL_GAS]?.unit || UNIT_TYPES.kBTU,
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.ENERGY,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'right',
        unit:
          portfolioUnits?.[UTILITY_TYPES.NATURAL_GAS]?.unit || UNIT_TYPES.kBTU,
        width: 150
      }
    ]
  },
  {
    id: `${utilitiesKeysValues.naturalGasUsage}_target_natural_gas_progressToTarget`,
    label: 'Natural Gas Use - Progress to Current Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.ENERGY,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'right',
        unit: '%',
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.ENERGY,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'right',
        unit: '%',
        width: 150
      }
    ]
  },
  {
    id: `${utilitiesKeysValues.naturalGasCost}_target_natural_gas_cost_reductionPercentage`,
    label: 'Natural Gas Cost - Current Reduction Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.COST,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'right',
        unit: '$',
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'reductionPercentage',
        targetKey: targetDictionary.COST,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'right',
        unit: '$',
        width: 150
      }
    ]
  },
  {
    id: `${utilitiesKeysValues.naturalGasCost}_target_natural_gas_cost_progressToTarget`,
    label: 'Natural Gas Cost - Progress to Current Target',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.COST,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'right',
        unit: '%',
        width: 150
      },
      {
        tab: 'measures',
        optionGroup: 'Target',
        value: 'progressToTarget',
        targetKey: targetDictionary.COST,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        option: 'right',
        unit: '%',
        width: 150
      }
    ]
  },
  {
    id: 'costSavingsBaselinePercent',
    label: 'Cost Savings - % of Baseline',
    decimals: 2,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.costSavingsBaselinePercent',
        unit: '%'
      },
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.costSavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.costSavingsBaselinePercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.costSavingsBaselinePercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'costSavingsTrendPercent',
    label: 'Cost Savings - % of 12 Month Trend',
    decimals: 1,
    portfolios: [
      {
        tab: 'measures',
        optionGroup: 'Measure',
        value: 'metric.costSavingsTrendPercent',
        unit: '%'
      },
      {
        tab: 'building',
        optionGroup: 'Measures',
        value: 'total.costSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'proposals',
        optionGroup: 'Proposal',
        value: 'total.costSavingsTrendPercent',
        unit: '%',
        total: true
      },
      {
        tab: 'projects',
        optionGroup: 'Project',
        value: 'total.costSavingsTrendPercent',
        unit: '%',
        total: true
      }
    ]
  },
  {
    id: 'twelveMonthTrendElectricityBlendedRate',
    label: '12 Month Trend Electricity Blended Rate',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        value: 'total.twelveMonthTrendElectricityBlendedRate',
        optionGroup: '12 Month Trend',
        unit: `$/${UNIT_TYPES.kBTU}`
      },
      {
        tab: 'measures',
        value: 'metric.twelveMonthTrendElectricityBlendedRate',
        optionGroup: '12 Month Trend',
        unit: `$/${UNIT_TYPES.kBTU}`
      }
    ]
  },
  {
    id: 'twelveMonthTrendGasBlendedRate',
    label: '12 Month Trend Natural Gas Blended Rate',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        value: 'total.twelveMonthTrendGasBlendedRate',
        optionGroup: '12 Month Trend',
        unit: `$/${UNIT_TYPES.kBTU}`
      },
      {
        tab: 'measures',
        value: 'metric.twelveMonthTrendGasBlendedRate',
        optionGroup: '12 Month Trend',
        unit: `$/${UNIT_TYPES.kBTU}`
      }
    ]
  },
  {
    id: 'baselineElectricityBlendedRate',
    label: 'Baseline Electricity Blended Rate',
    decimals: 2,
    portfolios: [
      {
        tab: 'measures',
        value: 'metric.baselineElectricityBlendedRate',
        optionGroup: 'Baseline',
        unit: `$/${UNIT_TYPES.kBTU}`
      }
    ]
  },
  {
    id: 'baselineGasBlendedRate',
    label: 'Baseline Natural Gas Blended Rate',
    portfolios: [
      {
        tab: 'measures',
        value: 'metric.baselineGasBlendedRate',
        optionGroup: 'Baseline',
        unit: `$/${UNIT_TYPES.kBTU}`
      }
    ]
  },
  {
    id: 'baselineElectricityCost',
    label: 'Baseline Electicity Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Baseline',
        value: 'baseline.totalUtilCosts.electric',
        option: 'right',
        unit: '$',
        order: 3,
        total: true
      },
      {
        tab: 'measures',
        value: 'metric.baselineElectricityCost',
        optionGroup: 'Baseline'
      }
    ]
  },
  {
    id: 'baselineElectricityUse',
    label: 'Baseline Electicity Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Baseline',
        value: 'baseline.totalUtilUsages.electric',
        option: 'right',
        unit:
          portfolioUnits?.[UTILITY_TYPES.ELECTRICITY]?.unit || UNIT_TYPES.kWH,
        order: 4,
        total: true,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        hasDynamicUnit: true
      },
      {
        tab: 'measures',
        value: 'metric.baselineElectricityUse',
        optionGroup: 'Baseline',
        unit:
          portfolioUnits?.[UTILITY_TYPES.ELECTRICITY]?.unit || UNIT_TYPES.kWH,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: 'baselineGasCost',
    label: 'Baseline Natural Gas Cost',
    decimals: 0,
    portfolios: [
      {
        tab: 'measures',
        value: 'metric.baselineGasCost',
        optionGroup: 'Baseline',
        unit: '$'
      }
    ]
  },
  {
    id: 'baselineGasUse',
    label: 'Baseline Natural Gas Use',
    decimals: 0,
    portfolios: [
      {
        tab: 'measures',
        value: 'metric.baselineGasUse',
        optionGroup: 'Baseline',
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        unit:
          portfolioUnits?.[UTILITY_TYPES.NATURAL_GAS]?.unit ||
          UNIT_TYPES.THERMS,
        hasDynamicUnit: true
      }
    ]
  },
  {
    id: 'twelveMonthTrendElectricityUseIntensity',
    label: '12 Month Trend Electricity Use Intensity',
    decimals: 2,
    portfolios: [
      {
        tab: 'measures',
        value: 'metric.twelveMonthTrendElectricityUseIntensity',
        optionGroup: '12 Month Trend',
        unit: `${UNIT_TYPES.kBTU}/${UNIT_TYPES.FT2}`,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        isIntensity: true
      }
    ]
  },
  {
    id: 'twelveMonthTrendGasUseIntensity',
    label: '12 Month Trend Natural Gas Use Intensity',
    decimals: 2,
    portfolios: [
      {
        tab: 'measures',
        value: 'metric.twelveMonthTrendGasUseIntensity',
        optionGroup: '12 Month Trend',
        unit: `${UNIT_TYPES.kBTU}/${UNIT_TYPES.FT2}`,
        fuelType: UTILITY_TYPES.NATURAL_GAS,
        isIntensity: true
      }
    ]
  },
  {
    id: 'twelveMonthTrendGhgUseIntensity',
    label: '12 Month Trend GHG Use Intensity',
    decimals: 2,
    portfolios: [
      {
        tab: 'measures',
        value: 'metric.twelveMonthTrendGhgUseIntensity',
        optionGroup: '12 Month Trend',
        unit: `${UNIT_TYPES.MTCO2E}/${UNIT_TYPES.FT2}`,
        isIntensity: true
      }
    ]
  },
  {
    id: 'twelveMonthTrendEnergyUseIntensity',
    label: '12 Month Trend Energy Use Intensity',
    decimals: 2,
    portfolios: [
      {
        tab: 'measures',
        value: 'metric.twelveMonthTrendEnergyUseIntensity',
        optionGroup: '12 Month Trend',
        unit: `${UNIT_TYPES.kBTU}/${UNIT_TYPES.FT2}`,
        isIntensity: true
      }
    ]
  },
  {
    id: 'twelveMonthTrendEnergyCostIntensity',
    label: '12 Month Trend Energy Cost Intensity',
    decimals: 2,
    portfolios: [
      {
        tab: 'measures',
        value: 'metric.twelveMonthTrendEnergyCostIntensity',
        optionGroup: '12 Month Trend',
        unit: `$/${UNIT_TYPES.FT2}`,
        isIntensity: true
      }
    ]
  },
  {
    id: 'baselineElectricityUseIntensity',
    label: 'Baseline Electricity Use Intensity',
    decimals: 2,
    portfolios: [
      {
        tab: 'measures',
        value: 'metric.baselineElectricityUseIntensity',
        optionGroup: 'Baseline',
        unit: `${portfolioUnits?.[UTILITY_TYPES.ELECTRICITY]?.unit ||
          UNIT_TYPES.kWH}/${UNIT_TYPES.FT2}`,
        fuelType: UTILITY_TYPES.ELECTRICITY,
        hasDynamicUnit: true,
        isIntensity: true
      }
    ]
  },
  {
    id: 'baselineEnergyCostIntensity',
    label: 'Baseline Energy Cost Intensity',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Baseline',
        value: 'baseline.costPerSqFoot',
        option: 'right',
        unit: `$/${UNIT_TYPES.FT2}`,
        order: 1,
        total: true,
        isIntensity: true
      },
      {
        tab: 'measures',
        value: 'metric.baselineEnergyCostIntensity',
        optionGroup: 'Baseline',
        unit: `$/${UNIT_TYPES.FT2}`,
        isIntensity: true
      }
    ]
  },
  {
    id: 'baselineEnergyUseIntensity',
    label: 'Baseline Energy Use Intensity',
    decimals: 2,
    portfolios: [
      {
        tab: 'building',
        optionGroup: 'Baseline',
        value: 'baseline.eui',
        option: 'right',
        unit: `${UNIT_TYPES.kBTU}/${UNIT_TYPES.FT2}`,
        order: 2,
        total: true,
        isIntensity: true
      },
      {
        tab: 'measures',
        value: 'metric.baselineEnergyUseIntensity',
        optionGroup: 'Baseline',
        unit: `${UNIT_TYPES.kBTU}/${UNIT_TYPES.FT2}`,
        isIntensity: true
      }
    ]
  }
]

// Team column options is the unique portfolio view that is not inside portfolioColumnOptions
export const teamColumnOptions = teamView => [
  {
    id: 'name',
    label: 'Name',
    portfolios: [
      {
        tab: 'teams',
        value: 'name',
        optionGroup: 'Team',
        order: 1
      }
    ]
  },
  {
    id: 'organization_name',
    label: 'Organization Name',
    portfolios: [
      {
        tab: 'teams',
        optionGroup: 'Organization',
        value: 'organization.name',
        order: 2
      }
    ]
  },
  {
    id: 'numberOfBuilding',
    label: '# of Buildings',
    portfolios: [
      {
        tab: 'teams',
        value: 'numberOfBuilding',
        optionGroup: 'Team',
        order: 3,
        total: true
      }
    ]
  },
  {
    id: 'project_identified',
    label: `# of ${teamView}s`,
    portfolios: [
      {
        tab: 'teams',
        value: 'project_identified',
        optionGroup: 'Team',
        order: 4,
        total: true
      }
    ]
  },
  {
    id: 'average_project_building',
    label: `Avg. # of ${teamView}s/Building`,
    portfolios: [
      {
        tab: 'teams',
        value: 'average_project_building',
        optionGroup: 'Team',
        order: 5,
        total: true
      }
    ]
  },
  {
    id: 'total_energy_savings_identified',
    label: 'Total Energy Savings Identified (kBTU)',
    portfolios: [
      {
        tab: 'teams',
        value: 'total_energy_savings_identified',
        optionGroup: 'Team',
        order: 6,
        total: true
      }
    ]
  },
  {
    id: 'average_project_kbtu',
    label: `Avg. Energy Savings/${teamView} (kBTU)`,
    portfolios: [
      {
        tab: 'teams',
        value: 'average_project_kbtu',
        optionGroup: 'Team',
        order: 7,
        total: true
      }
    ]
  },
  {
    id: 'total_ghg_cost_savings_identified',
    label: 'Total Cost Savings Identified ($)',
    portfolios: [
      {
        tab: 'teams',
        value: 'total_ghg_cost_savings_identified',
        optionGroup: 'Team',
        order: 8,
        total: true
      }
    ]
  },
  {
    id: 'total_ghg_savings_identified',
    label: 'Total GHG Savings Identified (mtCO2e)',
    portfolios: [
      {
        tab: 'teams',
        value: 'total_ghg_savings_identified',
        optionGroup: 'Team',
        order: 9,
        total: true
      }
    ]
  },
  {
    id: 'total_incentive_identified',
    label: 'Total Incentives Identified ($)',
    portfolios: [
      {
        tab: 'teams',
        value: 'total_incentive_identified',
        optionGroup: 'Team',
        order: 10,
        total: true
      }
    ]
  },
  {
    id: 'median_project_payback',
    label: 'Median Measure Payback (yrs)',
    portfolios: [
      {
        tab: 'teams',
        value: 'median_project_payback',
        optionGroup: 'Team',
        order: 11,
        total: true
      }
    ]
  }
]

export const PortfolioColumnOptionsCategories = {
  building: [
    'Property',
    'Utilities',
    'Overview',
    'Measures',
    'Task Lists',
    'Author',
    'Target',
    'Baseline',
    '12 Month Trend'
  ],
  measures: [
    'Organization',
    'Building',
    'Measure',
    'Utilities',
    'Author',
    'Target',
    'Baseline',
    '12 Month Trend'
  ],
  projects: ['Organization', 'Building', 'Project', 'Author'],
  proposals: ['Organization', 'Building', 'Proposal', 'Author'],
  teams: ['Organization', 'Team']
}

const getDefaultColumnsByPortfolioTable = (
  defaultColumns,
  portfolioTable,
  portfolioUnits
) => {
  const portfolioTableDefaultColumns = defaultColumns.filter(column =>
    column.portfolios.includes(portfolioTable)
  )
  return portfolioTableDefaultColumns.map(column =>
    portfolioColumnOptions(portfolioUnits).find(
      tablesColumn => tablesColumn.id === column.columnOptionId
    )
  )
}

export const defaultPortfolioLists = portfolioUnits => ({
  buildings: getDefaultColumnsByPortfolioTable(
    defaultPortfolioColumns,
    'building',
    portfolioUnits
  ),
  measures: getDefaultColumnsByPortfolioTable(
    defaultPortfolioColumns,
    'measures',
    portfolioUnits
  ),
  projects: getDefaultColumnsByPortfolioTable(
    defaultPortfolioColumns,
    'projects',
    portfolioUnits
  ),
  proposals: getDefaultColumnsByPortfolioTable(
    defaultPortfolioColumns,
    'proposals',
    portfolioUnits
  )
})
