import React, { useRef } from 'react'
import classNames from 'classnames'
import useOutsideClick from 'utils/hooks/useOutsideClick'
import styles from './BaseModal.scss'
import PropTypes from 'prop-types'

const BaseModal = ({ className, header, body, footer, onClose }) => {
  const modalRef = useRef(null)
  useOutsideClick(modalRef, onClose)

  return (
    <div
      className={classNames(styles.modal, className ? className['modal'] : '')}
    >
      <div
        className={classNames(
          styles.modalInner,
          className ? className['modalInner'] : ''
        )}
        ref={modalRef}
      >
        {(onClose || header) && (
          <div
            className={classNames(
              styles.modalHeading,
              className ? className['modalHeading'] : ''
            )}
          >
            {header}
            {onClose && (
              <div
                className={classNames(
                  styles.modalClose,
                  className ? className['modalClose'] : ''
                )}
                onClick={onClose}
              >
                <i className='material-icons'>close</i>
              </div>
            )}
          </div>
        )}
        <div
          className={classNames(
            styles.modalBody,
            className ? className['modalBody'] : ''
          )}
        >
          {body}
        </div>
        {footer && (
          <div
            className={classNames(
              styles.modalFooter,
              className ? className['modalFooter'] : ''
            )}
          >
            {footer}
          </div>
        )}
      </div>
    </div>
  )
}

BaseModal.propTypes = {
  onClose: PropTypes.func,
  header: PropTypes.element,
  body: PropTypes.element,
  footer: PropTypes.element,
  className: PropTypes.object
}

export default BaseModal
