import React, { useCallback, useState } from 'react'
import ProjectContentItem from './ProjectContentItem'
import styles from './ProjectImageContentItem.scss'
import classNames from 'classnames'

const ProjectImageContentItem = ({
  projectConfig,
  index,
  onRemove,
  onUpdateProjectConfig
}) => {
  const content = projectConfig.content[index]
  const onSaveTimestamp = useCallback(
    value => {
      content.timestamp = value
      projectConfig.content[index] = content
      onUpdateProjectConfig(projectConfig)
    },
    [onUpdateProjectConfig, content, projectConfig, index]
  )
  return (
    <ProjectContentItem
      isRemovable
      renderHeaderSection={() => (
        <>
          <div>Images(s)</div>
          <div className={styles.timestampContainer}>
            <div className={styles.checkboxContainer}>
              <label>
                <input
                  checked={content.timestamp}
                  value='hasTitle'
                  name={'Content'}
                  className={classNames(
                    content.timestamp ? styles['checked'] : ''
                  )}
                  onChange={() => onSaveTimestamp(!content.timestamp)}
                  type='checkbox'
                />
                <span />
              </label>
            </div>
            <span>Add a Timestamp</span>
          </div>
        </>
      )}
      handleContentRemoved={onRemove}
    />
  )
}

export default ProjectImageContentItem
