import {
  buildingEnergySummaryKeys,
  utilitiesKeysValues
} from '../utils/PortfolioOptions'
export const utilitiesToEndUseDict = {
  [buildingEnergySummaryKeys.avgEnergyUseIntensity]: 'eui-estimate',
  [buildingEnergySummaryKeys.avgAnnualEnergyUse]: 'total-energy-estimate',
  [buildingEnergySummaryKeys.avgAnnualEnergyCost]: 'cost-estimate',
  [buildingEnergySummaryKeys.avgAnnualGHGEmissions]: 'ghg-estimate',
  [buildingEnergySummaryKeys.avgGHGIntensity]: 'ghg-intensity-estimate',
  [utilitiesKeysValues.electricIntensity]: 'electric-use-intensity-estimate',
  [utilitiesKeysValues.naturalGasIntensity]: 'gas-use-intensity-estimate'
}

export const utilitiesToEndUse = key => {
  if (key.includes('totalUtilUsages.electric')) {
    return 'electric-energy-estimate'
  }
  if (key.includes('totalUtilCosts.electric')) {
    return 'electric-cost-estimate'
  }
  if (key.includes('totalUtilUsages.naturalgas')) {
    return 'gas-energy-estimate'
  }
  if (key.includes('totalUtilCosts.naturalgas')) {
    return 'gas-cost-estimate'
  }
  return ''
}

export const mapBuildingValidationKey = {
  country: 'Country',
  street_address: 'Street Address',
  city: 'City',
  state: 'State',
  postal_code: 'Postal Code',
  year_built: 'Year built',
  gross_floor_area: 'Gross Floor Area',
  occupancy: 'Occupancy',
  consecutive_months_of_energy_data: '12 consecutive months of energy data',
  primary_use: 'Primary Use',
  use_types: 'Use Types'
}
