import React, { useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '../../../Template/Template.scss'
import chartStyles from './Chart.scss'
import targetStyles from './TemplateTarget.scss'
import config from '../../../../../project.config'
import CustomRange from '../../../../utils/customRange'
import { sortChart, formatChartReportName, groupByChart } from 'utils/Utils'

const formatOptionsLabel = {
  pie: 'Pie Chart',
  donut: 'Donut Chart'
}

const proto = !config.secure ? 'http://' : 'https://'
const apiHost = config.apiHost

export const Chart = props => {
  const [reportList, setReportList] = useState([])
  const [imgsource, setImgsource] = useState('')
  const [reportObj, setReportObj] = useState()
  const [chartName, setChartName] = useState('')
  const [selectedYearRange, setSelectedYearRange] = useState('')

  const [formatOption, setFormatOption] = useState('')
  const [dataOption, setDataOption] = useState('')

  const [selectedYearList, setSelectedYearList] = useState([])
  const [customStartMonth, setCustomStartMonth] = useState('')
  const [customStartYear, setCustomStartYear] = useState('')
  const [customEndMonth, setCustomEndMonth] = useState('')
  const [customEndYear, setCustomEndYear] = useState('')
  const [yearOption, setYearOption] = useState('SetOnExport')
  const [colorOption, setColorOption] = useState('')

  let num = 0
  const yearList = Array.apply(null, { length: 11 }).map((e, i) => ({
    label: moment()
      .subtract(num, 'years')
      .format('YYYY'),
    value: moment()
      .subtract(num++, 'years')
      .format('YYYY')
  }))
  const monthList = moment.months().map((currentValue, index) => ({
    label: currentValue,
    value: currentValue
  }))

  const colorList = [
    {
      name: 'type1',
      value: 'type1'
    },
    {
      name: 'type2',
      value: 'type2'
    }
  ]

  useEffect(() => {
    getList()
  }, [props.views.length])

  /** @description : This function is called on selection of any of the charts, here by default last 12 months
   * option is selected. Here the template is modified every time a new chart is selected. The preview of static image is set here.
   */
  const handleChange = e => {
    let body = JSON.parse(JSON.stringify(props.body))
    let widget = body[props.index]
    let option = widget.options && widget.options[props.layoutIndex]

    option.fields = []
    if (e.target.value !== '') {
      let viewId = ''
      let src = ''
      let url = ''
      let buildId = ''
      let param = ''

      let reportObj = reportList.find(item =>
        item.reportName.includes(e.target.value)
      )

      viewId = reportObj.urls[0].viewId
      buildId = reportObj.previewBuildingId
      url = reportObj.urls[0].value
      const _formatOption =
        (reportObj?.formatOptions &&
          reportObj.formatOptions.length > 1 &&
          reportObj.formatOptions[0]) ||
        ''
      const isCostType = e.target.value.includes('Cost')
      const dataOption =
        reportObj?.formatOptions &&
        reportObj.formatOptions.includes('pieEstPer') &&
        reportObj.formatOptions.includes('donutEstPer')
          ? isCostType
            ? 'cost'
            : 'usage'
          : ''
      param = url.split('?')[1] || ''

      option.fields.push(`chart.reportName.${e.target.value}`)
      option.metaData = {
        yearOption: 'SetOnExport'
      }

      if (e.target.value === 'Custom Color Checker') {
        option.metaData.colorOption = colorOption
      }

      option.fields.push(`chart.Url.${url}`)
      if (param !== '') {
        option.fields.push(`chart.param.${param}`)
        param = `?${param}`
      } else {
        option.fields.push(`chart.param.${param}`)
      }
      if (_formatOption && _formatOption !== '') {
        option.fields.push(`chart.param.vf_format_type=${_formatOption}`)
      }
      if (dataOption?.length) {
        option.fields.push(`chart.param.vf_data_option=${dataOption}`)
      }
      if (buildId !== '' && viewId !== '') {
        if (e.target.value === 'Custom Color Checker') {
          if (param) param = `${param}&vf_color=${colorOption}`
          else param = `?vf_color=${colorOption}`
        }
        if (_formatOption && _formatOption !== '') {
          param = `${param}&vf_format_type=${_formatOption}`
        }
        src = `${proto}${apiHost}/building/${buildId}/chart/${viewId}/preview${param}`
      }
      setChartName(e.target.value)
      setImgsource(src)
      setSelectedYearList(reportObj.urls.length > 0 ? reportObj.urls : [])
      setSelectedYearRange(reportObj.urls[0].key || '')
      setYearOption('SetOnExport')
      setFormatOption(_formatOption)
      setDataOption(dataOption)
      setColorOption(colorList[0].value)
      setReportObj(reportObj)
      props.handleUpdateTemplateState(body)
    } else {
      if (option.fields.length > 0) {
        option.fields = []
      }
      setChartName('')
      setImgsource('')
      setSelectedYearList([])
      setSelectedYearRange('')
      setYearOption('SetOnExport')
      setColorOption(colorList[0].value)
      setReportObj(undefined)
      props.handleUpdateTemplateState(body)
    }
  }

  /** @description : This function is for selection of year range. Handles 3 situations 12 months, 36 months and custom.
   * This function is sent to custom range component as props.
   */
  const handleYearRange = event => {
    const { index } = props
    let body = JSON.parse(JSON.stringify(props.body))
    const id = event.target.id
    let eventValue = id === 'Range' ? event.target.value : 'Custom'
    let viewId = ''
    let src = ''
    let url = ''
    let buildId = ''
    let widget = body[index]
    let option = (widget.options && widget.options[props.layoutIndex]) || {}
    let param = ''
    let _customStartMonth
    let _customEndMonth
    let _customStartYear
    let _customEndYear
    const paramIndex = option.fields.findIndex(
      x => x.split('.')[1] === 'param' && x.indexOf('vf_format_type') === -1
    )
    const urlIndex = option.fields.findIndex(x => x.split('.')[1] === 'Url')

    if (id === 'Range') {
      if (eventValue !== '') {
        option.fields = option.fields.filter(a => {
          return !a.split('.')[1].includes('Url')
        })
        option.fields = option.fields.filter(a => {
          return !a.split('.')[1].includes('year')
        })
        option.fields = option.fields.filter(a => {
          return !a.split('.')[1].includes('param')
        })
        _customStartMonth = monthList[0].value
        _customEndMonth = monthList[monthList.length - 1].value
        _customStartYear = yearList[2].value
        _customEndYear = yearList[0].value
        _customEndMonth =
          new Date('1' + customEndMonth + customEndYear) > new Date()
            ? monthList[new Date().getMonth()].value
            : customEndMonth
        option.metaData = {
          yearOption: 'SetYearRange',
          yearRange: event.target.value,
          selectedStartMonth: customStartMonth,
          selectedStartYear: customStartYear,
          selectedEndMonth: customEndMonth,
          selectedEndYear: customEndYear
        }
      }
    } else {
      if (id === 'selectedStartMonth') _customStartMonth = event.target.value
      else if (id === 'selectedStartYear') _customStartYear = event.target.value
      else if (id === 'selectedEndMonth') _customEndMonth = event.target.value
      else if (id === 'selectedEndYear') _customEndYear = event.target.value
      option.metaData = {
        yearOption: 'SetYearRange',
        yearRange: 'Custom',
        selectedStartMonth: _customStartMonth,
        selectedStartYear: _customStartYear,
        selectedEndMonth: _customEndMonth,
        selectedEndYear: _customEndYear
      }
    }

    if (chartName === 'Custom Color Checker') {
      option.metaData.colorOption = colorOption
    }

    let arr = reportList.find(item => item.reportName === chartName)
    buildId = arr.previewBuildingId
    let urlsArr = arr.urls
    let urlObj = urlsArr.filter(item => item.key === eventValue)
    if (urlObj && urlObj[0] && urlObj[0].value) {
      viewId = urlObj[0].viewId
      url = urlObj[0].value
      param = url.split('?')[1] || ''
      if (urlIndex === -1) {
        option.fields.push(`charts.Url.${url}`)
      } else {
        option.fields[urlIndex] = `charts.Url.${url}`
      }
    }
    if (param !== '') {
      if (paramIndex === -1) option.fields.push(`chart.param.${param}`)
      else option.fields[paramIndex] = `chart.param.${param}`
      param = `?${param}`
    } else {
      if (paramIndex === -1) option.fields.push(`chart.param.${param}`)
      else option.fields[paramIndex] = `chart.param.${param}`
    }
    if (buildId !== '' && viewId !== '') {
      if (chartName === 'Custom Color Checker') {
        if (param) param = `${param}&vf_color=${colorOption}`
        else param = `?vf_color=${colorOption}`
      }
      src = `${proto}${apiHost}/building/${buildId}/chart/${viewId}/preview${param}`
    }

    if (formatOption && formatOption !== '') {
      if (!option.fields.some(elem => elem.indexOf('vf_format_type') !== -1)) {
        option.fields.push(`chart.param.vf_format_type=${formatOption}`)
      }
    }

    if (dataOption?.length) {
      if (!option.fields.some(elem => elem.indexOf('vf_data_option') !== -1)) {
        option.fields.push(`chart.param.vf_data_option=${dataOption}`)
      }
    }

    setSelectedYearRange(eventValue)
    setImgsource(src)
    setCustomStartMonth(_customStartMonth)
    setCustomStartYear(_customStartYear)
    setCustomEndMonth(_customEndMonth)
    setCustomEndYear(_customEndYear)
    props.handleUpdateTemplateState(body)
  }

  /** @description : On load function , where we get all the predefined charts and are set in state. Some changes are
   * needed to remove inconsequential code.
   */
  const getList = async () => {
    const { index, layoutIndex } = props
    let { views } = props
    let _customStartMonth = monthList[0].value
    let _customEndMonth = monthList[monthList.length - 1].value
    let _customStartYear = yearList[2].value
    let _customEndYear = yearList[0].value
    let _colorOption
    _customEndMonth =
      new Date('1' + _customEndMonth + _customEndYear) > new Date()
        ? monthList[new Date().getMonth()].value
        : _customEndMonth
    await props.getChartReports()

    let check = ''
    let src = ''
    let param = ''
    let year = _customStartYear
    let id = ''
    let buildId = ''
    let yList = []
    let body = JSON.parse(JSON.stringify(props.body))

    views = views.map(item => {
      if (
        item.reportName === 'Natural Gas End Use Breakdown Actual' ||
        item.reportName === 'Electric End Use Breakdown Actual' ||
        item.reportName === 'Energy End Use Breakdown - All Fuels Actual' ||
        item.reportName === 'GHG Impact' ||
        item.reportName === 'EUI Impact' ||
        item.reportName === 'Energy Saving by End Use'
      ) {
        const monthURL = item.urls[1]
        let urls = []
        let options = [12, 24, 36]
        for (let i = 0; i < options.length; i++) {
          urls.push({
            ...monthURL,
            key: `Last ${options[i]} months`
          })
        }
        item.urls = [...urls, item.urls[0]]
      }
      return item
    })

    const fields =
      (body[index] &&
        body[index].options &&
        body[index].options[layoutIndex]?.fields) ||
      []
    let formatOption = ''
    let dataOption = ''
    let _reportObj
    fields.map(each => {
      if (each.indexOf('Name') > -1) {
        check = each.split('.')[2]
      } else if (each.indexOf('param') > -1) {
        param = each.split('.')[2]
        if (param.indexOf('vf_format_type') !== -1) {
          formatOption = each.split('vf_format_type=')[1]
        }
        if (param.indexOf('vf_data_option') !== -1) {
          dataOption = each.split('vf_data_option=')[1]
        }
      }
    })
    let metaData =
      (body[index] &&
        body[index].options &&
        body[index].options[layoutIndex]?.metaData) ||
      {}
    if (metaData && metaData.yearRange) {
      year = metaData.yearRange || 'Last 12 months'
      _customStartMonth = metaData.selectedStartMonth
        ? metaData.selectedStartMonth
        : _customStartMonth
      _customStartYear = metaData.selectedStartYear
        ? metaData.selectedStartYear
        : _customStartYear
      _customEndMonth = metaData.selectedEndMonth
        ? metaData.selectedEndMonth
        : _customEndMonth
      _customEndYear = metaData.selectedEndYear
        ? metaData.selectedEndYear
        : _customEndYear
    }
    const _yearOption = (metaData && metaData.yearOption) || 'SetOnExport'
    if (!metaData || !metaData.yearOption) {
      metaData = {
        yearOption: _yearOption
      }
    }
    if (metaData && metaData.colorOption) _colorOption = metaData.colorOption

    let arr = props.views.filter(item => item.reportName === check)
    if (arr.length > 0) {
      buildId = arr[0].previewBuildingId
      yList = arr[0].urls
      let yearObj = arr[0].urls.filter(item => item.key === year)
      if (_yearOption === 'SetOnExport') yearObj = arr[0].urls
      id = (yearObj[0] && yearObj[0].viewId) || ''
      if (param !== '') {
        param = `?${param}`
      } else {
        param = ''
      }
      if (id !== '' && buildId !== '') {
        if (check === 'Custom Color Checker') {
          if (param) param = `${param}&vf_color=${_colorOption}`
          else param = `?vf_color=${_colorOption}`
        }
        src = `${proto}${apiHost}/building/${buildId}/chart/${id}/preview${param}`
      }
      if (check.length) {
        _reportObj = arr[0]
      }
    }

    views = sortChart(views)
    setReportList(views)
    setImgsource(src)
    setChartName(check)
    setSelectedYearRange(year)
    setSelectedYearList(yList)
    setCustomStartMonth(_customStartMonth)
    setCustomStartYear(_customStartYear)
    setCustomEndMonth(_customEndMonth)
    setCustomEndYear(_customEndYear)
    setYearOption(_yearOption)
    setColorOption(_colorOption)
    setFormatOption(formatOption)
    setDataOption(dataOption)
    setReportObj(_reportObj)
  }

  const renderYearOption = () => {
    const { layoutIndex } = props
    const array = [
      {
        name: 'Set on Export',
        value: 'SetOnExport'
      },
      {
        name: 'Set Year Range',
        value: 'SetYearRange'
      }
    ]
    return array.map((field, i) => {
      const ids = 'year-option-' + field.value + layoutIndex
      return (
        <label key={i} htmlFor={ids}>
          <input
            type='radio'
            id={ids}
            name={`year-option-${layoutIndex}`}
            value={field.value}
            checked={yearOption === field.value}
            onChange={handleClickYearOption}
          />
          <span>{field.name}</span>
        </label>
      )
    })
  }

  const handleClickFormatOption = event => {
    const { index, layoutIndex } = props
    let body = JSON.parse(JSON.stringify(props.body))
    let widget = body[index]
    const value = event.target.value
    let option = (widget && widget.options && widget.options[layoutIndex]) || {}
    const paramIndex = option.fields.findIndex(
      x => x.split('.')[1] === 'param' && x.indexOf('vf_format_type') > -1
    )
    if (paramIndex !== -1) {
      option.fields[paramIndex] = `chart.param.vf_format_type=${value}`
    }
    setFormatOption(value)
    props.handleUpdateTemplateState(body)
  }

  const handleClickDataOption = event => {
    const { index, layoutIndex } = props
    let body = JSON.parse(JSON.stringify(props.body))
    let widget = body[index]
    const value = event.target.value
    let option = (widget && widget.options && widget.options[layoutIndex]) || {}
    const paramIndex = option.fields.findIndex(
      x => x.split('.')[1] === 'param' && x.indexOf('vf_data_option') > -1
    )
    if (paramIndex !== -1) {
      option.fields[paramIndex] = `chart.param.vf_data_option=${value}`
    }
    if (option.fields[1]) {
      const chartTypeUrl =
        value === 'estimated'
          ? reportObj.chartTypes[
              formatOption === 'pie' ? 'pieEstPer' : 'donutEstPer'
            ]
          : undefined
      if (chartTypeUrl) {
        const oldViewId = option.fields[1]?.split('/views/')[1]?.split('/')[0]
        const newUrl = option.fields[1].replace(oldViewId, chartTypeUrl)
        option.fields[1] = `chart.Url.${newUrl}`
      }
    }
    setDataOption(value)
    props.handleUpdateTemplateState(body)
  }

  const renderFormatOption = () => {
    const { layoutIndex } = props
    return (reportObj?.formatOptions || []).map((field, i) => {
      const ids = 'format-option-' + field + layoutIndex
      if (formatOptionsLabel[field]) {
        return (
          <label key={i} htmlFor={ids}>
            <input
              type='radio'
              id={ids}
              name={`format-option-${layoutIndex}`}
              value={field}
              checked={formatOption === field}
              onChange={handleClickFormatOption}
            />
            <span>{formatOptionsLabel[field]}</span>
          </label>
        )
      }
    })
  }

  const renderDataOption = () => {
    const { layoutIndex } = props
    const isCostType = chartName.includes('Cost')
    const dataOptions = [
      isCostType
        ? { value: 'cost', label: 'Cost' }
        : { value: 'usage', label: 'Usage Percentage' },
      { value: 'estimated', label: 'Estimated Percentage' }
    ]
    return dataOptions.map((field, i) => {
      const id = `data-option-${field.value}-${layoutIndex}`
      return (
        <label
          key={i}
          htmlFor={id}
          className={!isCostType && i !== 0 ? targetStyles.displayBlock : {}}
        >
          <input
            type='radio'
            id={id}
            name={`data-option-${layoutIndex}`}
            value={field.value}
            checked={dataOption === field.value}
            onChange={handleClickDataOption}
          />
          <span>{field.label}</span>
        </label>
      )
    })
  }

  const handleClickYearOption = event => {
    const { index, layoutIndex } = props
    let body = JSON.parse(JSON.stringify(props.body))
    let widget = body[index]
    const value = event.target.value
    let option = (widget && widget.options && widget.options[layoutIndex]) || {}
    if (!option.metaData) option.metaData = {}
    if (value === 'SetOnExport') {
      option.metaData = {
        yearOption: value
      }
    } else {
      option.metaData = {
        yearOption: value,
        yearRange: 'Last 12 months'
      }
    }

    if (chartName === 'Custom Color Checker') {
      option.metaData.colorOption = colorOption
    }
    let eventValue = 'Last 12 months'
    let viewId = ''
    let src = ''
    let url = ''
    let buildId = ''
    let param = ''
    const paramIndex = option.fields.findIndex(x => x.split('.')[1] === 'param')
    const urlIndex = option.fields.findIndex(x => x.split('.')[1] === 'Url')
    let arr = reportList.find(item => item.reportName === chartName)
    buildId = arr.previewBuildingId
    let urlsArr = arr.urls
    let urlObj = urlsArr.filter(item => item.key === eventValue)
    if (urlObj && urlObj[0] && urlObj[0].value) {
      viewId = urlObj[0].viewId
      url = urlObj[0].value
      param = url.split('?')[1] || ''
      if (urlIndex === -1) {
        option.fields.push(`charts.Url.${url}`)
      } else {
        option.fields[urlIndex] = `charts.Url.${url}`
      }
    }
    if (param !== '') {
      if (paramIndex === -1) option.fields.push(`chart.param.${param}`)
      else option.fields[paramIndex] = `chart.param.${param}`
      param = `?${param}`
    } else {
      if (paramIndex === -1) option.fields.push(`chart.param.${param}`)
      else option.fields[paramIndex] = `chart.param.${param}`
    }

    if (buildId !== '' && viewId !== '') {
      if (chartName === 'Custom Color Checker') {
        if (param) param = `${param}&vf_color=${colorOption}`
        else param = `?vf_color=${colorOption}`
      }
      if (formatOption && formatOption !== '') {
        param = `${param}&vf_format_type=${formatOption}`
      }
      if (dataOption?.length) {
        param = `${param}&vf_data_option=${dataOption}`
      }
      src = `${proto}${apiHost}/building/${buildId}/chart/${viewId}/preview${param}`
    }

    setSelectedYearRange('Last 12 months')
    setYearOption(value)
    setImgsource(src)
    props.handleUpdateTemplateState(body)
  }

  const handleClickColorOption = event => {
    const { index, layoutIndex } = props
    let body = JSON.parse(JSON.stringify(props.body))
    let widget = body[index]
    let option = (widget && widget.options && widget.options[layoutIndex]) || {}

    const value = event.target.value
    if (!option.metaData) option.metaData = {}
    option.metaData.colorOption = value
    let src = ''
    let viewId = ''
    let url = ''
    let param = ''
    const paramIndex = option.fields.findIndex(x => x.split('.')[1] === 'param')
    const urlIndex = option.fields.findIndex(x => x.split('.')[1] === 'Url')
    let arr = reportList.find(item => item.reportName === chartName)
    let buildId = arr.previewBuildingId || ''
    let urlsArr = arr.urls
    let urlObj = urlsArr.filter(item => item.key === selectedYearRange)
    if (urlObj && urlObj[0] && urlObj[0].value) {
      viewId = urlObj[0].viewId
      url = urlObj[0].value
      param = url.split('?')[1] || ''
      if (urlIndex === -1) {
        option.fields.push(`charts.Url.${url}`)
      } else {
        option.fields[urlIndex] = `charts.Url.${url}`
      }
    }
    if (param !== '') {
      if (paramIndex === -1) option.fields.push(`chart.param.${param}`)
      else option.fields[paramIndex] = `chart.param.${param}`
      param = `?${param}`
    } else {
      if (paramIndex === -1) option.fields.push(`chart.param.${param}`)
      else option.fields[paramIndex] = `chart.param.${param}`
    }
    if (buildId !== '' && viewId !== '') {
      if (chartName === 'Custom Color Checker') {
        if (param) param = `${param}&vf_color=${value}`
        else param = `?vf_color=${value}`
      }
      src = `${proto}${apiHost}/building/${buildId}/chart/${viewId}/preview${param}`
    }

    setColorOption(value)
    setImgsource(src)
    props.handleUpdateTemplateState(body)
  }

  const isTwoColumnsOption = props.layoutOption === 'Two Columns'
  const startMonth = customStartMonth || monthList[0].value
  let endMonth = customEndMonth || monthList[monthList.length - 1].value
  const startYear = customStartYear || yearList[2].value
  const endYear = customEndYear || yearList[0].value
  endMonth =
    new Date('1' + endMonth + endYear) > new Date()
      ? monthList[new Date().getMonth()].value
      : endMonth
  const startDate = new Date(
    '1 ' + startMonth + (startYear && startYear !== undefined ? startYear : '1')
  )
  const endDate = new Date(
    '1 ' + endMonth + (endYear && endYear !== undefined ? endYear : '1')
  )
  const chartGroupBy = groupByChart(reportList, isTwoColumnsOption)
  return (
    <div className={chartStyles.reportList}>
      {reportList !== undefined && reportList !== '' && (
        <div>
          <div
            className={classNames(
              styles.tabContent,
              styles.tabArrow,
              isTwoColumnsOption ? styles.tabTwoColumnArrow : ''
            )}
          >
            <label value='Source'>Chart:</label>
            <select
              className={
                isTwoColumnsOption
                  ? styles.twoColumnDropdownContainer
                  : styles.dropdownContainer
              }
              name={'Source'}
              onChange={handleChange}
              value={chartName}
            >
              <option defaultValue value=''>
                Select a Chart
              </option>
              {Object.keys(chartGroupBy).map(key => (
                <optgroup label={key} key={key}>
                  {chartGroupBy[key] &&
                    chartGroupBy[key].map((item, index) => (
                      <option
                        value={item.reportName}
                        key={`options-${item.reportName}-${index}`}
                        name={item.reportName}
                        id={item.reportName}
                      >
                        {formatChartReportName(item.reportName)}
                      </option>
                    ))}
                </optgroup>
              ))}
            </select>
          </div>
          {chartName !== 'New Report' &&
            formatOption !== '' &&
            reportObj?.formatOptions && (
              <div className={targetStyles.yearOption}>
                <p>Format:</p>
                {renderFormatOption()}
              </div>
            )}
          {isTwoColumnsOption &&
            reportObj?.formatOptions &&
            reportObj.formatOptions.includes('pieEstPer') &&
            reportObj.formatOptions.includes('donutEstPer') && (
              <div className={targetStyles.yearOption}>
                <p>Data:</p>
                {renderDataOption()}
              </div>
            )}
          {chartName !== 'New Report' && chartName !== '' && (
            <div className={targetStyles.yearOption}>
              <p>Year options:</p>
              {renderYearOption()}
            </div>
          )}
          {chartName !== 'New Report' && chartName !== '' && (
            <div>
              {yearOption === 'SetYearRange' && (
                <div>
                  <div className={chartStyles.yearRange}>
                    <label htmlFor='Range'>Year Range</label>
                    <div className={chartStyles.tabArrow}>
                      <select
                        className={chartStyles.dropdownContainer}
                        onChange={e => {
                          handleYearRange(e)
                        }}
                        value={selectedYearRange}
                        name='Range'
                        id='Range'
                      >
                        {selectedYearList.map((each, i) => {
                          return (
                            <option value={each.key} key={each.key}>
                              {each.key}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  {selectedYearRange === 'Custom' && (
                    <div>
                      <CustomRange
                        handleChange={handleYearRange}
                        startMonth={startMonth}
                        startYear={startYear}
                        endMonth={endMonth}
                        endYear={endYear}
                        startDate={startDate}
                        endDate={endDate}
                        monthList={monthList}
                        yearList={yearList}
                        page={'Chart'}
                        showDirectionRow={isTwoColumnsOption}
                      />
                    </div>
                  )}
                </div>
              )}
              {chartName === 'Custom Color Checker' && (
                <div className={chartStyles.yearRange}>
                  <label htmlFor='color'>Color Options:</label>
                  <div className={chartStyles.tabArrow}>
                    <select
                      className={chartStyles.dropdownContainer}
                      onChange={e => {
                        handleClickColorOption(e)
                      }}
                      value={colorOption}
                    >
                      {colorList.map((each, i) => {
                        return (
                          <option value={each.value} key={each.value}>
                            {each.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              )}
              <p>Preview:</p>
              <small>
                *This is a chart generated from a particular building, the chart
                generated after exporting this template will differ in values,
                but will be similar in presentation.
              </small>
              {!isTwoColumnsOption ? (
                <img height='500' width='784' src={imgsource} />
              ) : (
                <img height='220' width='376' src={imgsource} />
              )}
            </div>
          )}
        </div>
      )}
      {(reportList === undefined || reportList === []) && (
        <div>Couldn't Load Reports!!</div>
      )}
      {chartName === 'New Report' && (
        <div className={chartStyles.overlay} id='overlay'>
          <a
          // onClick={() => {
          //   this.off()
          // }}
          >
            <i className='material-icons'>close</i>
          </a>
          <iframe
            height='90%'
            width='100%'
            id='iframe-holder'
            // src='https://tableau.buildee.com/t/buildee/authoringNewWorkbook/1dvqgqgoi$8jas-fw-7p-td-91er85/buildingsutilities#1'
          />
        </div>
      )}
    </div>
  )
}

Chart.propTypes = {
  index: PropTypes.number.isRequired,
  body: PropTypes.array.isRequired,
  handleUpdateTemplateState: PropTypes.func.isRequired,
  getChartReports: PropTypes.func,
  views: PropTypes.array,
  layoutOption: PropTypes.string,
  layoutIndex: PropTypes.number
}

export default Chart
