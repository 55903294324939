import React from 'react'
import PropTypes from 'prop-types'
import styles from './Select.scss'
import classNames from 'classnames'

function Select({
  id,
  className,
  label,
  name,
  value,
  onChange,
  options,
  disabled
}) {
  const onMouseDown = event => {
    event.stopPropagation()
  }
  const onMouseUp = event => {
    event.stopPropagation()
  }
  return (
    <div className={className}>
      {label && id ? (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      ) : null}
      <div className={classNames(styles.selectContainer)}>
        <select
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          className={classNames(
            !value && styles.selectPlaceholder,
            disabled && styles.selectDisabled
          )}
          disabled={disabled}
        >
          {options.map((option, index) => {
            return (
              <option
                key={index}
                value={option.value}
                selected={option.selected}
                disabled={option.disabled}
                hidden={option.hidden}
              >
                {option.label}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

Select.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool,
      disabled: PropTypes.bool,
      hidden: PropTypes.bool
    })
  ).isRequired
}

export default Select
