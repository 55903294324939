import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import projectStyles from '../components/Project/ProjectList.scss'
import chartStyles from '../components/Template/TemplateComponent/BodyComponents/Chart.scss'
import excelStyles from '../components/Template/TemplateComponent/ExcelEditor.scss'
import classNames from 'classnames'

export class CustomRange extends Component {
  static propTypes = {
    monthList: PropTypes.array.isRequired,
    yearList: PropTypes.array.isRequired,
    startMonth: PropTypes.string.isRequired,
    startYear: PropTypes.string.isRequired,
    endMonth: PropTypes.string.isRequired,
    endYear: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    page: PropTypes.string.isRequired
  }
  constructor(props) {
    super(props)
  }
  render() {
    let {
      monthList,
      yearList,
      startMonth,
      startYear,
      endMonth,
      endYear,
      startDate,
      endDate,
      page
    } = this.props
    let momentStartDate = moment(startDate)
      .utc()
      .startOf('month')
    let momentEndDate = moment(endDate)
      .utc()
      .endOf('month')
    let dateIsValid =
      page === 'Excel'
        ? true
        : moment(momentEndDate).diff(moment(momentStartDate), 'months') <= 36

    return (
      <div>
        <div
          className={classNames(
            page === 'Chart'
              ? chartStyles.customYear
              : page === 'Excel' ||
                page === 'Table' ||
                page === 'Data' ||
                page === 'DataList'
              ? excelStyles.customYear
              : '',
            !!this.props.showDirectionRow ? chartStyles.showDirectionRow : ''
          )}
        >
          <div>Starting : </div>
          <label>Month</label>
          <div className={projectStyles.selectContainer}>
            <select
              name={'selectedStartMonth'}
              type={'selectedStartMonth'}
              id='selectedStartMonth'
              value={startMonth || ''}
              onChange={e => {
                this.props.handleChange(e)
              }}
            >
              <option defaultValue value=''>
                Select Month
              </option>
              {monthList.map(({ label, value }) => {
                let date = moment(`${startYear} ${label}`, 'YYYY MMMM')
                  .utc()
                  .startOf('month')
                let valid = moment(momentEndDate).diff(date, 'month') > 0
                if (valid)
                  return (
                    <option value={value} key={`options-${label}-${value}`}>
                      {label}
                    </option>
                  )
                return ''
              })}
            </select>
          </div>
          <label>Year</label>
          <div className={projectStyles.selectContainer}>
            <select
              name={'selectedStartYear'}
              type={'selectedStartYear'}
              id='selectedStartYear'
              value={startYear || ''}
              onChange={e => {
                this.props.handleChange(e)
              }}
            >
              <option defaultValue value=''>
                Select Year
              </option>
              {yearList.map(({ label, value }) => {
                let date = moment(`${label} ${startMonth}`, 'YYYY MMMM')
                  .utc()
                  .startOf('month')
                let valid = moment(momentEndDate).diff(date, 'month') > 0
                if (valid)
                  return (
                    <option value={value} key={`options-${label}-${value}`}>
                      {label}
                    </option>
                  )
                return ''
              })}
            </select>
          </div>
        </div>
        <div
          className={classNames(
            page === 'Chart' ? chartStyles.customYear : excelStyles.customYear,
            !!this.props.showDirectionRow ? chartStyles.showDirectionRow : ''
          )}
        >
          <div>Ending : </div>
          <label style={{ marginLeft: 10 + 'px' }}>Month</label>
          <div className={projectStyles.selectContainer}>
            <select
              name={'selectedEndMonth'}
              type={'selectedEndMonth'}
              id='selectedEndMonth'
              value={endMonth || ''}
              onChange={e => {
                this.props.handleChange(e)
              }}
            >
              <option defaultValue value=''>
                Select Month
              </option>
              {monthList.map(({ label, value }) => {
                let date = moment(`${endYear} ${label}`, 'YYYY MMMM')
                  .utc()
                  .startOf('month')
                let now = moment()
                  .utc()
                  .startOf('month')
                let valid =
                  date.diff(momentStartDate, 'month') > 0 &&
                  now.diff(date, 'month') >= 0
                if (valid)
                  return (
                    <option value={value} key={`options-${label}-${value}`}>
                      {label}
                    </option>
                  )
                return ''
              })}
            </select>
          </div>
          <label>Year</label>
          <div className={projectStyles.selectContainer}>
            <select
              name={'selectedEndYear'}
              type={'selectedEndYear'}
              id='selectedEndYear'
              value={endYear || ''}
              onChange={e => {
                this.props.handleChange(e)
              }}
            >
              <option defaultValue value=''>
                Select Year
              </option>
              {yearList.map(({ label, value }) => {
                let date = moment(`${label} ${endMonth}`, 'YYYY MMMM')
                  .utc()
                  .startOf('month')
                let valid = date.diff(momentStartDate, 'month') > 0
                if (valid)
                  return (
                    <option value={value} key={`options-${label}-${value}`}>
                      {label}
                    </option>
                  )
                return ''
              })}
            </select>
          </div>
        </div>
        {!dateIsValid && (
          <div className={chartStyles.error}>
            <span>Limited to 3 years</span>
          </div>
        )}
      </div>
    )
  }
}
export default CustomRange
