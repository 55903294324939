const features = {
  assets: true,
  buildingAssets: true,
  buildingActions: true,
  buildingOperationsDates: false,
  equipmentProject: false,
  library: true,
  systemEquipment: false,
  espmEpicFeature: true,
  dashboardFilters: false,
  dashboardBaseline: false
}

export default features
