export const DAY_SCHEDULE = {
  UNOCCUPIED: 'unoccupied',
  WARM_UP: 'warmup',
  OCCUPIED: 'occupied',
  COOLDOWN: 'cooldown'
}

export const SCHEDULE_TYPES = {
  SETPOINT: 'setpoint',
  OPERATIONAL: 'operational',
  OCCUPANCY: 'occupancy'
}
