import {
  LAYOUTS,
  ACTIVE_COLUMN,
  CHART_TYPES,
  Y_AXIS_PRIMARY_FIRST_LEVEL,
  Y_AXIS_PRIMARY_SECOND_LEVEL,
  Y_AXIS_PRIMARY_THIRD_LEVEL,
  TARGET,
  METRIC_SECOND_LEVEL,
  CHART_FORMAT,
  BASELINE,
  Y_AXIS_SECONDARY,
  WEDGE_VALUE,
  WEDGES_FIRST_LEVEL,
  WEDGES_SECOND_LEVEL,
  WEDGES_THIRD_LEVEL,
  SAVINGS_FROM,
  METRIC_FIRST_LEVEL,
  TIME,
  CHART_SETTINGS,
  LEGEND_POSITION,
  WEDGE_DATA
} from 'static/charts'
import { COLOR_SECTIONS } from 'static/default-chart-colors'
import { SECONDARY_DATA } from 'static/secondary-data'
import { UNIT_DETAILS, UTILITY_TYPES } from 'static/utility-units'
import { defaultChartColorOptions } from './ChartColors/options'

export const layoutOptions = [
  { value: '', label: 'Select', default: true },
  {
    value: LAYOUTS.OneColumn,
    label: 'One Column'
  },
  {
    value: LAYOUTS.TwoColumns,
    label: 'Two Columns'
  }
]

export const activeColumnOptions = [
  {
    value: ACTIVE_COLUMN.Column1,
    label: 'Column 1',
    name: 'Column 1'
  },
  {
    value: ACTIVE_COLUMN.Column2,
    label: 'Column 2',
    name: 'Column 2'
  }
]

export const chartTypeOptions = [
  { value: '', label: 'Select', default: true },
  { value: CHART_TYPES.Breakdown, label: 'Breakdown' },
  { value: CHART_TYPES.Trend, label: 'Trend' },
  { value: CHART_TYPES.Savings, label: 'Savings' }
]

export const yAxisPrimaryOptions = [
  {
    value: Y_AXIS_PRIMARY_FIRST_LEVEL.UsageAndCost,
    label: 'Usage & Cost',
    options: [
      {
        value: Y_AXIS_PRIMARY_SECOND_LEVEL.All,
        label: 'All',
        options: [
          { value: Y_AXIS_PRIMARY_THIRD_LEVEL.EnergyUse, label: 'Energy Use' },
          { value: Y_AXIS_PRIMARY_THIRD_LEVEL.EnergyCost, label: 'Energy Cost' }
        ]
      },
      ...Object.entries(UNIT_DETAILS).map(([key, value]) => {
        const options = [
          { value: Y_AXIS_PRIMARY_THIRD_LEVEL.Use, label: 'Use' },
          { value: Y_AXIS_PRIMARY_THIRD_LEVEL.Cost, label: 'Cost' }
        ]
        if (key === 'electric') {
          options.push({
            value: Y_AXIS_PRIMARY_THIRD_LEVEL.Demand,
            label: 'Demand'
          })
          options.push({
            value: Y_AXIS_PRIMARY_THIRD_LEVEL.DemandCost,
            label: 'Demand Cost'
          })
        }
        return { value: key, label: value.title, options }
      })
    ]
  },
  {
    value: Y_AXIS_PRIMARY_FIRST_LEVEL.GHGEmissions,
    label: 'GHG Emissions',
    options: [
      {
        value: Y_AXIS_PRIMARY_SECOND_LEVEL.GHGEmissions,
        label: 'GHG Emissions'
      },
      {
        value: Y_AXIS_PRIMARY_SECOND_LEVEL.GHGEmissionsIntensity,
        label: 'GHG Emissions Intensity'
      }
    ]
  },
  {
    value: Y_AXIS_PRIMARY_FIRST_LEVEL.UsageAndCostIntensity,
    label: 'Usage & Cost Intensity',
    options: [
      {
        value: Y_AXIS_PRIMARY_SECOND_LEVEL.EnergyUseIntensityEUI,
        label: 'Energy Use Intensity (EUI)'
      },
      {
        value: Y_AXIS_PRIMARY_SECOND_LEVEL.EnergyCostIntensityECI,
        label: 'Energy Cost Intensity (ECI)'
      },
      {
        value: Y_AXIS_PRIMARY_SECOND_LEVEL.WaterUseIntensityWUI,
        label: 'Water Use Intensity (WUI)'
      },
      {
        value: Y_AXIS_PRIMARY_SECOND_LEVEL.WaterCostIntensityWCI,
        label: 'Water Cost Intensity (WCI)'
      }
    ]
  }
]

export const savingsBaselineOptions = () => {
  return [
    { value: BASELINE.Hide, label: 'Hide', default: true },
    { value: BASELINE.Bar, label: 'Bar' }
  ]
}

export const trendBaselineOptions = format => {
  if (format === CHART_FORMAT.Line) {
    return [
      { value: BASELINE.Hide, label: 'Hide', default: true },
      { value: BASELINE.Line, label: 'Line' }
    ]
  } else {
    return [
      { value: BASELINE.Hide, label: 'Hide', default: true },
      { value: BASELINE.Bar, label: 'Bar' }
    ]
  }
}

export const portfolioDashboardBaselineOptions = format => {
  if (format === CHART_FORMAT.Line) {
    return [
      { value: BASELINE.Hide, label: 'Hide Baseline', default: true },
      { value: BASELINE.Line, label: 'Show Baseline' }
    ]
  } else {
    return [
      { value: BASELINE.Hide, label: 'Hide Baseline', default: true },
      { value: BASELINE.Bar, label: 'Show Baseline' }
    ]
  }
}

export const yAxisSecondaryOptions = yPrimaryAxis => {
  const options = [
    { value: Y_AXIS_SECONDARY.Hide, label: 'Hide', default: true }
  ]
  const yPrimaryAxisSecondLevel = yPrimaryAxis?.options?.value
  if (yPrimaryAxisSecondLevel === UTILITY_TYPES.ELECTRICITY) {
    options.push({ value: Y_AXIS_SECONDARY.Demand, label: 'Demand' })
  }
  if (
    yPrimaryAxisSecondLevel === UTILITY_TYPES.ELECTRICITY ||
    yPrimaryAxisSecondLevel === UTILITY_TYPES.SOLAR ||
    yPrimaryAxisSecondLevel === UTILITY_TYPES.WIND
  ) {
    options.push({
      value: Y_AXIS_SECONDARY.CoolingDegreeDays,
      label: 'Cooling Degree Days'
    })
  }
  if (
    yPrimaryAxisSecondLevel === Y_AXIS_PRIMARY_SECOND_LEVEL.All ||
    (Object.values(UTILITY_TYPES).some(
      value => value === yPrimaryAxisSecondLevel
    ) &&
      yPrimaryAxisSecondLevel !== UTILITY_TYPES.WATER)
  ) {
    options.push(
      {
        value: Y_AXIS_SECONDARY.AverageTemperature,
        label: 'Average Temperature'
      },
      {
        value: Y_AXIS_SECONDARY.CoolingAndHeatingDegreeDays,
        label: 'Cooling And Heating Degree Days'
      },
      {
        value: Y_AXIS_SECONDARY.HeatingDegreeDays,
        label: 'Heating Degree Days'
      }
    )
  }
  if (options.length === 1) {
    return undefined
  }
  return options
}
export const trendTargetOptions = (yPrimaryAxis, time) => {
  const yPrimaryAxisSecondLevel = yPrimaryAxis?.options?.value
  if (
    yPrimaryAxisSecondLevel ===
      Y_AXIS_PRIMARY_SECOND_LEVEL.WaterCostIntensityWCI ||
    yPrimaryAxisSecondLevel ===
      Y_AXIS_PRIMARY_SECOND_LEVEL.WaterUseIntensityWUI ||
    time === TIME.Monthly
  ) {
    return undefined
  }
  return targetOptions
}

export const formatOptions = {
  [CHART_TYPES.Trend]: [
    { value: CHART_FORMAT.Bar, label: 'Bar', default: true },
    { value: CHART_FORMAT.StackedBar, label: 'Stacked Bar' },
    { value: CHART_FORMAT.Line, label: 'Line' }
  ],
  [CHART_TYPES.Breakdown]: [
    { value: CHART_FORMAT.Donut, label: 'Donut', default: true },
    { value: CHART_FORMAT.Pie, label: 'Pie' }
  ],
  [CHART_TYPES.Savings]: [
    { value: CHART_FORMAT.Bar, label: 'Bar', default: true }
  ]
}

export const portfolioDashboardFormatOptions = yAxisPrimary => {
  let portfolioDashboardFormatOptions = [...formatOptions[CHART_TYPES.Trend]]
  const primaryValue = yAxisPrimary.value
  const secondaryValue = yAxisPrimary.options?.value

  if (
    primaryValue === Y_AXIS_PRIMARY_FIRST_LEVEL.UsageAndCost &&
    Object.keys(UNIT_DETAILS).includes(secondaryValue)
  ) {
    portfolioDashboardFormatOptions = [
      { value: CHART_FORMAT.Bar, label: 'Bar', default: true },
      { value: CHART_FORMAT.Line, label: 'Line' }
    ]
  } else if (
    primaryValue === Y_AXIS_PRIMARY_FIRST_LEVEL.UsageAndCostIntensity
  ) {
    portfolioDashboardFormatOptions = [
      { value: CHART_FORMAT.Bar, label: 'Bar', default: true },
      { value: CHART_FORMAT.Line, label: 'Line' },
      {
        value: CHART_FORMAT.TreeMap,
        label: 'Tree Map'
      }
    ]
  } else if (
    secondaryValue === Y_AXIS_PRIMARY_SECOND_LEVEL.GHGEmissionsIntensity
  ) {
    portfolioDashboardFormatOptions = [
      ...portfolioDashboardFormatOptions,
      {
        value: CHART_FORMAT.TreeMap,
        label: 'Tree Map'
      }
    ]
  }
  return portfolioDashboardFormatOptions
}

export const wedgeValueOptions = [
  { value: WEDGE_VALUE.Value, label: 'Value', default: true },
  { value: WEDGE_VALUE.Percentage, label: 'Percentage' }
]

export const wedgeDataOptions = [
  {
    value: WEDGE_DATA.UsagePercentage,
    label: 'Don’t Estimate Use Without Billing Data',
    default: true
  },
  {
    value: WEDGE_DATA.EstimatedPercentage,
    label: 'Estimate Use Without Billing Data'
  }
]

export const wedgesOptions = [
  {
    value: WEDGES_FIRST_LEVEL.UsageAndCosts,
    label: 'Usage & Costs',
    options: [
      { value: WEDGES_SECOND_LEVEL.UtilityCosts, label: 'Utility Costs' },
      { value: WEDGES_SECOND_LEVEL.EnergyUses, label: 'Energy Uses' },
      { value: WEDGES_SECOND_LEVEL.EnergyCosts, label: 'Energy Costs' }
    ]
  },
  {
    value: WEDGES_FIRST_LEVEL.GHGEmissions,
    label: 'GHG Emissions',
    options: [
      { value: WEDGES_SECOND_LEVEL.GHGEmissions, label: 'GHG Emissions' }
    ]
  },
  {
    value: WEDGES_FIRST_LEVEL.EstimatedEndUsesAndCosts,
    label: 'Estimated End Uses & Costs',
    options: [
      {
        value: WEDGES_SECOND_LEVEL.Energy,
        label: 'Energy (CBECS/RECS)',
        options: [
          { value: WEDGES_THIRD_LEVEL.Use, label: 'Use' },
          { value: WEDGES_THIRD_LEVEL.Cost, label: 'Cost' }
        ]
      },
      {
        value: WEDGES_SECOND_LEVEL.Electricity,
        label: 'Electricity (CBECS/RECS)',
        options: [
          { value: WEDGES_THIRD_LEVEL.Use, label: 'Use' },
          { value: WEDGES_THIRD_LEVEL.Cost, label: 'Cost' }
        ]
      },
      {
        value: WEDGES_SECOND_LEVEL.NaturalGas,
        label: 'Natural Gas (CBECS/RECS)',
        options: [
          { value: WEDGES_THIRD_LEVEL.Use, label: 'Use' },
          { value: WEDGES_THIRD_LEVEL.Cost, label: 'Cost' }
        ]
      },
      {
        value: WEDGES_SECOND_LEVEL.Water,
        label: 'Water (WaterSense/WRF)',
        options: [
          { value: WEDGES_THIRD_LEVEL.Use, label: 'Use' },
          { value: WEDGES_THIRD_LEVEL.Cost, label: 'Cost' }
        ]
      }
    ]
  },
  {
    value: WEDGES_FIRST_LEVEL.ASHRAELevelIIAnalysis,
    label: 'ASHRAE Level II Analysis',
    options: [
      {
        value: WEDGES_SECOND_LEVEL.EnergyEndUses,
        label: 'Energy End Uses'
      },
      {
        value: WEDGES_SECOND_LEVEL.ElectricityEndUses,
        label: 'Electricity End Uses'
      },
      {
        value: WEDGES_SECOND_LEVEL.NaturalGasEndUses,
        label: 'Natural Gas End Uses'
      }
    ]
  }
]

export const savingsFromOptions = [
  { value: SAVINGS_FROM.Measures, label: 'Measures', default: true }
]

export const metricOptions = [
  {
    value: METRIC_FIRST_LEVEL.ASHRAELevelIIAnalysis,
    label: 'ASHRAE Level II Analysis',
    options: [
      {
        value: METRIC_SECOND_LEVEL.EnergyUseIntensityEUI,
        label: 'Energy Use Intensity (EUI)'
      },
      {
        value: METRIC_SECOND_LEVEL.EnergyCostIntensityECI,
        label: 'Energy Cost Intensity (ECI)'
      },
      { value: METRIC_SECOND_LEVEL.EnergyEndUses, label: 'Energy End Uses' },
      {
        value: METRIC_SECOND_LEVEL.ElectricityEndUses,
        label: 'Electricity End Uses'
      },
      {
        value: METRIC_SECOND_LEVEL.NaturalGasEndUses,
        label: 'Natural Gas End Uses'
      },
      {
        value: METRIC_SECOND_LEVEL.GHGEmissions,
        label: 'GHG Emissions'
      },
      {
        value: METRIC_SECOND_LEVEL.GHGEmissionsIntensity,
        label: 'GHG Emissions Intensity'
      }
    ]
  }
]

export const baselineOptions = [
  { value: BASELINE.Hide, label: 'Hide', default: true },
  { value: BASELINE.Bar, label: 'Bar' },
  { value: BASELINE.Line, label: 'Line' }
]

export const targetOptions = [
  { value: TARGET.Hide, label: 'Hide', default: true },
  { value: TARGET.Line, label: 'Line' }
]

export const timeOptions = [
  { value: TIME.Monthly, label: 'Monthly', default: true },
  { value: TIME.Annual, label: 'Annual' }
]

const getWedgesColorOptions = wedges => {
  switch (wedges?.value) {
    case WEDGES_FIRST_LEVEL.UsageAndCosts:
    case WEDGES_FIRST_LEVEL.GHGEmissions:
      return [
        {
          colorSection: COLOR_SECTIONS.UTILITIES,
          colors: defaultChartColorOptions[COLOR_SECTIONS.UTILITIES]
        }
      ]
    case WEDGES_FIRST_LEVEL.EstimatedEndUsesAndCosts:
      if (wedges.options.value === WEDGES_SECOND_LEVEL.Water) {
        return [
          {
            colorSection: COLOR_SECTIONS.WATER_END_USES,
            colors: defaultChartColorOptions[COLOR_SECTIONS.WATER_END_USES]
          }
        ]
      } else {
        return [
          {
            colorSection: COLOR_SECTIONS.CBECS_RECS_END_USES,
            colors: defaultChartColorOptions[COLOR_SECTIONS.CBECS_RECS_END_USES]
          }
        ]
      }
    case WEDGES_FIRST_LEVEL.ASHRAELevelIIAnalysis:
      return [
        {
          colorSection: COLOR_SECTIONS.ASHRAE_END_USES,
          colors: defaultChartColorOptions[COLOR_SECTIONS.ASHRAE_END_USES]
        }
      ]
    default:
      return []
  }
}

const getTrendBaselineColorOptions = (baseline, yAxisPrimary) => {
  switch (baseline) {
    case BASELINE.Bar:
    case BASELINE.Line: {
      switch (yAxisPrimary?.value) {
        case Y_AXIS_PRIMARY_FIRST_LEVEL.UsageAndCost: {
          if (
            yAxisPrimary?.options?.value === Y_AXIS_PRIMARY_SECOND_LEVEL.All &&
            baseline === BASELINE.Bar
          ) {
            return [
              {
                colorSection: COLOR_SECTIONS.BASELINE,
                colors: defaultChartColorOptions[
                  COLOR_SECTIONS.BASELINE
                ].filter(
                  colorOption => colorOption.value !== CHART_SETTINGS.Baseline
                )
              }
            ]
          } else {
            return [
              {
                colorSection: COLOR_SECTIONS.BASELINE,
                colors: defaultChartColorOptions[
                  COLOR_SECTIONS.BASELINE
                ].filter(
                  colorOption => colorOption.value === CHART_SETTINGS.Baseline
                )
              }
            ]
          }
        }
        case Y_AXIS_PRIMARY_FIRST_LEVEL.GHGEmissions:
          if (baseline === BASELINE.Bar) {
            return [
              {
                colorSection: COLOR_SECTIONS.BASELINE,
                colors: defaultChartColorOptions[
                  COLOR_SECTIONS.BASELINE
                ].filter(
                  colorOption => colorOption.value !== CHART_SETTINGS.Baseline
                )
              }
            ]
          } else {
            return [
              {
                colorSection: COLOR_SECTIONS.BASELINE,
                colors: defaultChartColorOptions[
                  COLOR_SECTIONS.BASELINE
                ].filter(
                  colorOption => colorOption.value === CHART_SETTINGS.Baseline
                )
              }
            ]
          }
        case Y_AXIS_PRIMARY_FIRST_LEVEL.UsageAndCostIntensity:
          return [
            {
              colorSection: COLOR_SECTIONS.BASELINE,
              colors: defaultChartColorOptions[COLOR_SECTIONS.BASELINE].filter(
                colorOption => colorOption.value === CHART_SETTINGS.Baseline
              )
            }
          ]
        default:
          return []
      }
    }
    default:
      return []
  }
}

const getSavingsBaselineColorOptions = baseline => {
  switch (baseline) {
    case BASELINE.Bar:
      return [
        {
          colorSection: COLOR_SECTIONS.BASELINE,
          colors: defaultChartColorOptions[COLOR_SECTIONS.BASELINE].filter(
            colorOption => colorOption.value === CHART_SETTINGS.Baseline
          )
        }
      ]
    default:
      return []
  }
}

const getTargetColorOptions = target => {
  switch (target) {
    case TARGET.Line:
      return [
        {
          colorSection: COLOR_SECTIONS.TARGET,
          colors: defaultChartColorOptions[COLOR_SECTIONS.TARGET]
        }
      ]
    default:
      return []
  }
}

const getYAxisPrimaryColorOptions = yAxisPrimary => {
  switch (yAxisPrimary?.value) {
    case Y_AXIS_PRIMARY_FIRST_LEVEL.UsageAndCost:
      return [
        {
          colorSection: COLOR_SECTIONS.UTILITIES,
          colors: defaultChartColorOptions[COLOR_SECTIONS.UTILITIES].filter(
            utility => {
              if (
                yAxisPrimary?.options?.value === Y_AXIS_PRIMARY_SECOND_LEVEL.All
              ) {
                return true
              } else {
                return utility.value === yAxisPrimary?.options?.value
              }
            }
          )
        }
      ]
    case Y_AXIS_PRIMARY_FIRST_LEVEL.GHGEmissions:
      return [
        {
          colorSection: COLOR_SECTIONS.UTILITIES,
          colors: defaultChartColorOptions[COLOR_SECTIONS.UTILITIES]
        }
      ]
    case Y_AXIS_PRIMARY_FIRST_LEVEL.UsageAndCostIntensity:
      return [
        {
          colorSection: COLOR_SECTIONS.INTENSITY,
          colors: defaultChartColorOptions[COLOR_SECTIONS.INTENSITY].filter(
            intensity => intensity.value === yAxisPrimary?.options?.value
          )
        }
      ]
    default:
      return []
  }
}

const getYAxisSecondaryColorOptions = yAxisSecondary => {
  switch (yAxisSecondary) {
    case Y_AXIS_SECONDARY.AverageTemperature:
    case Y_AXIS_SECONDARY.CoolingAndHeatingDegreeDays:
    case Y_AXIS_SECONDARY.CoolingDegreeDays:
    case Y_AXIS_SECONDARY.Demand:
    case Y_AXIS_SECONDARY.HeatingDegreeDays:
      return [
        {
          colorSection: COLOR_SECTIONS.SECONDARY_DATA,
          colors: defaultChartColorOptions[
            COLOR_SECTIONS.SECONDARY_DATA
          ].filter(secondaryData => {
            if (yAxisSecondary === SECONDARY_DATA.CoolingAndHeatingDegreeDays) {
              return (
                secondaryData.value === Y_AXIS_SECONDARY.CoolingDegreeDays ||
                secondaryData.value === Y_AXIS_SECONDARY.HeatingDegreeDays
              )
            }
            return secondaryData.value === yAxisSecondary
          })
        }
      ]
    default:
      return []
  }
}

const getMetricColorOptions = metric => {
  switch (metric?.value) {
    case METRIC_FIRST_LEVEL.ASHRAELevelIIAnalysis:
      return [
        {
          colorSection: COLOR_SECTIONS.PROPOSED,
          colors: defaultChartColorOptions[COLOR_SECTIONS.PROPOSED]
        }
      ]
    default:
      return []
  }
}

export const legendPositionOptions = layout => {
  if (layout === LAYOUTS.OneColumn) {
    return [
      { value: LEGEND_POSITION.Bottom, label: 'Bottom', default: true },
      { value: LEGEND_POSITION.Right, label: 'Right' },
      { value: LEGEND_POSITION.Top, label: 'Top' },
      { value: LEGEND_POSITION.Left, label: 'Left' }
    ]
  } else {
    return [
      { value: LEGEND_POSITION.Bottom, label: 'Bottom', default: true },
      { value: LEGEND_POSITION.Top, label: 'Top' }
    ]
  }
}

export const breakdownColorOptions = wedges => {
  const colorOptions = [...getWedgesColorOptions(wedges)]
  return colorOptions
}

export const trendColorOptions = (
  baseline,
  target,
  yAxisPrimary,
  yAxisSecondary
) => {
  const colorOptions = [
    ...getYAxisPrimaryColorOptions(yAxisPrimary),
    ...getYAxisSecondaryColorOptions(yAxisSecondary),
    ...getTrendBaselineColorOptions(baseline, yAxisPrimary),
    ...getTargetColorOptions(target)
  ]
  return colorOptions
}

export const savingsColorOptions = (baseline, metric) => {
  const colorOptions = [
    ...getMetricColorOptions(metric),
    ...getSavingsBaselineColorOptions(baseline)
  ]
  return colorOptions
}

export const getChartSettingOptions = (value, layout) => ({
  [CHART_TYPES.Trend]: [
    {
      label: 'Format',
      value: CHART_SETTINGS.Format,
      options: formatOptions[CHART_TYPES.Trend]
    },
    {
      label: 'Time',
      value: CHART_SETTINGS.Time,
      options: timeOptions
    },
    {
      label: 'Baseline',
      value: CHART_SETTINGS.Baseline,
      options: trendBaselineOptions(value[CHART_SETTINGS.Format])
    },
    {
      label: 'Target',
      value: CHART_SETTINGS.Target,
      options: trendTargetOptions(
        value[CHART_SETTINGS.YAxisPrimary],
        value[CHART_SETTINGS.Time]
      )
    },
    {
      label: 'Y-Axis (Primary)',
      value: CHART_SETTINGS.YAxisPrimary,
      options: yAxisPrimaryOptions
    },
    {
      label: 'Y-Axis (Secondary)',
      value: CHART_SETTINGS.YAxisSecondary,
      options: yAxisSecondaryOptions(value[CHART_SETTINGS.YAxisPrimary])
    },
    {
      label: 'Colors',
      value: CHART_SETTINGS.Color,
      options: trendColorOptions(
        value[CHART_SETTINGS.Baseline],
        value[CHART_SETTINGS.Target],
        value[CHART_SETTINGS.YAxisPrimary],
        value[CHART_SETTINGS.YAxisSecondary]
      )
    }
  ],
  [CHART_TYPES.Breakdown]: [
    {
      label: 'Format',
      value: CHART_SETTINGS.Format,
      options: formatOptions[CHART_TYPES.Breakdown]
    },
    { label: 'Wedges', value: CHART_SETTINGS.Wedges, options: wedgesOptions },
    ...(value[CHART_SETTINGS.Wedges]?.value ===
      WEDGES_FIRST_LEVEL.EstimatedEndUsesAndCosts &&
    value[CHART_SETTINGS.Wedges]?.options?.value !== WEDGES_SECOND_LEVEL.Water
      ? [
          {
            label: 'Data',
            value: CHART_SETTINGS.WedgeData,
            options: wedgeDataOptions
          }
        ]
      : []),
    {
      label: 'Wedge Value',
      value: CHART_SETTINGS.WedgeValue,
      options: wedgeValueOptions
    },
    {
      label: 'Legend Position',
      value: CHART_SETTINGS.LegendPosition,
      options: legendPositionOptions(layout)
    },
    {
      label: 'Colors',
      value: CHART_SETTINGS.Color,
      options: breakdownColorOptions(value[CHART_SETTINGS.Wedges])
    }
  ],
  [CHART_TYPES.Savings]: [
    {
      label: 'Format',
      value: CHART_SETTINGS.Format,
      options: formatOptions[CHART_TYPES.Savings]
    },
    {
      label: 'Savings From',
      value: CHART_SETTINGS.SavingsFrom,
      options: savingsFromOptions
    },
    {
      label: 'Baseline',
      value: CHART_SETTINGS.Baseline,
      options: savingsBaselineOptions()
    },
    { label: 'Metric', value: CHART_SETTINGS.Metric, options: metricOptions },
    {
      label: 'Colors',
      value: CHART_SETTINGS.Color,
      options: savingsColorOptions(
        value[CHART_SETTINGS.Baseline],
        value[CHART_SETTINGS.Metric]
      )
    }
  ]
})
