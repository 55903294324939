import React from 'react'
import PropTypes from 'prop-types'
import styles from './Checkbox.scss'
import classNames from 'classnames'

function Checkbox({ id, label, name, checked, onChange, disabled, className }) {
  return (
    <div className={classNames(styles.checkboxContainer, className)}>
      <label className={styles.label}>
        <input
          id={id}
          name={name}
          onChange={onChange}
          checked={checked}
          className={classNames(checked ? styles.checked : '')}
          type='checkbox'
          disabled={disabled}
        />
        <span>{label}</span>
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default Checkbox
