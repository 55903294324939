export const handleSumOfIndividualFields = (
  values,
  fieldsArr,
  fieldName,
  fieldValue
) => {
  fieldsArr.map(fieldArr => {
    if (fieldArr.fields) {
      const costKey = fieldArr.fields.find(
        field =>
          field.name === 'Unit' ||
          field.name === 'Cost' ||
          field.name === 'Rate'
      )?.label
      const quantityKey = fieldArr.fields.find(
        field => field.name === 'Quantity' || field.name === 'Hours'
      )?.label
      const totalKey = fieldArr.fields.find(field => field.name === 'Total')
        ?.label
      if (costKey && quantityKey) {
        const totalCost = getTotalCalculation(
          fieldName,
          fieldValue,
          costKey,
          quantityKey,
          values?.[costKey] || '',
          values?.[quantityKey] || ''
        )
        totalCost !== null && (values[totalKey] = totalCost)
      }
    }
  })
}

const getTotalCalculation = (
  key,
  value,
  costKey,
  quantityKey,
  initialCostValue,
  initialQuantityValue
) => {
  const isCostKey = key === costKey
  const isQuantityKey = key === quantityKey

  if (!isCostKey && !isQuantityKey) return null

  const costValue = isCostKey ? value : initialCostValue
  const quantityValue = isQuantityKey ? value : initialQuantityValue
  if (costValue === '' || quantityValue === '') return ''
  return (Number(costValue) * Number(quantityValue)).toFixed(2)
}

export const getFieldNames = fields => {
  return fields.flatMap(field => {
    if (field.fields) {
      return getFieldNames(field.fields)
    }
    return field.label
  })
}

const generateBSELabel = field => {
  const specialLabels = {
    // Hard cost
    additional_materials: 'additional_materials_rate',
    lifts_1: 'total_lift_fee_1',
    lifts_2: 'total_lift_fee_2',
    lifts_3: 'total_lift_fee_3',
    lifts_4: 'total_lift_fee_4',
    lifts_5: 'total_lift_fee_5',
    // Financial modal materials
    environment: 'total_environment_unit_cost',
    material: 'material_cost'
  }

  const totalLabels = [
    // Hard cost
    'subcontractor_labor',
    'additional_labor',
    // Soft cost
    'certified_commissioning_officer',
    'energy_manager',
    'energy_auditor',
    'junior_designer',
    'energy_modeler',
    'audit_services_senior_engineer',
    'cad_operator',
    'senior_designer',
    'associate_engineer',
    'project_engineer',
    'project_manager',
    // Financial modal materials
    'disposal',
    // Financial modal labor
    'general_labor_unit',
    'general_labor_rate',
    'general_labor_unit',
    'day_labor_unit',
    'day_labor_rate',
    'new_installation',
    'night_labor_unit',
    'overnight_labor_rate',
    'prevailing_wages_unit',
    'prevailing_wages_rate'
  ]

  if (field.label in specialLabels) {
    return specialLabels[field.label]
  }

  if (totalLabels.includes(field.label)) {
    return `${field.label}_total`
  }

  return field.label
}

export const handleSumOfFields = (values, fieldsArr) => {
  const valueEntries = Object.entries(values)
  const matchingKeys = fieldsArr.map(generateBSELabel)

  const total = valueEntries.reduce((accumulator, [key, value]) => {
    if (matchingKeys.includes(key)) {
      accumulator += +(value || 0)
    }
    return accumulator
  }, 0)

  return total.toFixed(2)
}

export const isNullOrEmpty = value => {
  return value === null || value === '' || value === undefined
}

export const isNullOrUndefined = value => {
  return value === null || value === undefined
}

export const cleanGroup = group => {
  // Remove null fields from formValues
  for (const key in group.formValues) {
    if (group.formValues[key] === null) {
      delete group.formValues[key]
    }
  }
  // Remove null properties from equipmentValues
  for (const id in group.equipmentValues) {
    let equipment = group.equipmentValues[id]
    for (const key in equipment) {
      if (equipment[key] === null) {
        delete group.equipmentValues[id][key]
      }
    }
  }
  // Remove null properties from financialValues
  for (const id in group.financialValues) {
    let financial = group.financialValues[id]
    for (const key in financial) {
      if (financial[key] === null) {
        delete group.financialValues[id][key]
      }
    }
  }

  return group
}
