import features from 'utils/Feature/config'

export const MEASURE_CONTENT_OPTIONS = {
  CATEGORY_HEADINGS: 'categoryHeadings',
  NAME: 'name',
  DESCRIPTION: 'description',
  IMAGES: 'images',
  TABLE: 'table',
  PROJECT_DESIGN_TABLE: 'projectDesignTable',
  BUSINESS_CASE_TABLE: 'businessCaseTable',
  EQUIPMENT_LIST_TABLE: 'equipmentListTable',
  COST_TABLE: 'cost_table'
}

export const MEASURE_CONTENT_TABLE_TYPE = {
  COST_TABLE: 'cost_table',
  ALL_FIELDS: 'all_fields',
  EXISTING_PROPOSED: 'existing_proposed'
}

export const DETAILS_SECTION = {
  name: 'Details',
  sectionName: 'details',
  sections: [
    { name: 'Name', value: 'name' },
    { name: 'Description', value: 'description' },
    { name: 'Budget Type', value: 'budgetType' },
    { name: 'Total Budget', value: 'totalBudget' },
    { name: 'Budget Period', value: 'budgetPeriod' },
    { name: 'Design Requirements', value: 'design_requirements' },
    { name: 'Existing Requirements', value: 'existing_requirements' },
    { name: 'Implementation Strategy', value: 'implementation_strategy' },
    { name: 'Rebate Code', value: 'rebate_code' },
    { name: 'Utility Company', value: 'utility_company' },
    { name: 'Effective Useful Life', value: 'eul' },
    { name: 'Project', value: 'project' }
  ]
}
export const DESIGN_SECTION = {
  name: 'Design',
  sectionName: 'design',
  sections: [
    { name: 'Name', value: 'project.name' },
    { name: 'Incentive', value: 'project.incentive' },
    { name: 'Utility Company', value: 'project.utility_company' },
    { name: 'Rebate Code', value: 'project.rebate_code' },
    {
      name: 'Existing Requirements',
      value: 'project.existing_requirements'
    },
    { name: 'Design Requirements', value: 'project.design_requirements' },
    { name: 'Design Fields', value: 'design_fields' },
    { name: 'Measure Cost', value: 'project.project_cost' },
    { name: 'Annual Cost Savings', value: 'project.annual_savings' },
    { name: 'Energy Savings', value: 'project.energy_savings' },
    { name: 'Electric Savings', value: 'project.electric_savings' },
    { name: 'Natural Gas Savings', value: 'project.gas_savings' },
    { name: 'Water Savings', value: 'project.water_savings' },
    { name: 'ROI', value: 'project.roi' },
    { name: 'Simple Payback', value: 'project.simple_payback' },
    { name: 'NPV', value: 'project.npv' },
    { name: 'SIR', value: 'project.sir' },
    { name: 'GHG Savings', value: 'project.ghg' },
    { name: 'GHG Cost', value: 'project.ghg-cost' },
    { name: 'Demand Savings', value: 'project.demand_savings' },
    { name: 'Effective Useful Life', value: 'project.eul' }
  ]
}
export const INCENTIVE_SECTION = {
  name: 'Incentive',
  sectionName: 'incentive',
  sections: [{ name: 'Incentive', value: 'incentive' }]
}
export const FINANCIAL_MODELING_SECTION = {
  name: 'Financial Modeling',
  sectionName: 'financial',
  sections: [
    {
      name: 'Summary',
      sectionName: 'financial.summary',
      sections: [
        { name: 'Total Cost', value: 'total_cost' },
        { name: 'Total Measure Cost Range', value: 'total_cost_range' },
        { name: 'First Year Cost', value: 'first_year_cost' },
        {
          name: 'Net Measure Cost after Incentive',
          value: 'net_project_cost'
        },
        { name: 'Total Hard Costs', value: 'total_hard_cost' },
        { name: 'Total Soft Costs', value: 'total_soft_cost' },
        {
          name: 'Total Financing/Funding',
          value: 'total_financing_funding'
        }
      ]
    },
    {
      name: 'Hard Costs',
      sectionName: 'financial.hardCost',
      sections: [
        { name: 'Total Hard Cost', value: 'hardCost_total_hard_cost' },
        { name: 'Total Material Cost', value: 'total_material_cost' },
        {
          name: 'Subcontractor Labor Description',
          value: 'subcontractor_labor_description'
        },
        {
          name: 'Subcontractor Labor Rate ($/hr)',
          value: 'subcontractor_labor_rate'
        },
        {
          name: 'Subcontractor Labor Hours',
          value: 'subcontractor_labor_hours'
        },
        {
          name: 'Subcontractor Labor Total ($)',
          value: 'subcontractor_labor_total'
        },
        {
          name: 'Additional Description',
          value: 'additional_labor_description'
        },
        { name: 'Additional Rate ($/hr)', value: 'additional_labor_rate' },
        { name: 'Additional Hours', value: 'additional_labor_hours' },
        { name: 'Additional Total ($)', value: 'additional_labor_total' },
        {
          name: 'Additional Materials Description',
          value: 'additional_materials_description'
        },
        {
          name: 'Additional Materials Rate ($/hr)',
          value: 'additional_materials_rate'
        },
        { name: 'Lift(s) 1 - Lift Type', value: 'lift_type_1' },
        { name: 'Lift(s) 1 - Lift Height', value: 'lift_height_1' },
        { name: 'Lift(s) 1 - Lift Rental Days', value: 'lift_rental_days_1' },
        { name: 'Lift(s) 1 - Pathway Width', value: 'pathway_width_1' },
        { name: 'Lift(s) 1 - Pathway Height', value: 'pathway_height_1' },
        {
          name: 'Lift(s) 1 - Pathway Description',
          value: 'pathway_description_1'
        },
        { name: 'Lift(s) 1 - Lift Equipment Fee/Week', value: 'lift_cost_1' },
        { name: 'Lift(s) 1 - Total Lift Fee', value: 'total_lift_fee_1' },
        { name: 'Lift(s) 2 - Lift Type', value: 'lift_type_2' },
        { name: 'Lift(s) 2 - Lift Height', value: 'lift_height_2' },
        { name: 'Lift(s) 2 - Lift Rental Days', value: 'lift_rental_days_2' },
        { name: 'Lift(s) 2 - Pathway Width', value: 'pathway_width_2' },
        { name: 'Lift(s) 2 - Pathway Height', value: 'pathway_height_2' },
        {
          name: 'Lift(s) 2 - Pathway Description',
          value: 'pathway_description_2'
        },
        { name: 'Lift(s) 2 - Lift Equipment Fee/Week', value: 'lift_cost_2' },
        { name: 'Lift(s) 2 - Total Lift Fee', value: 'total_lift_fee_2' },
        { name: 'Lift(s) 3 - Lift Type', value: 'lift_type_3' },
        { name: 'Lift(s) 3 - Lift Height', value: 'lift_height_3' },
        { name: 'Lift(s) 3 - Lift Rental Days', value: 'lift_rental_days_3' },
        { name: 'Lift(s) 3 - Pathway Width', value: 'pathway_width_3' },
        { name: 'Lift(s) 3 - Pathway Height', value: 'pathway_height_3' },
        {
          name: 'Lift(s) 3 - Pathway Description',
          value: 'pathway_description_3'
        },
        { name: 'Lift(s) 3 - Lift Equipment Fee/Week', value: 'lift_cost_3' },
        { name: 'Lift(s) 3 - Total Lift Fee', value: 'total_lift_fee_3' },
        { name: 'Lift(s) 4 - Lift Type', value: 'lift_type_4' },
        { name: 'Lift(s) 4 - Lift Height', value: 'lift_height_4' },
        { name: 'Lift(s) 4 - Lift Rental Days', value: 'lift_rental_days_4' },
        { name: 'Lift(s) 4 - Pathway Width', value: 'pathway_width_4' },
        { name: 'Lift(s) 4 - Pathway Height', value: 'pathway_height_4' },
        {
          name: 'Lift(s) 4 - Pathway Description',
          value: 'pathway_description_4'
        },
        { name: 'Lift(s) 4 - Lift Equipment Fee/Week', value: 'lift_cost_4' },
        { name: 'Lift(s) 4 - Total Lift Fee', value: 'total_lift_fee_4' },
        { name: 'Lift(s) 5 - Lift Type', value: 'lift_type_5' },
        { name: 'Lift(s) 5 - Lift Height', value: 'lift_height_5' },
        { name: 'Lift(s) 5 - Lift Rental Days', value: 'lift_rental_days_5' },
        { name: 'Lift(s) 5 - Pathway Width', value: 'pathway_width_5' },
        { name: 'Lift(s) 5 - Pathway Height', value: 'pathway_height_5' },
        {
          name: 'Lift(s) 5 - Pathway Description',
          value: 'pathway_description_5'
        },
        { name: 'Lift(s) 5 - Lift Equipment Fee/Week', value: 'lift_cost_5' },
        { name: 'Lift(s) 5 - Total Lift Fee', value: 'total_lift_fee_5' },
        { name: 'Total Labor Cost', value: 'total_labor_cost' },
        {
          name: 'Utility Service Upgrades',
          value: 'utility_service_upgrades'
        },
        {
          name: 'Temporary Services',
          value: 'temporary_services'
        },
        {
          name: 'Shipping Fee',
          value: 'shipping_fee'
        },
        {
          name: 'Domestic Freight Shipping Fee',
          value: 'domestic_freight_shipping_fee'
        },
        {
          name: 'Air Shipping Fee',
          value: 'air_shipping_fee'
        },
        {
          name: 'Ocean Shipping Fee',
          value: 'ocean_shipping_fee'
        },
        { name: 'Profit', value: 'profit' },
        { name: 'Taxes', value: 'taxes' },
        { name: 'Other Hard Costs', value: 'other_hard_cost' }
      ]
    },
    {
      name: 'Soft Costs',
      sectionName: 'financial.softCost',
      sections: [
        { name: 'Total Soft Costs', value: 'softCost_total_soft_cost' },
        { name: 'Pre-Design', value: 'pre_design' },
        { name: 'Design Fees', value: 'design_fees' },
        { name: 'Architect Fee', value: 'architect_fee' },
        { name: 'Engineering Fee', value: 'engineering_fee' },
        { name: 'Data Analysis Fee', value: 'data_analyst_fee' },
        { name: 'Photometric Fee', value: 'photometric_fee' },
        { name: 'Project Management', value: 'project_management' },
        { name: 'Permits & Inspections', value: 'permits' },
        {
          name: 'Construction Management',
          value: 'construction_management'
        },
        { name: 'Materials Handling', value: 'material_handling' },
        { name: 'Test and Balancing', value: 'test_and_balancing' },
        { name: 'Commissioning', value: 'commissioning' },
        { name: 'As-Built Fee', value: 'as_built_fee' },
        {
          name: 'Certified Commissioning Officer Description',
          value: 'certified_commissioning_officer_description'
        },
        {
          name: 'Certified Commissioning Officer Rate ($/hr)',
          value: 'certified_commissioning_officer_rate'
        },
        {
          name: 'Certified Commissioning Officer Hours',
          value: 'certified_commissioning_officer_hours'
        },
        {
          name: 'Certified Commissioning Officer Total ($)',
          value: 'certified_commissioning_officer_total'
        },
        {
          name: 'Certified Energy Manager Description',
          value: 'energy_manager_description'
        },
        {
          name: 'Certified Energy Manager Rate ($/hr)',
          value: 'energy_manager_rate'
        },
        {
          name: 'Certified Energy Manager Hours',
          value: 'energy_manager_hours'
        },
        {
          name: 'Certified Energy Manager Total ($)',
          value: 'energy_manager_total'
        },
        {
          name: 'Certified Energy Auditor Description',
          value: 'energy_auditor_description'
        },
        {
          name: 'Certified Energy Auditor Rate ($/hr)',
          value: 'energy_auditor_rate'
        },
        {
          name: 'Certified Energy Auditor Hours',
          value: 'energy_auditor_hours'
        },
        {
          name: 'Certified Energy Auditor Total ($)',
          value: 'energy_auditor_total'
        },
        {
          name: 'Junior Designer Description',
          value: 'junior_designer_description'
        },
        { name: 'Junior Designer Rate ($/hr)', value: 'junior_designer_rate' },
        { name: 'Junior Designer Hours', value: 'junior_designer_hours' },
        { name: 'Junior Designer Total ($)', value: 'junior_designer_total' },
        {
          name: 'Energy Modeler Description',
          value: 'energy_modeler_description'
        },
        { name: 'Energy Modeler Rate ($/hr)', value: 'energy_modeler_rate' },
        { name: 'Energy Modeler Hours', value: 'energy_modeler_hours' },
        { name: 'Energy Modeler Total ($)', value: 'energy_modeler_total' },
        {
          name: 'Senior Energy Engineer Description',
          value: 'audit_services_senior_engineer_description'
        },
        {
          name: 'Senior Energy Engineer Rate ($/hr)',
          value: 'audit_services_senior_engineer_rate'
        },
        {
          name: 'Senior Energy Engineer Hours',
          value: 'audit_services_senior_engineer_hours'
        },
        {
          name: 'Senior Energy Engineer Total ($)',
          value: 'audit_services_senior_engineer_total'
        },
        {
          name: 'Designer/CAD Operator Description',
          value: 'cad_operator_description'
        },
        {
          name: 'Designer/CAD Operator Rate ($/hr)',
          value: 'cad_operator_rate'
        },
        { name: 'Designer/CAD Operator Hours', value: 'cad_operator_hours' },
        {
          name: 'Designer/CAD Operator Total ($)',
          value: 'cad_operator_total'
        },
        {
          name: 'Senior Designer Description',
          value: 'senior_designer_description'
        },
        { name: 'Senior Designer Rate ($/hr)', value: 'senior_designer_rate' },
        { name: 'Senior Designer Hours', value: 'senior_designer_hours' },
        { name: 'Senior Designer Total ($)', value: 'senior_designer_total' },
        {
          name: 'Associate Engineer Description',
          value: 'associate_engineer_description'
        },
        {
          name: 'Associate Engineer Rate ($/hr)',
          value: 'associate_engineer_rate'
        },
        { name: 'Associate Engineer Hours', value: 'associate_engineer_hours' },
        {
          name: 'Associate Engineer Total ($)',
          value: 'associate_engineer_total'
        },
        {
          name: 'Project Engineer Description',
          value: 'project_engineer_description'
        },
        {
          name: 'Project Engineer Rate ($/hr)',
          value: 'project_engineer_rate'
        },
        { name: 'Project Engineer Hours', value: 'project_engineer_hours' },
        { name: 'Project Engineer Total ($)', value: 'project_engineer_total' },
        {
          name: 'Project Manager Description',
          value: 'project_manager_description'
        },
        { name: 'Project Manager Rate ($/hr)', value: 'project_manager_rate' },
        { name: 'Project Manager Hours', value: 'project_manager_hours' },
        { name: 'Project Manager Total ($)', value: 'project_manager_total' },
        { name: 'Program Fees', value: 'program_fees' },
        { name: 'Legal Fee', value: 'legal_fee' },
        { name: 'Overhead', value: 'overhead' },
        { name: 'Other Soft Costs', value: 'other_soft_cost' },
        { name: 'Additional Charge', value: 'additional_charge_soft_cost' },
        { name: 'Contingency', value: 'contingency' },
        { name: 'Profit', value: 'profit' },
        { name: 'Taxes', value: 'taxes' }
      ]
    },
    {
      name: 'Financing',
      sectionName: 'financial.financing',
      sections: [
        { name: 'Cost Share', value: 'finance_cost_share' },
        {
          name: 'Cost Share Rate',
          value: 'finance_cost_share_rate'
        },
        { name: 'Financing', value: 'finance_finance' },
        { name: 'Financing Rate', value: 'finance_finance_rate' }
      ]
    },
    {
      name: 'Funding',
      sectionName: 'financial.funding',
      sections: [
        { name: 'Cost Share', value: 'fund_cost_share' },
        { name: 'Cost Share Rate', value: 'fund_cost_share_rate' },
        { name: 'Funding', value: 'fund_finance' },
        { name: 'Funding Rate', value: 'fund_finance_rate' }
      ]
    }
  ]
}

export const COST_TABLE_FINANCIAL_MODELING_SECTION = {
  name: 'Financial Modeling',
  sectionName: 'financial',
  sections: [
    {
      name: 'Summary',
      sectionName: 'financial.summary',
      sections: [
        { name: 'Total Cost', value: 'total_cost' },
        { name: 'Total Measure Cost Range', value: 'total_cost_range' },
        { name: 'First Year Cost', value: 'first_year_cost' },
        {
          name: 'Net Measure Cost after Incentive',
          value: 'net_project_cost'
        },
        { name: 'Total Hard Costs', value: 'total_hard_cost' },
        { name: 'Total Soft Costs', value: 'total_soft_cost' },
        {
          name: 'Total Financing/Funding',
          value: 'total_financing_funding'
        }
      ]
    },
    {
      name: 'Hard Costs',
      sectionName: 'financial.hardCost',
      sections: [
        { name: 'Total Hard Cost', value: 'hardCost_total_hard_cost' },
        { name: 'Total Material Cost', value: 'total_material_cost' },
        {
          name: 'Subcontractor Labor',
          value: 'subcontractor_labor'
        },
        {
          name: 'Additional Labor',
          value: 'additional_labor'
        },
        {
          name: 'Additional Materials',
          value: 'additional_materials'
        },
        { name: 'Lift(s) 1', value: 'lift_1' },
        { name: 'Lift(s) 2', value: 'lift_2' },
        { name: 'Lift(s) 3', value: 'lift_3' },
        { name: 'Lift(s) 4', value: 'lift_4' },
        { name: 'Lift(s) 5', value: 'lift_5' },
        {
          name: 'Utility Service Upgrades',
          value: 'utility_service_upgrades'
        },
        {
          name: 'Temporary Services',
          value: 'temporary_services'
        },
        {
          name: 'Shipping Fee',
          value: 'shipping_fee'
        },
        {
          name: 'Domestic Freight Shipping Fee',
          value: 'domestic_freight_shipping_fee'
        },
        {
          name: 'Air Shipping Fee',
          value: 'air_shipping_fee'
        },
        {
          name: 'Ocean Shipping Fee',
          value: 'ocean_shipping_fee'
        },
        { name: 'Other Hard Costs', value: 'other_hard_cost' }
      ]
    },
    {
      name: 'Soft Costs',
      sectionName: 'financial.softCost',
      sections: [
        { name: 'Total Soft Costs', value: 'softCost_total_soft_cost' },
        { name: 'Pre-Design', value: 'pre_design' },
        { name: 'Design Fees', value: 'design_fees' },
        { name: 'Architect Fee', value: 'architect_fee' },
        { name: 'Engineering Fee', value: 'engineering_fee' },
        { name: 'Data Analysis Fee', value: 'data_analyst_fee' },
        { name: 'Photometric Fee', value: 'photometric_fee' },
        { name: 'Project Management', value: 'project_management' },
        { name: 'Permits & Inspections', value: 'permits' },
        {
          name: 'Construction Management',
          value: 'construction_management'
        },
        { name: 'Materials Handling', value: 'material_handling' },
        { name: 'Test and Balancing', value: 'test_and_balancing' },
        { name: 'Commissioning', value: 'commissioning' },
        { name: 'As-Built Fee', value: 'as_built_fee' },
        {
          name: 'Certified Commissioning Officer',
          value: 'certified_commissioning_officer'
        },
        {
          name: 'Certified Energy Manager',
          value: 'energy_manager'
        },
        {
          name: 'Certified Energy Auditor',
          value: 'energy_auditor'
        },
        {
          name: 'Junior Designer',
          value: 'junior_designer'
        },
        {
          name: 'Energy Modeler',
          value: 'energy_modeler'
        },
        {
          name: 'Senior Energy Engineer',
          value: 'audit_services_senior_engineer'
        },
        {
          name: 'Designer/CAD Operator',
          value: 'cad_operator'
        },
        {
          name: 'Senior Designer',
          value: 'senior_designer'
        },
        {
          name: 'Associate Engineer',
          value: 'associate_engineer'
        },
        {
          name: 'Project Engineer',
          value: 'project_engineer'
        },
        {
          name: 'Project Manager',
          value: 'project_manager'
        },
        { name: 'Program Fees', value: 'program_fees' },
        { name: 'Legal Fee', value: 'legal_fee' },
        { name: 'Overhead', value: 'overhead' },
        { name: 'Other Soft Costs', value: 'other_soft_cost' },
        { name: 'Additional Charge', value: 'additional_charge_soft_cost' },
        { name: 'Contingency', value: 'contingency' },
        { name: 'Profit', value: 'profit' },
        { name: 'Taxes', value: 'taxes' }
      ]
    },
    {
      name: 'Financing',
      sectionName: 'financial.financing',
      sections: [
        { name: 'Cost Share', value: 'finance_cost_share' },
        {
          name: 'Cost Share Rate',
          value: 'finance_cost_share_rate'
        },
        { name: 'Financing', value: 'finance_finance' },
        { name: 'Financing Rate', value: 'finance_finance_rate' }
      ]
    },
    {
      name: 'Funding',
      sectionName: 'financial.funding',
      sections: [
        { name: 'Cost Share', value: 'fund_cost_share' },
        { name: 'Cost Share Rate', value: 'fund_cost_share_rate' },
        { name: 'Funding', value: 'fund_finance' },
        { name: 'Funding Rate', value: 'fund_finance_rate' }
      ]
    }
  ]
}

export const ANALYSIS_RESULT_SECTION = {
  name: 'Analysis Results',
  sectionName: 'analysis',
  sections: [
    { name: 'Annual Cost Savings', value: 'annual_savings' },
    { name: 'Annual Cost Savings Range', value: 'annual_savings_range' },
    { name: 'Demand Savings', value: 'demand' },
    { name: 'Electric Savings', value: 'electric_savings' },
    { name: 'Energy Savings', value: 'energy_savings' },
    ...(features.espmEpicFeature
      ? [
          { name: 'Energy Star Score', value: 'energy_star_score' },
          { name: 'New Energy Star Score', value: 'new_energy_star_score' },
          {
            name: 'Energy Star Score Impact',
            value: 'energy_star_score_impact'
          }
        ]
      : []),
    { name: 'GHG Cost', value: 'ghg-cost' },
    { name: 'GHG Savings', value: 'ghg' },
    { name: 'Natural Gas Savings', value: 'gas_savings' },
    { name: 'NPV', value: 'npv' },
    { name: 'ROI', value: 'roi' },
    { name: 'Simple Payback', value: 'simple_payback' },
    { name: 'SIR', value: 'sir' },
    { name: 'Water Savings', value: 'water_savings' }
  ]
}

export const LOCATION_SECTION = {
  name: 'Location',
  sectionName: 'location',
  sections: [
    { name: 'Location Name', value: 'location_name' },
    { name: 'Building Use Type', value: 'building_use_type' },
    { name: 'Space Type', value: 'space_type' },
    { name: 'Floor', value: 'floor' }
  ]
}

export const PROJECT_LAYOUT_FIELDS = [
  { name: 'Individual Measure', value: 'individual' },
  { name: 'Group by Measure', value: 'groupProject' },
  { name: 'Group by Measure and Location', value: 'groupProjectLocation' }
]

export const PROJECT_DATA_FIELDS = [
  { name: 'Name', value: 'name' },
  { name: 'Description', value: 'description' },
  { name: 'Implementation Strategy', value: 'implementation_strategy' },
  { name: 'Incentive', value: 'incentive' },
  { name: 'Utility Company', value: 'utility_company' },
  { name: 'Project', value: 'project' },
  { name: 'Rebate Code', value: 'rebate_code' },
  { name: 'Existing Requirements', value: 'existing_requirements' },
  { name: 'Design Requirements', value: 'design_requirements' },
  { name: 'Measure Cost', value: 'project_cost' },
  { name: 'Annual Cost Savings', value: 'annual_savings' },
  { name: 'Energy Savings', value: 'energy_savings' },
  { name: 'Electric Savings', value: 'electric_savings' },
  { name: 'Natural Gas Savings', value: 'gas_savings' },
  { name: 'Water Savings', value: 'water_savings' },
  { name: 'ROI', value: 'roi' },
  { name: 'Simple Payback', value: 'simple_payback' },
  { name: 'NPV', value: 'npv' },
  { name: 'SIR', value: 'sir' },
  { name: 'GHG Savings', value: 'ghg' },
  { name: 'Demand Savings', value: 'demand' },
  { name: 'Effective Useful Life', value: 'eul' },
  { name: 'Design Fields', value: 'project.design_fields' },
  { name: 'Total Cost', value: 'total_cost' },
  { name: 'First Year Cost', value: 'first_year_cost' },
  { name: 'Net Measure Cost after Incentive', value: 'net_project_cost' },
  { name: 'Total Hard Costs', value: 'total_hard_cost' },
  { name: 'Total Hard Costs', value: 'hardCost_total_hard_cost' },
  { name: 'Total Material Cost', value: 'total_material_cost' },
  { name: 'Total Maintenance Cost/Savings', value: 'maintenance_cost_savings' },
  { name: 'Accessories Material Costs', value: 'accessories_material_costs' },
  { name: 'Vendor Cost', value: 'vendor_cost' },
  { name: 'Additional Material Cost', value: 'additional_materials_cost' },
  {
    name: 'Additional Material Quantity',
    value: 'additional_materials_quantity'
  },
  {
    name: 'Total Additional Material Cost',
    value: 'additional_materials_total'
  },
  { name: 'New Installation Cost/Unit', value: 'new_installation_cost_unit' },
  { name: 'New Installation Quantity', value: 'new_installation_quantity' },
  { name: 'Total New Installation Cost', value: 'new_installation_total' },
  { name: 'Disposal Fee Cost/Unit', value: 'disposal_fee_cost_unit' },
  { name: 'Disposal Fee Quantity', value: 'disposal_fee_quantity' },
  { name: 'Total Disposal Cost', value: 'disposal_total' },
  { name: 'General Labor/Unit Cost', value: 'general_labor_unit_cost' },
  { name: 'General Quantity', value: 'general_quantity' },
  { name: 'Total General Labor/Unit Cost', value: 'general_labor_unit_total' },
  { name: 'General Labor Rate', value: 'general_labor_rate' },
  { name: 'General Labor Hours', value: 'general_labor_hours' },
  { name: 'Total General Labor Rate', value: 'general_labor_rate_total' },
  { name: 'Day Labor/Unit Cost', value: 'day_labor_unit_cost' },
  { name: 'Day Labor/Unit Quantity', value: 'day_labor_unit_quantity' },
  { name: 'Total Day Labor/Unit Cost', value: 'day_labor_unit_total' },
  { name: 'Day Labor Rate', value: 'day_labor_rate' },
  { name: 'Day Labor Hours', value: 'day_labor_hours' },
  { name: 'Total Day Labor Rate', value: 'day_labor_rate_total' },
  { name: 'Overnight Labor/Unit Cost', value: 'night_labor_unit_cost' },
  {
    name: 'Overnight Labor/Unit Quantity',
    value: 'overnight_labor_unit_quantity'
  },
  { name: 'Total Overnight Labor/Unit Cost', value: 'night_labor_unit_total' },
  { name: 'Overnight Labor Rate', value: 'overnight_labor_rate' },
  { name: 'Overnight Labor Hours', value: 'overnight_labor_hours' },
  { name: 'Total Overnight Labor Rate', value: 'overnight_labor_rate_total' },
  { name: 'Prevailing Wages/Unit Cost', value: 'prevailing_wages_unit_cost' },
  {
    name: 'Prevailing Wages/Unit Quantity',
    value: 'prevailing_wages_unit_quantity'
  },
  {
    name: 'Total Prevailing Wages/Unit Cost',
    value: 'prevailing_wages_unit_total'
  },
  { name: 'Prevailing Wages Rate', value: 'prevailing_wages_rate' },
  { name: 'Prevailing Wages Hours', value: 'prevailing_wages_hours' },
  { name: 'Total Prevailing Wages Rate', value: 'prevailing_wages_rate_total' },
  { name: 'Maintenance Savings', value: 'maintenance_savings' },
  { name: 'Maintenance Cost', value: 'maintenance_cost' },
  { name: 'Disposal Fee Cost/Unit', value: 'disposal_fee' },
  { name: 'Disposal Fee Quantity', value: 'disposal_quantity' },
  { name: 'Day Labor Rate', value: 'day_labor_rate_cost' },
  { name: 'Day Labor Hours', value: 'day_labor_rate_hours' },
  { name: 'Prevailing Wages Rate', value: 'prevailing_wages' },
  { name: 'Prevailing Wages Hours', value: 'prevailing_hours' },
  {
    name: 'Subcontractor Labor Description',
    value: 'subcontractor_labor_description'
  },
  {
    name: 'Subcontractor Labor Rate ($/hr)',
    value: 'subcontractor_labor_rate'
  },
  { name: 'Subcontractor Labor Hours', value: 'subcontractor_labor_hours' },
  { name: 'Subcontractor Labor Total ($)', value: 'subcontractor_labor_total' },
  { name: 'GHG Cost', value: 'ghg-cost' },
  { name: 'Additional Description', value: 'additional_labor_description' },
  { name: 'Additional Rate ($/hr)', value: 'additional_labor_rate' },
  { name: 'Additional Hours', value: 'additional_labor_hours' },
  { name: 'Additional Total ($)', value: 'additional_labor_total' },
  {
    name: 'Additional Materials Description',
    value: 'additional_materials_description'
  },
  {
    name: 'Additional Materials Rate ($/hr)',
    value: 'additional_materials_rate'
  },
  { name: 'Total Soft Costs', value: 'total_soft_cost' },
  { name: 'Total Financing/Funding', value: 'total_financing_funding' },
  { name: 'Materials Unit Cost', value: 'material_unit_cost' },
  { name: 'Materials Quantity', value: 'material_quantity' },
  { name: 'Total Materials Cost', value: 'material_cost' },
  { name: 'Labor Rate', value: 'labor_rate' },
  { name: 'Hours', value: 'hours' },
  { name: 'Total Labor Cost', value: 'total_labor_cost' },
  { name: 'Site-Specific Installation Factors', value: 'installation_factors' },
  { name: 'Utility Service Upgrades', value: 'utility_service_upgrades' },
  { name: 'Temporary Services', value: 'temporary_services' },
  { name: 'Shipping Fee', value: 'shipping_fee' },
  {
    name: 'Domestic Freight Shipping Fee',
    value: 'domestic_freight_shipping_fee'
  },
  { name: 'Air Shipping Fee', value: 'air_shipping_fee' },
  { name: 'Ocean Shipping Fee', value: 'ocean_shipping_fee' },
  { name: 'Environment Unit Cost', value: 'environment_unit_cost' },
  { name: 'Envrionment Quantity', value: 'environment_quantity' },
  { name: 'Total Environment Unit Cost', value: 'total_environment_unit_cost' },
  { name: 'Contingency', value: 'contingency' },
  { name: 'Profit', value: 'profit' },
  { name: 'Taxes', value: 'taxes' },
  { name: 'Other Hard Costs', value: 'other_hard_cost' },
  { name: 'Total Soft Costs', value: 'softCost_total_soft_cost' },
  { name: 'Pre-Design', value: 'pre_design' },
  { name: 'Design Fees', value: 'design_fees' },
  { name: 'Architect Fee', value: 'architect_fee' },
  { name: 'Engineering Fee', value: 'engineering_fee' },
  { name: 'Data Analysis Fee', value: 'data_analyst_fee' },
  { name: 'Photometric Fee', value: 'photometric_fee' },
  { name: 'Project Management', value: 'project_management' },
  { name: 'Lift(s) 1 - Lift Type', value: 'lift_type_1' },
  { name: 'Lift(s) 1 - Lift Height', value: 'lift_height_1' },
  { name: 'Lift(s) 1 - Lift Rental Days', value: 'lift_rental_days_1' },
  { name: 'Lift(s) 1 - Pathway Width', value: 'pathway_width_1' },
  { name: 'Lift(s) 1 - Pathway Height', value: 'pathway_height_1' },
  { name: 'Lift(s) 1 - Pathway Description', value: 'pathway_description_1' },
  { name: 'Lift(s) 1 - Lift Equipment Fee/Week', value: 'lift_cost_1' },
  { name: 'Lift(s) 1 - Total Lift Fee', value: 'total_lift_fee_1' },
  { name: 'Lift(s) 2 - Lift Type', value: 'lift_type_2' },
  { name: 'Lift(s) 2 - Lift Height', value: 'lift_height_2' },
  { name: 'Lift(s) 2 - Lift Rental Days', value: 'lift_rental_days_2' },
  { name: 'Lift(s) 2 - Pathway Width', value: 'pathway_width_2' },
  { name: 'Lift(s) 2 - Pathway Height', value: 'pathway_height_2' },
  { name: 'Lift(s) 2 - Pathway Description', value: 'pathway_description_2' },
  { name: 'Lift(s) 2 - Lift Equipment Fee/Week', value: 'lift_cost_2' },
  { name: 'Lift(s) 2 - Total Lift Fee', value: 'total_lift_fee_2' },
  { name: 'Lift(s) 3 - Lift Type', value: 'lift_type_3' },
  { name: 'Lift(s) 3 - Lift Height', value: 'lift_height_3' },
  { name: 'Lift(s) 3 - Lift Rental Days', value: 'lift_rental_days_3' },
  { name: 'Lift(s) 3 - Pathway Width', value: 'pathway_width_3' },
  { name: 'Lift(s) 3 - Pathway Height', value: 'pathway_height_3' },
  { name: 'Lift(s) 3 - Pathway Description', value: 'pathway_description_3' },
  { name: 'Lift(s) 3 - Lift Equipment Fee/Week', value: 'lift_cost_3' },
  { name: 'Lift(s) 3 - Total Lift Fee', value: 'total_lift_fee_3' },
  { name: 'Lift(s) 4 - Lift Type', value: 'lift_type_4' },
  { name: 'Lift(s) 4 - Lift Height', value: 'lift_height_4' },
  { name: 'Lift(s) 4 - Lift Rental Days', value: 'lift_rental_days_4' },
  { name: 'Lift(s) 4 - Pathway Width', value: 'pathway_width_4' },
  { name: 'Lift(s) 4 - Pathway Height', value: 'pathway_height_4' },
  { name: 'Lift(s) 4 - Pathway Description', value: 'pathway_description_4' },
  { name: 'Lift(s) 4 - Lift Equipment Fee/Week', value: 'lift_cost_4' },
  { name: 'Lift(s) 4 - Total Lift Fee', value: 'total_lift_fee_4' },
  { name: 'Lift(s) 5 - Lift Type', value: 'lift_type_5' },
  { name: 'Lift(s) 5 - Lift Height', value: 'lift_height_5' },
  { name: 'Lift(s) 5 - Lift Rental Days', value: 'lift_rental_days_5' },
  { name: 'Lift(s) 5 - Pathway Width', value: 'pathway_width_5' },
  { name: 'Lift(s) 5 - Pathway Height', value: 'pathway_height_5' },
  { name: 'Lift(s) 5 - Pathway Description', value: 'pathway_description_5' },
  { name: 'Lift(s) 5 - Lift Equipment Fee/Week', value: 'lift_cost_5' },
  { name: 'Lift(s) 5 - Total Lift Fee', value: 'total_lift_fee_5' },
  { name: 'Permits & Inspections', value: 'permits' },
  { name: 'Construction Management', value: 'construction_management' },
  { name: 'Material Handling', value: 'material_handling' },
  { name: 'Test and Balancing', value: 'test_and_balancing' },
  { name: 'Commissioning', value: 'commissioning' },
  { name: 'As-Built Fee', value: 'as_built_fee' },
  {
    name: 'Certified Commissioning Officer Description',
    value: 'certified_commissioning_officer_description'
  },
  {
    name: 'Certified Commissioning Officer Rate ($/hr)',
    value: 'certified_commissioning_officer_rate'
  },
  {
    name: 'Certified Commissioning Officer Hours',
    value: 'certified_commissioning_officer_hours'
  },
  {
    name: 'Certified Commissioning Officer Total ($)',
    value: 'certified_commissioning_officer_total'
  },
  {
    name: 'Certified Energy Manager Description',
    value: 'energy_manager_description'
  },
  {
    name: 'Certified Energy Manager Rate ($/hr)',
    value: 'energy_manager_rate'
  },
  { name: 'Certified Energy Manager Hours', value: 'energy_manager_hours' },
  { name: 'Certified Energy Manager Total ($)', value: 'energy_manager_total' },
  {
    name: 'Certified Energy Auditor Description',
    value: 'energy_auditor_description'
  },
  {
    name: 'Certified Energy Auditor Rate ($/hr)',
    value: 'energy_auditor_rate'
  },
  { name: 'Certified Energy Auditor Hours', value: 'energy_auditor_hours' },
  { name: 'Certified Energy Auditor Total ($)', value: 'energy_auditor_total' },
  { name: 'Junior Designer Description', value: 'junior_designer_description' },
  { name: 'Junior Designer Rate ($/hr)', value: 'junior_designer_rate' },
  { name: 'Junior Designer Hours', value: 'junior_designer_hours' },
  { name: 'Junior Designer Total ($)', value: 'junior_designer_total' },
  { name: 'Energy Modeler Description', value: 'energy_modeler_description' },
  { name: 'Energy Modeler Rate ($/hr)', value: 'energy_modeler_rate' },
  { name: 'Energy Modeler Hours', value: 'energy_modeler_hours' },
  { name: 'Energy Modeler Total ($)', value: 'energy_modeler_total' },
  {
    name: 'Senior Energy Engineer Description',
    value: 'audit_services_senior_engineer_description'
  },
  {
    name: 'Senior Energy Engineer Rate ($/hr)',
    value: 'audit_services_senior_engineer_rate'
  },
  {
    name: 'Senior Energy Engineer Hours',
    value: 'audit_services_senior_engineer_hours'
  },
  {
    name: 'Senior Energy Engineer Total ($)',
    value: 'audit_services_senior_engineer_total'
  },
  {
    name: 'Designer/CAD Operator Description',
    value: 'cad_operator_description'
  },
  { name: 'Designer/CAD Operator Rate ($/hr)', value: 'cad_operator_rate' },
  { name: 'Designer/CAD Operator Hours', value: 'cad_operator_hours' },
  { name: 'Designer/CAD Operator Total ($)', value: 'cad_operator_total' },
  { name: 'Senior Designer Description', value: 'senior_designer_description' },
  { name: 'Senior Designer Rate ($/hr)', value: 'senior_designer_rate' },
  { name: 'Senior Designer Hours', value: 'senior_designer_hours' },
  { name: 'Senior Designer Total ($)', value: 'senior_designer_total' },
  {
    name: 'Associate Engineer Description',
    value: 'associate_engineer_description'
  },
  { name: 'Associate Engineer Rate ($/hr)', value: 'associate_engineer_rate' },
  { name: 'Associate Engineer Hours', value: 'associate_engineer_hours' },
  { name: 'Associate Engineer Total ($)', value: 'associate_engineer_total' },
  {
    name: 'Project Engineer Description',
    value: 'project_engineer_description'
  },
  { name: 'Project Engineer Rate ($/hr)', value: 'project_engineer_rate' },
  { name: 'Project Engineer Hours', value: 'project_engineer_hours' },
  { name: 'Project Engineer Total ($)', value: 'project_engineer_total' },
  { name: 'Project Manager Description', value: 'project_manager_description' },
  { name: 'Project Manager Rate ($/hr)', value: 'project_manager_rate' },
  { name: 'Project Manager Hours', value: 'project_manager_hours' },
  { name: 'Project Manager Total ($)', value: 'project_manager_total' },
  { name: 'Legal Fee', value: 'legal_fee' },
  { name: 'Additional Charge', value: 'additional_charge_soft_cost' },
  { name: 'Program Fees', value: 'program_fees' },
  { name: 'Overhead', value: 'overhead' },
  { name: 'Other Soft Costs', value: 'other_soft_cost' },
  { name: 'Cost Share', value: 'finance_cost_share' },
  { name: 'Cost Share Rate', value: 'finance_cost_share_rate' },
  { name: 'Financing', value: 'finance_finance' },
  { name: 'Financing Rate', value: 'finance_finance_rate' },
  { name: 'Cost Share', value: 'fund_cost_share' },
  { name: 'Cost Share Rate', value: 'fund_cost_share_rate' },
  { name: 'Funding', value: 'fund_finance' },
  { name: 'Funding Rate', value: 'fund_finance_rate' },
  { name: 'Location Name', value: 'location_name' },
  { name: 'Building Use Type', value: 'building_use_type' },
  { name: 'Space Type', value: 'space_type' },
  { name: 'Floor', value: 'floor' },
  { name: 'Name', value: 'project.name' },
  { name: 'Incentive', value: 'project.incentive' },
  { name: 'Utility Company', value: 'project.utility_company' },
  { name: 'Rebate Code', value: 'project.rebate_code' },
  { name: 'Existing Requirements', value: 'project.existing_requirements' },
  { name: 'Design Requirements', value: 'project.design_requirements' },
  { name: 'Design Fields', value: 'design_fields' },
  { name: 'Measure Cost', value: 'project.project_cost' },
  { name: 'Annual Cost Savings', value: 'project.annual_savings' },
  { name: 'Energy Savings', value: 'project.energy_savings' },
  { name: 'Electric Savings', value: 'project.electric_savings' },
  { name: 'Natural Gas Savings', value: 'project.gas_savings' },
  { name: 'Water Savings', value: 'project.water_savings' },
  { name: 'ROI', value: 'project.roi' },
  { name: 'Simple Payback', value: 'project.simple_payback' },
  { name: 'NPV', value: 'project.npv' },
  { name: 'SIR', value: 'project.sir' },
  { name: 'GHG Savings', value: 'project.ghg' },
  { name: 'GHG Cost', value: 'project.ghg-cost' },
  { name: 'Demand Savings', value: 'project.demand_savings' },
  { name: 'Effective Useful Life', value: 'project.eul' }
]

export const EQUIPMENT_FINANCIAL_MODELING_SECTION = {
  name: 'Financial Modeling',
  sectionName: 'financial',
  sections: [
    {
      name: 'Summary',
      sectionName: 'financial.summary',
      sections: [
        { name: 'Total Cost', value: 'total_cost' },
        { name: 'First Year Cost', value: 'first_year_cost' },
        {
          name: 'Net Measure Cost after Incentive',
          value: 'net_project_cost'
        },
        { name: 'Total Hard Costs', value: 'total_hard_cost' },
        { name: 'Total Soft Costs', value: 'total_soft_cost' },
        {
          name: 'Total Financing/Funding',
          value: 'total_financing_funding'
        }
      ]
    },
    {
      name: 'Hard Costs',
      sectionName: 'financial.hardCost',
      sections: [
        { name: 'Total Hard Cost', value: 'hardCost_total_hard_cost' },
        { name: 'Total Material Cost', value: 'total_material_cost' },
        {
          name: 'Total Maintenance Cost/Savings',
          value: 'maintenance_cost_savings'
        },
        {
          name: 'Accessories Material Costs',
          value: 'accessories_material_costs'
        },
        { name: 'Vendor Cost', value: 'vendor_cost' },
        {
          name: 'Additional Material Cost',
          value: 'additional_materials_cost'
        },
        {
          name: 'Additional Material Quantity',
          value: 'additional_materials_quantity'
        },
        {
          name: 'Total Additional Material Cost',
          value: 'additional_materials_total'
        },
        {
          name: 'New Installation Cost/Unit',
          value: 'new_installation_cost_unit'
        },
        {
          name: 'New Installation Quantity',
          value: 'new_installation_quantity'
        },
        {
          name: 'Total New Installation Cost',
          value: 'new_installation_total'
        },
        { name: 'Disposal Fee Cost/Unit', value: 'disposal_fee' },
        { name: 'Disposal Fee Quantity', value: 'disposal_quantity' },
        { name: 'Total Disposal Cost', value: 'disposal_total' },
        { name: 'General Labor/Unit Cost', value: 'general_labor_unit_cost' },
        { name: 'General Quantity', value: 'general_quantity' },
        {
          name: 'Total General Labor/Unit Cost',
          value: 'general_labor_unit_total'
        },
        { name: 'General Labor Rate', value: 'general_labor_rate' },
        { name: 'General Labor Hours', value: 'general_labor_hours' },
        { name: 'Total General Labor Rate', value: 'general_labor_rate_total' },
        { name: 'Day Labor/Unit Cost', value: 'day_labor_unit_cost' },
        { name: 'Day Labor/Unit Quantity', value: 'day_labor_unit_quantity' },
        {
          name: 'Total Day Labor/Unit Cost',
          value: 'day_labor_unit_total'
        },
        { name: 'Day Labor Rate', value: 'day_labor_rate_cost' },
        { name: 'Day Labor Hours', value: 'day_labor_rate_hours' },
        { name: 'Total Day Labor Rate', value: 'day_labor_rate_total' },
        {
          name: 'Overnight Labor/Unit Cost',
          value: 'night_labor_unit_cost'
        },
        {
          name: 'Overnight Labor/Unit Quantity',
          value: 'night_labor_unit_quantity'
        },
        {
          name: 'Total Overnight Labor/Unit Cost',
          value: 'night_labor_unit_total'
        },
        { name: 'Overnight Labor Rate', value: 'overnight_labor_rate' },
        { name: 'Overnight Labor Hours', value: 'overnight_labor_hours' },
        {
          name: 'Total Overnight Labor Rate',
          value: 'overnight_labor_rate_total'
        },
        {
          name: 'Prevailing Wages/Unit Cost',
          value: 'prevailing_wages_unit_cost'
        },
        {
          name: 'Prevailing Wages/Unit Quantity',
          value: 'prevailing_wages_unit_quantity'
        },
        {
          name: 'Total Prevailing Wages/Unit Cost',
          value: 'prevailing_wages_unit_total'
        },
        { name: 'Prevailing Wages Rate', value: 'prevailing_wages' },
        { name: 'Prevailing Wages Hours', value: 'prevailing_hours' },
        {
          name: 'Total Prevailing Wages Rate',
          value: 'prevailing_wages_rate_total'
        },
        { name: 'Maintenance Savings', value: 'maintenance_savings' },
        { name: 'Maintenance Cost', value: 'maintenance_cost' },
        { name: 'Materials Unit Cost', value: 'material_unit_cost' },
        { name: 'Materials Quantity', value: 'material_quantity' },
        { name: 'Total Materials Cost', value: 'material_cost' },
        { name: 'Labor Rate', value: 'labor_rate' },
        { name: 'Hours', value: 'hours' },
        { name: 'Total Labor Cost', value: 'total_labor_cost' },
        {
          name: 'Site-Specific Installation Factors',
          value: 'installation_factors'
        },
        {
          name: 'Utility Service Upgrades',
          value: 'utility_service_upgrades'
        },
        {
          name: 'Temporary Services',
          value: 'temporary_services'
        },
        {
          name: 'Shipping Fee',
          value: 'shipping_fee'
        },
        {
          name: 'Domestic Freight Shipping Fee',
          value: 'domestic_freight_shipping_fee'
        },
        {
          name: 'Air Shipping Fee',
          value: 'air_shipping_fee'
        },
        {
          name: 'Ocean Shipping Fee',
          value: 'ocean_shipping_fee'
        },
        {
          name: 'Environment Unit Cost',
          value: 'environment_unit_cost'
        },
        {
          name: 'Envrionment Quantity',
          value: 'environment_quantity'
        },
        {
          name: 'Total Environment Unit Cost',
          value: 'total_environment_unit_cost'
        },
        { name: 'Profit', value: 'profit' },
        { name: 'Taxes', value: 'taxes' },
        { name: 'Other Hard Costs', value: 'other_hard_cost' }
      ]
    }
  ]
}

export const EQUIPMENT_COST_TABLE_FINANCIAL_MODELING_SECTION = {
  name: 'Financial Modeling',
  sectionName: 'financial',
  sections: [
    { name: 'Materials Unit Cost', value: 'material_unit_cost' },
    { name: 'Additional Material Cost', value: 'additional_materials_cost' },
    { name: 'Total Accessories Cost', value: 'total_accessories_cost' },
    { name: 'Vendor Cost', value: 'vendor_cost' },
    { name: 'New Installation Fee', value: 'new_installation_fee' },
    { name: 'Disposal Fee', value: 'disposal_fee' },
    {
      name: 'Site-Specific Installation Factors',
      value: 'installation_factors'
    },
    { name: 'Environment Unit Cost', value: 'environment_unit_cost' },
    { name: 'General Labor/Unit Cost', value: 'general_labor_unit_cost' },
    { name: 'General Labor Rate', value: 'general_labor_rate_cost' },
    { name: 'Day Labor/Unit Cost', value: 'day_labor_unit_cost' },
    { name: 'Day Labor', value: 'day_labor_rate_cost' },
    { name: 'Overnight Labor/Unit Cost', value: 'night_labor_unit_cost' },
    { name: 'Overnight Labor', value: 'overnight_labor_rate_cost' },
    { name: 'Prevailing Wages/Unit Cost', value: 'prevailing_wages_unit_cost' },
    { name: 'Prevailing Wages', value: 'prevailing_wages_rate_cost' },
    { name: 'Total Incentive', value: 'input' }
  ]
}
