import ApiClient from 'utils/ApiClient'
import { showFlash as flash } from 'utils/Flash/modules/flash'

// ------------------------------------
// Constants
// ------------------------------------
export const LIBRARY_GET_MEASURES = 'LIBRARY/LIBRARY_GET_MEASURES'
export const GET_MEASURE_OR_PROJECT_BY_ID =
  'LIBRARY/GET_MEASURE_OR_PROJECT_BY_ID'

// ------------------------------------
// Actions
// ------------------------------------
export const getMeasures = () => {
  return (dispatch, getState) => {
    dispatch({ type: LIBRARY_GET_MEASURES })
    return new Promise((resolve, reject) => {
      const client = new ApiClient(dispatch, getState())
      client
        .get('/measure')
        .then(response => {
          resolve(response.measures)
        })
        .catch(err => {
          dispatch(flash(err || 'Issues retrieving building measures', 'error'))
          reject(err)
        })
    })
  }
}

export const getMeasureOrProjectById = id => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const client = new ApiClient(dispatch, getState())
      client
        .get(`/measureOrProject`, {
          params: { measureId: id }
        })
        .then(response => {
          if (response.status === 'Success') {
            resolve(response)
          }
        })
        .catch(err => {
          dispatch(flash(err || 'Issues retrieving building measure', 'error'))
          reject(err)
        })
    })
  }
}

// ------------------------------------
// Action Helpers
// ------------------------------------
const noStoreModifications = (state, action) => {
  return { ...state }
}
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LIBRARY_GET_MEASURES]: noStoreModifications,
  [GET_MEASURE_OR_PROJECT_BY_ID]: noStoreModifications
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  library: []
}
export default function libraryReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
