import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './SubHeaderTab.scss'
import classNames from 'classnames'

const SubHeaderTab = props => {
  const [subOptionsOpen, setSubOptionsOpen] = useState(false)
  const hasSubsection = props.subSectionOption?.length > 0

  const onClickTab = () => {
    if (hasSubsection) {
      setSubOptionsOpen(val => !val)
    } else {
      props.handleClick(props.tab)
    }
  }

  return (
    <div className={classNames(styles.link, props.active ? styles.active : '')}>
      <a onClick={onClickTab} data-test='reports-header-link'>
        <span>{props.label}</span>
        {hasSubsection && <i className='material-icons'>expand_more</i>}
      </a>
      {subOptionsOpen && (
        <ul className={classNames(styles.dropdown)}>
          {props.subSectionOption.map(subSectionData => {
            return (
              <li
                className={
                  props.activeTab === subSectionData.value
                    ? styles['link-active']
                    : ''
                }
                onClick={() => props.handleClick(subSectionData.value)}
                value={subSectionData.value}
              >
                {subSectionData.label}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

SubHeaderTab.propTypes = {
  tab: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  subSectionOption: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired
}

export default SubHeaderTab
