import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CompactColorInput from 'components/UI/CompactColorInput'
import Checkbox from 'components/UI/Checkbox'
import styles from './ChartColors.scss'
import { colorTitleLabels } from './options'
import { cloneDeep } from 'lodash'
function ChartColors({ label, selectedColors, defaultColors, onChange }) {
  const [showColors, setShowColors] = useState(false)
  const colorsToShow = selectedColors
    ? defaultColors?.map(d => {
        const found = selectedColors.find(
          v => v.colorSection === d.colorSection
        )
        return found || d
      })
    : defaultColors

  const handleColorChange = (colorSectionIndex, chartColorIndex, newColor) => {
    const newChartColors = cloneDeep(colorsToShow)
    newChartColors[colorSectionIndex].colors[chartColorIndex].color = newColor
    const oldChartColorsWithoutNew =
      selectedColors?.filter(d => {
        const found = newChartColors.find(
          v => v.colorSection === d.colorSection
        )
        return !found
      }) || []
    onChange([...oldChartColorsWithoutNew, ...newChartColors])
  }

  const colorsDefined = colorsToShow?.length

  return (
    <>
      <button
        className={classNames(
          styles.button,
          styles.buttonSecondary,
          styles.chartColorsButton
        )}
        onClick={() => setShowColors(!showColors)}
      >
        {label}
      </button>
      {showColors && colorsDefined ? (
        <div className={classNames(styles.chartColors)}>
          {colorsToShow.map(({ colorSection, colors }, colorSectionIndex) => {
            return (
              <Fragment key={colorSectionIndex}>
                <h5 className={styles.chartColorsSection}>
                  {colorTitleLabels[colorSection]}
                </h5>
                {colors.map((chartColor, chartColorIndex) => (
                  <CompactColorInput
                    key={chartColorIndex}
                    onColorChange={newColor =>
                      handleColorChange(
                        colorSectionIndex,
                        chartColorIndex,
                        newColor
                      )
                    }
                    color={chartColor.color}
                    label={chartColor.label}
                  />
                ))}
              </Fragment>
            )
          })}
          <div className={styles.checkboxContainer}>
            <Checkbox
              label='Use default colors'
              checked={!selectedColors}
              onChange={e => {
                onChange(e.target.checked ? undefined : [])
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}
ChartColors.propTypes = {
  label: PropTypes.string.isRequired,
  selectedColors: PropTypes.arrayOf(
    PropTypes.shape({
      colorSection: PropTypes.string.isRequired,
      colors: PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired
        })
      )
    })
  ),
  defaultColors: PropTypes.arrayOf(
    PropTypes.shape({
      colorSection: PropTypes.string.isRequired,
      colors: PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired
        })
      )
    })
  ),
  onChange: PropTypes.func.isRequired
}

export default ChartColors
