import React, { useCallback } from 'react'
import ProjectContentItem from './ProjectContentItem'
import styles from './ProjectNameContentItem.scss'

const headingStyle = [
  { name: 'Heading 1', value: 'h1' },
  { name: 'Heading 2', value: 'h2' },
  { name: 'Heading 3', value: 'h3' },
  { name: 'Heading 4', value: 'h4' },
  { name: 'Heading 5', value: 'h5' },
  { name: 'Heading 6', value: 'h6' }
]

const ProjectNameContentItem = ({ projectConfig, onUpdateProjectConfig }) => {
  const onSelectedHeadingStyle = useCallback(
    event => {
      projectConfig.styles = {
        ...(projectConfig.styles || {}),
        phs: event.target.value
      }
      onUpdateProjectConfig(projectConfig)
    },
    [onUpdateProjectConfig, projectConfig]
  )
  return (
    <ProjectContentItem
      isCollapsible
      renderHeaderSection={() => <div>Name</div>}
      renderCollapsibleSection={() => (
        <div className={styles.container}>
          <div className={styles.subContainer}>
            <div className={styles.subHeader}>Headings Styles</div>
            <div className={styles.styleContainer}>
              <div className={styles.selectContainer}>
                <select
                  value={projectConfig?.styles.phs || 'select'}
                  onChange={onSelectedHeadingStyle}
                >
                  <option disabled value='select'>
                    Select Heading
                  </option>
                  {headingStyle.map((item, i) => {
                    return (
                      <option key={i} value={item.value}>
                        {item.name}{' '}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default ProjectNameContentItem
