import React from 'react'
import PropTypes from 'prop-types'
import Select from 'components/UI/Select'
import MultiLevelSelect from 'components/UI/MultiLevelSelect'
import ChartColors from 'components/Template/TemplateComponent/BodyComponents/ChartContainer/ChartColors'
import styles from './ChartSettings.scss'
import { chartTypeOptions, getChartSettingOptions } from '../options'
import { CHART_SETTINGS } from 'static/charts'

function ChartSettings({ value, onChange, layout }) {
  const chartSettingOptions = getChartSettingOptions(value, layout)

  const onSettingOptionChange = (settingOption, newValue) => {
    onChange({
      ...value,
      [settingOption.value]: newValue,
      layout
    })
  }

  const getMultiLevelSelectValue = value => {
    if (value?.label)
      return `${value?.options?.label ?? value.label} ${value?.options?.options
        ?.label ?? ''} `
    else return ''
  }

  const onTypeChange = e => {
    if (!e.target.value) onChange({})
    else {
      const defaultValues = {}
      chartSettingOptions[e.target.value]?.map(settingOption => {
        defaultValues[settingOption.value] = settingOption.options?.find(
          option => !!option?.default
        )?.value
      })
      onChange({ ...defaultValues, type: e.target.value })
    }
  }

  return (
    <div className={styles.chartSettings}>
      <Select
        id='Type'
        label='Type'
        name='Type'
        className={styles.chartSettingsSelect}
        value={value?.type || ''}
        onChange={onTypeChange}
        options={chartTypeOptions}
      />

      {value.type ? (
        <>
          {chartSettingOptions[value.type].map((settingOption, index) =>
            settingOption.value === CHART_SETTINGS.Color ? (
              <ChartColors
                key={index}
                label={settingOption.label}
                onChange={newValue =>
                  onSettingOptionChange(settingOption, newValue)
                }
                selectedColors={value?.[settingOption.value]}
                defaultColors={settingOption.options}
              />
            ) : settingOption?.options ? (
              settingOption?.options.some(option => option?.options) ? (
                <MultiLevelSelect
                  key={index}
                  id={settingOption.value}
                  label={settingOption.label}
                  placeholder='Select'
                  value={value[settingOption.value] || {}}
                  valueToShow={getMultiLevelSelectValue(
                    value[settingOption.value] || settingOption.default || ''
                  )}
                  options={settingOption.options}
                  onChange={newValue =>
                    onSettingOptionChange(settingOption, newValue)
                  }
                />
              ) : (
                <Select
                  key={index}
                  id={settingOption.value}
                  className={styles.chartSettingsSelect}
                  label={settingOption.label}
                  name={settingOption.value}
                  value={
                    value[settingOption.value] ||
                    settingOption.options.find(option => option.default)
                      ?.value ||
                    ''
                  }
                  onChange={e =>
                    onSettingOptionChange(settingOption, e.target.value)
                  }
                  options={settingOption.options}
                />
              )
            ) : null
          )}
        </>
      ) : (
        <p>Choose your chart type.</p>
      )}
    </div>
  )
}

ChartSettings.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  layout: PropTypes.string.isRequired
}
export default ChartSettings
