import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import LayoutSettings from './LayoutSettings'
import ChartSettings from './ChartSettings'
import ChartPreview from './ChartPreview'
import {
  ACTIVE_COLUMN,
  BASELINE,
  CHART_FORMAT,
  CHART_SETTINGS,
  CHART_TYPES,
  LAYOUTS,
  LEGEND_POSITION,
  TARGET,
  TIME,
  WEDGES_FIRST_LEVEL,
  WEDGES_SECOND_LEVEL,
  WEDGE_DATA,
  Y_AXIS_PRIMARY_SECOND_LEVEL,
  Y_AXIS_SECONDARY
} from 'static/charts'
import styles from './ChartContainer.scss'

const optionInitialState = {
  chartSettings: {},
  metaData: {
    yearOption: 'SetOnExport'
  }
}
function ChartContainer({ index, handleUpdateTemplateState }) {
  const body = useSelector(state => state.template.templateViewBody || [])

  const [layout, setLayout] = useState(body[index]?.layoutOption || '')
  const [activeColumn, setActiveColumn] = useState(ACTIVE_COLUMN.Column1)
  const [options, setOptions] = useState(body[index]?.options || [])

  const handleLayoutChange = newLayout => {
    setLayout(newLayout)
    setActiveColumn(ACTIVE_COLUMN.Column1)
    updateLayoutOnTemplate(newLayout)
  }

  const handleActiveColumnChange = newActiveColumn =>
    setActiveColumn(newActiveColumn)

  const handleChartSettingsChange = (newChartSettings, layout) => {
    const newOptions = [...options]
    newOptions[activeColumn].chartSettings = getValidChartSettings(
      newChartSettings,
      newOptions[activeColumn].chartSettings,
      layout
    )
    setOptions(newOptions)
    updateOptionsOnTemplate(newOptions)
  }

  const updateLayoutOnTemplate = layoutOption => {
    const column1 = options?.[ACTIVE_COLUMN.Column1]
      ? options?.[ACTIVE_COLUMN.Column1]
      : { ...optionInitialState }
    const column2 = { ...optionInitialState }
    column1.chartSettings = getValidChartSettings(
      column1.chartSettings,
      column1.chartSettings,
      layoutOption
    )
    let newOptions = []
    if (layoutOption === LAYOUTS.OneColumn) {
      newOptions = [column1]
    } else if (layoutOption === LAYOUTS.TwoColumns) {
      newOptions = [column1, column2]
    }
    const newBody = [...body]
    setOptions(newOptions)
    newBody[index] = {
      ...newBody[index],
      layoutOption: layoutOption,
      options: newOptions
    }
    handleUpdateTemplateState(newBody)
  }

  const updateOptionsOnTemplate = newOptions => {
    const newBody = [...body]
    newBody[index].options = newOptions
    handleUpdateTemplateState(newBody)
  }

  const shouldIncludeWedgeData = chartSettings => {
    const eligibleWedges = [
      WEDGES_SECOND_LEVEL.Electricity,
      WEDGES_SECOND_LEVEL.NaturalGas,
      WEDGES_SECOND_LEVEL.Energy
    ]
    return (
      chartSettings[CHART_SETTINGS.Wedges]?.value ===
        WEDGES_FIRST_LEVEL.EstimatedEndUsesAndCosts &&
      eligibleWedges.includes(
        chartSettings[CHART_SETTINGS.Wedges]?.options?.value
      )
    )
  }

  const getValidChartSettings = (chartSettings, oldChartSettings, layout) => {
    const newChartSettings = { ...chartSettings }
    // Trend charts validations
    if (chartSettings?.type === CHART_TYPES.Trend) {
      // Baseline can't be line on bar chart format
      if (
        (chartSettings[CHART_SETTINGS.Format] === CHART_FORMAT.Bar ||
          chartSettings[CHART_SETTINGS.Format] === CHART_FORMAT.StackedBar) &&
        chartSettings[CHART_SETTINGS.Baseline] === BASELINE.Line
      ) {
        newChartSettings[CHART_SETTINGS.Baseline] = BASELINE.Hide
      }
      // Baseline can't be bar on line chart format
      if (
        chartSettings[CHART_SETTINGS.Format] === CHART_FORMAT.Line &&
        chartSettings[CHART_SETTINGS.Baseline] === BASELINE.Bar
      ) {
        newChartSettings[CHART_SETTINGS.Baseline] = BASELINE.Hide
      }
      // As secondary data depends on primary data, it should be reset on each change
      if (
        chartSettings[CHART_SETTINGS.YAxisPrimary] !==
        oldChartSettings[CHART_SETTINGS.YAxisPrimary]
      ) {
        newChartSettings[CHART_SETTINGS.YAxisSecondary] = Y_AXIS_SECONDARY.Hide
      }
      // There is no target for WCI or WUI
      if (
        chartSettings[CHART_SETTINGS.YAxisPrimary]?.options?.value ===
          Y_AXIS_PRIMARY_SECOND_LEVEL.WaterCostIntensityWCI ||
        chartSettings[CHART_SETTINGS.YAxisPrimary]?.options?.value ===
          Y_AXIS_PRIMARY_SECOND_LEVEL.WaterUseIntensityWUI
      ) {
        newChartSettings[CHART_SETTINGS.Target] = TARGET.Hide
      }
      // There is no target for monthly time
      if (chartSettings[CHART_SETTINGS.Time] === TIME.Monthly) {
        newChartSettings[CHART_SETTINGS.Target] = TARGET.Hide
      }
    }
    // Breakdown chart validations
    if (chartSettings?.type === CHART_TYPES.Breakdown) {
      // There is no right and left on two column layout
      if (
        layout === LAYOUTS.TwoColumns &&
        [LEGEND_POSITION.Right, LEGEND_POSITION.Left].includes(
          chartSettings[CHART_SETTINGS.LegendPosition]
        )
      ) {
        newChartSettings[CHART_SETTINGS.LegendPosition] = LEGEND_POSITION.Bottom
      }

      // Remove or add wedge data when it is necessary
      if (shouldIncludeWedgeData(chartSettings)) {
        if (!shouldIncludeWedgeData(oldChartSettings)) {
          newChartSettings[CHART_SETTINGS.WedgeData] =
            WEDGE_DATA.EstimatedPercentage
        }
      } else {
        delete newChartSettings[CHART_SETTINGS.WedgeData]
      }
    }
    return newChartSettings
  }

  return (
    <div className={styles.chartContainer}>
      <LayoutSettings
        layout={layout}
        onLayoutChange={handleLayoutChange}
        activeColumn={activeColumn}
        onActiveColumnChange={handleActiveColumnChange}
      />
      {layout ? (
        <>
          <ChartSettings
            value={options?.[activeColumn]?.chartSettings || {}}
            onChange={handleChartSettingsChange}
            layout={layout}
          />
          <ChartPreview
            index={index}
            chartSettings={options?.[activeColumn]?.chartSettings || {}}
          />
        </>
      ) : null}
    </div>
  )
}

ChartContainer.propTypes = {
  index: PropTypes.number.isRequired,
  handleUpdateTemplateState: PropTypes.func.isRequired
}

export default ChartContainer
