export const SECONDARY_DATA = {
  Hide: 'Hide',
  AverageTemperature: 'AverageTemperature',
  Demand: 'Demand',
  CoolingDegreeDays: 'CoolingDegreeDays',
  HeatingDegreeDays: 'HeatingDegreeDays',
  CoolingAndHeatingDegreeDays: 'CoolingAndHeatingDegreeDays'
}

export const secondaryDataLabels = {
  [SECONDARY_DATA.AverageTemperature]: 'Average Temperature',
  [SECONDARY_DATA.CoolingAndHeatingDegreeDays]:
    'Cooling And Heating Degree Days',
  [SECONDARY_DATA.CoolingDegreeDays]: 'Cooling Degree Days',
  [SECONDARY_DATA.Demand]: 'Demand',
  [SECONDARY_DATA.HeatingDegreeDays]: 'Heating Degree Days',
  [SECONDARY_DATA.Hide]: 'Hide'
}
