import { DynamicTag } from '../DynamicTag/DynamicTag'
import styles from './MeasureTag.scss'

const FILTER_FIELDS = ['include', 'type', 'category']

const getFilterQueryString = (data = {}) => {
  return FILTER_FIELDS.reduce((agg, field) => {
    if (data[field]) {
      agg.push(`${field}="${data[field]}"`)
    }
    return agg
  }, []).join(' ')
}

export class MeasureTag extends DynamicTag {
  static blotName = 'measureTag'
  static tagName = 'span'
  static className = 'qlMeasureTag'

  static create(data) {
    const node = super.create(data)
    node.innerHTML = `{{#measureFilter ${
      data.include === 'proposal' ? 'proposalProjects' : 'project'
    } building utilities ${getFilterQueryString(data)}}}{{${
      data.value
    }}}{{else}}${data.defaultValue || ''}{{/measureFilter}}`
    return MeasureTag.setDataValues(node, data)
  }
}
