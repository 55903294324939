import React from 'react'
import PropTypes from 'prop-types'
import Select from 'components/UI/Select'
import { ToggleTab } from 'components/Asset/ToggleTab'
import { activeColumnOptions, layoutOptions } from '../options'
import { LAYOUTS } from 'static/charts'
import styles from './LayoutSettings.scss'

function LayoutSettings({
  layout,
  onLayoutChange,
  activeColumn,
  onActiveColumnChange
}) {
  return (
    <div className={styles.layoutSettings}>
      <Select
        id='Layout'
        className={styles.layoutSettingsSelect}
        label='Layout'
        name='Layout'
        value={layout}
        onChange={e => onLayoutChange(e.target.value)}
        options={layoutOptions}
      />
      {layout ? (
        layout === LAYOUTS.TwoColumns ? (
          <ToggleTab
            onToggle={onActiveColumnChange}
            options={activeColumnOptions}
            defaultOption={activeColumn}
            className={styles.layoutSettingsToggleTab}
            itemClassName={styles.layoutSettingsToggleTabItem}
          />
        ) : null
      ) : (
        <p>
          Choose if you would like 1 chart in 1 column or 2 charts in 2 columns.
        </p>
      )}
    </div>
  )
}

LayoutSettings.propTypes = {
  layout: PropTypes.string.isRequired,
  onLayoutChange: PropTypes.func.isRequired,
  activeColumn: PropTypes.string.isRequired,
  onActiveColumnChange: PropTypes.func.isRequired
}

export default LayoutSettings
