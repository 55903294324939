import { ENABLED_FEATURES } from '../graphql/queries/user'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import React from 'react'

const UserFeatures = ({ featureNames = [], children }) => {
  return (
    <Query query={ENABLED_FEATURES} fetchPolicy='cache-and-network'>
      {({ loading, error, data }) => {
        const renderProps = {
          loading,
          error,
          enabled: false
        }

        if (!data || !data.enabledFeatures) {
          return children(renderProps)
        }

        renderProps.enabled = featureNames.reduce(
          (enabled, name) =>
            data.enabledFeatures.find(feature => feature.name === name) &&
            enabled,
          true
        )

        return children(renderProps)
      }}
    </Query>
  )
}

UserFeatures.propTypes = {
  children: PropTypes.func.isRequired,
  featureNames: PropTypes.arrayOf(PropTypes.string)
}

export default UserFeatures
