import { UTILITY_COLORS } from './default-chart-colors'
import { UTILITY_TYPES } from './utility-units'

export const TARGET_METRICS = ['Cost', 'Energy', 'GHG Emissions']

export const targetDictionary = {
  COST: 'Cost',
  ENERGY: 'Energy',
  GHG_EMISSIONS: 'GHG Emissions',
  ENERGY_COST_REDUCTION: 'energyCostReduction'
}

export const TARGET_DEFAULT_TYPE_TO_UNIT = {
  [targetDictionary.COST]: '$',
  [targetDictionary.ENERGY]: 'mBtu',
  [targetDictionary.GHG_EMISSIONS]: 'mtCO2e'
}

export const GRAPHS_COLORS = Object.keys(UTILITY_COLORS).reduce((acc, type) => {
  acc[
    type
  ] = `${UTILITY_COLORS[type].primary}, ${UTILITY_COLORS[type].secondary}, ${UTILITY_COLORS[type].tertiary}`
  return acc
}, {})

export const targetMetricToConformationDataDict = {
  [targetDictionary.COST]: 'costSaving',
  [targetDictionary.GHG_EMISSIONS]: 'ghgSaving',
  [targetDictionary.ENERGY]: 'energySaving'
}
export const targetMetricToElectricDataDict = {
  [targetDictionary.COST]: 'annualElectricSavings',
  [targetDictionary.GHG_EMISSIONS]: 'ghgElectricSaving',
  [targetDictionary.ENERGY]: 'electricSaving'
}
export const targetMetricToGasDataDict = {
  [targetDictionary.COST]: 'annualGasSavings',
  [targetDictionary.GHG_EMISSIONS]: 'ghgGasSaving',
  [targetDictionary.ENERGY]: 'gasSaving'
}
export const targetMetricToWaterDataDict = {
  [targetDictionary.COST]: 'annualWaterSavings',
  [targetDictionary.ENERGY]: 'waterSaving'
}

export const mapTargetMetricToKeyValue = (targetMetric, type) => {
  if (type) {
    switch (type) {
      case UTILITY_TYPES.ELECTRICITY:
        return targetMetricToElectricDataDict[targetMetric]
      case UTILITY_TYPES.NATURAL_GAS:
        return targetMetricToGasDataDict[targetMetric]
      case UTILITY_TYPES.WATER:
        return targetMetricToWaterDataDict[targetMetric]
      default:
        return targetMetricToConformationDataDict[targetMetric]
    }
  }

  // If don't receive a fuel type, the key is energySaving
  if (targetMetric?.toLowerCase() === 'ghg emissions') return 'ghgSaving'
  if (targetMetric?.toLowerCase() === 'cost') return 'costSaving'
  return 'energySaving'
}
