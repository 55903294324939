import {
  CHART_SETTINGS,
  PROPOSED,
  Y_AXIS_PRIMARY_SECOND_LEVEL,
  Y_AXIS_SECONDARY
} from './charts'
import { END_USES } from './end-uses'
import { UTILITY_TYPES, UTILITY_TYPES_WITH_ALL } from './utility-units'

export const COLOR_SECTIONS = {
  UTILITIES: 'utilities',
  ASHRAE_END_USES: 'ashraeLevelIIEndUses',
  CBECS_RECS_END_USES: 'cbecsRecsEndUses',
  WATER_END_USES: 'waterEndUses',
  SECONDARY_DATA: 'secondaryData',
  TARGET: 'target',
  BASELINE: 'baseline',
  PROPOSED: 'proposed',
  INTENSITY: 'usageAndCostIntensity'
}

// Utilities
export const UTILITY_COLORS = {
  [UTILITY_TYPES.ELECTRICITY]: {
    primary: '#FFB30F',
    secondary: '#ffc64a',
    tertiary: '#FFF1D4'
  },
  [UTILITY_TYPES.SOLAR]: {
    primary: '#62B58F',
    secondary: '#BDE0CF',
    tertiary: '#e0f0e8'
  },
  [UTILITY_TYPES.NATURAL_GAS]: {
    primary: '#e05263',
    tertiary: '#F6CDD2',
    secondary: '#f2b6bd'
  },
  [UTILITY_TYPES.STEAM]: {
    primary: '#49A3A1',
    secondary: '#bce1e0',
    tertiary: '#C8E7E6'
  },
  [UTILITY_TYPES.FUEL_OIL_1]: {
    primary: '#FE7F2D',
    tertiary: '#FFDBC4',
    secondary: '#ffc6a2'
  },
  [UTILITY_TYPES.FUEL_OIL_2]: {
    primary: '#FE7F2D',
    tertiary: '#FFDBC4',
    secondary: '#ffc6a2'
  },
  [UTILITY_TYPES.FUEL_OIL_4]: {
    primary: '#FE7F2D',
    tertiary: '#FFDBC4',
    secondary: '#ffc6a2'
  },
  [UTILITY_TYPES.FUEL_OIL_56]: {
    primary: '#FE7F2D',
    tertiary: '#FFDBC4',
    secondary: '#ffc6a2'
  },
  [UTILITY_TYPES.DIESEL]: {
    primary: '#9748A3',
    tertiary: '#E3C8E7',
    secondary: '#b67ebe'
  },
  [UTILITY_TYPES.PROPANE]: {
    primary: '#5d62b5',
    tertiary: '#BABDE0',
    secondary: '#8d91cb'
  },
  [UTILITY_TYPES.KEROSENE]: {
    primary: '#BC95DF',
    tertiary: '#E3D2F1',
    secondary: '#d0b4e8'
  },
  [UTILITY_TYPES.DISTRICT_HOT_WATER]: {
    primary: '#29c3be',
    tertiary: '#AAD4D3',
    secondary: '#69d5d1'
  },
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER]: {
    primary: '#096B72',
    tertiary: '#96BFC4',
    secondary: '#52979C'
  },
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER]: {
    primary: '#096B72',
    tertiary: '#96BFC4',
    secondary: '#52979C'
  },
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER]: {
    primary: '#096B72',
    tertiary: '#96BFC4',
    secondary: '#52979C'
  },
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER]: {
    primary: '#096B72',
    tertiary: '#96BFC4',
    secondary: '#52979C'
  },
  [UTILITY_TYPES.WIND]: {
    primary: '#26532B',
    tertiary: '#A2B7A5',
    secondary: '#67866a'
  },
  [UTILITY_TYPES.WOOD]: {
    primary: '#9c755f',
    tertiary: '#D5C4BB',
    secondary: '#b99e8f'
  },
  [UTILITY_TYPES.WATER]: {
    primary: '#73D2DE',
    tertiary: '#C4ECF1',
    secondary: '#9ddfe7'
  },
  [UTILITY_TYPES.OTHER]: {
    primary: '#858C92',
    secondary: '#a0a5aa',
    tertiary: '#DDDEE0'
  }
}

export const UTILITIES_COLORS_WITH_ALL = {
  [UTILITY_TYPES_WITH_ALL.ALL]: {
    primary: '#425F97',
    secondary: '#859cca',
    tertiary: '#CED4E1'
  },
  ...UTILITY_COLORS
}

// Commercial Buildings Energy Consumption Survey and
// Residential Energy Consumption Survey End Uses Breakdown
export const CBECS_RECS_EUB_COLORS = {
  [END_USES.CLOTHES_DRYER]: '#9C755F',
  [END_USES.CLOTHES_WASHER]: '#D58936',
  [END_USES.COMPUTING]: '#62B58F',
  [END_USES.COOKING]: '#FE7F2D',
  [END_USES.COOLING]: '#425F97',
  [END_USES.DISHWASHERS]: '#096B72',
  [END_USES.FANS]: '#9748A3',
  [END_USES.FREEZERS]: '#BC95DF',
  [END_USES.HEATING]: '#E05263',
  [END_USES.HOT_TUB]: '#2E2E3A',
  [END_USES.LIGHTING]: '#FFB30F',
  [END_USES.MICROWAVES]: '#26532B',
  [END_USES.OFFICE_EQUIPMENT]: '#26532B',
  [END_USES.OTHER]: '#858C92',
  [END_USES.POOLS]: '#73D2DE',
  [END_USES.REFRIGERATION]: '#5D62B5',
  [END_USES.TVS]: '#62B58F',
  [END_USES.VENTILATION]: '#9748A3',
  [END_USES.WATER_HEATING]: '#29C3BE'
}

// American Society of Heating, Refrigeration and Air-Conditioning Engineers End Uses Breakdown
export const ASHRAE_EUB_COLORS = {
  [END_USES.SPACE_HEATING]: '#E05263',
  [END_USES.SPACE_COOLING]: '#425F97',
  [END_USES.AIR_DISTRIBUTION]: '#096B72',
  [END_USES.WATER_DISTRIBUTION]: '#73D2DE',
  [END_USES.LIGHTING]: '#FFB30F',
  [END_USES.SHW_DHW]: '#29C3BE',
  [END_USES.VENTILATION]: '#9748A3',
  [END_USES.COOKING]: '#FE7F2D',
  [END_USES.REFRIGERATION]: '#5D62B5',
  [END_USES.CONVEYANCE]: '#9C755F',
  [END_USES.PROCESS_LOADS]: '#BC95DF',
  [END_USES.PLUG_LOADS]: '#26532B',
  [END_USES.IT]: '#62B58F',
  [END_USES.OTHER]: '#858C92'
}

// Water End Uses Breakdown
export const WATER_EUB_COLORS = {
  [END_USES.CLOTHES_WASHERS]: '#9748A3',
  [END_USES.DISHWASHER]: '#096B72',
  [END_USES.FAUCETS]: '#73D2DE',
  [END_USES.LANDSCAPING]: '#425F97',
  [END_USES.LEAKS]: '#2E2E3A',
  [END_USES.OTHER]: '#858C92',
  [END_USES.SHOWER_BATH]: '#5D62B5',
  [END_USES.TOILET]: '#29C3BE'
}

// Baseline
export const BASELINE_COLORS = {
  [CHART_SETTINGS.Baseline]: '#425F97',
  [UTILITY_TYPES.ELECTRICITY]: '#FFF1D4',
  [UTILITY_TYPES.SOLAR]: '#BDE0CF',
  [UTILITY_TYPES.NATURAL_GAS]: '#F6CDD2',
  [UTILITY_TYPES.STEAM]: '#C8E7E6',
  [UTILITY_TYPES.FUEL_OIL_1]: '#FFDBC4',
  [UTILITY_TYPES.FUEL_OIL_2]: '#FFDBC4',
  [UTILITY_TYPES.FUEL_OIL_4]: '#FFDBC4',
  [UTILITY_TYPES.FUEL_OIL_56]: '#FFDBC4',
  [UTILITY_TYPES.DIESEL]: '#E3C8E7',
  [UTILITY_TYPES.PROPANE]: '#BABDE0',
  [UTILITY_TYPES.KEROSENE]: '#E3D2F1',
  [UTILITY_TYPES.DISTRICT_HOT_WATER]: '#AAD4D3',
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER]: '#96BFC4',
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER]: '#96BFC4',
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER]: '#96BFC4',
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER]: '#96BFC4',
  [UTILITY_TYPES.WIND]: '#A2B7A5',
  [UTILITY_TYPES.WOOD]: '#D5C4BB',
  [UTILITY_TYPES.WATER]: '#C4ECF1',
  [UTILITY_TYPES.OTHER]: '#a0a5aa'
}

// Target
export const TARGET_COLORS = {
  [CHART_SETTINGS.Target]: '#2E2E3A'
}

// Proposed
export const PROPOSED_COLORS = {
  [PROPOSED.Proposed]: '#62B58F'
}

// Secondary data
export const SECONDARY_DATA_COLORS = {
  [Y_AXIS_SECONDARY.AverageTemperature]: '#2E2E3A',
  [Y_AXIS_SECONDARY.Demand]: '#69262E',
  [Y_AXIS_SECONDARY.CoolingDegreeDays]: '#293B5F',
  [Y_AXIS_SECONDARY.HeatingDegreeDays]: '#2E2E3A'
}

// Intensity
export const INTENSITY_COLORS = {
  [Y_AXIS_PRIMARY_SECOND_LEVEL.EnergyUseIntensityEUI]: '#62B58F',
  [Y_AXIS_PRIMARY_SECOND_LEVEL.EnergyCostIntensityECI]: '#62B58F',
  [Y_AXIS_PRIMARY_SECOND_LEVEL.WaterUseIntensityWUI]: '#62B58F',
  [Y_AXIS_PRIMARY_SECOND_LEVEL.WaterCostIntensityWCI]: '#62B58F'
}
