import React from 'react'
import PropTypes from 'prop-types'
import styles from './CustomLegend.scss'

function CustomLegend({ dataSource }) {
  const total = dataSource.data.reduce((acc, { value }) => {
    return acc + (+value || 0)
  }, 0)
  if (!total) return null
  return (
    dataSource.data?.length > 0 && (
      <div className={styles.customLegend}>
        <table>
          <tbody>
            {dataSource.data.map(({ color, label, value }) => {
              const formattedValue = `${
                dataSource.chart.numberPrefix
              } ${value.toLocaleString('en-US', {
                notation: 'compact'
              })} ${dataSource.chart.numberSuffix}`
              const percentage = `${((value * 100) / total).toFixed(1)}%`
              return (
                <tr key={label}>
                  <td>
                    <span
                      className={styles.color}
                      style={{ backgroundColor: `${color}` }}
                    />
                  </td>
                  <td>
                    <span>{label}</span>
                  </td>
                  {dataSource.chart.showPercentValues === '1' ? (
                    <td className={styles.value}>
                      <span>{percentage}</span>
                    </td>
                  ) : (
                    <>
                      <td className={styles.value}>
                        <span>{formattedValue}</span>
                      </td>
                      <td className={styles.value}>
                        <span>({percentage})</span>
                      </td>
                    </>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  )
}

CustomLegend.propTypes = {
  dataSource: PropTypes.object.isRequired
}

export default CustomLegend
