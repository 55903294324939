import React from 'react'
import PropTypes from 'prop-types'
import ReactFC from 'react-fusioncharts'
import FusionCharts from 'fusioncharts'
import Charts from 'fusioncharts/fusioncharts.charts'
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import PowerCharts from 'fusioncharts/fusioncharts.powercharts'
import TreeMap from 'fusioncharts/fusioncharts.treemap'
import { Loader } from 'utils/Loader'
import { CHART_ERROR } from 'static/charts'
import ChartError from './ChartError'

const FUSION_CHARTS_LICENSE_KEY =
  '7f1E1hwF-7C11C2C7D3E3A2C2C2G1B6D7E6F4xkcD2I3trxfsC1C1jI-7oB1E6B1B3H3E2A11A19B7B4C6A2C1G4F4D3I-7acA5C5E3sA2A1A1feA-16E2D6G2zB-7mC2D6C8nvrG4E1C3B3C7D2C6B1D3B4H3uA-21D-16E4F4NB2qH-8jA3C3C1B-11jD1e1H3H4fd1qB5D1B3C1C1C1B3D9C1A1D1H5E3C2i=='

export const FUSION_CHARTS_TYPE = {
  Doughnut2D: 'doughnut2d',
  Pie2D: 'pie2d',
  MSCombiDY2D: 'mscombidy2d',
  MSCombi2D: 'mscombi2d',
  MSStackedColumn2DLineDY: 'msstackedcolumn2dlinedy',
  MSStackedColumn2D: 'msstackedcolumn2d',
  StackedColumn2DLineDY: 'stackedcolumn2dlinedy',
  StackedColumn2DLine: 'stackedcolumn2dline',
  StackedColumn2D: 'stackedcolumn2d',
  StackedArea2DLineDY: 'stackedarea2dlinedy',
  StackedArea2D: 'stackedarea2d',
  StackedBar2D: 'stackedbar2d',
  StackedBar2DWithLine: 'msstackedcolumn2dlinedy',
  TreeMap: 'treemap'
}

FusionCharts.options.license({
  key: FUSION_CHARTS_LICENSE_KEY,
  creditLabel: false
})
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme, PowerCharts, TreeMap)

function Chart({
  type,
  dataSource,
  width = '100%',
  height = '100%',
  dataFormat = 'json',
  isLoading = false,
  error
}) {
  return isLoading ? (
    <Loader centered />
  ) : error ? (
    <ChartError error={error} />
  ) : (
    <ReactFC
      type={type}
      dataSource={{
        ...dataSource,
        chart: {
          ...dataSource?.chart,
          labelFont: 'Muli',
          baseFont: 'Muli',
          outCnvBaseFont: 'Muli',
          baseChartMessageFont: 'Muli',
          legendItemFont: 'Muli'
        }
      }}
      width={width}
      height={height}
      dataFormat={dataFormat}
    />
  )
}

Chart.propTypes = {
  type: PropTypes.string,
  dataSource: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  dataFormat: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(CHART_ERROR)),
    PropTypes.bool
  ])
}

export default Chart
