import {
  CalendarSettingsDictionary,
  MonthsDictionary,
  MONTH_LIST
} from '../static/settings-constants'
export const defaultFiscalYearConfig = {
  fromMonth: MonthsDictionary.July,
  toMonth: MonthsDictionary.June
}

export const getOrganizationFiscalYearConfigOfOrganizations = (
  organizations = []
) =>
  organizations.reduce((acc, organization) => {
    const { fiscalYearConfig } = (organization.settings || {}).timeRange || {}
    if (
      fiscalYearConfig &&
      fiscalYearConfig.fromMonth &&
      fiscalYearConfig.toMonth
    ) {
      acc.push({
        fromMonth: fiscalYearConfig.fromMonth,
        toMonth: fiscalYearConfig.toMonth,
        name: organization.name
      })
    }
    return acc
  }, [])

// The fiscal year config is determined by priority
// First - Building config
// Second - Organization config
// Third - Default config
export const getFiscalYearConfigForBuilding = (organization, building) => {
  const fiscalConfigIsValid = timeRange =>
    timeRange?.fiscalYearConfig?.fromMonth &&
    timeRange?.fiscalYearConfig?.toMonth

  if (
    building?.baselineSettings?.timeRange?.type.toLowerCase() !==
      CalendarSettingsDictionary.fiscal ||
    !building?.baselineSettings?.timeRange
  ) {
    return
  }

  const buildingTimeRange = building?.baselineSettings?.timeRange

  if (buildingTimeRange) {
    return fiscalConfigIsValid(buildingTimeRange)
      ? buildingTimeRange.fiscalYearConfig
      : defaultFiscalYearConfig
  }

  const organizationTimeRange = organization?.settings?.timeRange

  if (organizationTimeRange) {
    return fiscalConfigIsValid(organizationTimeRange)
      ? organizationTimeRange.fiscalYearConfig
      : defaultFiscalYearConfig
  }
}

export const firstMonthIsLater = (firstMonth, secondMonth) => {
  const firstMonthIndex = MONTH_LIST.findIndex(month => month === firstMonth)
  const secondMonthIndex = MONTH_LIST.findIndex(month => month === secondMonth)
  return firstMonthIndex > secondMonthIndex
}

export const getUtilitiesInPeriod = (
  utilities,
  years,
  fiscalYearConfigs = null
) => {
  let fiscalYears = [...years]
  let monthlyUtilities = []

  let utilityIsInPeriod = utility => years.includes(utility.year)
  if (fiscalYearConfigs) {
    const { fromMonth, toMonth } = fiscalYearConfigs

    fiscalYears.sort()

    const initialYear = `${+fiscalYears[0] - 1}`
    const lastYear = years[years.length - 1]

    // Add the first year
    fiscalYears.splice(0, 0, initialYear)

    utilityIsInPeriod = utility => {
      const isIncludedInPeriod = fiscalYears.includes(utility.year)
      const isNotInLimitYear =
        utility.year !== initialYear && utility.year !== lastYear
      const isFirstYearAndAfterMonth =
        utility.year === initialYear &&
        (utility.month === fromMonth ||
          firstMonthIsLater(utility.month, fromMonth))

      const isLastYearAndBeforeMonth =
        utility.year === lastYear &&
        (utility.month === toMonth || firstMonthIsLater(toMonth, utility.month))
      return (
        isIncludedInPeriod &&
        (isNotInLimitYear ||
          isFirstYearAndAfterMonth ||
          isLastYearAndBeforeMonth)
      )
    }
  }
  utilities.forEach(utility => {
    if (utilityIsInPeriod(utility)) {
      monthlyUtilities.push(utility)
    }
  })
  return monthlyUtilities
}
