export const defaultFiscalYearConfig = {
  fromMonth: 'Jan',
  toMonth: 'Dec'
}

export const MonthsDictionary = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec'
}

export const CalendarSettingsDictionary = {
  calendar: 'calendar',
  fiscal: 'fiscal'
}

export const MONTH_LIST = [
  MonthsDictionary.January,
  MonthsDictionary.February,
  MonthsDictionary.March,
  MonthsDictionary.April,
  MonthsDictionary.May,
  MonthsDictionary.June,
  MonthsDictionary.July,
  MonthsDictionary.August,
  MonthsDictionary.September,
  MonthsDictionary.October,
  MonthsDictionary.November,
  MonthsDictionary.December
]
