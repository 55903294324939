import { isNullOrEmpty } from 'utils/helpers'

const DEFAULT_GHG_ELECTRICITY_VALUE = 0.000744
const DEFAULT_GHG_NATURAL_GAS_VALUE = 0.0053
const DEFAULT_GHG_FUEL_OIL_2 = 0.01021
const DEFAULT_GHG_FUEL_OIL_4 = 0.01096

export const UTILITY_TYPES = {
  ELECTRICITY: 'electric',
  SOLAR: 'solar',
  NATURAL_GAS: 'naturalgas',
  STEAM: 'steam',
  FUEL_OIL_1: 'fueloil1',
  FUEL_OIL_2: 'fueloil2',
  FUEL_OIL_4: 'fueloil4',
  FUEL_OIL_56: 'fueloil56',
  DIESEL: 'diesel',
  PROPANE: 'propane',
  KEROSENE: 'kerosene',
  DISTRICT_HOT_WATER: 'districtHotWater',
  DISTRICT_CHILLED_WATER_ELECTRIC_METER: 'districtChilledWaterElectricMeter',
  DISTRICT_CHILLED_WATER_ABSORPTION_METER:
    'districtChilledWaterAbsorptionMeter',
  DISTRICT_CHILLED_WATER_ENGINE_METER: 'districtChilledWaterEngineMeter',
  DISTRICT_CHILLED_WATER_OTHER_METER: 'districtChilledWaterOtherMeter',
  WIND: 'wind',
  WOOD: 'wood',
  WATER: 'water',
  OTHER: 'other'
}

export const CONSUMPTION_OR_DELIVERY = {
  CONSUMPTION: 'consumption',
  DELIVERY: 'delivery'
}

export const UTILITY_SOURCES = {
  METER: 'meter',
  DELIVERY: 'delivery'
}

export const UTILITY_SOURCES_PLURALS = {
  [UTILITY_SOURCES.METER]: 'meters',
  [UTILITY_SOURCES.DELIVERY]: 'deliveries'
}

export const UTILITY_TYPE_SOURCE_MAP = {
  [UTILITY_TYPES.ELECTRICITY]: UTILITY_SOURCES.METER,
  [UTILITY_TYPES.SOLAR]: UTILITY_SOURCES.METER,
  [UTILITY_TYPES.NATURAL_GAS]: UTILITY_SOURCES.METER,
  [UTILITY_TYPES.STEAM]: UTILITY_SOURCES.METER,
  [UTILITY_TYPES.FUEL_OIL_1]: UTILITY_SOURCES.DELIVERY,
  [UTILITY_TYPES.FUEL_OIL_2]: UTILITY_SOURCES.DELIVERY,
  [UTILITY_TYPES.FUEL_OIL_4]: UTILITY_SOURCES.DELIVERY,
  [UTILITY_TYPES.FUEL_OIL_56]: UTILITY_SOURCES.DELIVERY,
  [UTILITY_TYPES.DIESEL]: UTILITY_SOURCES.DELIVERY,
  [UTILITY_TYPES.PROPANE]: UTILITY_SOURCES.DELIVERY,
  [UTILITY_TYPES.KEROSENE]: UTILITY_SOURCES.DELIVERY,
  [UTILITY_TYPES.DISTRICT_HOT_WATER]: UTILITY_SOURCES.METER,
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER]: UTILITY_SOURCES.METER,
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER]:
    UTILITY_SOURCES.METER,
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER]: UTILITY_SOURCES.METER,
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER]: UTILITY_SOURCES.METER,
  [UTILITY_TYPES.WIND]: UTILITY_SOURCES.METER,
  [UTILITY_TYPES.WOOD]: UTILITY_SOURCES.DELIVERY,
  [UTILITY_TYPES.WATER]: UTILITY_SOURCES.METER,
  [UTILITY_TYPES.OTHER]: UTILITY_SOURCES.METER
}

export const UTILITY_TYPES_WITH_ALL = { ALL: 'all', ...UTILITY_TYPES }

export const UNIT_TYPES = {
  kWH: 'kWh',
  kW: 'kW',
  KG: 'kg',
  KLBS: 'KLbs',
  THERMS: 'therms',
  MLB: 'Mlb',
  GAL: 'Gal',
  GAL_UK: 'Gallons (UK)',
  GAL_US: 'Gallons (US)',
  CCF: 'ccf',
  CF: 'cf',
  CM: 'cm',
  CGAL_UK: 'cGal (UK)',
  CGAL_US: 'cGal (US)',
  GJ: 'GJ',
  kBTU: 'kBtu',
  kCF: 'kcf',
  kCM: 'kcm',
  kGAL_UK: 'kGal (UK)',
  kGAL_US: 'kGal (US)',
  MBTU: 'MBtu',
  MMBTU: 'MMBtu',
  MLBS: 'MLbs',
  MWH: 'MWh',
  DTH: 'Dth',
  MCF: 'Mcf',
  MGAL_UK: 'MGal (UK)',
  MGAL_US: 'MGal (US)',
  LITERS: 'Liters',
  LBS: 'Lbs',
  TONNES: 'Tonnes',
  TONS: 'Tons',
  TON_HOURS: 'ton hours',
  FT: 'ft',
  FT2: 'ft\u00B2',
  MTCO2E: 'mtCO2e'
}

export const ELECTRIC_DEMAND_UNITS_FROM_UTIL_UNITS = {
  [UNIT_TYPES.kWH]: UNIT_TYPES.kW,
  [UNIT_TYPES.GJ]: UNIT_TYPES.GJ,
  [UNIT_TYPES.kBTU]: UNIT_TYPES.kBTU,
  [UNIT_TYPES.MWH]: UNIT_TYPES.MWH,
  [UNIT_TYPES.MBTU]: UNIT_TYPES.MBTU,
  [UNIT_TYPES.MMBTU]: UNIT_TYPES.MMBTU,
  [UNIT_TYPES.DTH]: UNIT_TYPES.DTH
}

export const UTILITY_UNITS_OPTIONS = {
  [UTILITY_TYPES.ELECTRICITY]: [
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.kWH,
    UNIT_TYPES.MWH,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH
  ],
  [UTILITY_TYPES.SOLAR]: [
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.kWH,
    UNIT_TYPES.MWH,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH
  ],
  [UTILITY_TYPES.WIND]: [
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.kWH,
    UNIT_TYPES.MWH,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH
  ],
  [UTILITY_TYPES.NATURAL_GAS]: [
    UNIT_TYPES.CCF,
    UNIT_TYPES.CF,
    UNIT_TYPES.CM,
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.kCF,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH,
    UNIT_TYPES.MCF,
    UNIT_TYPES.THERMS
  ],
  [UTILITY_TYPES.STEAM]: [
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.KG,
    UNIT_TYPES.KLBS,
    UNIT_TYPES.MLBS,
    UNIT_TYPES.LBS,
    UNIT_TYPES.THERMS,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH
  ],
  [UTILITY_TYPES.FUEL_OIL_1]: [
    UNIT_TYPES.GAL,
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.LITERS,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH
  ],
  [UTILITY_TYPES.FUEL_OIL_2]: [
    UNIT_TYPES.GAL,
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.LITERS,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH
  ],
  [UTILITY_TYPES.FUEL_OIL_4]: [
    UNIT_TYPES.GAL,
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.LITERS,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH
  ],
  [UTILITY_TYPES.FUEL_OIL_56]: [
    UNIT_TYPES.GAL,
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.LITERS,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH
  ],
  [UTILITY_TYPES.DIESEL]: [
    UNIT_TYPES.GAL,
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.LITERS,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH
  ],
  [UTILITY_TYPES.PROPANE]: [
    UNIT_TYPES.CCF,
    UNIT_TYPES.CF,
    UNIT_TYPES.GAL,
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.kCF,
    UNIT_TYPES.LITERS,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH
  ],
  [UTILITY_TYPES.KEROSENE]: [
    UNIT_TYPES.GAL,
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.LITERS,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH
  ],
  [UTILITY_TYPES.DISTRICT_HOT_WATER]: [
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.THERMS,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH
  ],
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER]: [
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH,
    UNIT_TYPES.TON_HOURS
  ],
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER]: [
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH,
    UNIT_TYPES.TON_HOURS
  ],
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER]: [
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH,
    UNIT_TYPES.TON_HOURS
  ],
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER]: [
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH,
    UNIT_TYPES.TON_HOURS
  ],
  [UTILITY_TYPES.WOOD]: [
    UNIT_TYPES.GJ,
    UNIT_TYPES.kBTU,
    UNIT_TYPES.MBTU,
    UNIT_TYPES.MMBTU,
    UNIT_TYPES.DTH,
    UNIT_TYPES.TONNES,
    UNIT_TYPES.TONS
  ],
  [UTILITY_TYPES.WATER]: [
    UNIT_TYPES.CCF,
    UNIT_TYPES.CF,
    UNIT_TYPES.CGAL_UK,
    UNIT_TYPES.CGAL_US,
    UNIT_TYPES.CM,
    UNIT_TYPES.GAL_UK,
    UNIT_TYPES.GAL_US,
    UNIT_TYPES.kCF,
    UNIT_TYPES.kCM,
    UNIT_TYPES.kGAL_UK,
    UNIT_TYPES.kGAL_US,
    UNIT_TYPES.LITERS,
    UNIT_TYPES.MCF,
    UNIT_TYPES.MGAL_UK,
    UNIT_TYPES.MGAL_US
  ]
}

export const UNIT_DETAILS = {
  [UTILITY_TYPES.ELECTRICITY]: {
    icon: 'flash_on',
    title: 'Electricity',
    defaultUnit: UNIT_TYPES.kWH,
    isConsumption: true,
    ghgSettingKey: 'electricGHG',
    ghgFactor: DEFAULT_GHG_ELECTRICITY_VALUE,
    ghgEmissionsType: 'indirect'
  },
  [UTILITY_TYPES.SOLAR]: {
    icon: 'flash_on',
    title: 'On-Site Solar Generation',
    defaultUnit: UNIT_TYPES.kWH,
    isConsumption: true,
    ghgSettingKey: 'solarGHG',
    ghgFactor: DEFAULT_GHG_ELECTRICITY_VALUE,
    ghgEmissionsType: 'direct'
  },
  [UTILITY_TYPES.WIND]: {
    icon: 'flash_on',
    title: 'On-Site Wind Generation',
    defaultUnit: UNIT_TYPES.kWH,
    ghgSettingKey: 'windGHG',
    ghgFactor: DEFAULT_GHG_ELECTRICITY_VALUE,
    ghgEmissionsType: 'greenPower'
  },
  [UTILITY_TYPES.NATURAL_GAS]: {
    icon: 'whatshot',
    title: 'Natural Gas',
    defaultUnit: UNIT_TYPES.THERMS,
    isConsumption: true,
    ghgSettingKey: 'gasGHG',
    ghgFactor: DEFAULT_GHG_NATURAL_GAS_VALUE,
    ghgEmissionsType: 'direct'
  },
  [UTILITY_TYPES.STEAM]: {
    icon: 'scatter_plot',
    title: 'District Steam',
    defaultUnit: UNIT_TYPES.MLBS,
    isConsumption: true,
    ghgSettingKey: 'steamGHG',
    ghgFactor: 0.0000664,
    ghgEmissionsType: 'indirect'
  },
  [UTILITY_TYPES.FUEL_OIL_1]: {
    title: 'Fuel Oil 1',
    isFuel: true,
    defaultUnit: UNIT_TYPES.GAL,
    isConsumption: false,
    ghgSettingKey: 'fuelOil1GHG',
    ghgFactor: 0.0000735,
    ghgEmissionsType: 'direct'
  },
  [UTILITY_TYPES.FUEL_OIL_2]: {
    title: 'Fuel Oil 2',
    isFuel: true,
    defaultUnit: UNIT_TYPES.GAL,
    isConsumption: false,
    ghgSettingKey: 'fuelOil2GHG',
    ghgFactor: DEFAULT_GHG_FUEL_OIL_2,
    ghgEmissionsType: 'direct'
  },
  [UTILITY_TYPES.FUEL_OIL_4]: {
    title: 'Fuel Oil 4',
    isFuel: true,
    defaultUnit: UNIT_TYPES.GAL,
    isConsumption: false,
    ghgSettingKey: 'fuelOil4GHG',
    ghgFactor: DEFAULT_GHG_FUEL_OIL_4,
    ghgEmissionsType: 'direct'
  },
  [UTILITY_TYPES.FUEL_OIL_56]: {
    title: 'Fuel Oil 5 & 6',
    isFuel: true,
    defaultUnit: UNIT_TYPES.GAL,
    isConsumption: false,
    ghgSettingKey: 'fuelOil56GHG',
    ghgFactor: 0.00007535,
    ghgEmissionsType: 'direct'
  },
  [UTILITY_TYPES.DIESEL]: {
    title: 'Diesel',
    isFuel: true,
    defaultUnit: UNIT_TYPES.GAL,
    isConsumption: false,
    ghgSettingKey: 'dieselGHG',
    ghgFactor: 0.00007535,
    ghgEmissionsType: 'direct'
  },
  [UTILITY_TYPES.KEROSENE]: {
    title: 'Kerosene',
    isFuel: true,
    defaultUnit: UNIT_TYPES.GAL,
    isConsumption: false,
    ghgSettingKey: 'keroseneGHG',
    ghgFactor: 0.00007769,
    ghgEmissionsType: 'direct'
  },
  [UTILITY_TYPES.PROPANE]: {
    title: 'Propane',
    isFuel: true,
    defaultUnit: UNIT_TYPES.GAL,
    isConsumption: false,
    ghgSettingKey: 'propaneGHG',
    ghgFactor: 0.00006425,
    ghgEmissionsType: 'direct'
  },
  [UTILITY_TYPES.DISTRICT_HOT_WATER]: {
    icon: 'water',
    title: 'District Hot Water',
    defaultUnit: UNIT_TYPES.kBTU,
    isConsumption: true,
    ghgSettingKey: 'districtHotWaterGHG',
    ghgFactor: 0.0000664,
    ghgEmissionsType: 'indirect'
  },
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER]: {
    icon: 'water',
    title: 'District Chilled Water Electric Meter',
    defaultUnit: UNIT_TYPES.TON_HOURS,
    isConsumption: true,
    ghgSettingKey: 'districtChilledWaterElectricMeterGHG',
    ghgFactor: 0.0000527,
    ghgEmissionsType: 'direct'
  },
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER]: {
    icon: 'water',
    title: 'District Chilled Water Absorption Meter',
    defaultUnit: UNIT_TYPES.TON_HOURS,
    isConsumption: true,
    ghgSettingKey: 'districtChilledWaterAbsorptionMeterGHG',
    ghgFactor: 0.00007389,
    ghgEmissionsType: 'indirect'
  },
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER]: {
    icon: 'water',
    title: 'District Chilled Water Engine Meter',
    defaultUnit: UNIT_TYPES.TON_HOURS,
    isConsumption: true,
    ghgSettingKey: 'districtChilledWaterEngineMeterGHG',
    ghgFactor: 0.00004931,
    ghgEmissionsType: 'indirect'
  },
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER]: {
    icon: 'water',
    title: 'District Chilled Water Other Meter',
    defaultUnit: UNIT_TYPES.TON_HOURS,
    isConsumption: true,
    ghgSettingKey: 'districtChilledWaterOtherMeterGHG',
    ghgFactor: 0.00004931,
    ghgEmissionsType: 'indirect'
  },
  [UTILITY_TYPES.WOOD]: {
    icon: 'forest',
    title: 'Wood',
    defaultUnit: UNIT_TYPES.TONS,
    isConsumption: true,
    ghgSettingKey: 'woodGHG',
    ghgEmissionsType: 'biomass'
  },
  [UTILITY_TYPES.WATER]: {
    icon: 'water',
    title: 'Water',
    defaultUnit: UNIT_TYPES.CCF,
    isConsumption: true,
    ghgSettingKey: 'waterGHG',
    ghgEmissionsType: ''
  }
}

export const UNIT_DETAILS_WITH_ALL = {
  [UTILITY_TYPES_WITH_ALL.ALL]: {
    title: 'All'
  },
  ...UNIT_DETAILS
}

export const DISABLED_FUEL_TYPES = ['Water', 'Wood']
export const HIDE_GHG_FACTOR = ['electric', 'solar', 'wind', 'water']

// These are the factors that a value in {unit_type} should be multiplied to convert to kBtu
// For example:
//     -  1 MBTU - 1000 kBtu
//     -  1 KWH  - 3.412 kBtu
//     -  1 GJ   - 947.817 kBtu

// const ENERGY_UNIT_KBTU_FACTOR = {
//   [UNIT_TYPES.CM]: 36.303,
//   [UNIT_TYPES.GJ]: 947.817,
//   [UNIT_TYPES.kBTU]: 1,
//   [UNIT_TYPES.kCF]: 1026,
//   [UNIT_TYPES.KG]: 2.632,
//   [UNIT_TYPES.KLBS]: 1194,
//   [UNIT_TYPES.kWH]: 3.412,
//   [UNIT_TYPES.LBS]: 1.194,
//   [UNIT_TYPES.MBTU]: 1000,
//   [UNIT_TYPES.MCF]: 1026,
//   [UNIT_TYPES.MLBS]: 1194000,
//   [UNIT_TYPES.MWH]: 3412,
//   [UNIT_TYPES.THERMS]: 100,
//   [UNIT_TYPES.TON_HOURS]: 12.0,
//   [UNIT_TYPES.TONNES]: 15857,
//   [UNIT_TYPES.TONS]: 17480,
//   [UNIT_TYPES.DTH]: 1000
// }

const ENERGY_UNIT_KBTU_FACTOR = {
  [UNIT_TYPES.CM]: 36.303,
  [UNIT_TYPES.GJ]: 947.817,
  [UNIT_TYPES.kBTU]: 1,
  [UNIT_TYPES.kCF]: 1026,
  [UNIT_TYPES.KG]: 2.632,
  [UNIT_TYPES.KLBS]: 1194,
  [UNIT_TYPES.kWH]: 3.412,
  [UNIT_TYPES.LBS]: 1.194,
  [UNIT_TYPES.MBTU]: 1000,
  [UNIT_TYPES.MCF]: 1026,
  [UNIT_TYPES.MLBS]: 1194000,
  [UNIT_TYPES.MWH]: 3412,
  [UNIT_TYPES.THERMS]: 100,
  [UNIT_TYPES.TON_HOURS]: 12.0,
  [UNIT_TYPES.TONNES]: 15857,
  [UNIT_TYPES.TONS]: 17480,
  [UNIT_TYPES.DTH]: 1000
}

const UTILITY_UNIT_KBTU_FACTOR = {
  [UTILITY_TYPES.ELECTRICITY]: {
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.MBTU]: 1000,
    [UNIT_TYPES.kWH]: 3.412,
    [UNIT_TYPES.MWH]: 3412,
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.DTH]: 1000
  },
  [UTILITY_TYPES.SOLAR]: {
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.kWH]: 3.412,
    [UNIT_TYPES.MWH]: 3412,
    [UNIT_TYPES.MBTU]: 1000
  },
  [UTILITY_TYPES.WIND]: {
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.kWH]: 3.412,
    [UNIT_TYPES.MWH]: 3412,
    [UNIT_TYPES.MBTU]: 1000
  },
  [UTILITY_TYPES.NATURAL_GAS]: {
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.MBTU]: 1000,
    [UNIT_TYPES.CF]: 1.026,
    [UNIT_TYPES.CCF]: 102.6,
    [UNIT_TYPES.kCF]: 1026,
    [UNIT_TYPES.MCF]: 10260,
    [UNIT_TYPES.THERMS]: 100,
    [UNIT_TYPES.CM]: 36.303,
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.DTH]: 1000
  },
  [UTILITY_TYPES.STEAM]: {
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.KG]: 2.632,
    [UNIT_TYPES.KLBS]: 1194,
    [UNIT_TYPES.MLBS]: 1194000,
    [UNIT_TYPES.LBS]: 1.194,
    [UNIT_TYPES.THERMS]: 100,
    [UNIT_TYPES.MBTU]: 1000
  },
  [UTILITY_TYPES.FUEL_OIL_1]: {
    [UNIT_TYPES.GAL]: 139,
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.LITERS]: 36.72,
    [UNIT_TYPES.MBTU]: 1000
  },
  [UTILITY_TYPES.FUEL_OIL_2]: {
    [UNIT_TYPES.GAL]: 138,
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.LITERS]: 36.456,
    [UNIT_TYPES.MBTU]: 1000
  },
  [UTILITY_TYPES.FUEL_OIL_4]: {
    [UNIT_TYPES.GAL]: 146,
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.LITERS]: 36.456,
    [UNIT_TYPES.MBTU]: 1000
  },
  [UTILITY_TYPES.FUEL_OIL_56]: {
    [UNIT_TYPES.GAL]: 150,
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.LITERS]: 39.626,
    [UNIT_TYPES.MBTU]: 1000
  },
  [UTILITY_TYPES.DIESEL]: {
    [UNIT_TYPES.GAL]: 138,
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.LITERS]: 36.456,
    [UNIT_TYPES.MBTU]: 1000
  },
  [UTILITY_TYPES.PROPANE]: {
    [UNIT_TYPES.CCF]: 251.6,
    [UNIT_TYPES.CF]: 251.6,
    [UNIT_TYPES.GAL]: 92,
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.kCF]: 2516,
    [UNIT_TYPES.LITERS]: 24.304,
    [UNIT_TYPES.MBTU]: 1000
  },
  [UTILITY_TYPES.KEROSENE]: {
    [UNIT_TYPES.GAL]: 135,
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.LITERS]: 35.663,
    [UNIT_TYPES.MBTU]: 1000
  },
  [UTILITY_TYPES.DISTRICT_HOT_WATER]: {
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.THERMS]: 100,
    [UNIT_TYPES.MBTU]: 1000
  },
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER]: {
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.MBTU]: 1000,
    [UNIT_TYPES.TON_HOURS]: 12.0
  },
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER]: {
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.MBTU]: 1000,
    [UNIT_TYPES.TON_HOURS]: 12.0
  },
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER]: {
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.MBTU]: 1000,
    [UNIT_TYPES.TON_HOURS]: 12.0
  },
  [UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER]: {
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.MBTU]: 1000,
    [UNIT_TYPES.TON_HOURS]: 12.0
  },
  [UTILITY_TYPES.WOOD]: {
    [UNIT_TYPES.GJ]: 947.817,
    [UNIT_TYPES.kBTU]: 1,
    [UNIT_TYPES.MBTU]: 1000,
    [UNIT_TYPES.TONNES]: 15857,
    [UNIT_TYPES.TONS]: 17480
  }
}

export const WATER_UNIT_CCF_FACTOR = {
  [UNIT_TYPES.CCF]: 1,
  [UNIT_TYPES.CF]: 100,
  [UNIT_TYPES.kCF]: 100000,
  [UNIT_TYPES.MCF]: 100000000,
  [UNIT_TYPES.CM]: 2.83168466,
  [UNIT_TYPES.kCM]: 0.00283168466,
  [UNIT_TYPES.LITERS]: 0.000353147,
  [UNIT_TYPES.CGAL_US]: 0.000013368056,
  [UNIT_TYPES.GAL_US]: 0.0013368056,
  [UNIT_TYPES.kGAL_US]: 1.3368056,
  [UNIT_TYPES.MGAL_US]: 1336.8056,
  [UNIT_TYPES.CGAL_UK]: 0.000016054365,
  [UNIT_TYPES.GAL_UK]: 0.0016054365,
  [UNIT_TYPES.kGAL_UK]: 1.6054365,
  [UNIT_TYPES.MGAL_UK]: 1605.4365
}

export const getConversionFactorsTokBtu = (utilityType, unit = '') => {
  return UTILITY_UNIT_KBTU_FACTOR[utilityType]?.[unit] || null
}

export const getConversionFactorsToCCF = (unit = '') => {
  return WATER_UNIT_CCF_FACTOR?.[unit] || null
}

export const getDefaultCommoditySettings = () => {
  return Object.entries(UNIT_DETAILS).reduce((agg, [commodity, details]) => {
    agg[commodity] = {
      unit: details.defaultUnit,
      [EMISSION_FACTOR.Site]: details.ghgFactor || 0,
      [EMISSION_FACTOR.Source]: details.ghgFactor || 0
    }
    return agg
  }, {})
}

export const convertValueFromUnitAToUnitB = (value, type, unitA, unitB) => {
  if (value) {
    let conversionAFactor = getConversionFactorsTokBtu(type, unitA) || 1
    let conversionBFactor = getConversionFactorsTokBtu(type, unitB) || 1
    if (type === UTILITY_TYPES.WATER) {
      conversionAFactor = getConversionFactorsToCCF(unitA) || 1
      conversionBFactor = getConversionFactorsToCCF(unitB) || 1
    }
    const newValue = (value * conversionAFactor) / conversionBFactor
    if (!isNaN(newValue)) return newValue
  }
  return null
}

export const getCommodityUnits = commoditySettings => {
  const commodityUnits = {}
  for (let key in commoditySettings) {
    commodityUnits[key] = {
      unit: commoditySettings[key].unit
    }
  }
  return commodityUnits
}

export const defaultCommoditySettingsUnits = Object.keys(UNIT_DETAILS).reduce(
  (acc, key) => {
    const unit = UNIT_DETAILS[key].defaultUnit
    acc[key] = { unit }
    return acc
  },
  {}
)

/**
 * Determines the utility unit TO BE SHOWN on building tables for each utility.
 * If there are no utilities uploaded for the utility, it uses organization commodity settings.
 * If the organization commodity settings are undefined, it uses Buildee default units.
 *
 * @param {Object} utilities - The utilities object containing utility data.
 * @param {Object} organization - The organization object containing organization settings.
 * @returns {Object} - An object with utility types as keys and their respective units as values.
 */
export const getUtilitiesUnits = (utilities, organization) => {
  const units = {}

  // Get organization commodity settings
  const organizationCommoditySettings = organization.settings.commodity

  // Iterate over utilities
  Object.keys(UNIT_DETAILS).forEach(utility => {
    // Determine unit for each utility based on uploaded utilities
    units[utility] =
      utilities?.[utility]?.[0].units ||
      organizationCommoditySettings?.[utility]?.unit ||
      UNIT_DETAILS[utility].defaultUnit
  })

  return units
}

export const EMISSION_FACTOR = {
  Site: 'siteEmissionFactor',
  Source: 'sourceEmissionFactor'
}
