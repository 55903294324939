import { UTILITY_TYPES } from './utility-units'

export default [
  { label: 'Electric', value: 'electric' },
  { label: 'Fuel Oil 2', value: 'fuel-oil-2' },
  { label: 'Fuel Oil 5 & 6', value: 'fuel-oil-5-6' },
  { label: 'Natural Gas', value: 'natural-gas' },
  { label: 'Fuel Oil 4', value: 'fuel-oil-4' },
  { label: 'Water', value: 'water' },
  { label: 'Steam', value: 'steam' },
  { label: 'Solar', value: 'solar' },
  { label: 'Propane', value: 'propane' },
  { label: 'Kerosene', value: 'kerosene' },
  { label: 'District Hot Water', value: 'districtHotWater' },
  {
    label: 'District Chilled Water Electric Meter',
    value: 'districtChilledWaterElectricMeter'
  },
  {
    label: 'District Chilled Water Absorption Meter',
    value: 'districtChilledWaterAbsorptionMeter'
  },
  {
    label: 'District Chilled Water Engine Meter',
    value: 'districtChilledWaterEngineMeter'
  },
  {
    label: 'District Chilled Water Other Meter',
    value: 'districtChilledWaterOtherMeter'
  },
  { label: 'Wind', value: 'wind' },
  { label: 'Wood', value: 'wood' },
  { label: 'Diesel', value: 'diesel' },
  { label: 'Other', value: 'other' }
]

export const MEASURE_FUEL_TO_UTILITY_FUEL = Object.freeze({
  electric: UTILITY_TYPES.ELECTRICITY,
  stream: UTILITY_TYPES.STEAM,
  gas: UTILITY_TYPES.NATURAL_GAS,
  fuelOil2: UTILITY_TYPES.FUEL_OIL_2,
  fuelOil4: UTILITY_TYPES.FUEL_OIL_4,
  fuelOil56: UTILITY_TYPES.FUEL_OIL_56,
  diesel: UTILITY_TYPES.DIESEL,
  water: UTILITY_TYPES.WATER,
  other: UTILITY_TYPES.OTHER,
  propane: UTILITY_TYPES.PROPANE,
  kerosene: UTILITY_TYPES.KEROSENE,
  districtHotWater: UTILITY_TYPES.DISTRICT_HOT_WATER,
  districtChilledWaterElectricMeter:
    UTILITY_TYPES.DISTRICT_CHILLED_WATER_ELECTRIC_METER,
  districtChilledWaterAbsorptionMeter:
    UTILITY_TYPES.DISTRICT_CHILLED_WATER_ABSORPTION_METER,
  districtChilledWaterEngineMeter:
    UTILITY_TYPES.DISTRICT_CHILLED_WATER_ENGINE_METER,
  districtChilledWaterOtherMeter:
    UTILITY_TYPES.DISTRICT_CHILLED_WATER_OTHER_METER,
  wind: UTILITY_TYPES.WIND,
  wood: UTILITY_TYPES.WOOD
})
