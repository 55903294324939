import { UNIT_DETAILS } from 'static/utility-units'
import {
  COLOR_SECTIONS,
  ASHRAE_EUB_COLORS,
  CBECS_RECS_EUB_COLORS,
  WATER_EUB_COLORS,
  BASELINE_COLORS,
  PROPOSED_COLORS,
  TARGET_COLORS,
  UTILITIES_COLORS_WITH_ALL,
  INTENSITY_COLORS,
  SECONDARY_DATA_COLORS
} from 'static/default-chart-colors'
import {
  CHART_SETTINGS,
  PROPOSED,
  Y_AXIS_PRIMARY_SECOND_LEVEL
} from 'static/charts'
import { secondaryDataLabels } from 'static/secondary-data'

export const colorTitleLabels = {
  [COLOR_SECTIONS.ASHRAE_END_USES]: 'ASHRAE Level II End Uses',
  [COLOR_SECTIONS.BASELINE]: 'Baseline',
  [COLOR_SECTIONS.CBECS_RECS_END_USES]: 'CBECS/RECS End Uses',
  [COLOR_SECTIONS.SECONDARY_DATA]: 'Secondary Data',
  [COLOR_SECTIONS.TARGET]: 'Target',
  [COLOR_SECTIONS.UTILITIES]: 'Utilities',
  [COLOR_SECTIONS.WATER_END_USES]: 'Water End Uses',
  [COLOR_SECTIONS.INTENSITY]: 'Usage and Cost Intensity',
  [COLOR_SECTIONS.PROPOSED]: 'Metric'
}

export const baselineColors = [
  {
    label: 'Baseline',
    value: CHART_SETTINGS.Baseline,
    color: BASELINE_COLORS[CHART_SETTINGS.Baseline]
  },
  ...Object.entries(UNIT_DETAILS).map(([key, value]) => {
    return {
      label: value.title,
      value: key,
      color: BASELINE_COLORS[key]
    }
  })
]

export const targetColors = [
  {
    label: 'Target',
    value: CHART_SETTINGS.Target,
    color: TARGET_COLORS[CHART_SETTINGS.Target]
  }
]

export const proposedColors = [
  {
    label: 'Proposed',
    value: PROPOSED.Proposed,
    color: PROPOSED_COLORS[PROPOSED.Proposed]
  }
]

export const utilityColors = Object.entries(UNIT_DETAILS).map(
  ([key, value]) => {
    return {
      label: value.title,
      value: key,
      color: UTILITIES_COLORS_WITH_ALL[key].primary
    }
  }
)
export const ashraeLevelIIEndUsesColors = Object.entries(ASHRAE_EUB_COLORS).map(
  ([key, value]) => ({
    label: key,
    value: key,
    color: value
  })
)
export const cbecsRecsEndUsesColors = Object.entries(CBECS_RECS_EUB_COLORS).map(
  ([key, value]) => ({
    label: key,
    value: key,
    color: value
  })
)
export const waterEndUsesColors = Object.entries(WATER_EUB_COLORS).map(
  ([key, value]) => ({
    label: key,
    value: key,
    color: value
  })
)
export const secondaryDataColors = Object.entries(SECONDARY_DATA_COLORS).map(
  ([key, value]) => ({
    label: secondaryDataLabels[key],
    value: key,
    color: value
  })
)
export const intensityColors = [
  {
    value: Y_AXIS_PRIMARY_SECOND_LEVEL.EnergyUseIntensityEUI,
    label: 'Energy Use Intensity (EUI)',
    color: INTENSITY_COLORS[Y_AXIS_PRIMARY_SECOND_LEVEL.EnergyUseIntensityEUI]
  },
  {
    value: Y_AXIS_PRIMARY_SECOND_LEVEL.EnergyCostIntensityECI,
    label: 'Energy Cost Intensity (ECI)',
    color: INTENSITY_COLORS[Y_AXIS_PRIMARY_SECOND_LEVEL.EnergyCostIntensityECI]
  },
  {
    value: Y_AXIS_PRIMARY_SECOND_LEVEL.WaterUseIntensityWUI,
    label: 'Water Use Intensity (WUI)',
    color: INTENSITY_COLORS[Y_AXIS_PRIMARY_SECOND_LEVEL.WaterUseIntensityWUI]
  },
  {
    value: Y_AXIS_PRIMARY_SECOND_LEVEL.WaterCostIntensityWCI,
    label: 'Water Cost Intensity (WCI)',
    color: INTENSITY_COLORS[Y_AXIS_PRIMARY_SECOND_LEVEL.WaterCostIntensityWCI]
  }
]

export const defaultChartColorOptions = {
  [COLOR_SECTIONS.UTILITIES]: utilityColors,
  [COLOR_SECTIONS.ASHRAE_END_USES]: ashraeLevelIIEndUsesColors,
  [COLOR_SECTIONS.CBECS_RECS_END_USES]: cbecsRecsEndUsesColors,
  [COLOR_SECTIONS.WATER_END_USES]: waterEndUsesColors,
  [COLOR_SECTIONS.SECONDARY_DATA]: secondaryDataColors,
  [COLOR_SECTIONS.BASELINE]: baselineColors,
  [COLOR_SECTIONS.TARGET]: targetColors,
  [COLOR_SECTIONS.INTENSITY]: intensityColors,
  [COLOR_SECTIONS.PROPOSED]: proposedColors
}
