const buildingTypes = [
  { value: 'adult-education', name: 'Adult Education' },
  { value: 'aquarium', name: 'Aquarium' },
  { value: 'automobile-dealership', name: 'Automobile Dealership' },
  { value: 'bank-branch', name: 'Bank Branch' },
  { value: 'bar', name: 'Bar/Nightclub' },
  { value: 'barracks', name: 'Barracks' },
  { value: 'bowling-alley', name: 'Bowling Alley' },
  { value: 'casino', name: 'Casino' },
  { value: 'college', name: 'College/University' },
  {
    value: 'convenience-store-gas',
    name: 'Convenience Store with Gas Station'
  },
  { value: 'convenience-store', name: 'Convenience Store without Gas Station' },
  { value: 'convention-center', name: 'Convention Center' },
  { value: 'courthouse', name: 'Courthouse' },
  { value: 'data-center', name: 'Data Center' },
  { value: 'distribution-center', name: 'Distribution Center' },
  { value: 'water-treatment', name: 'Drinking water Treatment & Distribution' },
  { value: 'enclosed-mall', name: 'Enclosed Mall' },
  { value: 'energy-station', name: 'Energy/Power Station' },
  { value: 'fast-food', name: 'Fast Food Restaurants' },
  { value: 'financial-office', name: 'Financial Office' },
  { value: 'fire-station', name: 'Fire Station' },
  { value: 'fitness-center', name: 'Fitness Center/Health Club/GYM' },
  { value: 'food-sales', name: 'Food Sales' },
  { value: 'food-services', name: 'Food Services' },
  { value: 'hospital', name: 'Hospital (General Medical &Surgical)' },
  { value: 'hotel', name: 'Hotel' },
  { value: 'ice-rink', name: 'Ice/Curling Rink' },
  { value: 'indoor-arena', name: 'Indoor Arena' },
  { value: 'school', name: 'K-12 School' },
  { value: 'laboratory', name: 'Laboratory' },
  { value: 'library', name: 'Library' },
  { value: 'lifestyle-center', name: 'Lifestyle Center' },
  { value: 'mailing-center', name: 'Mailing center/Post office' },
  { value: 'manufacturing-plant', name: 'Manufacturing/Industrial Plant' },
  { value: 'medical-office', name: 'Medical Office' },
  { value: 'microbreweries', name: 'Microbreweries' },
  { value: 'mixed-use', name: 'Mixed Use Property' },
  { value: 'movie-theater', name: 'Movie Theater' },
  { value: 'multifamily-housing', name: 'Multifamily Housing' },
  { value: 'museum', name: 'Museum' },
  { value: 'non-refrigerated', name: 'Non-Refrigerated Warehouse' },
  { value: 'office', name: 'Office' },
  { value: 'other-education', name: 'Other - Education' },
  {
    value: 'other-entertainment',
    name: 'Other - Entertainment/Public Assembly'
  },
  { value: 'other-lodging/Residential', name: 'Other - Lodging/Residential' },
  { value: 'other-office', name: 'Other - Office' },
  { value: 'other-other', name: 'Other - Other' },
  { value: 'other-public', name: 'Other - Public Service' },
  { value: 'other-recreation', name: 'Other - Recreation ' },
  { value: 'other-restaurant', name: 'Other - Restaurant/Bar' },
  { value: 'other-retail', name: 'Other - Retail/Mall' },
  { value: 'other-services', name: 'Other - Services' },
  { value: 'other-hospital', name: 'Other - Specialty Hospital' },
  { value: 'other-stadium', name: 'Other - Stadium' },
  { value: 'other-technology', name: 'Other - Technology/Science' },
  { value: 'other-utility', name: 'Other - Utility' },
  {
    value: 'outpatient-rehabilitation',
    name: 'Outpatient Rehabilitation/Physical Therapy'
  },
  { value: 'performing-arts', name: 'Performing Arts' },
  {
    value: 'personal-services',
    name: 'Personal Services (Health/Beauty, Dry Cleaning, etc'
  },
  { value: 'police-station', name: 'Police Station' },
  { value: 'pre-school', name: 'Pre-school/Daycare' },
  { value: 'prison', name: 'Prison/Incarceration' },
  { value: 'race-track', name: 'Race Track' },
  { value: 'refrigerated-warehouse', name: 'Refrigerated Warehouse' },
  {
    value: 'repair-services',
    name: 'Repair Services (Vehicle, Shoe, Locksmith, etc)'
  },
  { value: 'residential-care', name: 'Residential Care Facility' },
  { value: 'residential-hall', name: 'Residential Hall/Dormitory' },
  { value: 'restaurants', name: 'Restaurants' },
  { value: 'retail-store', name: 'Retail Store' },
  { value: 'roller-rink', name: 'Roller Rink' },
  { value: 'self-storage', name: 'Self-storage Facility' },
  { value: 'senior-care', name: 'Senior Care Community' },
  { value: 'social-hall', name: 'Social/Meeting Hall' },
  { value: 'stadium-closed', name: 'Stadium (Closed)' },
  { value: 'stadium-open', name: 'Stadium (Open)' },
  { value: 'strip-mall', name: 'Strip Mall' },
  { value: 'supermarket', name: 'Supermarket/ Grocery Store' },
  { value: 'transportation-terminal', name: 'Transportation Terminal/Station' },
  { value: 'urgent-care', name: 'Urgent Care/Clinic/Other Outpatient' },
  { value: 'veterinary-office', name: 'Veterinary Office' },
  { value: 'vocational-school', name: 'Vocational School' },
  { value: 'wastewater-treatment-plant', name: 'Wastewater Treatment Plant' },
  { value: 'wholesale-club', name: 'Wholesale Club/Supercenter' },
  { value: 'worship-facility', name: 'Worship Facility' },
  { value: 'zoo', name: 'Zoo' },
  { value: 'parking', name: 'Parking' },
  { value: 'swimming-pool', name: 'Swimming Pool' },
  { value: 'mobile-home', name: 'Mobile home' },
  { value: 'single-family-detached', name: 'Single-family detached house' },
  { value: 'single-family-attached', name: 'Single-family attached house' },
  {
    value: 'apartment-small',
    name: 'Apartment in a building with 2 to 4 units'
  },
  {
    value: 'apartment-medium',
    name: 'Apartment in a building with 5 or more units'
  }
]

export const waterEUBBuildingTypes = [
  'apartment-small',
  'apartment-medium',
  'mobile-home',
  'single-family-attached',
  'single-family-detached'
]

export function showWaterEUBByBuildingTypes(primaryBuildingType) {
  return waterEUBBuildingTypes.findIndex(o => o === primaryBuildingType) >= 0
}

// Use types previously in location-types.js which are NOT in building-types.js, or
// are spelled differently.
// export default [
//   { name: 'House of Worship', value: 'house-of-worship' },
//   { name: 'Residence Hall/Dorm', value: 'residence-hall' },
//   { name: 'Retail', value: 'retail' },
//   { name: 'Senior Care Facility', value: 'senior-care-facility' },
//   { name: 'Supermarket/Grocery', value: 'supermarket' },
//   { name: 'Warehouse', value: 'warehouse' },
//   { name: 'Other', value: 'other' }
// ]
export default buildingTypes.sort((a, b) => (a.name > b.name ? 1 : -1))
