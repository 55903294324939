import React from 'react'
import ProjectContentItem from './ProjectContentItem'

const ProjectDescriptionContentItem = ({ onRemove }) => {
  return (
    <ProjectContentItem
      isRemovable
      renderHeaderSection={() => <div>Description</div>}
      handleContentRemoved={onRemove}
    />
  )
}

export default ProjectDescriptionContentItem
