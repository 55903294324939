import { ENABLED_FEATURES } from '../graphql/queries/user'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import React from 'react'

const UserFeature = ({ name = '', children }) => {
  return (
    <Query query={ENABLED_FEATURES} fetchPolicy='cache-and-network'>
      {({ loading, error, data }) => {
        const renderProps = {
          loading,
          error,
          enabled: false
        }

        if (!data || !data.enabledFeatures) {
          return children(renderProps)
        }

        renderProps.enabled = !!data.enabledFeatures.find(
          feature => feature.name === name
        )

        return children(renderProps)
      }}
    </Query>
  )
}

UserFeature.propTypes = {
  children: PropTypes.func.isRequired,
  name: PropTypes.string
}

export default UserFeature
