import React, { useCallback, useState } from 'react'
import ProjectContentItem from './ProjectContentItem'
import styles from './ProjectCategoryContentItem.scss'
import projectStyles from '../../Projects.scss'
import classNames from 'classnames'
import BaseModal from '../../../../../Modals/BaseModal'
import ProjectOrdering from '../../ProjectOrdering'

const headingStyle = [
  { name: 'Heading 1', value: 'h1' },
  { name: 'Heading 2', value: 'h2' },
  { name: 'Heading 3', value: 'h3' },
  { name: 'Heading 4', value: 'h4' },
  { name: 'Heading 5', value: 'h5' },
  { name: 'Heading 6', value: 'h6' }
]

const ProjectCategoryDescriptionModal = ({ onClose, projectConfig, index }) => {
  return (
    <BaseModal
      header='Category Descriptions'
      body={
        <ProjectOrdering filteringFields={projectConfig.filter} index={index} />
      }
      footer={
        <button
          className={classNames(
            projectStyles.button,
            projectStyles.buttonPrimary,
            styles.button
          )}
          onClick={onClose}
        >
          DONE
        </button>
      }
      onClose={onClose}
      className={styles}
    />
  )
}

const ProjectCategoryContentItem = ({
  projectConfig,
  index,
  projectIndex,
  onRemove,
  onUpdateProjectConfig
}) => {
  const [showModal, toggleOrderingModel] = useState(false)
  const onSelectedHeadingStyle = useCallback(
    event => {
      projectConfig.styles = {
        ...(projectConfig.styles || {}),
        chs: event.target.value
      }
      onUpdateProjectConfig(projectConfig)
    },
    [onUpdateProjectConfig, projectConfig, index]
  )
  return (
    <ProjectContentItem
      isRemovable
      isCollapsible
      renderHeaderSection={() => <div>Category Headings</div>}
      handleContentRemoved={onRemove}
      renderCollapsibleSection={() => (
        <div className={styles.container}>
          <div className={styles.subContainer}>
            <div className={styles.subHeader}>Headings Styles</div>
            <div className={styles.styleContainer}>
              <span>Start With</span>
              <div className={styles.selectContainer}>
                <select
                  value={projectConfig?.styles.chs || 'select'}
                  onChange={onSelectedHeadingStyle}
                >
                  <option disabled value='select'>
                    Select Heading
                  </option>
                  {headingStyle.map((item, i) => {
                    return (
                      <option key={i} value={item.value}>
                        {item.name}{' '}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className={styles.subContainer}>
            <div className={styles.subHeader}>Category Descriptions</div>
            <button
              className={classNames(
                projectStyles.button,
                projectStyles.buttonPrimary,
                styles.button
              )}
              onClick={() => toggleOrderingModel(true)}
            >
              EDIT
            </button>
            {showModal && (
              <ProjectCategoryDescriptionModal
                onClose={() => toggleOrderingModel(false)}
                index={projectIndex}
                projectConfig={projectConfig}
              />
            )}
          </div>
        </div>
      )}
    />
  )
}

export default ProjectCategoryContentItem
