import ApiClient from '../../../utils/ApiClient'
import { GET_TASK_LIST_BY_ID_SUCCESS } from './taskList'
import { UPDATE_BUILDING_VIEW_TASKS_LISTS } from 'routes/Building/modules/building'
import { showFlash as flash } from 'utils/Flash/modules/flash'
import { updatePortfolioReload } from 'routes/Portfolio/modules/portfolio'

// ------------------------------------
// Constants
// ------------------------------------

export const CLEAR_TASK_BUILDING = 'TASK_BUILDING/CLEAR_TASK_BUILDING'
export const GET_TASK_BUILDING_REQUEST =
  'TASK_BUILDING/GET_TASK_BUILDING_REQUEST'
export const GET_TASK_BUILDING_SUCCESS =
  'TASK_BUILDING/GET_TASK_BUILDING_SUCCESS'
export const GET_TASK_BUILDING_FAIL = 'TASK_BUILDING/GET_TASK_BUILDING_FAIL'
export const GET_UPCOMING_TASK_BUILDING_REQUEST =
  'TASK_BUILDING/GET_UPCOMING_TASK_BUILDING_REQUEST'
export const GET_UPCOMING_TASK_BUILDING_SUCCESS =
  'TASK_BUILDING/GET_UPCOMING_TASK_BUILDING_SUCCESS'
export const GET_UPCOMING_TASK_BUILDING_FAIL =
  'TASK_BUILDING/GET_UPCOMING_TASK_BUILDING_FAIL'
export const UPDATE_TASK_BUILDING_REQUEST =
  'TASK_BUILDING/UPDATE_TASK_BUILDING_REQUEST'
export const UPDATE_TASK_BUILDING_SUCCESS =
  'TASK_BUILDING/UPDATE_TASK_BUILDING_SUCCESS'
export const UPDATE_TASK_BUILDING_FAIL =
  'TASK_BUILDING/UPDATE_TASK_BUILDING_FAIL'
export const CREATE_TASK_BUILDING_REQUEST =
  'TASK_BUILDING/CREATE_TASK_BUILDING_REQUEST'
export const CREATE_TASK_BUILDING_SUCCESS =
  'TASK_BUILDING/CREATE_TASK_BUILDING_SUCCESS'
export const CREATE_TASK_BUILDING_FAIL =
  'TASK_BUILDING/CREATE_TASK_BUILDING_FAIL'
export const DELETE_TASK_BUILDING_REQUEST =
  'TASK_BUILDING/DELETE_TASK_BUILDING_REQUEST'
export const DELETE_TASK_BUILDING_SUCCESS =
  'TASK_BUILDING/DELETE_TASK_BUILDING_SUCCESS'
export const DELETE_TASK_BUILDING_FAIL =
  'TASK_BUILDING/DELETE_TASK_BUILDING_FAIL'
export const UPDATE_SELECTED_TASK = 'TASK_BUILDING/UPDATE_SELECTED_TASK'
// ------------------------------------
// Actions
// ------------------------------------

export const clearTaskBuilding = () => {
  return async (dispatch, getState) => {
    dispatch({ type: CLEAR_TASK_BUILDING })
  }
}

export const getTaskBuilding = (
  { organizationId, buildingId, status, assignee, filter },
  forceUpdateSelectedTask
) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_TASK_BUILDING_REQUEST })
    const api = new ApiClient(dispatch, getState())
    const params = {}
    if (status) params.status = status
    if (assignee) params.assignee = assignee
    if (filter) params.filter = filter
    try {
      const data = await api.get(
        `/organization/${organizationId}/task-list/task-building/${buildingId}`,
        {
          params
        }
      )
      dispatch({
        type: GET_TASK_BUILDING_SUCCESS,
        payload: data,
        forceUpdateSelectedTask
      })
      dispatch({
        type: UPDATE_BUILDING_VIEW_TASKS_LISTS,
        payload: data
      })
    } catch (error) {
      dispatch({ type: GET_TASK_BUILDING_FAIL, payload: error })
    }
  }
}

export const getUpcomingTasks = (
  { organizationId, buildingId, status },
  forceUpdateSelectedTask
) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_UPCOMING_TASK_BUILDING_REQUEST })
    const api = new ApiClient(dispatch, getState())
    const params = {}
    if (status) params.status = status
    try {
      const data = await api.get(
        `/organization/${organizationId}/task-list/task-building/${buildingId}/upcoming`,
        {
          params
        }
      )
      dispatch({
        type: GET_UPCOMING_TASK_BUILDING_SUCCESS,
        payload: data,
        forceUpdateSelectedTask
      })
    } catch (error) {
      dispatch({ type: GET_UPCOMING_TASK_BUILDING_FAIL, payload: error })
    }
  }
}

export const updateTaskBuilding = ({
  organizationId,
  buildingId,
  taskListBuildingId,
  payload
}) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_TASK_BUILDING_REQUEST })
    const api = new ApiClient(dispatch, getState())
    try {
      const data = await api.put(
        `/organization/${organizationId}/task-list/task-building/${buildingId}/${taskListBuildingId}`,
        {
          data: payload
        }
      )
      dispatch({ type: UPDATE_TASK_BUILDING_SUCCESS, payload: data })
      dispatch(updatePortfolioReload(true))
      dispatch(flash(data.message, 'success', 2))
    } catch (error) {
      dispatch({ type: UPDATE_TASK_BUILDING_FAIL, payload: error })
      dispatch(flash(error[0].message, 'error', 5))
    }
  }
}

export const createTaskBuilding = ({
  organizationId,
  buildingId,
  taskListId
}) => {
  return async (dispatch, getState) => {
    dispatch({ type: CREATE_TASK_BUILDING_REQUEST })
    const api = new ApiClient(dispatch, getState())
    try {
      const data = await api.post(
        `/organization/${organizationId}/task-list/task-building/${buildingId}/${taskListId}`
      )
      dispatch({ type: CREATE_TASK_BUILDING_SUCCESS, payload: data })
      dispatch(updatePortfolioReload(true))
      dispatch(flash(data.message, 'success', 2))
    } catch (error) {
      dispatch({ type: CREATE_TASK_BUILDING_FAIL, payload: error })
      dispatch(flash(error[0].message, 'error', 5))
    }
  }
}

export const deleteTaskBuilding = ({
  organizationId,
  buildingId,
  taskListBuildingId
}) => {
  return async (dispatch, getState) => {
    dispatch({ type: DELETE_TASK_BUILDING_REQUEST })
    const api = new ApiClient(dispatch, getState())
    try {
      const data = await api.del(
        `/organization/${organizationId}/task-list/task-building/${buildingId}/${taskListBuildingId}`
      )
      dispatch({ type: DELETE_TASK_BUILDING_SUCCESS, payload: data })
      dispatch(updatePortfolioReload(true))
      dispatch(flash(data?.message, 'success', 2))
    } catch (error) {
      dispatch({ type: DELETE_TASK_BUILDING_FAIL, payload: error })
      dispatch(flash(error[0]?.message, 'error', 5))
    }
  }
}

export const cloneTaskListFromBuilding = ({
  organizationId,
  buildingId,
  taskListBuildingId,
  taskListId
}) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const api = new ApiClient(dispatch, getState())
      api
        .get(
          `/organization/${organizationId}/task-list/${taskListId}/task-building/${buildingId}/${taskListBuildingId}`
        )
        .then(response => {
          const payload = response
          dispatch(flash(response.message, 'success', 2))
          dispatch({ type: UPDATE_TASK_BUILDING_SUCCESS, payload })
          dispatch({ type: GET_TASK_LIST_BY_ID_SUCCESS, payload })
          dispatch(updatePortfolioReload(true))
          resolve(response.taskList)
        })
        .catch(err => {
          dispatch(flash(err || 'Issues generating tasklist', 'error', 5))
          reject(err)
        })
    })
  }
}

// ------------------------------------
// Action Helpers
// ------------------------------------

const handleClearTaskBuilding = (state, action) => {
  return {
    ...initialState,
    hardReloadPortfolioTasks: state.hardReloadPortfolioTasks
  }
}

const handleGetTaskBuildingsRequest = (state, action) => {
  return {
    ...state,
    taskBuildings: {
      ...state.taskBuildings,
      loading: true,
      error: undefined
    }
  }
}

const handleGetTaskBuildingsFail = (state, action) => {
  return {
    ...state,
    taskBuildings: {
      ...state.taskBuildings,
      loading: false,
      error: action.payload
    }
  }
}

const handleGetTaskBuildingsSuccess = (state, action) => {
  return {
    ...state,
    taskBuildings: {
      ...state.taskBuildings,
      loading: false,
      data: action.payload.taskBuildings,
      forceUpdate: false
    },
    updateSelectedTask: action.forceUpdateSelectedTask
  }
}
const handleGetUpcomingTasksRequest = (state, action) => {
  return {
    ...state,
    upcomingTasks: {
      ...state.upcomingTasks,
      loading: true,
      error: undefined
    }
  }
}

const handleGetUpcomingTasksFail = (state, action) => {
  return {
    ...state,
    upcomingTasks: {
      ...state.upcomingTasks,
      loading: false,
      error: action.payload
    }
  }
}

const handleGetUpcomingTasksSuccess = (state, action) => {
  return {
    ...state,
    upcomingTasks: {
      ...state.upcomingTasks,
      loading: false,
      data: action.payload.taskBuildings
    },
    updateSelectedTask: action.forceUpdateSelectedTask
  }
}
const handleUpdateTasksRequest = (state, action) => {
  return {
    ...state,
    taskBuildings: {
      ...state.taskBuildings,
      loading: true,
      error: undefined,
      forceUpdate: false
    },
    upcomingTasks: {
      ...state.upcomingTasks,
      loading: true,
      error: undefined
    }
  }
}

const handleUpdateTasksFail = (state, action) => {
  return {
    ...state,
    taskBuildings: {
      ...state.taskBuildings,
      loading: false,
      error: action.payload,
      forceUpdate: false
    },
    upcomingTasks: {
      ...state.upcomingTasks,
      loading: false,
      error: action.payload
    }
  }
}

const handleUpdateTasksSuccess = (state, action) => {
  return {
    ...state,
    taskBuildings: {
      ...state.taskBuildings,
      loading: false,
      error: undefined,
      forceUpdate: true
    },
    upcomingTasks: {
      ...state.upcomingTasks,
      loading: false,
      error: undefined
    },
    hardReloadPortfolioTasks: new Date()
  }
}
const handleCreateTaskBuildingRequest = (state, action) => {
  return {
    ...state,
    taskBuildings: {
      ...state.taskBuildings,
      loading: true,
      error: undefined,
      forceUpdate: false
    }
  }
}

const handleCreateTaskBuildingFail = (state, action) => {
  return {
    ...state,
    taskBuildings: {
      ...state.taskBuildings,
      loading: false,
      error: action.payload,
      forceUpdate: false
    }
  }
}

const handleCreateTaskBuildingSuccess = (state, action) => {
  return {
    ...state,
    taskBuildings: {
      ...state.taskBuildings,
      loading: false,
      error: undefined,
      forceUpdate: true
    },
    hardReloadPortfolioTasks: new Date()
  }
}

const handleDeleteTaskBuildingRequest = (state, action) => {
  return {
    ...state,
    taskBuildings: {
      ...state.taskBuildings,
      loading: true,
      error: undefined,
      forceUpdate: false
    },
    upcomingTasks: {
      ...state.upcomingTasks,
      loading: true,
      error: undefined
    }
  }
}

const handleDeleteTaskBuildingFail = (state, action) => {
  return {
    ...state,
    taskBuildings: {
      ...state.taskBuildings,
      loading: false,
      error: action.payload,
      forceUpdate: false
    },
    upcomingTasks: {
      ...state.upcomingTasks,
      loading: false,
      error: action.payload
    }
  }
}

const handleDeleteTaskBuildingSuccess = (state, action) => {
  return {
    ...state,
    taskBuildings: {
      ...state.taskBuildings,
      loading: false,
      error: undefined,
      forceUpdate: true
    },
    upcomingTasks: {
      ...state.upcomingTasks,
      loading: false,
      error: undefined
    },
    hardReloadPortfolioTasks: new Date()
  }
}

export const handleUpdateSelectedTask = () => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_SELECTED_TASK
    })
  }
}

const updateSelectedTask = state => {
  return {
    ...state,
    updateSelectedTask: false
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CLEAR_TASK_BUILDING]: handleClearTaskBuilding,
  [GET_TASK_BUILDING_REQUEST]: handleGetTaskBuildingsRequest,
  [GET_TASK_BUILDING_SUCCESS]: handleGetTaskBuildingsSuccess,
  [GET_TASK_BUILDING_FAIL]: handleGetTaskBuildingsFail,
  [GET_UPCOMING_TASK_BUILDING_REQUEST]: handleGetUpcomingTasksRequest,
  [GET_UPCOMING_TASK_BUILDING_SUCCESS]: handleGetUpcomingTasksSuccess,
  [GET_UPCOMING_TASK_BUILDING_FAIL]: handleGetUpcomingTasksFail,
  [UPDATE_TASK_BUILDING_REQUEST]: handleUpdateTasksRequest,
  [UPDATE_TASK_BUILDING_SUCCESS]: handleUpdateTasksSuccess,
  [UPDATE_TASK_BUILDING_FAIL]: handleUpdateTasksFail,
  [CREATE_TASK_BUILDING_REQUEST]: handleCreateTaskBuildingRequest,
  [CREATE_TASK_BUILDING_SUCCESS]: handleCreateTaskBuildingSuccess,
  [CREATE_TASK_BUILDING_FAIL]: handleCreateTaskBuildingFail,
  [DELETE_TASK_BUILDING_REQUEST]: handleDeleteTaskBuildingRequest,
  [DELETE_TASK_BUILDING_SUCCESS]: handleDeleteTaskBuildingSuccess,
  [DELETE_TASK_BUILDING_FAIL]: handleDeleteTaskBuildingFail,
  [UPDATE_SELECTED_TASK]: updateSelectedTask
}
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  taskBuildings: {
    loading: false,
    error: undefined,
    forceUpdate: false,
    data: []
  },
  upcomingTasks: {
    loading: false,
    error: undefined,
    data: []
  },
  updateSelectedTask: false,
  hardReloadPortfolioTasks: false
}

export default function taskBuildingReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
