import features from 'utils/Feature/config'

export const LAYOUTS = {
  OneColumn: 'One Column',
  TwoColumns: 'Two Columns'
}

export const ACTIVE_COLUMN = {
  Column1: '0',
  Column2: '1'
}

export const CHART_TYPES = {
  Trend: 'Trend',
  Breakdown: 'Breakdown',
  Savings: 'Savings'
}

export const CHART_SETTINGS = {
  Format: 'format',
  YAxisPrimary: 'yAxisPrimary',
  Baseline: 'baseline',
  Target: 'target',
  YAxisSecondary: 'yAxisSecondary',
  Time: 'time',
  Wedges: 'wedges',
  WedgeData: 'wedgeData',
  WedgeValue: 'wedgeValue',
  SavingsFrom: 'savingsFrom',
  Metric: 'metric',
  LegendPosition: 'legendPosition',
  Color: 'color'
}

export const PROPOSED = {
  Proposed: 'proposed'
}

export const CHART_FORMAT = {
  StackedBar: 'StackedBar',
  Bar: 'Bar',
  Line: 'Line',
  Donut: 'Donut',
  Pie: 'Pie',
  TreeMap: 'TreeMap'
}

export const WEDGE_VALUE = {
  Value: 'Value',
  Percentage: 'Percentage'
}

export const WEDGE_DATA = {
  EstimatedPercentage: 'EstimatedPercentage',
  UsagePercentage: 'UsagePercentage'
}

export const LEGEND_POSITION = {
  Bottom: 'bottom',
  Right: 'right',
  Top: 'top',
  Left: 'left'
}

export const Y_AXIS_PRIMARY_FIRST_LEVEL = {
  UsageAndCost: 'UsageAndCost',
  UsageAndCostIntensity: 'UsageAndCostIntensity',
  GHGEmissions: 'GHGEmissions'
}

export const Y_AXIS_PRIMARY_SECOND_LEVEL = {
  All: 'All',
  EnergyUseIntensityEUI: 'EnergyUseIntensityEUI',
  WaterUseIntensityWUI: 'WaterUseIntensityWUI',
  EnergyCostIntensityECI: 'EnergyCostIntensityECI',
  WaterCostIntensityWCI: 'WaterCostIntensityWCI',
  GHGEmissions: 'GHGEmissions',
  GHGEmissionsIntensity: 'GHGEmissionsIntensity'
}

export const Y_AXIS_PRIMARY_THIRD_LEVEL = {
  Use: 'Use',
  Cost: 'Cost',
  Demand: 'Demand',
  DemandCost: 'DemandCost',
  EnergyUse: 'EnergyUse',
  EnergyCost: 'EnergyCost'
}

export const Y_AXIS_SECONDARY = {
  Hide: 'Hide',
  AverageTemperature: 'AverageTemperature',
  Demand: 'Demand',
  CoolingDegreeDays: 'CoolingDegreeDays',
  HeatingDegreeDays: 'HeatingDegreeDays',
  CoolingAndHeatingDegreeDays: 'CoolingAndHeatingDegreeDays'
}

export const WEDGES_FIRST_LEVEL = {
  UsageAndCosts: 'UsageAndCosts',
  GHGEmissions: 'GHGEmissions',
  EstimatedEndUsesAndCosts: 'EstimatedEndUsesAndCosts',
  ASHRAELevelIIAnalysis: 'ASHRAELevelIIAnalysis'
}

export const WEDGES_SECOND_LEVEL = {
  UtilityCosts: 'UtilityCosts',
  EnergyUses: 'EnergyUses',
  EnergyCosts: 'EnergyCosts',
  GHGEmissions: 'GHGEmissions',
  Energy: 'Energy',
  Electricity: 'Electricity',
  NaturalGas: 'NaturalGas',
  Water: 'Water',
  EnergyEndUses: 'EnergyEndUses',
  ElectricityEndUses: 'ElectricityEndUses',
  NaturalGasEndUses: 'NaturalGasEndUses'
}

export const WEDGES_THIRD_LEVEL = {
  Use: 'Use',
  Cost: 'Cost'
}

export const SAVINGS_FROM = {
  Measures: 'Measures'
}

export const METRIC_FIRST_LEVEL = {
  ASHRAELevelIIAnalysis: 'ASHRAELevelIIAnalysis'
}

export const METRIC_SECOND_LEVEL = {
  EnergyUseIntensityEUI: 'EnergyUseIntensityEUI',
  EnergyCostIntensityECI: 'EnergyCostIntensityECI',
  EnergyEndUses: 'EnergyEndUses',
  ElectricityEndUses: 'ElectricityEndUses',
  NaturalGasEndUses: 'NaturalGasEndUses',
  GHGEmissions: 'GHGEmissions',
  GHGEmissionsIntensity: 'GHGEmissionsIntensity'
}

export const BASELINE = {
  Hide: 'Hide',
  Bar: 'Bar',
  Line: 'Line'
}

export const TARGET = {
  Hide: 'Hide',
  Line: 'Line'
}

export const TIME = {
  Monthly: 'Monthly',
  Annual: 'Annual'
}

export const CHART_ERROR = {
  NoUtilities: 'noUtilities',
  NoMeasures: 'noMeasures',
  NoMeasuresFound: 'noMeasuresFound',
  NoProjects: 'noProjects',
  NoProjectsFound: 'noProjectsFound'
}

export const chartErrorMessages = {
  [CHART_ERROR.NoUtilities]: {
    title: 'Add a Building with Utility Bills',
    description: 'Navigate to a building and add utilities'
  },
  [CHART_ERROR.NoMeasures]: {
    title: 'Create Your First Measure',
    description: 'Navigate to a building and add a measure'
  },
  [CHART_ERROR.NoMeasuresFound]: {
    title: 'No Measures Found',
    description: 'Update your filters and try again'
  },
  [CHART_ERROR.NoProjects]: {
    title: 'Create Your First Project',
    description: 'Navigate to a building and add a project'
  },
  [CHART_ERROR.NoProjectsFound]: {
    title: 'No Projects Found',
    description: 'Update your filters and try again'
  }
}

export const DEFAULT_PORTFOLIO_DASHBOARD_CHART_SETTINGS = {
  type: CHART_TYPES.Trend,
  yAxisPrimary: {
    label: 'Usage & Cost',
    value: Y_AXIS_PRIMARY_FIRST_LEVEL.UsageAndCost,
    options: {
      label: 'All',
      value: Y_AXIS_PRIMARY_SECOND_LEVEL.All,
      options: {
        label: 'Energy Use',
        value: Y_AXIS_PRIMARY_THIRD_LEVEL.EnergyUse
      }
    }
  },
  baseline: {
    label: features.dashboardBaseline ? 'Show Baseline' : 'Hide Baseline',
    value: features.dashboardBaseline ? BASELINE.Bar : BASELINE.Hide
  },
  format: {
    label: 'Stacked Bar',
    value: CHART_FORMAT.StackedBar
  },
  time: {
    label: 'Annual',
    value: TIME.Annual
  }
}
